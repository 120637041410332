import React from 'react';
import {View, StyleSheet} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyles from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

interface CriteriaCheckMarkTypes {
  value: boolean,
  text: string,
  icon: string,
  color: string
}

const CommonCriteriaCheckMark = ({value, text, icon, color}: CriteriaCheckMarkTypes) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  return(
    <>
      <Icon name={icon} size={15} color={value ? color : GlobalStyles.global.grey}/>
      <View style={{marginLeft: PR(10)}}>
        <Text style={styles.txt}>{text}</Text>
      </View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    txt: {
      fontSize: PR(14),
      color: colors.primary,
    }
  })
}

export default CommonCriteriaCheckMark;