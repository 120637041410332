import { DateTime } from 'luxon';

const parseHours = hours => {
    const parsed = DateTime.fromFormat(hours, "HH:mm");
    if (parsed.invalid) {
        return null;
    }

    return parsed;
}

const parseDate = date => {
    const parsed = DateTime.fromISO(date);
    if (parsed.invalid) {
        return null;
    }
    return parsed;
}

const calculateMedicationAdherence = (eventDates: any[]) => {
    let _intakes = 0;
    let _taken = 0;

    const now = DateTime.local();
    eventDates
        .filter(date => Array.isArray(date.reminders) && date.reminders.length > 0)
        .forEach(function (day) {
            for (let index = 0; day.reminders[index]; index++) {
                let medication = day.reminders[index];
                let _date = parseDate(medication.date);
                if (!_date) {
                    continue;
                }

                if (medication.time) {
                    const time = parseHours(medication.time);
                    if (time) {
                        _date = _date.set({ hour: time.hour, minute: time.minute })
                    }
                }
                if (_date > now)
                    continue;
                if (medication.taken)
                    _taken++;
                _intakes++;
            }
        })
    return _intakes > 0 ? Math.round((_taken / _intakes) * 100) : 0;
}

export default calculateMedicationAdherence;
