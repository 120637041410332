import React, { useState, useEffect } from 'react';
import {View, ScrollView, SafeAreaView, StyleSheet, Platform} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonSelect from 'common/src/components/CommonSelect';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonFooter from 'common/src/components/CommonFooter';
import {useTranslation} from 'react-i18next';
import { getTimeZones } from "@vvo/tzdb";
import {checkBottomScroll} from 'common/src/services/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateTimezoneAction } from 'common/src/store/timezones/changeTimezoneActions';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { APP_SHORTCUT_NAME } from 'common/src/env.json';
import Loading from 'common/src/components/Loading';

const Timezone = ({navigation}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const {i18n, t} = useTranslation();
    const lang = i18n.language;
    const dispatch = useDispatch();
    const getMeRes = useSelector((state:any) => state.getMe.response);
    const [scrollState, setScrollState] = useState<number>(0);
    const [contentHeight,] = useState<number>(5000);
    const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
    const [selectedTimezone, setSelectedTimeZone] = useState<string>(getMeRes?.result?.settings?.timezone || '')
    const [timezoneList, setTimezoneList] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const timeZones = getTimeZones();
    let foundTz = timeZones.find(tz => tz.name === selectedTimezone || tz.group.includes(selectedTimezone))

    useEffect(() => {
        if(timezoneList.length === 0)
            getTimeZonesFunc()
    },[])
    
    const getTimeZonesFunc = () => {
        let tempArrTimeZone:any = []
        let tempItem = {};
        timeZones.sort((a,b) => (a.rawOffsetInMinutes > b.rawOffsetInMinutes) ? 1 : ((b.rawOffsetInMinutes > a.rawOffsetInMinutes) ? -1 : 0))
        timeZones.forEach((item) => {
            if(preSelected(item.name)){
                tempItem = {label:'('+'GMT'+item.rawFormat.slice(0, 6)+') '+item.name, value:item.name, selected: true}
            } else {
                tempArrTimeZone.push({label:'('+'GMT'+item.rawFormat.slice(0, 6)+') '+item.name, value:item.name, selected: false})
            }
        })
        tempArrTimeZone.unshift(tempItem)
        setTimezoneList(tempArrTimeZone)
    }
    const preSelected = (value:string) => {
        if(value === foundTz?.name){
            return true
        }
      }
    
    const updateTimezone = (data) => {
        data.map((item:any) => {
            if(item.selected !== undefined && item.selected){
                setSelectedTimeZone(item.value)
            }
        })
    }

    const save = () => {
        setIsLoading(true);
        const dataToUpdate = {
            dataToUpdate:{
                settings:{
                    language: lang,
                    timezone: selectedTimezone
                }
            },
            callback: () => {
                navigation.goBack();
                setIsLoading(false);
            },
            errorCallback: () => {
                setIsLoading(false);
            }
        }
        dispatch(updateTimezoneAction(dataToUpdate))
    }

    if(isLoading)
        return <Loading netConnected={true} />

    return (
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="timezone"
                title={t('translation:general.page_title.timezone')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles[APP_SHORTCUT_NAME].color}
                leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
                scroll={scrollState}
            />
            <ScrollView
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    let calc = checkBottomScroll(event.nativeEvent, 0);
                    if (!scrollAtBottom && calc)
                        setScrollAtBottom(true)
                    else if (scrollAtBottom && !calc)
                        setScrollAtBottom(false)

                    if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
                        setScrollState(event.nativeEvent.contentOffset.y);
                    else if(scrollState > 0 && event.nativeEvent.contentOffset.y <= 0)
                        setScrollState(0);
                }}
            >
                <View 
                    style={styles.wrapper}
                    onLayout={(event: any) => {
                        // setContentHeight(event.nativeEvent.layout.height)
                      }}
                >
                    <CommonSelect type={'radio'} data={timezoneList} onSelect={updateTimezone}/>
                </View>
            </ScrollView>
            <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
                <CommonBtn type="primary" title={t('translation:general.header.btn_save')} click={() => {save()}}/>
            </CommonFooter>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            backgroundColor: colors.background,
            flex: 1,
        },
        wrapper: {
            width:'90%',
            alignSelf:'center',
            marginTop: PR(20),
            marginBottom: PR(20)
        }
    })
};

export default Timezone;

