import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CommonTimelineGraphs from '../CommonTimelineGraphs';
import { useTranslation } from 'react-i18next';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import cmsFile from 'common/src/services/localization/en/en.json'
import {APP_SHORTCUT_NAME} from '../../env.json';
import {convertLangCode} from 'common/src/services/translationList';
import moment from 'moment';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import {
    defineStartIndex, 
    isConnectedToHCP, 
    defineSeizureTypeOtherColor,
    displayHeadacheTypeName,
    defineHeadacheTypeColor
} from '../../services/utils';
import Loading from 'common/src/components/Loading';
import { getImpactEvents } from 'common/src/store/events/eventActions';

const colors = cmsFile["list.headache_colors"]
let otherColors = cmsFile["list.otherSeizureColors"]
let max = 1;

type OwnProps = {
    Me: MeTypes,
    Events: Record<string,any>[]
}

interface MeTypes {
    patientData: Record<string,any>,
    result: Record<string,any>
}

const CommonImpactHeadachesGraph = ({Me, Events}: OwnProps) => {
    const colors = useTheme().colors;
    const {i18n, t} = useTranslation();
    const lang = convertLangCode(i18n.language);
    const [Wseizures, setSeizures] = useState([] as any);
    const [seizureTypes, setSeizureTypes] = useState([] as any);
    const [otherColorsMapping, setOtherColorsMapping] = useState([] as any);
    const [weekArray, setWeekArray] = useState<any[]>([]);
    const [currentWeek, setCurrentWeek] = useState(moment().week());
    const firstWeek = currentWeek - 5;
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        let newArray: any = [];
        for(let i=0; i <= 5; i++){
            newArray.push({
                date: moment().week(firstWeek+i).startOf('isoWeek').format(),
                seizures: []
            })
        }
        setWeekArray(newArray)
        groupTheEvents(newArray)
    }, [currentWeek, Events])
    
    const groupTheEvents = (tempArray) => {
        let tempWeekArray:any = [...tempArray]
        if(Events.length > 0){
            Events.forEach(seizure => {
                let weekIndex = tempWeekArray.findIndex(item => moment(item.date).startOf('isoWeek') <= moment(seizure.date) && moment(item.date).endOf('isoWeek') >= moment(seizure.date))
                if(weekIndex > -1)
                    tempWeekArray[weekIndex].seizures.push(seizure)
            })
            setWeekArray(tempWeekArray)
        }
    }

    useEffect(() => {
        max = 0;
        sortBySeizure();
    }, [weekArray])

    const sortBySeizure = () => {
        const _seizures: any = [];
        const _seizureTypes: any = [];
        const _otherColors: any = isConnectedToHCP(Me) ? null : otherColorsMapping;

        let colorIndex = defineStartIndex(otherColorsMapping, otherColors);
        if (colorIndex >= otherColors.length - 1)
            colorIndex = 0;
        weekArray.forEach((day: any, seizureIndex) => {
            _seizures.push({
                date: day.date,
                seizureTypes: []
            })
            if(day.seizures.length > max)
                max = day.seizures.length;

            day.seizures.forEach((seizure: any) => {
                let existsForDate = _seizures[seizureIndex].seizureTypes.find((item: any) => item.name === seizure.name)
                const existsInGlobal = _seizureTypes.find((item: any) => item.name === seizure.name)
                if (!existsInGlobal) {
                    let seizureTypeColor = defineHeadacheTypeColor(seizure.name, seizure.name, Me.patientData, colors)
                    if (!seizureTypeColor) {
                        if (!_otherColors)
                            seizureTypeColor = GlobalStyles.global.customTypeGrey;
                        else {
                            let otherTypeColor = defineSeizureTypeOtherColor(seizure.name, otherColorsMapping);
                            if (otherTypeColor === -1) {
                                seizureTypeColor = otherColors[colorIndex]['other_' + (colorIndex + 1)]
                                _otherColors.push({
                                    color: seizureTypeColor,
                                    type: seizure.name
                                })
                                colorIndex = colorIndex >=  otherColors.length - 1 ? 0 : colorIndex + 1;
                            }
                            else
                                seizureTypeColor = otherColorsMapping[otherTypeColor].color;
                        }
                    }
                    _seizureTypes.push({name: seizure.name, color: seizureTypeColor})
                    _seizures[seizureIndex].seizureTypes.push({date: day.date, name: seizure.name, color: seizureTypeColor, count: 1})
                }
                else if (!existsForDate){
                    _seizures[seizureIndex].seizureTypes.push({date: day.date, name: seizure.name, color: existsInGlobal.color, count: 1})
                }
                else
                    existsForDate.count++
            })
        })
        setSeizures(_seizures)
        setSeizureTypes(_seizureTypes);
        if (_otherColors && otherColorsMapping.length !== _otherColors.length) {
            setOtherColorsMapping(_otherColors)
        }
    }  

    const loadPrev = () => {
        const from = moment().week(firstWeek-1).startOf('isoWeek').format();
        const to = moment().week(firstWeek-1).endOf('isoWeek').format()
        setIsLoading(true)
        const obj = {
            date: {
                from,
                to
            },
            event_type: 'headache'
        }
        dispatch(getImpactEvents({obj, callback: () => {
            setIsLoading(false)
        }}));
        setCurrentWeek(currentWeek-1)
    }
    
    const loadNext = () => {
        setCurrentWeek(currentWeek+1)
    }

    if (isLoading)
        return <Loading netConnected={true}/>

    return(
        <View style={{flexDirection:'column', width:'100%'}}>
            <Text style={{textAlign: 'center'}}>{t('translation:programs.impact.headache.title')}</Text>
            <Text style={styles.bottomPlaceholder}>{t('translation:programs.impact.headache.subtitle')}</Text>
            <View style={styles.seizureImpactContainer}>
                <TouchableOpacity onPress={loadPrev} style={{position:'absolute', top:PR(55), left:-20}}>
                    <Icon 
                        name={'chevron-left'} 
                        size={PR(25)} 
                        color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} 
                    />
                </TouchableOpacity>
                <View style={[{opacity:0.5, position:'absolute', left:PR(-42), flexDirection: 'column', width: PR(40), zIndex:-1}]}>
                    <CommonTimelineGraphs 
                        day = {{seizureTypes:[], date:''}}
                        max = {max}
                        seizureCount = {0}
                    />
                </View>
                {
                    Wseizures.slice(0,6).map((day, index) => {
                        let seizureCount = day.seizureTypes.length > 0 ? day.seizureTypes.reduce((x, y) => x + y.count, 0) : 0;
                        return (
                            <View key={index} style={styles.seizureImpactBar}>
                                <CommonTimelineGraphs 
                                    day = {day}
                                    max = {max}
                                    seizureCount = {seizureCount}
                                    impactLabels={moment(day.date).locale(lang || 'en').format('DD MMM') +'\n' +moment(day.date).endOf('isoWeek').locale(lang || 'en').format('DD MMM')}
                                />
                            </View>
                        )
                    })
                }
                {currentWeek !== moment().week() &&
                    <View style={[{opacity:0.5, position:'absolute', right:PR(-42), flexDirection: 'column', width: PR(40), zIndex:-1}]}>
                        <CommonTimelineGraphs 
                            day = {{seizureTypes:[], date:''}}
                            max = {max}
                            seizureCount = {0}
                        />
                    </View>
                }
                <TouchableOpacity 
                    onPress={loadNext} 
                    style={{position:'absolute', top:PR(55), right:-20}} 
                    disabled={currentWeek === moment().week()}
                >
                    <Icon 
                        name={'chevron-right'} 
                        size={PR(25)} 
                        color={currentWeek !== moment().week() ? GlobalStyles[APP_SHORTCUT_NAME].btnColor : GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor} 
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.legendContainer}>
                {
                    seizureTypes.map((seizure: any, index: number) => (
                        <View key={index} style={styles.legendItem}>
                            <View style={[styles.legendDot, {backgroundColor: seizure.color}]}></View>
                            <Text style={[styles.legendText, {color: colors.primary}]}>{displayHeadacheTypeName(seizure.name, cmsFile["list.headache_names_1_9"], t)}</Text>
                        </View>
                    ))
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    seizureImpactContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: PR(10)
    },
    seizureImpactBar: {
        flex: 1,
        flexDirection: 'column'
    },
    bottomPlaceholder: {
        marginTop:PR(12),
        fontSize: PR(12),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        color: GlobalStyles.global.grey,
        textAlign:'center'
    },
    legendContainer: {
        flexDirection: 'column',
        marginTop: PR(10)
    },
    legendItem: {
        flex: 1,
        flexDirection: 'row',
        padding: PR(10),
    },
    legendDot: {
        borderRadius: PR(10), 
        width: PR(15), 
        height: PR(15)
    },
    legendText: {
        marginLeft: PR(15),
    }
});

export default CommonImpactHeadachesGraph;