import React, {useEffect, useState} from "react";
import {View, StyleSheet, SafeAreaView, ScrollView} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from "common/src/components/CommonHeader";
import CommonFooter from "common/src/components/CommonFooter";
import CommonBtn from "common/src/components/CommonBtn";
import GlobalStyles from "common/src/styles/GlobalStyles";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from 'common/src/store/reducers';
import CommonSettingsBtn from "common/src/components/CommonSettingsBtn";
import { getRescueAction } from "common/src/store/treatments/rescueActions";
import { RescueObject } from "common/src/store/treatments/rescueReducer";

const AddRescueMedication = ({navigation}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const rescueMedications = useSelector((state: RootState) => state.rescueReducer.rescueMedications);
    const ProfileResponse = useSelector((state:any) => state.getMe.response);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [scrollState, setScrollState] = useState<number>(0);
    const [rescueMeds, setRescueMeds] = useState<RescueObject[]>([]);

    useEffect(() => {
        dispatch(getRescueAction({
            patientID: ProfileResponse?.result?.patients?.id,
            callback: (res) => {
                setRescueMeds(res)
            },
            errorCallback: () => {
            }
        }))
    }, [])

    useEffect(() => {
        setRescueMeds(rescueMedications)
    }, [rescueMedications])
    
    const setUpdateClick = (res) => {
        navigation.navigate('AddEmergencyMedication', {medicationDetails:res})
    }

    return(
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="RescueMedicationTitle"
                title={t('translation:headache_event.manageEmergencyMedication.header')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.migraine.color}
                leftClick={() => {navigation.goBack()}}
                scroll={scrollState}
            />
            <ScrollView
                testID="AddMedication_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
                        setScrollState(event.nativeEvent.contentOffset.y);
                    else if(scrollState > 0 && event.nativeEvent.contentOffset.y === 0)
                        setScrollState(0);
                }}
            >
                <CommonSettingsBtn 
                    listNames={
                        rescueMeds?.map(item => {
                            return(
                                {
                                    name: item.name,
                                    value: item
                                }
                            )
                        }
                    )} 
                    clicked={setUpdateClick}
                />
            </ScrollView>
            <CommonFooter>
                <CommonBtn testID={"AddNewTreatmentBtnID"} type={'primary'} title={t('translation:headache_event.emergencyMedications.listing.add')} click={() => {navigation.navigate('AddEmergencyMedication', {medicationDetails: {}})}}/>
            </CommonFooter>
        </SafeAreaView>
    )
};

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
      container: {
        backgroundColor: colors.background,
        flex: 1,
      },
    })
}

export default AddRescueMedication;