import React, {useState, useEffect, ReactComponentElement, ReactElement} from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import { useTheme } from '@react-navigation/native';
import {APP_SHORTCUT_NAME} from '../env.json';
import ToggleSwitch from 'toggle-switch-react-native';
import { View } from 'react-native';

/**
 * for more parameters of the toggle-switch-react-native
 * please visit the https://github.com/aminebenkeroum/toggle-switch-react-native#api
 */

interface SwitchTypes {
  isOn: boolean | undefined,
  size: 'large' | 'medium' | 'small',
  onToggle: any,
  testID: string
  icon?: ReactElement
  onColor?: string,
  offColor?: string
}

const CommonSwitchBtn = ({isOn, size, onToggle, testID, icon, onColor, offColor}:SwitchTypes) => {
  const { colors } = useTheme();
  const [isEnabled, setIsEnabled] = useState(isOn)
  useEffect(() => {
    setIsEnabled(isOn)
  },[isOn])
  const sendBackStatus = (status) => {
    onToggle(status)
  }
  return (
    <View testID={testID}>
      <ToggleSwitch
        isOn={isEnabled || false}
        onColor={onColor ?? GlobalStyles[APP_SHORTCUT_NAME].color}
        offColor={offColor ?? colors.inputBackgroundError}
        size={size}
        onToggle={status => sendBackStatus(status)}
        icon={icon}
      />
    </View>
  )
}

export default CommonSwitchBtn;