import React, {useReducer, useState} from 'react';
import { ScrollView, StyleSheet, Platform, View} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonPwdCriteria from 'common/src/components/CommonPwdCriteria';
import InputField from 'common/src/components/InputField';
import CommonFooter from 'common/src/components/CommonFooter';
import {isSamePassword} from 'common/src/services/utils';
import { useDispatch } from 'react-redux';
import { changePasswordAction } from 'common/src/store/actions/authenticationActions';
import {ChangePdwTypes} from '../components/interfaces';
import {useTranslation} from 'react-i18next';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const ChangeMyPdw = (props:any) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [scrollState, setScrollState] = useState();
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<boolean>(false);
  const [pdwDetails, setPdwDetails] = useReducer(
    (state:ChangePdwTypes, newState:ChangePdwTypes) => ({...state, ...newState}),
    {
    oldPdw: '',
    newPdw: '',
    confirmPdw: ''
  })
  
  const setOldPassword = (text:string) => {
    setPdwDetails({
      oldPdw: text
    })
  }
  const setNewPassword = (text:string) => {
    setPdwDetails({
      newPdw: text
    })
  }
  const setConfirmPassword = (text:string) => {
    setPdwDetails({
      confirmPdw: text
    })
  }

  const changePass = async () => {
    const obj = {
      changePdw:{
        old_password: pdwDetails.oldPdw,
        password: pdwDetails.newPdw,
        password_repeat: pdwDetails.confirmPdw
      },
      navigation: props.navigation,
      language: await AsyncStorage.getItem('APP_LANG') || 'en',
      message_success: t('translation:profile.change_password.success')
    }
    dispatch(changePasswordAction(obj));
  }
  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => (pdwDetails.oldPdw?.length === 0 || pdwDetails.newPdw?.length === 0 || pdwDetails.confirmPdw?.length === 0 || !criteria || !isSamePassword(pdwDetails.newPdw, pdwDetails.confirmPdw)) 
  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader
        title={t('translation:general.page_title.change_password')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.migraine.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : props.navigation.goBack()}}
        scroll={scrollState}
        testIDTitle="change_password_title"
      />
      <View 
        style={styles.scrollViewWrapper}
      >
        <ScrollView 
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, 0);
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <View
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            <InputField 
              labelText={t('translation:profile.change_password.old')}
              inputType="password" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setOldPassword}
              placeholderTxt={t('translation:profile.change_password.old_placeholder')}
              testID="old_password"
              value={pdwDetails.oldPdw}
            />
            <InputField 
              labelText={t('translation:profile.change_password.new')}
              inputType="password" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setNewPassword}
              placeholderTxt={t('translation:profile.change_password.new_placeholder')}
              testID="new_password"
              value={pdwDetails.newPdw}
              validated={criteria}
            />
            <CommonPwdCriteria password={pdwDetails.newPdw || ''} checkCriteria={setCriteria}/>
            <InputField 
              labelText={t('translation:profile.change_password.confirm')}
              inputType="password" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setConfirmPassword}
              placeholderTxt={t('translation:profile.change_password.confirm_placeholder')}
              testID="confirm_password"
              value={pdwDetails.confirmPdw}
              validated={isSamePassword(pdwDetails.newPdw, pdwDetails.confirmPdw)}
              error={!isSamePassword(pdwDetails.newPdw, pdwDetails.confirmPdw)}
              errorText={t('translation:server.errors.passwords_error')}
            />
          </View>
        </ScrollView>
      </View>
      <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
        <CommonBtn type="primary" click={changePass} title={t('translation:profile.change_password.send')} testID="change_pass" disable={checkDisable()}/>
      </CommonFooter>
      <View style={{marginBottom:Platform.OS === 'ios' ? PR(40) : 0}}></View>
    </CommonKeyboardAvoidingView>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    scrollViewWrapper: {
      marginTop: PR(5),
      flex: 1
    },
    scrollview: {
      paddingTop: PR(20),
      flex:1
    },
  })
}

export default ChangeMyPdw;