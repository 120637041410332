import { call, takeLatest, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { 
    MFAActions,
    CheckPassInterface,
    EnablePushNotificationMFAInterface,
    DisablePushNotificationMFAInterface,
    VerifyOTPCodeInterface,
    GetOTPCodeInterface,
    VerifyPasswordInterface,
    GetMfaStatusInterface,
    EnableEmailMFAInterface,
    DisableEmailMFAInterface,
    VerifyPasswordForEmailInterface,
    GetOTPForRegistrationInterface
} from "./mfaActions";

export function* checkCredentialsSaga(payload: AnyAction & CheckPassInterface) {
    yield call(makeRequest, {
        path: `/auth/checkpass?language=${payload.language}`,
        method: 'POST',
        data: {email:payload.email, password:payload.password},
        ...payload
    });   
}

export function* verifyPasswordSaga(payload: AnyAction & VerifyPasswordInterface) {
    yield call(makeRequest, {
        path: `/mfa/verify/password/${payload.userId}`,
        method: 'POST',
        data: {
            password: payload.password,
            deviceFBToken: payload.deviceFBToken,
        },
        ...payload
    });
}

export function* enablePushNotificationMFASaga(payload: AnyAction & EnablePushNotificationMFAInterface) {
    yield call(makeRequest, {
        path: `/mfa/enable/pushnotification/${payload.userId}`,
        method: 'POST',
        data: {
            deviceFBToken: payload.deviceFBToken,
        },
        ...payload
    });
}

export function* disablePushNotificationMFASaga(payload: AnyAction & DisablePushNotificationMFAInterface) {
    yield call(makeRequest, {
        path: `/mfa/disable/pushnotification/${payload.userId}`,
        method: 'POST',
        data: {
            password: payload.password
        },
        ...payload
    });
}

export function* verifyOTPCodeSaga(payload: AnyAction & VerifyOTPCodeInterface) {
    yield call(makeRequest, {
        path: `/mfa/verify/otpcode/${payload.userId}?language=${payload.language}`,
        method: 'POST',
        data: {
            otpCode: payload.otpCode,
        },
        ...payload
    });
}

export function* getOTPCodeSaga(payload: AnyAction & GetOTPCodeInterface) {
    const path = payload?.deviceFBToken ? `/mfa/get/otpcode/${payload.userId}?language=${payload.language}&name=${payload.name}&whereToSend=${payload.whereToSend}&deviceFBToken=${payload?.deviceFBToken}` : `/mfa/get/otpcode/${payload.userId}?language=${payload.language}&name=${payload.name}&whereToSend=${payload.whereToSend}`
    yield call(makeRequest, {
        path: path,
        method: 'GET',
        ...payload
    });
}

export function* getMfaStatusSaga(payload: AnyAction & GetMfaStatusInterface) {
    yield call(makeRequest, {
        path: `/mfa/get/status/${payload.userId}?language=${payload.language}`,
        method: 'GET',
        ...payload
    });
}

export function* getOTPForRegistrationSaga(payload: AnyAction & GetOTPForRegistrationInterface) {
    yield call(makeRequest, {
        path: `/mfa/get/otp/forRegistration/${payload.userId}`,
        method: 'GET',
        ...payload
    });
}

export function* verifyPasswordForEmailSaga(payload: AnyAction & VerifyPasswordForEmailInterface) {
    yield call(makeRequest, {
        path: `/mfa/verify/password/forEmail/${payload.userId}`,
        method: 'POST',
        data: {
            password: payload.password,
            email: payload.email,
        },
        ...payload
    });
}

export function* enableEmailMFASaga(payload: AnyAction & EnableEmailMFAInterface) {
    yield call(makeRequest, {
        path: `/mfa/enable/email/${payload.userId}`,
        method: 'POST',
        data: {
            email: payload.email,
        },
        ...payload
    });
}

export function* disableEmailMFASaga(payload: AnyAction & DisableEmailMFAInterface) {
    yield call(makeRequest, {
        path: `/mfa/disable/email/${payload.userId}`,
        method: 'POST',
        ...payload
    });
}


export default function* watch() {
    yield takeLatest(MFAActions.CHECK_LOGIN_CREDENTIALS, checkCredentialsSaga);
    yield takeLatest(MFAActions.VERIFY_PASSWORD, verifyPasswordSaga);
    yield takeLatest(MFAActions.ENABLE_PUSH_NOTIFICATION_MFA, enablePushNotificationMFASaga);
    yield takeLatest(MFAActions.DISABLE_PUSH_NOTIFICATION_MFA, disablePushNotificationMFASaga);
    yield takeLatest(MFAActions.VERIFY_OTP_CODE, verifyOTPCodeSaga);
    yield takeLatest(MFAActions.GET_OTP_CODE, getOTPCodeSaga);
    yield takeLatest(MFAActions.GET_MFA_STATUS, getMfaStatusSaga);
    yield takeLatest(MFAActions.GET_OTP_FOR_REGISTRATION, getOTPForRegistrationSaga);
    yield takeLatest(MFAActions.VERIFY_PASSWORD_FOR_EMAIL, verifyPasswordForEmailSaga);
    yield takeLatest(MFAActions.ENABLE_EMAIL_MFA, enableEmailMFASaga);
    yield takeLatest(MFAActions.DISABLE_EMAIL_MFA, disableEmailMFASaga);
}