import React, {useEffect, useState} from 'react';
import {
  StyleSheet,
  Linking,
  ScrollView,
  View,
  SafeAreaView,
  Platform,
  LogBox
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyle from 'common/src/styles/GlobalStyles';
import CommonAboutFooter from "common/src/components/CommonAboutFooter";
import CommonHeader from "common/src/components/CommonHeader";
import CommonSettingsBtn from 'common/src/components/CommonSettingsBtn';
import CommonActionModal from 'common/src/components/CommonActionModal';
import { version } from '../../package.json';
import Text from 'common/src/components/CommonCustomTxt';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch } from 'react-redux';
import { updateLanguageAction } from 'common/src/store/actions/authenticationActions';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {SettingsTypes, defaultMMLang} from 'common/src/services/settings_service';
import { switchThemeAction } from 'common/src/store/theme/themeActions';

LogBox.ignoreAllLogs();

const Settings = ({ navigation, route }) => {
  const colors = useTheme().colors;
  const styles: any = generateStyleSheet(colors);
  const {i18n, t} = useTranslation();
  const lang = i18n.language;
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const [scrollState, setScrollState] = useState<number>(0);
  const [displayLanguageModal, setDisplayLanguageModal] = useState<boolean>(false);
  const [displayThemeModal, setDisplayThemeModal] = useState<boolean>(false);
  const [themeValue, setThemeValue] = useState<string>('light');

  useEffect(() => {
    AsyncStorage.getItem('token')
      .then((value:any) => {
        if (value !== null && value !== "") {
          setLoggedIn(true)
        }
      });
     
    AsyncStorage.getItem('theme')
      .then((value:any) => {
        if (value !== null && value !== "") {
          setThemeValue(value)
        }
      });  
  },[]);

  const setUpdateClick = (itemName:string) => {
    if (itemName === SettingsTypes.Help) {
      Linking.openURL("mailto:help@neuroventis.care");
    } 
    else if (itemName === SettingsTypes.Our_privacy_notice) {
      let hrefPrivacy = t('translation:settings.support.privacy_url')
      Platform.OS === 'web' ? window.open(hrefPrivacy, '_blank') : Linking.openURL(hrefPrivacy)
    }
    else if (itemName === SettingsTypes.Our_terms_of_use) {
      let hrefTerms = t('translation:settings.support.terms_url')
      Platform.OS === 'web' ? window.open(hrefTerms, '_blank') : Linking.openURL(hrefTerms)
    }
    else if (itemName === SettingsTypes.Treatment_reminders) {
      navigation.navigate(SettingsTypes.Treatment_reminders,{itemType:'Reminder'})
    }
    else if (itemName === SettingsTypes.Language) {
      setDisplayLanguageModal(true)
    }
    else if (itemName === SettingsTypes.Theme) {
      setDisplayThemeModal(true)
    }
    else {
      navigation.navigate(itemName)
    }
  }

  const updateLanguage = (values) => {
    const selectedLanguage = values.find(language => language.selected);
    
    if (!selectedLanguage) {
      return ;
    }

    i18n.changeLanguage(selectedLanguage.lngCode);
    if(loggedIn){
      dispatch(updateLanguageAction({ language: selectedLanguage.lngCode }));
    }
    setDisplayLanguageModal(false)
  }

  const switchTheme = (values:Array<Record<string, any>>) => {
    const selectedTheme = values.find(theme => theme.selected);
    if (!selectedTheme) {
      return ;
    }
    AsyncStorage.setItem('theme', selectedTheme.value)
    dispatch(switchThemeAction({
      themeMode: selectedTheme.value
    }))
    setThemeValue(selectedTheme.value)
    setDisplayThemeModal(false)
  }

  const changeModalVisibility = () => {
    setDisplayLanguageModal(!displayLanguageModal)
  }

  return (
    <>
      <SafeAreaView style={styles.wrapper}>
        {
          displayLanguageModal &&
            <CommonActionModal 
              testID={"LanguageModalID"} 
              title={t('translation:settings.select_language_title')}
              modalType={'select-radio'} 
              buttons={[{title: t('translation:general.header.btn_save'), 
              type: 'primary', click: updateLanguage, action: 'save'}]} 
              onClose={changeModalVisibility} 
              data={defaultMMLang(lang)}
            />
        }
        {
          displayThemeModal &&
            <CommonActionModal 
              testID={"themeModalID"} 
              title={"Switch theme"}
              modalType={'select-radio'} 
              buttons={[{title: t('translation:general.header.btn_save'), 
              type: 'primary', click: switchTheme, action: 'save'}]} 
              onClose={() => setDisplayThemeModal(false)} 
              data={
                [
                  {label: 'Dark', value: 'dark', selected: 'dark' === themeValue},
                  {label: "Light", value: 'light', selected: 'light' === themeValue}
                ]
              }
            />
        }
        {loggedIn? 
          (<CommonHeader
            title={t('translation:general.page_title.settings')} 
            leftIconName="arrow-left"
            leftIconColor={GlobalStyle.migraine.color}
            leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
            scroll={scrollState}
            testIDTitle="settings_title"
          />
          ) : (
            <CommonHeader
              title={t('translation:general.page_title.settings')} 
              leftIconName="arrow-left"
              leftIconColor={GlobalStyle.migraine.color}
              leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
              testIDTitle="settings_title"
              scroll={scrollState}
              disableFormsBanner={true}
            />
          )
        }
        <ScrollView 
          testID="settings_scrollView"
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
                setScrollState(event.nativeEvent.contentOffset.y);
            else if(scrollState > 0 && event.nativeEvent.contentOffset.y <= 0)
                setScrollState(0);
          }}
        >
          {loggedIn? (
            <View>
              <View style={styles.sectionHeader}>
                <Text style={styles.sectionHeaderText}>
                  {t('translation:settings.titles.profile')}
                </Text>
              </View>
              <CommonSettingsBtn 
                listNames={
                  [
                    {name:t('translation:settings.btn.change_password'), value: SettingsTypes.Change_my_password},
                    {name:t('translation:settings.btn.enable_disable_biometrics'), value: SettingsTypes.Enable_Disable_auth},
                    {name:t('translation:treatment.form.timezone'), value: SettingsTypes.Timezone},
                  ]
                } 
                clicked={setUpdateClick}
              />
            </View>
          ) : (
            <View>
              <View style={styles.sectionHeader}>
                <Text style={styles.sectionHeaderText}>
                  {t('translation:settings.titles.language')}
                </Text>
              </View>
              <CommonSettingsBtn listNames={
                [{name:t('translation:settings.btn.language'), value: SettingsTypes.Language},]
                } 
                clicked={setUpdateClick}
              />
            </View>
          )}
          {!loggedIn &&
          <View>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>
                {"Theme"}
              </Text>
            </View>
            <CommonSettingsBtn listNames={
              [{name:themeValue.charAt(0).toUpperCase()+themeValue.slice(1), value: SettingsTypes.Theme},]
              } 
              clicked={setUpdateClick}
            />
          </View>
          }
          {loggedIn && (
            <View>
              <View style={styles.settingsSection}>
                <View style={styles.sectionHeader}>
                  <Text style={styles.sectionHeaderText}>
                    {t('translation:settings.titles.treatment')}
                  </Text>
                </View>
                <CommonSettingsBtn listNames={
                  [
                    {name:t('translation:settings.btn.reminders'), value: SettingsTypes.Treatment_reminders},
                    {name:t('translation:settings.btn.mood_sleep'), value: SettingsTypes.Mood_and_sleep},
                  ]
                  } 
                  clicked={setUpdateClick}
                />
              </View>
            </View>
          )}

          <View style={styles.settingsSection}>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>
                {t('translation:settings.titles.support')}
              </Text>
            </View>
            {
            loggedIn?
            (
              <CommonSettingsBtn listNames={
                [
                  {name:t('translation:settings.support.help'), value: SettingsTypes.Help},
                  {name:t('translation:settings.support.tutorial'), value: SettingsTypes.Mini_tutorial},
                  {name:t('translation:settings.support.privacy'), value: SettingsTypes.Our_privacy_notice},
                  {name:t('translation:settings.support.terms'), value: SettingsTypes.Our_terms_of_use},
                  {name:t('translation:settings.support.consent'), value: SettingsTypes.InformedConsent},
                  {name:t('translation:settings.about.title'), value: SettingsTypes.About},
                ]
                } 
                clicked={setUpdateClick}
              />
            )
            :
            <CommonSettingsBtn listNames={
              [
                {name:t('translation:settings.support.help'), value: SettingsTypes.Help},
                {name:t('translation:settings.support.tutorial'), value: SettingsTypes.Tutorial},
                {name:t('translation:settings.support.privacy'), value: SettingsTypes.Our_privacy_notice},
                {name:t('translation:settings.support.terms'), value: SettingsTypes.Our_terms_of_use},
                {name:t('translation:settings.about.title'), value: SettingsTypes.About},
              ]
              } 
              clicked={setUpdateClick}
            />
            }
          </View>
          {!loggedIn && 
            <View style={styles.infoSection}>
              <Text style={styles.versionText}>
              {t('translation:settings.about.app_version')} - {version}
              </Text>
            </View>
          }
          <CommonAboutFooter />
          <View style={{marginBottom:PR(40)}}></View>
        </ScrollView>
      </SafeAreaView>
    </>
  )
}

const generateStyleSheet = (colors:any) => {
  return StyleSheet.create({
    wrapper: {
      backgroundColor: colors.background,
      flex: 1,
    },
    scrollview: {
      paddingTop: PR(20),
      flex:1
    },
    settingsSection: {
      marginTop: PR(30)
    },
    sectionHeader: {
      // paddingHorizontal: 20,
      width: '90%',
      alignSelf: 'center',
      paddingVertical: PR(6),
    },
    sectionHeaderText: {
      color: colors.primary,
      fontSize: PR(20),
      fontFamily: GlobalStyle.global.fontFamily.Bold,
      letterSpacing: 0,
    },
    item: {
      paddingVertical: PR(13),
      paddingHorizontal: PR(18),
      borderBottomWidth: PR(1),
      borderBottomColor: GlobalStyle.global.lightGrey,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row"
    },
    itemText: {
      fontSize: PR(16),
      letterSpacing: PR(1),
    },
    infoSection: {
      marginTop: PR(30),
      display: "flex",
      flexDirection: "column",
      alignContent: "center"
    },
    ceMarking: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    versionText: {
      color: "#bbb",
      fontSize: PR(13),
      textAlign: "center",
      marginBottom: PR(40)
    },
    selectElement: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    timezoneSelect: {
      paddingVertical: PR(16),
      display: "flex",
      flexDirection: "column",

    },
    timezoneTitle: {
      fontSize: PR(16),
    },
    timezoneDropdownIcon: {
      height: 0,
      marginTop: "auto",
      paddingBottom: PR(37),
    }

  });
}

export default Settings;