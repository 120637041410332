import { takeLatest, takeLeading } from 'redux-saga/effects';
import { 
  loginSaga, 
  registerSaga, 
  getMeSaga, 
  passwordForgottenSaga, 
  updatePatientSaga, 
  updateConsentSaga,
  updateQuestionOnBoardingSaga,
  updateAddressSaga,
  updateMoodAndSleepSaga,
  updateMoodAndSleepNotificationsSaga,
  updateRemindersSaga,
  addNeurologistSaga, 
  addEventSaga,
  addEventMoodAndSleepSaga,
  changePasswordSaga,
  getNeurologistSaga,
  getEventsSaga,
  getNightwatchEventsSaga,
  getTimelineEventsSaga,
  getSeizureVideosSaga,
  getMedicationSaga,
  getMedicationHistorySaga,
  getEditMedicationHistorySaga,
  resetSuccessModalSaga,
  resetErrorModalSaga,
  deleteNeurologistSaga,
  logoutSaga,
  removeAccountSaga, 
  getNotificationsSaga,
  updateMedicationIntakeSaga,
  updatePatientSiteSaga,
  updateDataSharingSaga,
  deleteNotificationSaga,
  allSetSaga,
  updateLanguageSaga,
  updateVersionSaga,
  addNotificationSaga,
  resetSeizureVideosSaga,
  getPatientSitesSaga,
  getDataSharingSaga,
  updateAzmmSaga
} from './authenticationSaga';

import * as types from '../actions';

// exporting a generator function which basically watches for the actions and makes a call to registerSaga, loginSaga etc
export default function* watchUserAuthentication() {
  yield takeLatest(types.LOGIN_USER, loginSaga);
  yield takeLatest(types.REGISTER_USER, registerSaga);
  yield takeLatest(types.GET_ME, getMeSaga);
  yield takeLatest(types.PASSWORD_FORGOTTEN, passwordForgottenSaga);
  yield takeLatest(types.UPDATE_PATIENT, updatePatientSaga);
  yield takeLatest(types.UPDATE_CONSENT, updateConsentSaga);
  yield takeLatest(types.UPDATE_ONBOARDING_QUESTIONS, updateQuestionOnBoardingSaga);
  yield takeLatest(types.UPDATE_ADDRESS, updateAddressSaga);
  yield takeLatest(types.UPDATE_MOOD_AND_SLEEP, updateMoodAndSleepSaga);
  yield takeLatest(types.UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS, updateMoodAndSleepNotificationsSaga);
  yield takeLatest(types.UPDATE_REMINDERS, updateRemindersSaga);
  yield takeLatest(types.UPDATE_MEDICATION_INTAKE, updateMedicationIntakeSaga);
  yield takeLatest(types.ADD_NEUROLOGIST, addNeurologistSaga);
  yield takeLeading(types.ADD_EVENT, addEventSaga);
  yield takeLeading(types.ADD_EVENT_MOOD_AND_SLEEP, addEventMoodAndSleepSaga);
  yield takeLatest(types.CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(types.GET_NEUROLOGIST, getNeurologistSaga);
  yield takeLeading(types.GET_EVENTS, getEventsSaga);
  yield takeLeading(types.GET_NIGHTWATCH_EVENTS, getNightwatchEventsSaga);
  yield takeLeading(types.GET_TIMELINE_EVENTS, getTimelineEventsSaga);
  yield takeLatest(types.GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeLatest(types.DELETE_NOTIFICATIONS, deleteNotificationSaga)
  yield takeLatest(types.GET_SEIZUREVIDEOS, getSeizureVideosSaga);
  yield takeLatest(types.GET_MEDICATION, getMedicationSaga);
  yield takeLatest(types.GET_MEDICATION_HISTORY, getMedicationHistorySaga);
  yield takeLatest(types.GET_EDIT_MEDICATION_HISTORY, getEditMedicationHistorySaga);
  yield takeLatest(types.SUCCESS_RESET, resetSuccessModalSaga);
  yield takeLatest(types.ERROR_RESET, resetErrorModalSaga);
  yield takeLatest(types.DELETE_NEUROLOGIST, deleteNeurologistSaga);
  yield takeLatest(types.LOGOUT_USER, logoutSaga);
  yield takeLatest(types.REMOVE_ACCOUNT, removeAccountSaga);
  yield takeLatest(types.UPDATE_PATIENT_SITE, updatePatientSiteSaga)
  yield takeLatest(types.UPDATE_DATA_SHARING, updateDataSharingSaga)
  yield takeLatest(types.GET_DATA_SHARING, getDataSharingSaga)
  yield takeLatest(types.ALL_SET, allSetSaga)
  yield takeLatest(types.UPDATE_LANGUAGE, updateLanguageSaga);
  yield takeLatest(types.UPDATE_VERSION, updateVersionSaga);
  yield takeLatest(types.ADD_NOTIFICATIONS, addNotificationSaga);
  yield takeLatest(types.RESET_SEIZUREVIDEOS, resetSeizureVideosSaga);
  yield takeLatest(types.GET_PATIENT_SITES, getPatientSitesSaga);
  yield takeLatest(types.UPDATE_AZMM, updateAzmmSaga);
}