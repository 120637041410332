import {call, put, takeLatest, select} from "redux-saga/effects";
import {UPDATE_DOSE_ACTION, UPDATE_DOSE_SUCCESS,} from "./treatmentsActions";
import {makeRequest} from "../../services/requestService";
import {getUniqueEventSaga} from "./treatmentsSagas";
import moment from "moment";

export function* editDoseSaga(payload:any) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        data: payload.dataToUpdate,
        path: `/patients/${id}/intakes/${payload.dataToUpdate.id}`,
        method: 'PUT',
        responseAction: UPDATE_DOSE_SUCCESS,
        successMessage: 'translation:treatment.add_medication.success.edit_intake',
        ...payload
    })
    yield call(getUniqueEventSaga, {obj:{from: moment().format('yyyy-MM-DD'), to: moment().format('yyyy-MM-DD')}})
    
}

export default function* watchUserAuthentication() {
    yield takeLatest(UPDATE_DOSE_ACTION, editDoseSaga);
}