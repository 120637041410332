import React from 'react';
import { logOutAction, updateAzmmAction, updatePatientAction } from 'common/src/store/actions/authenticationActions';
import CommonHeader from 'common/src/components/CommonHeader';
import { SafeAreaView, ScrollView, View, StyleSheet } from 'react-native';
import CommonBtn from 'common/src/components/CommonBtn';
import { useTheme } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import CommonImage from 'common/src/components/CommonImage';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { ThemeColors } from '../../../../*';
import { APP_SHORTCUT_NAME } from 'common/src/env.json';
import { useDispatch, useSelector } from 'react-redux';

const Azmm = ({ navigation, route: { params } }) => {
    const { t } = useTranslation();
    const { colors, dark } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const dispatch = useDispatch();
    const { nextAction } = params;

    const goNext = () => {
        dispatch(updateAzmmAction({
            callback: () => {
                navigation.reset({
                    index: 0,
                    routes: [{ name: nextAction }],
                });
                navigation.navigate(nextAction)
            }
        }))
    }

    const logout = () => {
        dispatch(logOutAction());
        navigation.navigate('Welcome');
        navigation.reset({
            index: 0,
            routes: [{ name: 'Welcome' }],
        });
    };

    const azmmLogo = dark ? t('translation:server.neuro_custom.azmm.logo_headache_white') : t('translation:server.neuro_custom.azmm.logo_headache')

    return (
        <SafeAreaView style={styles.container}>
            {
                nextAction === 'Home' ?
                    <CommonHeader
                        testIDTitle="azmm"
                        title={t('translation:general.page_title.neurologist')}
                        disableFormsBanner={true}
                    />
                    :
                    <CommonHeader
                        testIDTitle="azmm"
                        title={t('translation:general.page_title.neurologist')}
                        rightIconNameFirst="log-out"
                        rightIconColorFirst={GlobalStyles[APP_SHORTCUT_NAME].btnColor}
                        rightClickFirst={logout}
                        disableFormsBanner={true}
                    />
            }
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ marginBottom: PR(170) }}
            >
                <View style={{ width: '90%', alignSelf: 'center', }}>
                    <CommonImage name='' uri={azmmLogo} height={PR(125)} width={PR(225)} style={{ alignSelf: 'center', margin: PR(10) }} />
                    <Text renderAsHtml={true} style={styles.text}>{t('translation:onboarding.neurologist.azmm')}</Text>
                </View>
            </ScrollView>
            <View style={styles.btnContainer}>
                <CommonBtn type="primary" title={t('translation:general.next')} click={goNext} />
            </View>
        </SafeAreaView>
    );
};

export default Azmm;

const generateStyleSheet = (colors: ThemeColors) => {
    return StyleSheet.create({
        container: {
            backgroundColor: colors.background,
            flex: 1,
        },
        text: {
            fontSize: PR(22),
            alignSelf: 'center',
            marginTop: PR(20)
        },
        btnContainer: {
            flex: 1,
            position: 'absolute',
            width: '100%',
            alignSelf: 'center',
            bottom: PR(35)
        }
    })
} 