import { getHeaderRequest } from '../../callService';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {APP_SHORTCUT_NAME, TRANSLATION_ENV} from '../../../env.json';

let API:string 
// if(APP_SHORTCUT_NAME === 'helpi') {
  API = 'https://i18n.neuroventis.care/helpilepsy'
// } else {
//   API = 'https://i18n.neuroventis.care/migrainemanager'
// }

export const languages = async (lang:string) => {
  const tranRes = await getHeaderRequest(API+TRANSLATION_ENV+lang+'.json');
  let lastModifiedDate = await AsyncStorage.getItem('last-modified-'+lang)
  if (lastModifiedDate !== null && JSON.parse(lastModifiedDate) === tranRes.headers.get('last-modified')) {
    let res = (await data(lang, tranRes.res)).res;
    return {res,}
  } else {
    await AsyncStorage.setItem('last-modified-'+lang, JSON.stringify(tranRes.headers.get('last-modified')));
    let langRes = await getLang(lang, tranRes.res)
    let res = langRes.language
    return {res,}
  }
}

const data = async (lang:string, languageRes:Record<string, any>) => {
  let value: string | null = await AsyncStorage.getItem(lang)
  if (value !== null && value !== "") {
    let res = await JSON.parse(value)
    return {res}
  } else {
    let langRes = await getLang(lang, languageRes)
    let res = langRes.language
    return {res,}
  }
}

const getLang = async (lang:string, languageRes:Record<string, any>) => {
  try {
    await AsyncStorage.setItem(lang, JSON.stringify(languageRes));
    return {
      language: languageRes,
    };
  } catch (e) {
      throw e;
  }
};