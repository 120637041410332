import EventEmitter from 'events';

const notificationEmitter = new EventEmitter();

export enum NotificationTypes{
    All = "All_notifications",
    Nigthwatch = "nightwatch_seizure",
    Nigthwatch_recording = "nightwatch_report",
    New_program = "new_program",
    MFA_notif = "mfa_notif",
}

export default notificationEmitter;