import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import GlobalStyles from '../styles/GlobalStyles';

type Props = {
    listNames: ListNames[];
    clicked: (res: string) => void;
    isHighlighted?: boolean;
    highlightedColor?: string;
    highlightedIcon?: string;
}

type ListNames = {
    name: string;
    value: string;
}

const CommonHighlightedMenuItem = ({
    listNames, 
    isHighlighted, 
    highlightedColor, 
    highlightedIcon, 
    clicked
}:Props) => {
    const colors = useTheme().colors;
    const styles: any = generateStyleSheet(colors);
    const clickedBtn = (itemName: string) => {
        clicked(itemName)
    }
    return (
        <>
        {listNames.map((item, key) => {
            return (
                <TouchableOpacity testID={`setting-item-${item.value}`} key={key} style={styles.innerItem} onPress={() => {
                    clickedBtn(item.value)
                }}>
                    {isHighlighted &&
                        <View style={{
                            height: PR(20),
                            width: PR(20),
                            borderRadius: PR(40),
                            justifyContent: 'center',
                            alignItems: "center",
                            backgroundColor: highlightedColor || GlobalStyles.global.redError,
                            zIndex: 999,
                        }}>
                            <Text style={{color:'#fff'}}>{highlightedIcon || "!"}</Text>
                        </View>
                    }
                    <Text numberOfLines={1} style={styles.itemText}>
                        {item.name}
                    </Text>
                    <Icon name={"chevron-right"} size={PR(25)} color={colors.primary} style={{opacity:0.5}} />
                </TouchableOpacity>
            )
        })}
        </>
    );
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    innerItem: {
        paddingVertical: PR(10),
        width: '90%',
        alignSelf: 'center',
        alignItems: 'center',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    itemText: {
        fontSize: PR(18),
        letterSpacing: 0,
        width: '90%',
        color: colors.primary
    },
  });
}

export default CommonHighlightedMenuItem;