export enum BiometricsActions {
    ENABLE_BIOMETRIC = "ENABLE_BIOMETRIC",
    DISABLE_BIOMETRIC = "DISABLE_BIOMETRIC",
    VERIFY_BIOMETRIC = "VERIFY_BIOMETRIC",
    BIOMETRIC_KEY_EXISTS = "BIOMETRIC_KEY_EXISTS",
    BIOMETRIC_KEY_EXISTS_SUCCESS = "BIOMETRIC_KEY_EXISTS_SUCCESS",
    ENABLE_PIN_CODE = "ENABLE_PIN_CODE",
    ENABLE_PIN_CODE_SUCCESS = "ENABLE_PIN_CODE_SUCCESS",
    DISABLE_PIN_CODE = "DISABLE_PIN_CODE",
    DISABLE_PIN_CODE_SUCCESS = "DISABLE_PIN_CODE_SUCCESS",
    GET_PIN_STATUS = "GET_PIN_STATUS",
    GET_PIN_STATUS_SUCCESS = "GET_PIN_STATUS_SUCCESS",
    VERIFY_PIN_CODE = "VERIFY_PIN_CODE"
}

export interface EnableBiometricInterface {
    publicKey: string,
    deviceID: string,
    userID: string,
    callback: () => void;
    errorCallback: () => void;
}

export interface DisableBiometricInterface {
    deviceID: string,
    userID: string,
    callback: () => void;
    errorCallback: () => void;
}

export interface VerifyBiometricInterface {
    signature: string | undefined,
    payload: string,
    deviceID: string,
    userID: string,
    callback: () => void;
}

export interface EnablePinCodeInterface {
    userID: string,
    biometric_pin: string,
    callback: () => void;
}

export interface DisablePinCodeInterface {
    userID: string,
    biometric_pin: string,
    callback: () => void;
}

export interface GetPinStatusInterface {
    userID: string,
    language: string,
    callback?: (res:{message:boolean}) => void; 
}

export interface VerifyPinCodeInterface {
    userID: string,
    biometric_pin: string,
    callback: () => void;
}

export interface BiometricKeyExistsInterface {
    key: boolean
}

export const enableBiometricAction = (payload: EnableBiometricInterface) => {
    return {
        type: BiometricsActions.ENABLE_BIOMETRIC,
        ...payload
    };
};

export const disableBiometricAction = (payload: DisableBiometricInterface) => {
    return {
        type: BiometricsActions.DISABLE_BIOMETRIC,
        ...payload
    };
};

export const verifyBiometricAction = (payload: VerifyBiometricInterface) => {
    return {
        type: BiometricsActions.VERIFY_BIOMETRIC,
        ...payload
    };
};

export const biometricKeyExistsAction = (payload: BiometricKeyExistsInterface) => {
    return {
        type: BiometricsActions.BIOMETRIC_KEY_EXISTS,
        ...payload
    };
};

export const enablePinCodeAction = (payload: EnablePinCodeInterface) => {
    return {
        type: BiometricsActions.ENABLE_PIN_CODE,
        ...payload
    }
}

export const disablePinCodeAction = (payload: DisablePinCodeInterface) => {
    return {
        type: BiometricsActions.DISABLE_PIN_CODE,
        ...payload
    }
}

export const getPinStatusAction = (payload: GetPinStatusInterface) => {
    return {
        type: BiometricsActions.GET_PIN_STATUS,
        ...payload
    }
}

export const verifyPinCodeAction = (payload: VerifyPinCodeInterface) => {
    return {
        type: BiometricsActions.VERIFY_PIN_CODE,
        ...payload
    }
}