import { put, call, select, all } from 'redux-saga/effects';
import { logout } from 'common/src/services/callService';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as types from '../actions'
import {makeRequest} from "../../services/requestService";
import NetInfo, { NetInfoState } from '@react-native-community/netinfo';
import { DateTime } from 'luxon';
import { BiometricsActions } from '../biometrics/biometricsActions';
import { APP_SHORTCUT_NAME } from '../../env.json';
import { AnyAction } from 'redux';
import { GetNotificationInterface } from '../actions/authenticationActions';

// Those functions are responsible for calling postRequest or any other request, 
// which makes an ajax call to our server 

let registration = false

// post functions

export function* registerSaga(payload:any) {
  const { user } = payload;
  const language = user.settings.language || 'en';
  const response = yield call(makeRequest, {
    path: `/usersApp?language=${language}`,
    method: 'POST',
    data: user,
    callback: payload.user.callback,
    errorCallback: payload.user.errorCallback,
    ...payload
  });
  if (response) {
    yield put({type: types.GET_ME_SUCCESS, response: {success: true, result: response}});
    registration = true;
    yield call(loginSaga, { ...payload, user: { ...user, language }});
  }
}

export function* loginSaga(payload:any) {
  const language = payload.user.language || 'en';
  const response = yield call(makeRequest, {
    path: '/auth/generate?language=' + language,
    method: 'POST',
    data: payload.user,
    callback: payload.user?.callback,
    errorCallback: payload.user.errorCallback,
    ...payload
  });
  if (response) {
    let sendVersion = {
      settings: {
        app_version: payload.user.version,
      }
    }

    if(response?.result?.token) {
      AsyncStorage.setItem('token', response?.result?.token);
    }
    
    AsyncStorage.setItem('token', response?.token);
    if (!registration) {
      const getMeRes = yield call(getMeSaga)
      const dataSharing = yield call(getDataSharingSaga)
      const GetMeOnboarding = getMeRes?.patientData?.onboarding;
      if(getMeRes?.success){
        yield call(updateVersionSaga, sendVersion)
        if(getMeRes.result.isEmailValidated === false)
          payload.user.navigation.navigate('VerifyEmail')
        else if (getMeRes.patientData?.azmm && getMeRes.patientData?.azmmValidation === false)
          payload.user.navigation.navigate('Azmm', {nextAction: GetMeOnboarding && GetMeOnboarding.endOnboarding ? 'Home' : 'OnboardingQuestions'})
        else if(!GetMeOnboarding || (!GetMeOnboarding?.endOnboarding && !GetMeOnboarding?.neurologistDone)) {
          if (dataSharing?.success && dataSharing?.result.length > 0)
            payload.user.navigation.navigate('DataSharingRequest', {nextAction: 'OnboardingQuestions'})
          else
            payload.user.navigation.navigate('NeurologistStep')
        }
        else if(getMeRes.result.consent === false)
          payload.user.navigation.navigate('PrivacyConsent')
        else if(GetMeOnboarding && !GetMeOnboarding?.endOnboarding && !GetMeOnboarding?.questionsDone)
          payload.user.navigation.navigate('OnboardingQuestions')
      }
    }
  }
}

export function* passwordForgottenSaga(payload:any) {
  
  yield call(makeRequest, {
    path: '/password/reset',
    method: 'POST',
    data: payload.user,
    callback: payload.user.callback,
    successMessage: payload.user.msg,
    ...payload
  });
}

export function* allSetSaga(payload:any) {
  yield put({ type: types.ALL_SET_SUCCESS, response: payload.value });
}

export function* addNeurologistSaga(payload:any) {
  const state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/permissions',
    method: 'POST',
    data: payload.user.neuro,
    ...payload
  });
  if (response) {
    yield put ({ type: types.ADD_NEUROLOGIST_SUCCESS, response: response})
    // if response is success go back to My neurologists page
    payload?.user?.navigation?.navigate?.('My_neurologists')
  }
}

export function* addEventSaga(payload:any) {
  const state = yield select()
  const id = state?.getMe?.response?.result?.patients?.id;
  const path = payload?.data?.type === 'form' ? '/patients/'+id+'/events-v2' : '/patients/'+id+'/events';
  yield call(makeRequest, {
    path: path,
    method: 'POST',
    responseAction: types.ADD_EVENT_SUCCESS,
    successMessage: payload.data.msg,
    ...payload
  })
}

export function* addEventMoodAndSleepSaga(payload:any) {
  const state = yield select()
  const id = state?.getMe?.response?.result?.patients?.id;
  yield call(makeRequest, {
    path: '/patients/'+id+'/moodsleep',
    method: 'POST',
    responseAction: types.ADD_EVENT_MOOD_AND_SLEEP_SUCCESS,
    ...payload
  })
}

export function* changePasswordSaga(payload:any) {
  const response = yield call(makeRequest, {
    path: '/password/change?language=' + payload.user.language,
    method: 'POST',
    data: payload.user.changePdw,
    ...payload
  });
  if (response) {
    yield put ({
      type: types.SUCCESS,
      message: payload.user.message_success,
    });
    payload?.user?.navigation?.goBack();
  }
}

// update functions
export function* updatePatientSaga(payload:any) {
  if (payload.user.obj.callPermissions !== undefined && payload.user.obj.callPermissions) {
    // @ts-ignore
    yield call(makeRequest, {
      path: '/patients/'+payload.user.id+'/permissions',
      method: 'POST',
      data: payload.user.obj.permission,
    });
  }
  const response = yield call(makeRequest, {
    path: '/patients/'+payload.user.id,
    method: 'PUT',
    data: payload.user.obj.patient,
    ...payload
  });
  if (response) {
    yield put({ type: types.UPDATE_PATIENT_SUCCESS, response: {success: true, result: response} });
    payload?.user?.navigation?.navigate?.('OnboardingQuestions')
  }
}

export function* updateAzmmSaga(payload) {
  const state = yield select()
  const id = state.getMe.response.result.patients.id;
  yield call(makeRequest, {
    path: '/patients/'+id+'/validateAzmm',
    method: 'PUT',
    ...payload
  });
};

export function* updateConsentSaga(payload:any) {
  const response = yield call(makeRequest, {
    path: '/meApp',
    method: 'PUT',
    data: payload.user,
    ...payload
  });
  if (response) {
    yield put({ type: types.UPDATE_CONSENT_SUCCESS, response: {success: true, result: response} });
    if(response.consent){
      if (!payload.user.fromSettings)
        payload.user.navigation.navigate('OnboardingQuestions');
      yield put ({ type: types.LOADING, response:false});
    } else {
      yield call(logoutSaga);
      yield put ({ type: types.LOADING, response:false});
    }
  }
}

export function* updateQuestionOnBoardingSaga(payload:any) {
  const state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id,
    method: 'PUT',
    data: payload.patients,
    ...payload
  });
  if (response) {
    yield put({type: types.UPDATE_ONBOARDING_QUESTIONS_SUCCESS, response: {success: true, result: response}})
    yield call(allSetSaga,{value:true})
  }
}

export function* updateAddressSaga(payload:any) {
  const response = yield call(makeRequest, {
    path: '/meApp',
    method: 'PUT',
    data: payload.user,
    ...payload
  });
  if (response) {
    yield put({ type: types.UPDATE_ADDRESS_SUCCESS, response: { success: true, result: response } });
  }
}

export function* updateMoodAndSleepSaga(payload:any) {
  const state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/moodsleep/'+payload.user.eventID,
    method: 'PUT',
    data: payload.user.data,
    ...payload
  });
  if (response) {
    yield put({ type: types.UPDATE_MOOD_AND_SLEEP_SUCCESS, response: { success: true, result: response } });
  }
}

export function* updateMoodAndSleepNotificationsSaga(payload:any) {
  const response = yield call(makeRequest, {
    path: '/meApp',
    method: 'PUT',
    callback: payload.user.callback,
    errorCallback: payload.user.errorCallback,
    data: payload.user.data,
    ...payload
  });
  if (response) {
    yield put({ type: types.UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS_SUCCESS, response: { success: true, result: response } });
    yield put ({ type: types.SUCCESS, message: payload.user.success_msg});
  }
}

export function* updateRemindersSaga(payload:any) {
  const state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/reminders/settings',
    method: 'PUT',
    data: payload.user.data,
    callback: payload.user.callback,
    ...payload
  });
  if (response) {
    yield put({ type: types.UPDATE_REMINDERS_SUCCESS, response: { success: true, result: response } });
    yield put ({ type: types.SUCCESS, message: payload.user.success_msg});
  }
}

export function* updateVersionSaga(payload:any) {
  const tempPayload = payload.obj || payload
  yield call(makeRequest, {
    path: '/meApp',
    method: 'PUT',
    data: tempPayload,
    ...payload
  });
}
// get functions
export function* getMeSaga(payload?:any) {
  const response = yield call(makeRequest, {
    path: '/meAppV2',
    method: 'GET',
    ...payload
  });
  if(response?.user_role === 'doctor' && !response?.patients){
    yield put({type: types.ERROR, message: 'errors.messages.cant_login'});
    yield call(logoutSaga);
    return {success: false}
  }
  else if (response?.patients) {
    yield call(makeRequest, {
      path: `/mfa/getPin/status/${response?.id}`,
      method: 'GET',
      responseAction: BiometricsActions.GET_PIN_STATUS_SUCCESS,
      ...payload
    });
    const patientResponse =  yield call(makeRequest, {
      path: `/patients/${response.patients.id}/v2`,
      method: 'GET',
    });
    yield put({
      type: types.GET_ME_SUCCESS,
      response: {success: true, result: response, patientData: patientResponse}
    });
    return {success: true, result: response, patientData: patientResponse}
  } else {
    const connection: NetInfoState = yield NetInfo.fetch();
    // If there's no network avoid terminating user's session
    if (connection.isConnected) {
      yield put({type: types.ERROR, message: 'errors.messages.cant_login'})
      yield call(logoutSaga)
    }
    
  }
}

export function* getNeurologistSaga() {
  const state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/permissions',
    method: 'GET',
  });
  if (response) {
    yield all(response.doctors.map(item => {
      return put ({ type: types.ADD_NEUROLOGIST_SUCCESS, response:{user:item}})
    }))
  }
}

export function* getEventsSaga(payload:any) {
  let state = yield select()
  if(state.getMe.response === undefined) {
    yield put ({ type: types.LOADING, response:true});
    yield call(getMeSaga)
    state = yield select()
  }
  const id = state.getMe.response.result.patients.id
  yield put ({ type: types.LOADING, response:true});
  const { from, to, eventId } = payload.obj;
  if (eventId) {
    const response = yield call(makeRequest, {
      path: `/patients/${id}/events/${eventId}`,
      method: 'GET',
      ...payload.obj
    });
    yield put({ type: "GET_SINGLE_EVENT_SUCCESS", response: {success: true, result: response} });
  }
  else {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const query = `from=${from}&to=${to}&timezone=${timezone}`;
    const response = yield call(makeRequest, {
      path: `/patients/${id}/events_dates_rework_v2?${query}`,
      method: 'GET',
      ...payload.obj
    });
    yield put({ type: types.GET_EVENTS_SUCCESS, response: {success: true, result: response, prevNext: payload.obj.prevNext} });
  }
}

export function* getNightwatchEventsSaga(payload:any) {
  let state = yield select()
  if(state.getMe.response === undefined) {
    yield put ({ type: types.LOADING, response:true});
    yield call(getMeSaga)
    state = yield select()
  }
  const id = state.getMe.response.result.patients.id
  yield put ({ type: types.LOADING, response:true});
  const { from, to } = payload.obj;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const query = `from=${from}&to=${to}&timezone=${timezone}`;
  const response = yield call(makeRequest, {
    path: `/patients/${id}/events_dates_rework?${query}`,
    method: 'GET',
  });
  if(response) {
    yield put({ type: types.GET_NIGHTWATCH_EVENTS_SUCCESS, response: {success: true, result: response} });
  }
}

export function* getTimelineEventsSaga(payload:any) {
  let state = yield select()
  if(state.getMe.response === undefined) {
    yield put ({ type: types.LOADING, response:true});
    yield call(getMeSaga)
    state = yield select()
  }
  const id = state.getMe.response?.result?.patients.id
  yield put ({ type: types.LOADING, response:true});
  const groupBy = payload.obj.groupBy ? '&groupBy=month' : '';
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const path = APP_SHORTCUT_NAME === 'migraine' ? '/patients/'+id+'/events_timeline_headache?from='+payload.obj.from+'&to='+payload.obj.to+groupBy+'&timezone='+timezone : '/patients/'+id+'/events_timeline?from='+payload.obj.from+'&to='+payload.obj.to+groupBy+'&timezone='+timezone;
  yield call(makeRequest, {
    path: path,
    method: 'GET',
    responseAction: types.GET_TIMELINE_EVENTS_SUCCESS,
    ...payload.obj
  });
}

export function* getNotificationsSaga(payload: AnyAction & GetNotificationInterface) {
  let state = yield select()
  if(state.getMe.response === undefined) {
    yield call(getMeSaga)
    state = yield select()
  }
  const id = state.getMe.response?.result?.patients.id
  yield call(makeRequest, {
    path: '/patients/'+id+'/notifications',
    method: 'GET',
    responseAction: types.GET_NOTIFICATIONS_SUCCESS,
    ...payload
  });
}

export function* addNotificationSaga(payload) {
  const data = payload.obj.data
  yield put ({ type: types.ADD_NOTIFICATIONS_SUCCESS, response: {success: true, result: data} });
}

export function* deleteNotificationSaga(payload) {
  const data = payload.obj
  let state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: `/patients/${id}/notifications/${data.notificationId}`,
    method: 'DELETE',
  });
  if (response) {
    yield put ({ type: types.DELETE_NOTIFICATIONS_SUCCESS, response: {success: true, result: response} });
  }
}

export function* getSeizureVideosSaga() {
  const response = yield call(makeRequest, {
    path: '/video-event',
    method: 'GET',
  });
  if (response) {
    yield put ({ type: types.GET_SEIZUREVIDEOS_SUCCESS, response: {success: true, result: response} })
  }
}

export function* resetSeizureVideosSaga() {
    yield put ({ type: types.RESET_SEIZUREVIDEOS_SUCCESS })
}

export function* getMedicationSaga(payload?) {
  let state = yield select()
  if(state.getMe.response === undefined) {
    yield call(getMeSaga)
    state = yield select()
  }
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/medications',
    method: 'GET',
    ...payload.obj
  });
  if (response) {
    yield put ({ type: types.GET_MEDICATION_SUCCESS, response: {success: true, result: response}})
  }
}

export function* getMedicationHistorySaga(payload) {
    let state = yield select()
    if(state.getMe.response === undefined) {
      yield call(getMeSaga)
      state = yield select()
    }
    const id = state.getMe.response.result.patients.id
    const response = yield call(makeRequest, {
      path: '/patients/'+id+'/medications/historic',
      method: 'GET',
      ...payload.obj
    });
    if (response) {
      yield put ({ type: types.GET_MEDICATION_HISTORY_SUCCESS, response: {success: true, result: response} })
    }
}

export function* getEditMedicationHistorySaga(payload:any) {
  let state = yield select()
  if(state.getMe.response === undefined) {
    yield call(getMeSaga)
    state = yield select()
  }
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/medications/'+payload.id+'/historic',
    method: 'GET',
  });
  if (response) {
    yield put ({ type: types.GET_EDIT_MEDICATION_HISTORY_SUCCESS, response: {success: true, result: response} })
  }
}

export function* updateMedicationIntakeSaga(payload) {
  const data = payload.obj
  let state = yield select()
  if(state.getMe.response === undefined) {
    yield call(getMeSaga)
    state = yield select()
  }
  const id = state.getMe.response.result.patients.id
  const takenDate = data.taken_date ? DateTime.fromISO(data.taken_date).toUTC() : undefined;
  const response = yield call(makeRequest, {
    path: `/patients/${id}/medication/take/${data.eventId}`,
    method: 'PUT',
    data: {taken: data.taken, taken_date: takenDate},
  });
  if (response) {
    yield put({ type: types.UPDATE_MEDICATION_INTAKE_SUCCESS, response: {success: true, result: response} });
  }
}


// reset success and error state
export function* resetSuccessModalSaga() {
  yield put ({ type: types.SUCCESS_RESET_SUCCESS, response: undefined});
}

export function* resetErrorModalSaga() {
  yield put ({ type: types.ERROR_RESET_SUCCESS, response: undefined});
}


// delete functions
export function* deleteNeurologistSaga(payload:any) {
  // get the state of getMe in order to identify the id of the patient
  const state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/permissions/'+payload.id,
    method: 'DELETE',
  });
  if (response) {
    yield put({ type: types.DELETE_NEUROLOGIST_SUCCESS, response: payload.id })
  }
}

// logout user
export function* logoutSaga() {
  try {
    registration = false
    yield call(logout);
  } catch(error) {
    registration = false
    yield put({ type: types.ERROR, error })
  }
}

// remove account
export function* removeAccountSaga(payload:any) {
  yield call(makeRequest, {
    path: '/me',
    method: 'DELETE',
    callback: payload.callback
  });
}

export function* updatePatientSiteSaga(payload) {
  const data = payload.obj;
  const response = yield call(makeRequest, {
    path: `/sites/${data.siteId}/patients/${data.patientId}/update`,
    method: 'POST',
    responseAction: types.UPDATE_PATIENT_SITE_SUCCESS,
    data: data.data
  });
}

export function* updateDataSharingSaga(payload) {
  const { data } = payload;
  const endpoint = data.accepted ? 'accept_data_sharing' : 'discard_data_sharing';
  const response =  yield call(makeRequest, {
    path: `/patients/${endpoint}`,
    method: 'POST',
    ...payload,
  });
  if(response)
    yield put({type: types.UPDATE_DATA_SHARING_SUCCES, response: { success: true, result: response}})
  
}

export function* getDataSharingSaga(payload?: any) {
  const state = yield select()
  const id = state.getMe.response.result.patients.id
  const response = yield call(makeRequest, {
    path: '/patients/'+id+'/data_sharing',
    method: 'GET',
    ...payload
  });
  if (response) {
    yield put({ type: types.GET_DATA_SHARING_SUCCESS, response: {success: true, result: response}})
    return {success: true, result: response}
  }
}

export function* updateLanguageSaga(payload: any) {
  const { language } = payload;
  yield call(makeRequest, {
    path: '/meApp',
    method: 'PUT',
    responseAction: types.UPDATE_SETTINGS_SUCCESS,
    data: { settings: { language } },
  });
}

export function* getPatientSitesSaga(payload: any) {
  const {patientId} = payload
  yield call(makeRequest, {
    path: `/patients/${patientId}/sites`,
    method: 'GET',
    responseAction: types.GET_PATIENT_SITES_SUCCESS,
    ...payload
})
}