const Images: any = {
    migraineIcon: {
        web: '../../assets/migraine_color_icon.png',
        mobile: require('../../assets/migraine_color_icon.png')
    },
    helpilepsyIcon: {
        web: '../../assets/helpilepsy_icon_web.png',
        mobile: require('../../assets/helpilepsy_icon.png')
    },
    helpilepsyIconWeb: {
        web: '../../assets/helpilepsy_icon_web.png',
        mobile: require('../../assets/helpilepsy_icon_web.png')
    },
    migraineTut1: {
        web: '../../assets/tuto1.jpg',
        mobile: require('../../assets/tuto1.jpg')
    },
    migraineTut2: {
        web: '../../assets/tuto2.jpg',
        mobile: require('../../assets/tuto2.jpg')
    },
    migraineTut3: {
        web: '../../assets/tuto3.jpg',
        mobile: require('../../assets/tuto3.jpg')
    },
    HelpiTut1: {
        web: '../../assets/HelpiTuto1.png',
        mobile: require('../../assets/HelpiTuto1.png')
    },
    HelpiTut2: {
        web: '../../assets/HelpiTuto2.png',
        mobile: require('../../assets/HelpiTuto2.png')
    },
    HelpiTut3: {
        web: '../../assets/HelpiTuto3.png',
        mobile: require('../../assets/HelpiTuto3.png')
    },
    ceIcon: {
        web: '../../assets/ce.png',
        mobile: require('../../assets/ce.png')
    },
    ce_lightIcon: {
        web: '../../assets/ce_light.png',
        mobile: require('../../assets/ce_light.png')
    },
    manufacturerIcon: {
        web: '../../assets/manufacturer.png',
        mobile: require('../../assets/manufacturer.png')
    },
    manufacturer_lightIcon: {
        web: '../../assets/manufacturer_light.png',
        mobile: require('../../assets/manufacturer_light.png')
    },
    manufacturer_dateIcon: {
        web: '../../assets/manufacturer_date.png',
        mobile: require('../../assets/manufacturer_date.png')
    },
    manufacturer_date_lightIcon: {
        web: '../../assets/manufacturer_date_light.png',
        mobile: require('../../assets/manufacturer_date_light.png')
    },
    ifu_icon: {
        web: '../../assets/ifu_icon.png',
        mobile: require('../../assets/ifu_icon.png')
    },
    doctorIconHelpi: {
        web: '../../assets/doctorIconHelpi.png',
        mobile: require('../../assets/doctorIconHelpi.png')
    },
    doctorIconMM: {
        web: '../../assets/doctorIconMM.png',
        mobile: require('../../assets/doctorIconMM.png')
    },
    loginBackgroundImage: {
        web: '',
        mobile: require('../../assets/login.png')
    },
    treatmentsIcon: {
        web: '../../assets/treatmentsIcons.png',
        mobile: require('../../assets/treatmentsIcons.png')
    },
    treatmentsIconMM: {
        web: '../../assets/treatmentsIconsMM.png',
        mobile: require('../../assets/treatmentsIconsMM.png')
    },
    novideos: {
        web: '../../assets/novideos.png',
        mobile: require('../../assets/novideos.png')
    },
    lockImage: {
        web: 'https://s3.eu-central-1.amazonaws.com/helpilepsy-content/images/lock.png',
        mobile: {
            uri: 'https://s3.eu-central-1.amazonaws.com/helpilepsy-content/images/lock.png',
          }
    },
    nightWatchLogo: {
        web: 'https://epione-content.s3.eu-central-1.amazonaws.com/logoNW.PNG',
        mobile: {
            uri: 'https://epione-content.s3.eu-central-1.amazonaws.com/logoNW.PNG'
        }
    },
    notification_icon: {
        web: '../../assets/notification_icon.png',
        mobile: require('../../assets/notification_icon.png')
    },
    applied_filter_Helpi: {
        web: '../../assets/appliedFilterH.png',
        mobile: require('../../assets/appliedFilterH.png')
    },
    applied_filter_Migraine: {
        web: '../../assets/appliedFilterM.png',
        mobile: require('../../assets/appliedFilterM.png')
    },
    checkmark_green: {
        web: '../../assets/checkmarkGreen.png',
        mobile: require('../../assets/checkmarkGreen.png')
    },
    warning_red: {
        web: '../../assets/warningIconRed.png',
        mobile: require('../../assets/warningIconRed.png')
    },
    informationStudyIcon: {
        web: '../../assets/study-info.png',
        mobile: require('../../assets/study-info.png')
    },
    formsStudyIcon : {
        web: '../../assets/questionnaires.png',
        mobile: require('../../assets/questionnaires.png')
    },
    seizureStudyIcon: {
        web: '../../assets/seizure.png',
        mobile: require('../../assets/seizure.png')
    },
    consentStudyIcon: {
        web: '../../assets/consent.png',
        mobile: require('../../assets/consent.png')
    },
    contactStudyIcon: {
        web: '../../assets/contact.png',
        mobile: require('../../assets/contact.png')
    },
    infoIcon: {
        web: '../../assets/infoIcon.png',
        mobile: require('../../assets/infoIcon.png')
    },
    medicalIcon: {
        web: '../../assets/medicalIcon.png',
        mobile: require('../../assets/medicalIcon.png')
    },
    moonIcon: {
        web: '../../assets/moonIcon.png',
        mobile: require('../../assets/moonIcon.png')
    },
    sunIcon: {
        web: '../../assets/sunIcon.png',
        mobile: require('../../assets/sunIcon.png')
    },
    logsIcon: {
        web: '../../assets/logsIcon.png',
        mobile: require('../../assets/logsIcon.png')
    },
    antibioticIcon: {
        web: '../../assets/antibioticIcon.png',
        mobile: require('../../assets/antibioticIcon.png')
    },
    settingsIcon: {
        web: '../../assets/settingsIcon.png',
        mobile: require('../../assets/settingsIcon.png')
    },
    seizureIcon: {
        web: '../../assets/seizureIcon.png',
        mobile: require('../../assets/seizureIcon.png')
    },
    sideEffectIcon: {
        web: '../../assets/sideEffect.png',
        mobile: require('../../assets/sideEffect.png')
    },
    calendarIcon: {
        web: '../../assets/calendarIcon.png',
        mobile: require('../../assets/calendarIcon.png')
    },
    chatIcon: {
        web: '../../assets/chatIcon.png',
        mobile: require('../../assets/chatIcon.png')
    },
    doctorDefaultLogo: {
        web: '../../assets/doctorDefaultLogo.png',
        mobile: require('../../assets/doctorDefaultLogo.png')
    },
    homeIconFilled: {
        web: '../../assets/homeIconFilled.png',
        mobile: require('../../assets/homeIconFilled.png')
    },
    homeIconLine: {
        web: '../../assets/homeIconLine.png',
        mobile: require('../../assets/homeIconLine.png')
    },
    diaryIconFilled: {
        web: '../../assets/diaryIconFilled.png',
        mobile: require('../../assets/diaryIconFilled.png')
    },
    diaryIconLine: {
        web: '../../assets/diaryIconLine.png',
        mobile: require('../../assets/diaryIconLine.png')
    },
    treatmentIconFilled: {
        web: '../../assets/treatmentIconFilled.png',
        mobile: require('../../assets/treatmentIconFilled.png')
    },
    treatmentIconLine: {
        web: '../../assets/treatmentIconLine.png',
        mobile: require('../../assets/treatmentIconLine.png')
    },
    settingsIconFilled: {
        web: '../../assets/settingsIconFilled.png',
        mobile: require('../../assets/settingsIconFilled.png')
    },
    settingsIconLine: {
        web: '../../assets/settingsIconLine.png',
        mobile: require('../../assets/settingsIconLine.png')
    },
    studyIconFilled: {
        web: '../../assets/studyIconFilled.png',
        mobile: require('../../assets/studyIconFilled.png')
    },
    studyIconLine: {
        web: '../../assets/studyIconLine.png',
        mobile: require('../../assets/studyIconLine.png')
    },
    icon_book: {
        web: '../../assets/icon-book-default.png',
        mobile: require('../../assets/icon-book-default.png')
    },
    drugs_default: {
        web: '../../assets/drugs-default.png',
        mobile: require('../../assets/drugs-default.png')
    },
    hand_plant: {
        web: '../../assets/hand-plant-default.png',
        mobile: require('../../assets/hand-plant-default.png')
    },
    megaphone_default: {
        web: '../../assets/megaphone-default.png',
        mobile: require('../../assets/megaphone-default.png')
    },
    helpiAddIconFilled: {
        web: '../../assets/helpiAddIconFilled.png',
        mobile: require('../../assets/helpiAddIconFilled.png')
    },
    MMAddIconFilled: {
        web: '../../assets/MMAddIconFilled.png',
        mobile: require('../../assets/MMAddIconFilled.png')
    },
    favIcon: {
        web: '../../assets/favicon16.png',
        mobile: require('../../assets/favicon16.png')
    },
    programsIconFilled: {
        web: '../../assets/programsIconFilled.png',
        mobile: require('../../assets/programsIconFilled.png')
    },
    programsIconLine: {
        web: '../../assets/programsIconLine.png',
        mobile: require('../../assets/programsIconLine.png')
    },
    programsIconFilledBlack: {
        web: '../../assets/programsIconFilledBlack.png',
        mobile: require('../../assets/programsIconFilledBlack.png')
    },
    noImage: {
        web: '../../assets/no_image.png',
        mobile: require('../../assets/no_image.png')
    },
    actionIcon: {
        web: '../../assets/actionIcon.png',
        mobile: require('../../assets/actionIcon.png')
    },
    ribbonIcon: {
        web: '../../assets/ribbonIcon.png',
        mobile: require('../../assets/ribbonIcon.png')
    },
    fdaLabel: {
        web: 'https://s3.eu-central-1.amazonaws.com/helpilepsy-content/images/fdaBarcode.png',
        mobile: {
            uri: 'https://s3.eu-central-1.amazonaws.com/helpilepsy-content/images/fdaBarcode.png',
        }
    },
    biometric: {
        web: '../../assets/biometric.png',
        mobile: require('../../assets/biometric.png')
    },
    helpilepsyPlusLogo: {
        web: 'https://helpilepsy-content.s3.eu-central-1.amazonaws.com/images/helpilepsy_plus_logo.png',
        mobile: {
            uri: 'https://helpilepsy-content.s3.eu-central-1.amazonaws.com/images/helpilepsy_plus_logo.png',
        }
    },
    pillIcon: {
        web: '../../assets/pillIcon.png',
        mobile: require('../../assets/pillIcon.png')
    },
    headacheIconBlack: {
        web: '../../assets/icon-headache-black.png',
        mobile: require('../../assets/icon-headache-black.png')
    },
    headacheIconWhite: {
        web: '../../assets/icon-headache-white.png',
        mobile: require('../../assets/icon-headache-white.png')
    },
    headacheIconGreen: {
        web: '../../assets/headache-green.png',
        mobile: require('../../assets/headache-green.png')
    }
}

export default Images;