import React, {useState, useEffect} from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {convertLangCode} from 'common/src/services/translationList';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { getDotsColor } from './utils';
import { ThemeColors } from '../../../../../*';

type EventTypesProps = {
  color: string;
  type: string;
}

type EventProps = {
  date: string;
  id: string;
  type: string;
  value?: number;
  reminder?: Record<any, any>;
  taken?: boolean;
  taken_date?: string;
  name?: string;
  form?: Record<any, any>;
  updatedAt?: string;
}

const CommonWeekView = ({ data, focus, startEndDay, elScroll}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const today = DateTime.now();
  const {i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  const [selectedDay, setSelectedDay] = useState<string>(DateTime.fromFormat(new Date().toLocaleString(),'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
  const [clickedDay, setClickedDay] = useState<string>('')

  useEffect(() => {
    if(data.length > 0 ){
      if(focus === -1) {
        setSelectedDay(DateTime.fromFormat(new Date().toLocaleString(),'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
      }
      else if (clickedDay !== '') {
        setClickedDay('')
      }
      else if(focus >= 0 && clickedDay === '') {
        setSelectedDay(DateTime.fromFormat((data[focus]?.date || today.toString()),'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
      }
    }
  },[data,focus]);
  
  // this func send back to the parent component(Diary) the position we want to scroll to & also update the selected day
  const scrollFunc = (day: string) => {
    elScroll(DateTime.fromFormat(day,'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
    setSelectedDay(DateTime.fromFormat(day,'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
    setClickedDay(day)
  }

  const getEventTypes = (events: EventProps[]) => {
    const types:Array<EventTypesProps> = [];
    events.forEach((event) => {
      if (event.type !== 'mood' && event.type !== 'sleep' && !types.find(item => item.type === event.type))
        types.push({
          type: event.type,
          color: getDotsColor(event.type, colors)
        })
    })
    return types
  }

  return (
    // creating the visual part of the week view
    <>
      <View  style={[styles.item, {flexDirection:'row'}]}>
        {data.slice(startEndDay[0], startEndDay[1]).map((day:any) =>{
          const eventTypes = getEventTypes(day.events);
          return (
            <TouchableOpacity 
              testID={'weekview_'} 
              onPress={()=>{scrollFunc(day.date)}} 
              key={day.date} 
              style={
                DateTime.fromFormat(day.date,'yyyy-MM-dd').toFormat('yyyy-MM-dd')=== selectedDay
                ?
                [styles.weekDay,{flexDirection:'column', width: '14.5%',alignItems:'center'}]
                :
                {flexDirection:'column', width: '14.5%',alignItems:'center', paddingVertical: PR(15)}}
            >
              <Text style={{textTransform: 'uppercase', fontSize: PR(10),color: colors.primary}}>{DateTime.fromISO(day.date, { locale: lang }).toFormat('EEE')}</Text>
              <Text style={{
                fontSize: PR(18), 
                color: colors.primary, 
                fontFamily: GlobalStyles.global.fontFamily.Bold}}
              >
                {DateTime.fromISO(day.date).toFormat('dd')}
              </Text>
                <View style={{flexDirection:'row',justifyContent: 'space-around'}}>
                  {
                    eventTypes.map((type: EventTypesProps, index: number) => {
                      return (
                        <View key={index}>
                          <View style={[styles.eventDots,{backgroundColor: type.color}]} />
                          <View style={{padding:PR(1)}}></View>
                        </View>
                      )
                    })
                  }
                </View>
            </TouchableOpacity>
          )
        })}
      </View>
    </>
  )
}

const generateStyleSheet = (colors:ThemeColors) => {
  return StyleSheet.create({
    item: {
      width: '100%',
      alignSelf: 'center',
    },
    weekDay: {
      borderRadius: PR(20),
      backgroundColor: colors.selectTimelineContainer,
      paddingVertical: PR(15),
    },
    eventDots: {
      height: PR(5),
      width: PR(5),
      borderRadius: PR(5 / 2),
    },
  })
}

export default CommonWeekView;