import React, {useState, useRef} from 'react';
import {Platform, Pressable, TouchableOpacity, View} from "react-native";
import Text from "./CommonCustomTxt";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {convertLangCode} from 'common/src/services/translationList';
import Icon from 'react-native-vector-icons/Feather';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import GlobalStyles from 'common/src/styles/GlobalStyles';

const CommonDateInput = ({ value, onChange, fieldStyle, textStyle, webInputStyle, openPicker, maxDate, minDate, isValueDeletable, deleteDate, errorMessage }:any) => {
    const {i18n} = useTranslation();
    const lang = convertLangCode(i18n.language)
    const [showDateInputModal, setShowDateInputModal] = useState(openPicker || false);
    const inputRef = useRef(null);

    const onDeleteDateClick = () => {
        // @ts-ignore (us this comment if typescript raises an error)
        inputRef.current.value = "";
        deleteDate(null);
    };

    return (
        <>
            <DateTimePickerModal
                date={value ? DateTime.fromFormat(value, 'dd-MM-yyyy').toJSDate() : new Date()}
                isVisible={showDateInputModal}
                mode="date"
                maximumDate={maxDate ? maxDate : undefined}
                minimumDate={minDate ? minDate : undefined}
                onConfirm={(date) => {
                    setShowDateInputModal(false);
                    onChange(DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd'));
                }}
                onCancel={() => {
                    setShowDateInputModal(false);
                }}
                locale={lang}
            />
            {Platform.OS === 'web' ? (
                <View style={[fieldStyle, {flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems:'center'}]} >
                    <View style={{width: '95%'}}>
                        <Text style={textStyle}>
                            <input
                                style={webInputStyle}
                                type="date"
                                defaultValue={value && DateTime.fromFormat(value, 'dd-MM-yyyy').toFormat('yyyy-MM-dd')}
                                max={maxDate ? DateTime.fromJSDate(maxDate).toFormat('yyyy-MM-dd') : undefined}
                                min={minDate ? DateTime.fromJSDate(minDate).toFormat('yyyy-MM-dd') : undefined}
                                ref={inputRef}
                                onChange={e => {
                                    onChange(e.target.value);
                                }}
                            />
                        </Text>
                    </View>
                    {isValueDeletable && 
                        <TouchableOpacity style={{width: '5%'}} onPress={() => {onDeleteDateClick()}}>
                            <Icon name="trash-2" size={PR(15)} color={GlobalStyles.global.redError}/>
                        </TouchableOpacity>
                    }
                </View>
            ) : (
                <Pressable onPress={() => setShowDateInputModal(true)}>
                    <View style={[fieldStyle, {flexDirection: 'row', justifyContent: 'space-between', alignItems:'center'}]} >
                        <Text renderAsHtml={true} style={textStyle}>{value? value : 'dd-MM-yyyy'}</Text>
                        {isValueDeletable &&
                            <TouchableOpacity onPress={() => deleteDate(null)}>
                                <Icon name="trash-2" size={PR(15)} color={GlobalStyles.global.redError}/>
                            </TouchableOpacity>
                        }
                    </View>
                </Pressable>
            )}
            <Text style={{color: GlobalStyles.global.redError, fontSize: PR(12), textAlign: "center"}}>{errorMessage}</Text>
        </>
    );
}


export default CommonDateInput;