import React, {useState, useEffect, useReducer, Fragment} from 'react';
import {View, StyleSheet, ScrollView, TouchableOpacity, Platform} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonTitle from './CommonTitle';
import CommonBtn from './CommonBtn';
import Text from './CommonCustomTxt';
import InputField from './InputField';
import CommonFooter from './CommonFooter';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import {useTranslation} from 'react-i18next';
import {checkBottomScroll, checkFrequency} from '../services/utils';
import { translate } from '../services/translationList';
import CommonTimeInput from "./CommonTimeInput";
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { ThemeColors } from '../../../../*';

// find the translation value of the key in an array of object in the CMS
const getListCMSData = (t, key, list) => {
  // @ts-ignore
  let index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
    Object.keys(itemType).toString() === key)
  if (index > -1) 
    return t('translation:'+list+'.'+index+'.'+key)
}

const CommonReminderView = ({navigation, route, setScroll, onClick}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  let week = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']
  const [intakeType, setIntakeType] = useState<string>();
  const {t} = useTranslation();
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [times, setTimes] = useReducer(
    (state:any, newState:any) => ({...state, ...newState}),
    {
      everyDay: undefined,
      0: undefined,
      1: undefined,
      2: undefined,
      3: undefined,
      4: undefined,
      5: undefined,
      6: undefined
    }
    )
  useEffect(() => {
    setIntakeType(checkDays())
    // initialize the state
    route.params.time.map(item => {
      if(Object.keys(item).toString() === route.params.moment) {
        item[route.params.moment].map((tItem, index) => {
          if(tItem === 'Invalid date')
            tItem = '07:00'
          setTimes({[index]: tItem})
          setTimes({everyDay: tItem})
        })
      }
    })
  },[])

  const checkDays = () => {
    let count = 0 
    let i:any = 0
    route.params.time.map(m => {
      if(Object.keys(m).toString() === route.params.moment) {
        for(i in m[route.params.moment]){
          if(m[route.params.moment][i] === m[route.params.moment][i-1]){
            count++
          }
        }
      }
    })
    if(count >= 6)
      return 'every'
    else
      return 'custom'
  }

  const editBtn = () => {
    let obj
    if(intakeType === 'every')
      obj= {[route.params.moment] : [times.everyDay, times.everyDay, times.everyDay, times.everyDay, times.everyDay, times.everyDay, times.everyDay]}
    else
      obj= {[route.params.moment] : [times[0], times[1], times[2], times[3], times[4], times[5], times[6]]}
    onClick(obj)
  }

  const setEveryDayTime = (time) => {
    if(time === '')
      time = '07:00'
    setTimes({everyDay: time})
  }

  const setEveryCustom = (i, time) => {
    if(time === '')
      time = '07:00'
    setTimes({[i]: time})
  }
  return(
    <>
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          testID="AddEvent_scrollView"
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, PR(15));
            if (!scrollAtBottom && calc)
              setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
              setScrollAtBottom(false)
            setScroll(event.nativeEvent.contentOffset.y);
          }}
        >
          <View
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            <CommonTitle text={getListCMSData(t, route.params.moment, 'list.moments')} color={colors.primary}/>
            <InputField
              labelText={t('translation:treatment.reminder.intake')}
              inputType="dropdown" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setIntakeType}
              placeholderTxt={t('translation:modal.select.title_one')}
              testID="intake_id"
              value={intakeType === undefined ? null :t('translation:list.reminder_frequencies.'+intakeType)}
              data={[
                { label: t('translation:list.reminder_frequencies.every'), value: 'every' },
                { label: t('translation:list.reminder_frequencies.custom'), value: 'custom' },
              ]}
            />
            <View style={styles.intakeDaysContainer}>
              {/* display the custom days or every day(default) */}
              {route.params.time.map((item, index) => {
                  if(Object.keys(item).toString() === route.params.moment) {
                    return (
                      <Fragment key={index}>
                        {intakeType === 'every' ?
                          <View style={styles.customDaysContainer}>
                            <Text>{t('translation:treatment.reminder.time')}</Text>
                            <CommonTimeInput
                              onChange={(e) => setEveryDayTime(e)}
                              value={times.everyDay}
                              fieldStyle={{paddingHorizontal: PR(20)}}
                              textStyle={{color:colors.primary, fontFamily: GlobalStyles.global.fontFamily.Regular}}
                              webInputStyle={{
                                // backgroundColor: "#F2F4F5", 
                                border: "none", 
                                width: "100%", 
                                paddingHorizontal: PR(20), 
                                color: colors.primary,
                                fontSize: PR(16),
                                fontFamily: GlobalStyles.global.fontFamily.Medium
                              }}
                            />
                          </View>
                        :
                          <>
                          {item[route.params.moment].map((tItem, index) => {
                            return (
                              <Fragment key={index}>
                              <View style={styles.customDaysContainer}>
                                <Text>{t('translation:general.date.days.'+index+'.'+week[index])}</Text>
                                <CommonTimeInput
                                  onChange={(e) => setEveryCustom(index, e)}
                                  value={times[index]}
                                  fieldStyle={{paddingHorizontal: PR(20)}}
                                  textStyle={{color:colors.primary, fontFamily: GlobalStyles.global.fontFamily.Regular}}
                                  webInputStyle={{
                                    // backgroundColor: "#F2F4F5", 
                                    border: "none", 
                                    width: "100%", 
                                    paddingHorizontal: PR(20), 
                                    color: colors.primary,
                                    fontSize: PR(16),
                                    fontFamily: GlobalStyles.global.fontFamily.Medium
                                  }}
                                />
                              </View>
                              </Fragment>
                            )
                          })}
                          </>
                        }
                      </Fragment>
                    )
                  }  
                }
              )}
            </View>
            <View style={styles.wrapper}>
              <View style={styles.medContainer}>
                <View style={styles.horizontalDivider}>
                  <Text renderAsHtml={true} style={styles.medMomentTxt}>{t('translation:treatment.reminder.medication')}</Text>
                </View>
                <View style={{paddingTop:PR(10)}}>
                  {route.params.medications.map((med, index) => {
                    return (
                      <Fragment key={index}>
                      {med?.intake?.dosage?.map((dos,i) =>{
                        if(dos.moment === route.params.moment ){
                          return(
                            // display the drug name, dose, unit
                            <Text key={i} style={styles.medTxt}>{med.name+' '+dos.dose+' '+translate(t, dos.unit, 'list.units')+' '+'('+checkFrequency(t, med.intake.days)+')'}</Text>
                          )
                        }
                      })}
                      </Fragment>
                    )
                  })}
                </View>
              </View>
            </View>
            <TouchableOpacity onPress={() => navigation.navigate('Main', {screen: 'Treatments'})} style={{alignItems:'center', width:'100%', marginBottom: PR(35)}}>
              <Text renderAsHtml={true} style={{color:GlobalStyles[APP_SHORTCUT_NAME].btnColor, fontSize: PR(16), textDecorationLine:'underline'}}>{t('translation:treatment.reminder.info_edit')}</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
      <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
        <CommonBtn type="primary" click={editBtn} title={t('translation:treatment.reminder.edit')} testID="edit_reminders_id"/>
      </CommonFooter>
      <View style={{marginBottom:Platform.OS === 'ios' ? PR(15) : 0}}></View>
    </>
  )
}

const generateStyleSheet = (colors: ThemeColors) => {
  return StyleSheet.create({
    wrapper: {
      backgroundColor: colors.inputBackground,
      borderRadius: PR(20),
      width:'90%',
      alignSelf:'center', 
      marginBottom:PR(30)
    },
    scrollViewWrapper: {
      flex:1,
      marginTop: PR(5),
    },
    scrollview: {
      flex:1,
      paddingTop: PR(20),
    },
    intakeDaysContainer: {
      width: '90%',
      alignSelf: 'center',
      marginBottom:PR(30)
    },
    medContainer: {
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
    },
    medMomentTxt: {
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium,
      color: colors.primary,
      paddingBottom:PR(10)
    },
    medTxt: {
      flexGrow: 1,
      color: colors.primary,
      marginLeft: PR(10),
      fontSize: PR(12),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    horizontalDivider: {
      borderBottomColor: GlobalStyles.global.white,
      borderBottomWidth: 1,
    },
    customDaysContainer: {
      paddingVertical: PR(10),
      flexDirection:'row', 
      justifyContent:'space-between',
      alignItems: 'center',
      borderBottomColor: GlobalStyles.global.lightGrey,
      borderBottomWidth: PR(1),
    }
  })
}

export default CommonReminderView;