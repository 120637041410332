import React, {useState, useEffect} from 'react';
import { StyleSheet, View, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonActionModal from '../CommonActionModal';
import Text from '../CommonCustomTxt';
import CommonSwitchBtn from 'common/src/components/CommonSwitchBtn';
import Medications from './Medications';
import GlobalStyles from '../../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../../env.json';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { useSelector } from 'react-redux';

const CommonReminder = ({ReminderData, isSubmitButtonActive, getMedicationsRes, navigation}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const [displayWhenReceiveModal, setDisplayWhenReceiveModal] = useState<boolean>(false);
  const [displayHowLongModal, setDisplayHowLongModal] = useState<boolean>(false);
  const [displayHowMuchModal, setDisplayHowMuchModal] = useState<boolean>(false);
  const patients = useSelector((state) => state.getMe.patient)
  const [whenReceive, setWhenReceive] = useState<string>();
  const [howLong, setHowLong] = useState<string | number>();
  const [howMuch, setHowMuch] = useState<string | number>();
  const [answer, setAnswer] = useState<boolean>();
  const [reminders, setReminders] = useState<Array<any>>([]);
  const [reminderSettings, steReminderSettings] = useState<object>()

  useEffect(() => {
    if(patients !== undefined) {
      setAnswer(patients.reminder_settings.notification)
      setWhenReceive(patients.reminder_settings.when)
      steReminderSettings(patients.reminder_settings)
      if(patients.reminder_settings.when === 'before'){
        setHowLong(convertTme(patients.reminder_settings.time))
      } 
      else if(patients.reminder_settings.when === 'after'){
        setHowMuch(convertTme(patients.reminder_settings.time))
      }
    }
  },[patients])
  // checking if btn clicked
  useEffect(() => {
    if(isSubmitButtonActive)
      sendDataBack()
    if(getMedicationsRes !== undefined)
      setReminders(getMedicationsRes)

  },[isSubmitButtonActive, getMedicationsRes])

  // checking which values are selected inside the modal
  const selectedReceiveModalValue = (sValue) => {
    let value = JSON.parse(sValue.replace(/[\[\]']+/g,''))
    if(value !== undefined) {
      if(value === whenReceive)
        return true
    }
  }

  // checking which values are selected inside the modal
  const selectedTimeValue = (sValue, type) => {
    if(sValue !== undefined) {
      if(type === 'how_much' && sValue === howMuch)
        return true
      if(type === 'how_long' && sValue === howLong)
        return true
    }
  }
  // send the data to parent component in order to do the request
  const sendDataBack = () => {
    let obj = {
      type: 'reminder',
      early_morning: patients.reminder_settings.early_morning,
      morning: patients.reminder_settings.morning,
      noon: patients.reminder_settings.noon,
      afternoon: patients.reminder_settings.afternoon,
      evening: patients.reminder_settings.evening,
      notification: answer,
      time: whenReceive === 'before' ? convertTme(howLong) : whenReceive === 'after' ? convertTme(howMuch) : 0,
      when: whenReceive,
      btnValue: false
    }
    ReminderData(obj)
  }

  const changeModalVisibility = (type) => {
    switch(type){
      case 'when_receive': 
        setDisplayWhenReceiveModal(!displayWhenReceiveModal)
        break;
      case 'how_long':
        setDisplayHowLongModal(!displayHowLongModal)
        break;
      case 'how_much':
        setDisplayHowMuchModal(!displayHowMuchModal)
        break;
      default:
        break;
    }
  }
  const convertTme = (value) => {
    switch(value){
      case '1h':
        return 60;
      case '2h':
        return 120;
      case '3h':
        return 180;
      case 60:
        return '1h';
      case 120: 
        return '2h';
      case 180:
        return '3h';
      default:
        return 0
    }
  }

  const updateRadioType = (values, type) => {
    let value = ''
    values.map((item:any) => {
      if(item.selected !== undefined && item.selected && type === 'when_receive')
        value = JSON.parse(item.value.replace(/[\[\]']+/g,''))
      else if(item.selected !== undefined && item.selected && (type === 'how_long' || type === 'how_much'))
        value = item.value
    })
    switch(type){
      case 'when_receive':
        setWhenReceive(value)
        setDisplayWhenReceiveModal(!displayWhenReceiveModal)
        break;
      case 'how_long':
        setHowLong(value)
        setDisplayHowLongModal(!displayHowLongModal)
        break;
      case 'how_much':
        setHowMuch(value)
        setDisplayHowMuchModal(!displayHowMuchModal)
        break;
      default:
        return;
    }
  }

  const displayValues = (key, list) => {
    // @ts-ignore
    let index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=> {
      return Object.keys(itemType).toString() === key
    })
    if (index > -1) 
      return t('translation:'+list+'.'+index+'.'+key)
    else 
      return key
  }
  return (
    <>
      { displayWhenReceiveModal &&
        <CommonActionModal 
          testID={"WhenReceiveModalID"}
          title={t('translation:modal.select.title_one')} 
          modalType={'select-radio'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: (e)=>updateRadioType(e, 'when_receive'), action: 'save'}]} 
          onClose={()=>changeModalVisibility('when_receive')} 
          // @ts-ignore
          data={t<string | TemplateStringsArray>('translation:treatment.intakes.reminders_list', {returnObjects: true}).map((name,i) => {
            return {label:t('translation:treatment.intakes.reminders_list.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), selected:selectedReceiveModalValue(JSON.stringify(Object.keys(name))), position: i}
          })}
          other={false}
        />
      }
      { displayHowLongModal &&
        <CommonActionModal 
          testID={"HowLongModalID"}
          title={t('translation:modal.select.title_one')} 
          modalType={'select-radio'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: (e)=>updateRadioType(e, 'how_long'), action: 'save'}]} 
          onClose={()=>changeModalVisibility('how_long')} 
          // @ts-ignore
          data={[
            {label:'1h', value:'1h', selected:selectedTimeValue('1h', 'how_long')},
            {label:'2h', value:'2h', selected:selectedTimeValue('2h', 'how_long')},
            {label:'3h', value:'3h', selected:selectedTimeValue('3h', 'how_long')}
          ]}
          other={false}
        />
      }
      { displayHowMuchModal &&
        <CommonActionModal 
          testID={"HowMuchModalID"}
          title={t('translation:modal.select.title_one')} 
          modalType={'select-radio'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: (e)=>updateRadioType(e, 'how_much'), action: 'save'}]} 
          onClose={()=>changeModalVisibility('how_much')} 
          // @ts-ignore
          data={[
            {label:'1h', value:'1h', selected:selectedTimeValue('1h', 'how_much')},
            {label:'2h', value:'2h', selected:selectedTimeValue('2h', 'how_much')},
            {label:'3h', value:'3h', selected:selectedTimeValue('3h', 'how_much')}
          ]}
          other={false}
        />
      }
      <View style={styles.toogleContainer}>
        <Text style={styles.txt}>{t('translation:treatment.intakes.activate_reminders')}</Text>
        <CommonSwitchBtn 
          isOn={answer}
          size="medium"
          onToggle={setAnswer}
          testID="activate_reminders"
        />
      </View>
      {answer &&
        <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
          <Text style={styles.label}>{t('translation:treatment.intakes.when')}</Text>
          <TouchableOpacity style={styles.container} onPress={() => setDisplayWhenReceiveModal(!displayWhenReceiveModal)}>
            <View style={styles.inputContainer}>
              <Text style={styles.inputValue}>{whenReceive === undefined ? t('translation:modal.select.title_one'): displayValues(whenReceive, 'treatment.intakes.reminders_list')}</Text>
            </View>
          </TouchableOpacity>
        </View>
      }
      {whenReceive === 'before' && answer &&
        <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
          <Text style={styles.label}>{t('translation:treatment.intakes.hours_before')}</Text>
          <TouchableOpacity style={styles.container} onPress={() => setDisplayHowLongModal(!displayHowLongModal)}>
            <View style={styles.inputContainer}>
              <Text style={styles.inputValue}>{howLong === undefined ? t('translation:modal.select.title_one'): howLong}</Text>
            </View>
          </TouchableOpacity>
        </View>
      }
      {whenReceive === 'after' && answer &&
        <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
          <Text style={styles.label}>{t('translation:treatment.intakes.hours_later')}</Text>
          <TouchableOpacity style={styles.container} onPress={() => setDisplayHowMuchModal(!displayHowMuchModal)}>
            <View style={styles.inputContainer}>
              <Text style={styles.inputValue}>{howMuch === undefined ? t('translation:modal.select.title_one'): howMuch}</Text>
            </View>
          </TouchableOpacity>
        </View>
      }
      {
        reminders.length === 0 &&
        <Text style={styles.reminderTextTitle}>{t('translation:treatment.reminder.no_reminders')}</Text>
      }
      {
        reminders.length > 0 && answer &&
        <>
          <Text style={styles.reminderTextTitle}>{t('translation:treatment.reminder.all_reminders')}</Text>
          <Medications 
            medications={reminders} 
            reminder_settings={reminderSettings}
            navigation={navigation}
          />
        </>
      }
      <View style={{marginBottom:PR(50)}}></View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    label: { 
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(12)
    },
    container: {
      backgroundColor: colors.inputBackground,
      borderRadius: PR(20),
    },
    inputContainer: {
      flex: 1,
      flexDirection: 'row',
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputValue: {
      flex: 1,
      flexGrow: 1,
      color: colors.primary,
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    txt: {
      fontSize: PR(16),
      marginLeft: PR(15),
      color: colors.primary
    },
    reminderTextTitle: {
      fontSize: PR(16),
      color: GlobalStyles[APP_SHORTCUT_NAME].color,
      alignSelf:'center',
      fontFamily: GlobalStyles.global.fontFamily.Bold,
      paddingBottom: PR(10)
    },
    toogleContainer:{
      flexDirection:'row',
      alignItems:'center',
      alignSelf:'center',
      width:'90%',
      justifyContent:'space-between',
      flex:1,
      paddingBottom: PR(20),
    },
  })
}

export default CommonReminder;