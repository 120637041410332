import React from 'react';
import {View, StyleSheet} from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

type IProps = {
  text: string,
  txtAlign?: "center" | "auto" | "left" | "right" | "justify",
}

const CommonSubTitle = ({text, txtAlign}:IProps) => {
  const { colors } = useTheme();
  txtAlign = txtAlign || "center";
  const styles: any = generateStyleSheet(colors, txtAlign);
  return (
    <>
      <View>
         <Text renderAsHtml={true} style={styles.subtitle}>{text}</Text>
      </View>
    </>
  );
}

const generateStyleSheet = (colors:Record<string, string>, txtAlign: any) => {
  return StyleSheet.create({
    subtitle: {
      fontSize: PR(16),
      textAlign: txtAlign,
      lineHeight: PR(25),
      color: colors.subtitleColor,
    }
  })
}

export default CommonSubTitle;