import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from '../CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { APP_SHORTCUT_NAME } from 'common/src/env.json';
import CommonImage from '../CommonImage';
import { ThemeColors } from '../../../../../*';

type OwnProps = {
    dataItem: DataItemTypes,
    isExpanded: boolean,
    updateDisplayList: (index: number) => void;
    index: number,
    children: any,
}

interface DataItemTypes {
    itemTitle: string,
    itemType: string,
    icon?: string,
}

const CommonExpandContainer = ({
    dataItem,
    isExpanded,
    updateDisplayList,
    index,
    children
}: OwnProps) => {

    const colors = useTheme().colors;
    const styles: any = generateStyleSheet(colors, dataItem?.itemType);
    const updateDisplay = () => {
        updateDisplayList(index)
    }
    return (
        <View
            style={styles.container}
            testID={`expanded_container_${index}`}
        >
            <TouchableOpacity onPress={updateDisplay} style={styles.wrapper}>
                <View style={styles.sectionContainer}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                        {dataItem?.icon &&
                            <View
                                style={styles.sectionIcon}
                                testID={`expanded_icon_${index}`}
                            >
                                {
                                    dataItem?.itemType === 'headache' ?
                                        <CommonImage name={dataItem.icon} height={PR(25)} width={PR(25)} />
                                        :
                                        <Icon
                                            name={dataItem.icon}
                                            size={PR(20)}
                                            color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}
                                        />
                                }
                            </View>
                        }
                        <View style={{ width: dataItem?.icon ? '80%' : '90%', paddingLeft: dataItem?.icon ? 0 : PR(10) }}>
                            <Text
                                style={styles.sectionTitle}
                                testID={`expanded_title_${index}`}
                            >
                                {dataItem.itemTitle
                                }
                            </Text>
                        </View>
                        <View style={styles.colapseIconContainer}>
                            <Icon
                                name={isExpanded ? "chevron-up" : "chevron-down"}
                                size={PR(20)}
                                color={GlobalStyles.global.grey}
                            />
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
            {
                isExpanded &&
                <>
                    <View style={styles.horizontalDivider}></View>
                    <View style={styles.bottomContainer}>
                        <View testID='expanded_content' style={styles.bottomWrapper}>
                            {children}
                        </View>
                    </View>
                </>
            }
        </View>
    )
}

const generateStyleSheet = (colors: ThemeColors, itemType: string) => {
    return StyleSheet.create({
        container: {
            borderRadius: PR(20),
            borderWidth: 0.2,
            borderColor: colors.inputBackground,
            width: '90%',
            alignSelf: 'center',
            marginBottom: PR(10),
            backgroundColor: colors.inputBackground,
            shadowColor: colors.shadowColor,
            shadowOffset: {
                width: PR(2),
                height: PR(10),
            },
            shadowOpacity: 0.10,
            shadowRadius: 13.97,
            elevation: 10,
        },
        wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        sectionContainer: {
            flex: 1,
            paddingVertical: PR(15),
            paddingHorizontal: PR(10),
            fontSize: PR(16),
            width: '80%',
        },
        sectionTitle: {
            fontSize: PR(16),
            fontFamily: GlobalStyles.global.fontFamily.Medium,
            color: colors.primary,
            textTransform: 'capitalize'
        },
        colapseIconContainer: {
            width: '10%',
            paddingRight: PR(10),
            alignItems: 'flex-end'
        },
        sectionIcon: {
            width: '10%',
            paddingLeft: itemType === 'headache' ? PR(5) : PR(10),
            alignItems: 'flex-start'
        },
        horizontalDivider: {
            borderBottomColor: GlobalStyles.global.grey,
            borderBottomWidth: PR(1),
        },
        bottomContainer: {
            backgroundColor: colors.background,
            borderBottomLeftRadius: PR(20),
            borderBottomRightRadius: PR(20),
            borderColor: colors.inputBackground,
            borderWidth: 0.2,
            padding: PR(20),
            flexDirection: 'column',
            shadowColor: colors.shadowColor,
            shadowOffset: {
                width: PR(2),
                height: PR(10),
            },
            shadowOpacity: 0.10,
            shadowRadius: 13.97,
            elevation: 10,
            overflow: 'hidden'
        },
        bottomWrapper: {
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
    })
};

export default CommonExpandContainer;