import React from "react";
import {View, TouchableOpacity, StyleSheet, SafeAreaView} from 'react-native';
import { useTheme } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from "common/src/styles/GlobalStyles";
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { APP_SHORTCUT_NAME } from 'common/src/env.json';

type OwnTypes = {
    setInput: (num: string) => void;
    deleteInput: () => void;
    isConfirmActive: boolean;
    sendConfirmation: () => void;
}

const CommonNumPad = ({setInput, deleteInput, isConfirmActive, sendConfirmation}:OwnTypes) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);

    return(
        <SafeAreaView style={styles.container}>
            <View style={styles.numRowContainer}>
                {["1","2","3"].map((num:string) => {
                    return(
                        <TouchableOpacity
                            key={num} 
                            style={styles.numKeyContainer}
                            onPress={() =>setInput(num)}
                            testID={`num_id_${num}`}
                        >
                            <Text style={styles.txtNum}>{num}</Text>
                        </TouchableOpacity>
                    )
                })
                }
            </View>
            <View style={styles.numRowContainer}>
                {["4","5","6"].map((num:string) => {
                    return(
                        <TouchableOpacity
                            key={num} 
                            style={styles.numKeyContainer}
                            onPress={() =>setInput(num)}
                            testID={`num_id_${num}`}
                        >
                            <Text style={styles.txtNum}>{num}</Text>
                        </TouchableOpacity>
                    )
                })
                }
            </View>
            <View style={styles.numRowContainer}>
                {["7","8","9"].map((num:string) => {
                    return(
                        <TouchableOpacity
                            key={num} 
                            style={styles.numKeyContainer}
                            onPress={() =>setInput(num)}
                            testID={`num_id_${num}`}
                        >
                            <Text style={styles.txtNum}>{num}</Text>
                        </TouchableOpacity>
                    )
                })
                }
            </View>
            <View style={styles.numRowContainer}>
                <TouchableOpacity
                    style={[styles.numKeyContainer, {backgroundColor: colors.background}]}
                    onPress={() =>deleteInput()}
                    testID={'pin_delete_id'}
                >
                    <Icon name="delete" size={PR(20)} color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}/>
                </TouchableOpacity>
                <TouchableOpacity
                    style={styles.numKeyContainer}
                    onPress={() => setInput("0")}
                    testID={`num_id_${0}`}
                >
                    <Text style={styles.txtNum}>0</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    disabled={!isConfirmActive}
                    style={[styles.numKeyContainer, {borderColor: GlobalStyles.global.greenSuccess, backgroundColor: colors.background, opacity: isConfirmActive ? 1 : 0.5}]}
                    onPress={() =>sendConfirmation()}
                    testID={'pin_validate_id'}
                >
                    <Icon name="lock" size={PR(20)} color={GlobalStyles.global.greenSuccess}/>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    );
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            backgroundColor: colors.background,
            flex: 1,
        },
        numRowContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: PR(5)
        },
        numKeyContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 1,
            width: PR(70),
            height: PR(70),
            borderRadius: PR(70) / 2,
            backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor,
            borderColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor,
            marginRight: PR(15),
            marginLeft: PR(15)
        },
        txtNum: {
            color: colors.background,
            fontFamily: GlobalStyles.global.fontFamily.Bold,
            fontSize: PR(16)
        }
    })
}

export default CommonNumPad;