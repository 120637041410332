import {DateTime} from 'luxon';

export function checkBottomScroll (e, padding) {
    return (e.layoutMeasurement.height + e.contentOffset.y >= e.contentSize.height - padding);
}

export const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - 10;
}

export const generateArrayOfYears = () => {
    let max:any = new Date().getFullYear()
    let min:any = new Date('1903-01-01').getFullYear()
    let years:any = []

    for (let i = max; i >= min; i--) {
        years.push({label:i.toString(), value:i.toString()})
      }
      return years
}

export const checkFrequency = (t, array) => {
  let week = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']
  let count = 0
  let i:any = 0
  for( i in array) {
    if(array[i] === true)
      count++
  }
  if(count > 6)
    return t('translation:treatment.intakes.every_day')
  else {
    let index:Array<number> = []
    array.map((itemPos,itemIndex) => {
      if(itemPos === true)
        index.push(itemIndex)
    })
    return index.map(item => t('translation:general.date.days.'+item+'.'+week[item])).join(', ');
  }
}

export const dateTimeEvents = (date, time) => {
  let dateTime:any = DateTime.fromFormat(date, 'dd-MM-yyyy').toLocal().toFormat('yyyy-MM-dd')+'T'+DateTime.fromFormat(time, 'HH:mm').toFormat('HH:mm:ss')
  dateTime = DateTime.fromISO(dateTime).toUTC().toISO()
  return dateTime
}

export const isSamePassword = (password, confirmPdw) => password.localeCompare(confirmPdw) === 0;

export const isObjectOrObjectElementsEmpty = inputObject => {
  if(!inputObject || Object.keys(inputObject).length === 0)
    return true
  else if(Object.keys(inputObject).length > 0){
    let element = Object.values(inputObject).find((i:any) => i.length > 0)
    if(element)
      return false
    else 
      return true
  } else return false
};

// Seizure graphs global functions
export const defineStartIndex = (otherColorsMapping, otherColors) => {
  if (otherColorsMapping.length < otherColors.length)
      return otherColorsMapping.length;
  else {
      let length = otherColorsMapping.length
      while (length > otherColors.length) {
          length = length - otherColors.length
      }
      return length
  }
}

export const isConnectedToHCP = (Me) => {
  return !!Me?.result?.permissions?.find(item => item.type === 'doctor')
}

export const calculateTotalDaySeizures = (day) => day.seizures.filter(x => x.count > 0).reduce((x, y) => x + y.count, 0)

export const defineSeizureTypeColor = (eventSeizureType, seizureTypeId, Patient, colors) => {
  let seizureType = '';
  Patient?.lists?.neurologist_seizure_types?.map((typeObj) => {
      if (typeObj.id === seizureTypeId)
          seizureType = typeObj.seizureType;
  })

  if (!seizureType)
      seizureType = eventSeizureType

  for (let i = 0; colors[i]; i++) {
      if (colors[i][seizureType])
          return colors[i][seizureType]
  }

  return null;
}

export const defineHeadacheTypeColor = (eventSeizureType, seizureTypeId, Patient, colors) => {
  let seizureType = '';
  Patient?.lists?.neurologist_headache_types?.map((typeObj) => {
      if (typeObj.id === seizureTypeId)
          seizureType = typeObj.seizureType;
  })

  if (!seizureType)
      seizureType = eventSeizureType

  for (let i = 0; colors[i]; i++) {
      if (colors[i][seizureType]) {
          return colors[i][seizureType]
      }
  }

  return null;
}

export const defineSeizureTypeOtherColor = (seizureType, otherColorsMapping) => {
  return otherColorsMapping.findIndex(item => item.type === seizureType)
}

export const displaySeizureTypeName = (seizureType, allSeizureTypes, t) => {
  const seizureTypeIndex = allSeizureTypes?.findIndex((item) => item[seizureType])
  if (seizureTypeIndex === -1)
      return seizureType
  else
      return t('translation:list.seizureList.' + seizureTypeIndex + '.' + seizureType)
}

export const displayHeadacheTypeName = (seizureType, allSeizureTypes, t) => {
  const seizureTypeIndex = allSeizureTypes?.findIndex((item) => item[seizureType])
  if (seizureTypeIndex === -1)
      return seizureType
  else
      return t('translation:list.headache_names_1_9.' + seizureTypeIndex + '.' + seizureType)
}

export const hasSite = (array) => {
  if(array.length > 0){
      return !!array.find(element => element?.site);
  } else return false;
  
}

export const changeEndDateToNextDay = (startDate: string, startTime: string): DateTime => {
    if(startTime > "20:00")
        return DateTime.fromFormat(startDate, "dd-MM-yyyy").plus({days: 1})
    else return DateTime.now()
}