import React, {useState, useEffect} from 'react';
import {StyleSheet, ScrollView, View} from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { registerUserAction } from 'common/src/store/actions/authenticationActions';
import CommonBtn from 'common/src/components/CommonBtn';
import InputField from 'common/src/components/InputField';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonAboutFooter from 'common/src/components/CommonAboutFooter';
import CommonPwdCriteria from 'common/src/components/CommonPwdCriteria';
import CommonAcceptanceCheckbox from 'common/src/components/CommonAcceptanceCheckbox';
import Text from 'common/src/components/CommonCustomTxt';
import Loading from 'common/src/components/Loading';
import {isSamePassword} from 'common/src/services/utils';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import { version } from '../../package.json';
import { getTimeZones } from "@vvo/tzdb";
import moment from 'moment';
import 'moment-timezone';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { checkEmailExistanceAction } from 'common/src/store/digaRegistration/digaRegistrationActions';
import {countries} from 'common/src/services/countries.json';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const Register = ({ navigation }:any) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t, i18n} = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [confirmPdw, setConfirmPassword] = useState<string>('');
  const [emailValidated, setEmailValidated] = useState(false);
  const [scrollState, setScrollState] = useState<number>(0);
  const [criteria, setCriteria] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countries_list, setCountriesList] = useState<Array<any>>([]); 
  const [checkBoxAnswer, setCheckBoxAnswer] = useState<boolean>();
  const [optionalCheckBoxAnswer, setOptionalCheckBoxAnswer] = useState<boolean>();

  useEffect(() => {
    fetchIpLocation();
  }, [])

  const fetchIpLocation = async () => {
    fetch('https://ip.nf/me.json')
    .then(response => response.json())
    .then(async data => {
      countries.sort((a,b) => (a.country === data?.ip?.country) ? -1 : ((b.country !== data?.ip?.country) ? 1 : 0))
      setCountriesList(countries)
    }).catch(() => {
      setCountriesList(countries)
    })
  }

  // keep tracking of the changes of the email input
  const callbackEmail = (text:string) => {
    setEmail(text);
  }
  // keep tracking of the changes of the password input
  const callbackPassword = (text:string) => {
    setPassword(text);
  }

  const checkEmailValidation = (validated: boolean) => {
    setEmailValidated(validated);
  }

  const getTimezone = () => {
    const timezones = getTimeZones();
    const guessedTz = moment.tz.guess(true);
    let foundTz = timezones.find(tz => tz.name === guessedTz || tz.group.includes(guessedTz))
    if(foundTz){
      return foundTz.name
    } else {
      return 'Europe/Brussels'
    }
  }
  // post request in order to register to the app
  const signUp = async () => {
    const timezone = getTimezone();
    setIsLoading(true)

    // if(Platform.OS !== 'web')
    //   removeBiometricKeysOnFirstLaunch({callback: dispatch(biometricKeyExistsAction({key:false}))}); // remove biometrics keys form keychain before registration
    
    let obj = {
      consent: checkBoxAnswer,
      analyticConsent: optionalCheckBoxAnswer,
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
      add_me_as_patient: true,
      settings: {
        language,
        timezone,
        app_version: version,
      },
      address: {
        country: country,
      },
      callback: (e) => {
        setIsLoading(false)
        if(e?.token) 
          navigation?.navigate?.('VerifyEmail');
      },
      errorCallback: () => {
        setIsLoading(false)
      }
    };
    
    const setRegister = () => (registerUserAction(obj));
    setRegister()
    dispatch(checkEmailExistanceAction({
      email: email,
      callback: () => {
        dispatch(setRegister())
      },
      errorCallback: () => {
        setIsLoading(false)
      }
    }))
  }
  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => (!email.length || !password.length || !firstName.length || !lastName.length || !emailValidated || !criteria || !country || !isSamePassword(password, confirmPdw) || !checkBoxAnswer)

  if (isLoading)
    return <Loading netConnected={true}/>

  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader 
        title={t('translation:login.title_register')}
        rightIconNameSecond="settings" 
        rightIconColorSecond={GlobalStyles.migraine.color}
        rightClickSecond={() => navigation.navigate("Settings")}
        scroll={scrollState}
        testIDTitle="registration"
        disableFormsBanner={true}
        />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          testID="register_scroll"
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
              setScrollState(event.nativeEvent.contentOffset.y);
            else if(scrollState > 0 && event.nativeEvent.contentOffset.y === 0)
              setScrollState(0);
          }}
        >
          <InputField
            labelText={t('translation:register.form.inp_first_name')}
            labelTextSize={PR(14)}
            inputType="string"
            customStyle={{marginBottom:PR(30)}}
            changeText={setFirstName}
            placeholderTxt={t('translation:register.form.firstname_placeholder')}
            value={firstName}
            testID="first_name"
          />
          <InputField
            labelText={t('translation:register.form.inp_last_name')}
            labelTextSize={PR(14)}
            inputType="string"
            customStyle={{marginBottom:PR(30)}}
            changeText={setLastName}
            placeholderTxt={t('translation:register.form.lastname_placeholder')}
            value={lastName}
            testID="last_name"
          />
          <InputField
            labelText={t('translation:register.form.inp_email')}
            labelTextSize={PR(14)}
            inputType="email"
            customStyle={{marginBottom:PR(30)}}
            changeText={callbackEmail}
            placeholderTxt={t('translation:register.form.email_placeholder')}
            value={email}
            testID="email"
            isValidationRequired={true}
            changeValidation={checkEmailValidation}
          />
          <InputField
            labelText={t('translation:modal.address_picker.country')}
            inputType="dropdown" 
            customStyle={{marginBottom:PR(30)}}
            changeText={setCountry}
            highlightFirstItem={!country ? true: false}
            data={countries_list.map(c => ({label: c.country, value: c.country}))}
            testID="country"
            value={country === '' ? t('translation:register.form.country.placeholder') : country}
            isSearchEnabled={countries_list.length > 0 ? true : false}
          />
          <InputField
            labelText={t('translation:register.form.inp_password')}
            labelTextSize={PR(14)}
            inputType="password"
            customStyle={{marginBottom:PR(30)}}
            changeText={callbackPassword}
            placeholderTxt={t('translation:register.form.password_placeholder')}
            value={password}
            validated={criteria}
            testID="password"
          />
          <CommonPwdCriteria password={password || ''} checkCriteria={setCriteria}/>
          <InputField 
              labelText={t('translation:profile.change_password.confirm')}
              inputType="password" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setConfirmPassword}
              placeholderTxt={t('translation:profile.change_password.confirm_placeholder')}
              testID="confirm_password"
              value={confirmPdw}
              validated={isSamePassword(password, confirmPdw)}
              error={!isSamePassword(password, confirmPdw)}
              errorText={t('translation:server.errors.passwords_error')}
          />
          <View style={styles.checkboxContainer}>
              <CommonAcceptanceCheckbox testID="acceptanceCheckbox" text={t('translation:consent.consent_text') + ' *'} updateValidation={setCheckBoxAnswer} checked={checkBoxAnswer}/>
          </View>
          {
            language !== 'ko' && 
              <View style={styles.checkboxContainer}>
                <CommonAcceptanceCheckbox testID="acceptanceOptionalCheckbox" text={t('translation:consent.analytic_consent_text_mm')} updateValidation={setOptionalCheckBoxAnswer} checked={optionalCheckBoxAnswer}/>
              </View>
          }
          <Text style={{marginLeft: PR(20), marginBottom: PR(20)}}>{t('translation:consent.required_text')}</Text>
          <View style={{marginBottom: PR(5)}}>
            <CommonBtn type="tertiary" click={() => navigation.navigate('Login')} title={t('translation:login.have_account')} testID="login_btn"/>
          </View>
          <CommonBtn type="primary" click={signUp} title={t('translation:login.form.btn_register')} disable={checkDisable()} testID="register"/>
          <CommonAboutFooter />
        </ScrollView>
      </View>
    </CommonKeyboardAvoidingView>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    scrollViewWrapper: {
      marginTop: PR(5),
      flex: 1,
    },
    scrollview: {
      paddingTop: PR(20),
      flex:1
    },
    nfo: {
      fontSize: PR(14),
      lineHeight: PR(20),
      color: GlobalStyles.global.grey,
      alignSelf: 'center',
      marginTop: PR(10),
      marginBottom: PR(10)
    },
    checkboxContainer: {
      width:'90%',
      alignSelf:'center', 
      paddingVertical: PR(10)
    },
  })
};

export default Register;