import React from 'react';
import CommonAbout from 'common/src/components/CommonAbout';
import { version } from '../../package.json';

const About = ({navigation}) => {
  return (
    <>
      <CommonAbout 
        AppName="MIGRAINEMANAGER" 
        navigation={navigation} 
        footerTitle="MigraineManager" 
        version={version} 
      />
    </>
  )
}

export default About