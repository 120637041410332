export enum VerifyEmailActions {
    VALIDATE_EMAIL = "VALIDATE_EMAIL",
    VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS"
}

export interface ValidateEmailInterface {
    id: string,
    patientId: string
}

export const validateEmailAction = (payload: ValidateEmailInterface) => {
    return {
        type: VerifyEmailActions.VALIDATE_EMAIL,
        ...payload
    };
};