import { call, takeLatest, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { 
    BiometricsActions, 
    EnableBiometricInterface, 
    DisableBiometricInterface, 
    VerifyBiometricInterface, 
    BiometricKeyExistsInterface,
    EnablePinCodeInterface,
    DisablePinCodeInterface,
    GetPinStatusInterface, 
    VerifyPinCodeInterface
} from "./biometricsActions";

export function* enableBiometricSaga(payload: AnyAction & EnableBiometricInterface) {
    yield call(makeRequest, {
        path: `/mfa/enableBiometric/${payload.userID}`,
        method: 'POST',
        data: {
            publicKey: payload.publicKey,
            deviceID: payload.deviceID
        },
        ...payload
    });
}

export function* disableBiometricSaga(payload: AnyAction & DisableBiometricInterface) {
    yield call(makeRequest, {
        path: `/mfa/disableBiometric/${payload.userID}`,
        method: 'POST',
        data: {
            deviceID: payload.deviceID
        },
        ...payload
    });
}

export function* verifyBiometricSaga(payload: AnyAction & VerifyBiometricInterface) {
    yield call(makeRequest, {
        path: `/mfa/verifyBiometricSignature/${payload.userID}`,
        method: 'POST',
        data: {
            signature: payload.signature,
            payload: payload.payload,
            deviceID: payload.deviceID
        },
        ...payload
    });
}

export function* biometricKeyExistsSaga(payload: AnyAction & BiometricKeyExistsInterface) {
    yield put({ type: BiometricsActions.BIOMETRIC_KEY_EXISTS_SUCCESS, response: payload.key });
}

export function* enablePinCodeSaga(payload: AnyAction & EnablePinCodeInterface) {
    yield call(makeRequest, {
        path: `/mfa/enable/pin/${payload.userID}`,
        method: 'POST',
        responseAction: BiometricsActions.ENABLE_PIN_CODE_SUCCESS,
        data: {
            biometric_pin: payload.biometric_pin,
        },
        ...payload
    });
}

export function* disablePinCodeSaga(payload: AnyAction & DisablePinCodeInterface) {
    yield call(makeRequest, {
        path: `/mfa/disable/pin/${payload.userID}`,
        method: 'POST',
        responseAction: BiometricsActions.DISABLE_PIN_CODE_SUCCESS,
        data: {
            biometric_pin: payload.biometric_pin,
        },
        ...payload
    });
}

export function* getPinStatusSaga(payload: AnyAction & GetPinStatusInterface) {
    yield call(makeRequest, {
        path: `/mfa/getPin/status/${payload.userID}?language=${payload.language}`,
        method: 'GET',
        responseAction: BiometricsActions.GET_PIN_STATUS_SUCCESS,
        ...payload
    });
}

export function* verifyPinCodeSaga(payload: AnyAction & VerifyPinCodeInterface) {
    yield call(makeRequest, {
        path: `/mfa/verify/pin/${payload.userID}`,
        method: 'POST',
        data: {
            biometric_pin: payload.biometric_pin,
        },
        ...payload
    });
}

export default function* watch() {
    yield takeLatest(BiometricsActions.ENABLE_BIOMETRIC, enableBiometricSaga);
    yield takeLatest(BiometricsActions.DISABLE_BIOMETRIC, disableBiometricSaga);
    yield takeLatest(BiometricsActions.VERIFY_BIOMETRIC, verifyBiometricSaga);
    yield takeLatest(BiometricsActions.BIOMETRIC_KEY_EXISTS, biometricKeyExistsSaga);
    yield takeLatest(BiometricsActions.ENABLE_PIN_CODE, enablePinCodeSaga);
    yield takeLatest(BiometricsActions.DISABLE_PIN_CODE, disablePinCodeSaga);
    yield takeLatest(BiometricsActions.GET_PIN_STATUS, getPinStatusSaga);
    yield takeLatest(BiometricsActions.VERIFY_PIN_CODE, verifyPinCodeSaga);
}