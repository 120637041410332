export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

export const ALL_SET = 'ALL_SET';
export const ALL_SET_SUCCESS = 'ALL_SET_SUCCESS';

export const GET_ME = 'GET_ME';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';

export const PASSWORD_FORGOTTEN = 'PASSWORD_FORGOTTEN';
export const PASSWORD_FORGOTTEN_SUCCESS = 'PASSWORD_FORGOTTEN_SUCCESS';

export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';

export const UPDATE_AZMM = 'UPDATE_AZMM';
export const UPDATE_AZMM_SUCCESS = 'UPDATE_AZMM_SUCCESS';

export const UPDATE_CONSENT = 'UPDATE_CONSENT';
export const UPDATE_CONSENT_SUCCESS = 'UPDATE_CONSENT_SUCCESS';

export const UPDATE_ONBOARDING_QUESTIONS = 'UPDATE_ONBOARDING_QUESTIONS';
export const UPDATE_ONBOARDING_QUESTIONS_SUCCESS = 'UPDATE_ONBOARDING_QUESTIONS_SUCCESS';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';

export const UPDATE_MOOD_AND_SLEEP = 'UPDATE_MOOD_AND_SLEEP';
export const UPDATE_MOOD_AND_SLEEP_SUCCESS = 'UPDATE_MOOD_AND_SLEEP_SUCCESS';

export const UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS = 'UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS';
export const UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS_SUCCESS = 'UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS_SUCCESS';

export const UPDATE_REMINDERS = 'UPDATE_REMINDERS';
export const UPDATE_REMINDERS_SUCCESS = 'UPDATE_REMINDERS_SUCCESS';

export const UPDATE_MEDICATION_INTAKE = 'UPDATE_MEDICATION_INTAKE';
export const UPDATE_MEDICATION_INTAKE_SUCCESS = 'UPDATE_MEDICATION_INTAKE_SUCCESS';

export const UPDATE_VERSION = 'UPDATE_VERSION';
export const UPDATE_VERSION_SUCCESS = 'UPDATE_VERSION_SUCCESS';

export const ADD_NEUROLOGIST = 'ADD_NEUROLOGIST';
export const ADD_NEUROLOGIST_SUCCESS = 'ADD_NEUROLOGIST_SUCCESS';

export const ADD_EVENT = 'ADD_EVENT';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';

export const ADD_EVENT_MOOD_AND_SLEEP = 'ADD_EVENT_MOOD_AND_SLEEP';
export const ADD_EVENT_MOOD_AND_SLEEP_SUCCESS = 'ADD_EVENT_MOOD_AND_SLEEP_SUCCESS';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const GET_NEUROLOGIST = 'GET_NEUROLOGIST';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';

export const GET_NIGHTWATCH_EVENTS = 'GET_NIGHTWATCH_EVENTS';
export const GET_NIGHTWATCH_EVENTS_SUCCESS = 'GET_NIGHTWATCH_EVENTS_SUCCESS';

export const GET_TIMELINE_EVENTS = 'GET_TIMELINE_EVENTS';
export const GET_TIMELINE_EVENTS_SUCCESS = 'GET_TIMELINE_EVENTS_SUCCESS';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS';

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const ADD_NOTIFICATIONS_SUCCESS = 'ADD_NOTIFICATIONS_SUCCESS';

export const GET_SEIZUREVIDEOS = 'GET_SEIZUREVIDEOS';
export const GET_SEIZUREVIDEOS_SUCCESS = 'GET_SEIZUREVIDEOS_SUCCESS';

export const GET_MEDICATION = 'GET_MEDICATION';
export const GET_MEDICATION_SUCCESS = 'GET_MEDICATION_SUCCESS';

export const GET_MEDICATION_HISTORY = 'GET_MEDICATION_HISTORY';
export const GET_MEDICATION_HISTORY_SUCCESS = 'GET_MEDICATION_HISTORY_SUCCESS';

export const GET_EDIT_MEDICATION_HISTORY = 'GET_EDIT_MEDICATION_HISTORY';
export const GET_EDIT_MEDICATION_HISTORY_SUCCESS = 'GET_EDIT_MEDICATION_HISTORY_SUCCESS';

export const DELETE_NEUROLOGIST = 'DELETE_NEUROLOGIST';
export const DELETE_NEUROLOGIST_SUCCESS = 'DELETE_NEUROLOGIST_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const REMOVE_ACCOUNT_SUCCESS = 'REMOVE_ACCOUNT_SUCCESS';

export const LOADING = 'LOADING';

export const SUCCESS = 'SUCCESS';
export const SUCCESS_RESET = 'SUCCESS_RESET';
export const SUCCESS_RESET_SUCCESS = 'SUCCESS_RESET_SUCCESS';

export const ERROR = 'ERROR';
export const ERROR_RESET = 'ERROR_RESET';
export const ERROR_RESET_SUCCESS = 'ERROR_RESET_SUCCESS';

export const UPDATE_PATIENT_SITE = 'UPDATE_PATIENT_SITE'
export const UPDATE_PATIENT_SITE_SUCCESS = 'UPDATE_PATIENT_SITE_SUCCESS'

export const UPDATE_DIARY_MEDICATION = 'UPDATE_DIARY_MEDICATION'
export const UPDATE_DIARY_MEDICATION_SUCCESS = 'UPDATE_DIARY_MEDICATION_SUCCESS'

export const UPDATE_DATA_SHARING = 'UPDATE_DATA_SHARING';
export const UPDATE_DATA_SHARING_SUCCES = 'UPDATE_DATA_SHARING_SUCCESS';

export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCES';

export const UPDATE_NIGHTWATCH_EVENT_SUCCESS = 'UPDATE_NIGHTWATCH_EVENT_SUCCESS';

export const RESET_SEIZUREVIDEOS = 'RESET_SEIZUREVIDEOS';
export const RESET_SEIZUREVIDEOS_SUCCESS = 'RESET_SEIZUREVIDEOS_SUCCESS';

export const GET_PATIENT_SITES = 'GET_PATIENT_SITES';
export const GET_PATIENT_SITES_SUCCESS = 'GET_PATIENT_SITES_SUCCESS';

export const GET_DATA_SHARING = 'GET_DATA_SHARING';
export const GET_DATA_SHARING_SUCCESS = 'GET_DATA_SHARING_SUCCESS';