import React, {useState, memo} from 'react';
import { View, StyleSheet, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from '../CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from '../../styles/GlobalStyles';
import CommonModal from 'common/src/components/CommonModal';
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {convertLangCode} from 'common/src/services/translationList';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import {compareMoodAndSleepDates} from '../../services/compare/index';
import MedicationEvent from './EventSubComponents/MedicationEvent';
import { 
  getListCMSData, 
  getContainerBackgroundColor, 
  getIconColor, 
  getMoodSleepColor, 
  getIcon, 
  getColor
} from './utils';
import { useNavigation } from '@react-navigation/native';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';
import CommonImage from '../CommonImage';
import { ThemeColors } from '../../../../../*';

// this child component return the correct icon according to the mood and sleep
const MoodSleep = ({value}) => {
  return (
    <>
      {value === null ? <Text></Text>
      : value >= 0.7 ?
        <Icon 
          name={"smile"} 
          size={PR(15)} 
          color={"#1de9b6"} 
        />
      : value >= 0.4 && value <=0.6 ?
        <Icon 
          name={"meh"} 
          size={PR(15)} 
          color={"#ffd039"} 
        />
      : 
        <Icon 
          name={"frown"} 
          size={PR(15)} 
          color={"#fb667a"} 
        />
      }
    </>
  )
}

const CommonDiaryItem = (props:any) => {
  const { colors } = useTheme();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [actionWarningModal, setActionWarningModal] = useState<boolean>(false);
  const [tempActionData, setTempActionData] = useState<Record<string, any>>()
  const {i18n, t} = useTranslation();
  const lang = convertLangCode(i18n.language) 
  const styles: any = generateStyleSheet(colors);
  const patientData = useSelector((state:any) => state.getMe.patient);
  const [containerCollapse, setContainerCollapse] = useState<boolean>(props.data.expanded);
  const navigation: any = useNavigation();
  const filterItemToDisplay = props.filterData && props.filterData[0] ? props.filterData[0].label : null;
  const isEventAfterToday = compareMoodAndSleepDates(DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'),DateTime.fromISO(props.data.date).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy');

  const moodCallback = () => {
    if (Mood)
      return props.callbackMnS(Mood.id, Mood.value, 'mood', Mood.date, false);
    else
      return props.callbackMnS(null, null, 'mood', props.data.date, true);
  }

  const sleepCallback = () => {
    if (Sleep)
      return props.callbackMnS(Sleep.id, Sleep.value, 'sleep', Sleep.date, false);
    else
      return props.callbackMnS(null, null, 'sleep', props.data.date, true);
  }

  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }
  const errorModalBtn = [{
    type: 'primary',
    title: t('translation:modal.btn.close'),
    click: callbackVisible
  }]

  // keep tracking of the changes of the action warning modal
  const callbackActionVisible = () => {
    setActionWarningModal(false);
  }

  const confirmActionUnDone = () => {
    props.callbackActions(tempActionData);
    callbackActionVisible();
  }

  const actionModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackActionVisible
    },
    {
      type: 'primary',
      title: t('translation:modal.btn.confirm'),
      click: confirmActionUnDone
    }
  ]
  
  const renderEventsSummary = () => {
    const eventsSummary = props.data.summary;
    return eventsSummary 
  }

  const getMoodSleep = (type) => {
    const MSEvent = props.data.events.find(item => item.type === type)
    return MSEvent ? MSEvent : null;
  }

  const Mood = getMoodSleep('mood')
  const Sleep = getMoodSleep('sleep')

  const handlePress = (event) => {
    const type = event.type;

    if (type === "seizure") {
      navigation.navigate("AddEvent", {
        itemType: "Seizure",
        initialData: event,
      });
    } else if (type === "headache") {
      navigation.navigate("AddEvent", {
        itemType: "Headache",
        initialData: event,
      });
    } else if (type === "appointment") {
      navigation.navigate("AddEvent", {
        itemType: "Appointment",
        initialData: event,
      });
    } else if (type === "side_effect") {
      navigation.navigate("AddEvent", {
        itemType: "Side Effect",
        initialData: event,
      });
    } else if (type === "other") {
      navigation.navigate("AddEvent", {
        itemType: "Note / Event",
        initialData: event,
      });
    } 
    else if (type === "reminder") {
      props.callbackMedication && props.callbackMedication(event); 
    }
    else if (type === "form") {
      navigation.navigate("StudyForm", {
        questionnaire: {
          eventId: event.id,
          formId: event?.form?.id,
          expirationDate: event?.studyParameters?.expiration,
          site: event?.studyParameters?.site,
          sentDate: event?.studyParameters?.send_date,
          targetDate: event?.studyParameters?.targetDate
        }
      });
    } 
    else if (type === "nightwatch_report") {
      navigation.navigate("NightwatchEvent", {
        itemType: "Nightwatch",
        initialData: event,
      });
    } 
  };

  const updateCollapse = () => {
    props.updateExpandDay(props.data.date);
    setContainerCollapse(!containerCollapse)
  }

  return (
    <>
    {/* creating the list calendar */}
      { modalVisible &&
        <CommonModal testID="errorModal" type={'error'} title={t('translation:modal.titles.error')} text={t('translation:errors.mood_sleep_in_future')} buttons={errorModalBtn} onClose={callbackVisible}/>
      }
      { actionWarningModal &&
        <CommonModal testID="actionModal" type={'info'} title={t('translation:modal.titles.warning')} text={t('translation:modal.actionWarning.text')} buttons={actionModalBtn} onClose={callbackActionVisible}/>
      }
      <View
        testID={'diary_'}
        style={[styles.container,
          {backgroundColor: colors.diaryDateContainer }
          ]}
       >
        <TouchableOpacity 
          onPress={updateCollapse}
          style={[styles.item, {flexDirection:'row'}]}
        >
          <View style={{flexDirection:'column', width: '20%'}}>
            <Text style={styles.dayNum}>{DateTime.fromISO(props.data.date, { zone: patientData?.timezone }).toFormat('dd')}</Text>
            <Text style={styles.day}>{DateTime.fromISO(props.data.date, { locale: lang, zone: patientData?.timezone }).toFormat('EEE')}</Text>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.dotsContainer}>
               <View style={{flexDirection:'row'}}>
              {
                 ((!filterItemToDisplay || filterItemToDisplay === 'mood')) && !isEventAfterToday &&
                  <View style={styles.eventDots}>
                    <Icon 
                      name={"sun"} 
                      size={PR(20)} 
                      color={getMoodSleepColor(Mood?.value)} 
                    />
                  </View>
              }
              {
                 ((!filterItemToDisplay || filterItemToDisplay === 'sleep')) && !isEventAfterToday &&
                 <View style={styles.eventDots}>
                  <Icon 
                    name={"moon"} 
                    size={PR(20)} 
                    color={getMoodSleepColor(Sleep?.value)} 
                    />
                  </View>
              }
                  {
                        Object.keys(renderEventsSummary()).map((eventType, i) => {
                        const test = renderEventsSummary()[eventType]
                        if (eventType !== 'mood' && eventType !== 'sleep' && ((!filterItemToDisplay || filterItemToDisplay === eventType)))
                          return (
                            <View key={i} style={[styles.eventDots]}>
                              {
                                eventType === 'headache' ?
                                  <CommonImage 
                                    name="headacheIconGreen"
                                    height={PR(20)}
                                    width={PR(20)}
                                  /> 
                                  : 
                                  <Icon 
                                    name={getIcon(eventType)}
                                    size={PR(20)} 
                                    color={getIconColor(eventType)} 
                                  />
                              }
                              {
                                eventType === 'reminder' ? 
                                <Text style={styles.eventNum}>{test.taken + '/' + test.count}</Text>
                                :
                                <Text style={styles.eventNum}>{test.count}</Text>
                              }

                            </View>
                          )
                      })
                  }
                </View>
              </View>
              <View style={{alignItems:'flex-end', justifyContent:'flex-end'}}>
                <Icon 
                  name={containerCollapse ? "chevron-up" : "chevron-down"} 
                  size={PR(20)} 
                  color={GlobalStyles[APP_SHORTCUT_NAME].color} 
                />
              </View>
          </View>
        </TouchableOpacity>
          {
            containerCollapse &&
            <>
            <View style={{flexDirection:'row', justifyContent: 'space-evenly', marginTop: PR(10)}}>
              <View style={{width: '45%'}}>
                {/* Mood */}
                {
                  ((!filterItemToDisplay || filterItemToDisplay === 'mood')) && !isEventAfterToday &&
                    <TouchableOpacity testID={'mood_id'} onPress={moodCallback} style={styles.moodAndSleepContainer}>
                      <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
                        <Icon 
                          name={"sun"} 
                          size={PR(15)} 
                          color={"#0079ff"} 
                        />
                        <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.mood')}</Text>
                      </View>
                      <View style={{width: "10%"}}>
                        {Mood &&
                          <MoodSleep value={Mood.value} />
                        }
                      </View>
                    </TouchableOpacity>

                }
              </View>
              <View style={{width: '45%'}}>

                {/* Sleep */}
                {
                  ((!filterItemToDisplay || filterItemToDisplay === 'sleep')) && !isEventAfterToday &&
                    <TouchableOpacity testID={'sleep_id'} onPress={sleepCallback} style={styles.moodAndSleepContainer}>
                      <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
                        <Icon 
                          name={"moon"} 
                          size={PR(15)} 
                          color={"#0079ff"} 
                        />
                        <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.sleep')}</Text>
                      </View>
                      <View style={{width: "10%"}}>
                        {Sleep &&
                          <MoodSleep value={Sleep.value} />
                        }
                      </View>
                    </TouchableOpacity>

                }
              </View>

            </View>

              {
                props.data && props.data.events && props.data.events.map((event:Record<string,any>, index:number) => {
                  const eventType = event.type
                  const eventStyle = generateStyleSheet(colors, eventType)
                  if (eventType !== 'mood' && eventType !== 'sleep' && (!filterItemToDisplay || filterItemToDisplay === eventType))
                  return (
                  <View style={eventStyle.eventContainer} key={index}>
                    <View>
                        { eventType === 'reminder' && 
                            <MedicationEvent handlePress={() => {handlePress(event)}} itemNames={['time','reminder','taken_date']} sItem={event} styles={eventStyle} userTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone } />
                        }
                        { eventType === 'seizure' && 
                            <TouchableOpacity style={{ width: '100%', marginVertical:PR(4)}} onPress={() => {handlePress(event)}}>
                              <View style={{flexDirection:"row", width: '100%'}}>
                                  <Text style={eventStyle.eventSubtitle}>{DateTime.fromISO(event.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')}</Text>
                                  <Text style={eventStyle.eventSubtitle}> - </Text>
                                  <Text numberOfLines={1} style={[eventStyle.eventSubtitle, {flex:1}]}>
                                      {getListCMSData(t, event.seizure_type, 'list.seizureList')}
                                  </Text>
                              </View>
                            </TouchableOpacity>
                        }
                        { eventType === 'headache' && 
                            <TouchableOpacity style={{ width: '100%', marginVertical:PR(4)}} onPress={() => {handlePress(event)}}>
                              <View style={{flexDirection:"row", width: '100%'}}>
                                  <Text style={eventStyle.eventSubtitle}>{DateTime.fromISO(event.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')}</Text>
                                  <Text style={eventStyle.eventSubtitle}> - </Text>
                                  <Text numberOfLines={1} style={[eventStyle.eventSubtitle, {flex:1}]}>
                                    {getListCMSData(t, event.name, 'list.headache_names_1_9') || event.name}
                                  </Text>
                              </View>
                            </TouchableOpacity>
                        }
                        { eventType === 'appointment' && 
                            <TouchableOpacity style={{ width: '100%', marginVertical:PR(4)}} onPress={() => {handlePress(event)}}>
                              <View style={{flexDirection:"row", width: '100%'}}>
                                  <Text style={eventStyle.eventSubtitle}>{DateTime.fromISO(event.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')}</Text>
                                  <Text style={eventStyle.eventSubtitle}> - </Text>
                                  <Text numberOfLines={1} style={[eventStyle.eventSubtitle, {flex:1}]}>
                                      {event.doctor_info.name}
                                  </Text>
                              </View>
                            </TouchableOpacity>
                        }
                        { eventType === 'form' && 
                            <TouchableOpacity style={{ width: '100%', marginVertical:PR(4)}} onPress={() => {handlePress(event)}}>
                              <View style={{flexDirection:"row", width: '100%'}}>
                                  <Text style={eventStyle.eventSubtitle}>{DateTime.fromISO(event.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')}</Text>
                                  <Text style={eventStyle.eventSubtitle}> - </Text>
                                  <Text numberOfLines={1} style={[eventStyle.eventSubtitle, {flex:1}]}>
                                      {event.form?.title ? event.form?.title[lang] : t("translation:general.empty_title")}
                                  </Text>
                              </View>
                              <Text renderAsHtml={true} style={[eventStyle.eventSubtitle, {fontSize:PR(12), alignSelf:'flex-end'}]}>{t('translation:journal.events.lastEdit',{date: DateTime.fromISO(event.updatedAt).toFormat('dd/MM/yyyy')})}</Text>
                            </TouchableOpacity>
                        }
                        { eventType === 'side_effect' && 
                            <TouchableOpacity style={{ width: '100%', marginVertical:PR(4)}} onPress={() => {handlePress(event)}}>
                              <View style={{flexDirection:"row", width: '100%'}}>
                                  <Text style={eventStyle.eventSubtitle}>{DateTime.fromISO(event.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')} - {getListCMSData(t, event.effect_type, 'list.side_effect_types')}</Text>
                                  <Text style={eventStyle.eventSubtitle}> - </Text>
                                  <Text numberOfLines={1} style={[eventStyle.eventSubtitle, {flex:1}]}>
                                      {getListCMSData(t, event.intensity, 'list.side_effect_intensities')}
                                  </Text>
                              </View>
                            </TouchableOpacity>
                        }
                        { eventType === 'other' && 
                            <TouchableOpacity style={{ width: '100%', marginVertical:PR(4)}} onPress={() => {handlePress(event)}}>
                              <View style={{flexDirection:"row", width: '100%'}}>
                                  <Text style={eventStyle.eventSubtitle}>{DateTime.fromISO(event.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')} - {getListCMSData(t, event.other_type, 'list.other_event_categories')}</Text>
                                  <Text style={eventStyle.eventSubtitle}> - </Text>
                                  <Text numberOfLines={1} style={[eventStyle.eventSubtitle, {flex:1}]}>
                                      {event.remark}
                                  </Text>
                              </View>
                            </TouchableOpacity>
                        }
                        { eventType === 'nightwatch_report' && 
                            <TouchableOpacity style={{ width: '100%', marginVertical:PR(4)}} onPress={() => {handlePress(event)}}>
                              <View style={{flexDirection:"row", width: '100%'}}>
                                  <Text style={eventStyle.eventSubtitle}>{DateTime.fromISO(event.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')}</Text>
                                  <Text style={eventStyle.eventSubtitle}> - </Text>
                                  <Text numberOfLines={1} style={[eventStyle.eventSubtitle, {flex:1}]}>
                                      {t('translation:journal.events.nightwatch_recording')}
                                  </Text>
                              </View>
                            </TouchableOpacity>
                        }
                      </View>
                    </View>
                  )
                })
              }
            </>
          }
      </View>
    </>
  )
  
}

const generateStyleSheet = (colors: ThemeColors, type?:string) => {
  return StyleSheet.create({
    container: {
      borderRadius: PR(20),
      width:'90%',
      alignSelf:'center', 
      marginBottom: PR(10),
      shadowColor: '#333333',
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.10,
      shadowRadius: 13.97,
      elevation: 10,
    },
    wrapper: {
      flexDirection: 'row',
      alignItems:'center',
      width: '80%'
    },
    dotsContainer: {
      flex:1,
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      width: '100%',
      justifyContent:'center'
    },
    eventDots: {
      // marginRight: PR(5),
      marginHorizontal: PR(5),
      flexDirection: 'column',
      alignItems:'center'
    },
  item: {
    width: '90%',
    alignSelf: 'center',
    marginVertical: PR(8),
    color: 'red'
  },
  moodAndSleepContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: PR(1),
    borderColor: '#0079ff',
    borderRadius: PR(18),
    padding: PR(10),
    marginBottom: PR(10)
  },
  title: {
    fontFamily: GlobalStyles.global.fontFamily.Medium,
    fontSize: PR(16),
    color:"#0079ff",
    paddingLeft: PR(10)
  },
  dayNum:{
    fontSize: PR(32),
    color: colors.primary,
    fontFamily: GlobalStyles.global.fontFamily.Bold
  },
  day: {
    fontSize: PR(14),
    color: GlobalStyles.global.grey,
    paddingLeft: PR(4),
    textTransform: "uppercase"
  },
  eventNum: {
    color: colors.primary
  },
  eventContainer: {
    backgroundColor: type && getContainerBackgroundColor(type),
    borderColor: type && getColor(type),
    borderWidth: type === 'other' || type === 'reminder' || type === 'program_actions' ? PR(1): 0,
    borderRadius: PR(20),
    padding: PR(10),
    marginBottom: PR(10),
    width: "93%",
    alignSelf: 'center'
    },
    eventTitle: {
        fontFamily: GlobalStyles.global.fontFamily.Medium,
        fontSize: PR(16),
        color: type && getColor(type)
    },
    eventSubtitle: {
        fontSize: PR(13),
        color: type && getColor(type),
    }
})
}

function arePropsEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  if(prevProps.filterData !== nextProps.filterData)
    return false
  else if(prevProps.data !== nextProps.data)
    return false
  else
    return true
}

export default memo(CommonDiaryItem, arePropsEqual);