import React, { useState } from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyle from '../../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../../env.json';
import Text from '../CommonCustomTxt';
import CommonImage from "../CommonImage";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import CommonModal from '../CommonModal';
import { useTranslation } from 'react-i18next';

// type = rounded || rounded-checkbox || squared
const CommonSelectItem = ({selected, onClick, onDelete, showDelete, type, text, infoText, infoTitle}:any) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors, type || 'rounded', selected);
    const {t} = useTranslation();
    const [modalVisible, setModalVisible]= useState<boolean>(false);

    const callbackVisible = () => {
        setModalVisible(false);
    }

    const modalOpen = ( )=> {
        setModalVisible(true)
    }
    
    return (
        <View>
            <TouchableOpacity onPress={onClick}>
                <View style={styles.wrapper}>
                    <View style={[styles.txtWrapper, !infoText && styles.txtWrapperNoInfo]}>
                        {infoText && infoTitle && (
                            <View>
                                <TouchableOpacity style={styles.infoIcon} onPress={modalOpen}>
                                    <Icon name={'info'} size={PR(24)} color={GlobalStyle[APP_SHORTCUT_NAME].color}/>
                                </TouchableOpacity>
                                {
                                    modalVisible &&
                                    <CommonModal
                                        type={'info'}
                                        title={infoTitle}
                                        text= {infoText}
                                        buttons={undefined} 
                                        onClose={callbackVisible}
                                    />
                                }
                            </View>
                        )}
                        <Text style={styles.checkBoxTxt}>{text}</Text>
                    </View>
                    <View style={styles.iconsWrapper}>
                        {showDelete && (
                            <TouchableOpacity onPress={() => onDelete()}>
                                <View style={styles.deleteIconContainer}>
                                    <Icon name="trash-2" size={PR(24)} color={GlobalStyle.global.redError}/>
                                </View>
                            </TouchableOpacity>
                        )}
                        <View style={styles.checkBoxContainer}>
                            {
                                selected && (
                                        type === 'rounded-checkbox' ?
                                            <View style={styles.checkMark}>
                                                <Icon name="check" size={PR(17)} color={GlobalStyle[APP_SHORTCUT_NAME].darkColor}/>
                                            </View> :
                                            <View style={styles.checkedRadio}>
                                            </View>
                                )
                            }
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}

const generateStyleSheet = (colors:Record<string, string>, type:string, selected:boolean) => {
    return StyleSheet.create({
        infoIcon: {
            paddingVertical: PR(5),
            marginRight: PR(5)
        },
        wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: PR(5),
            paddingHorizontal: PR(12),
            flex: 1
        },
        txtWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexShrink: 1,
            flex: 1
        },
        txtWrapperNoInfo: {
            marginLeft: PR(27)
        },
        checkBoxContainer: {
            height: PR(24),
            width: PR(24),
            borderWidth: PR(2),
            backgroundColor: colors.background,
            borderColor: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : colors.timeLineBarEmpty,
            borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0,
            justifyContent: 'center',
            alignItems: 'center',
            padding: PR(2)
        },
        checkedRadio: {
            height: PR(16),
            width: PR(16),
            backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].darkColor,
            borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0,
        },
        checkMark: {

        },
        checkBoxTxt: {
            color: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : colors.primary,
            fontSize: PR(16),
            lineHeight:PR(25),
            opacity: selected ? 1 : 0.8,
            fontFamily: selected ? GlobalStyle.global.fontFamily.Medium : GlobalStyle.global.fontFamily.Regular
        },
        deleteIconContainer: {
            paddingHorizontal: PR(10),
        },
        iconsWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    })
}

export default CommonSelectItem;