import React from 'react';
import {View, StyleSheet} from 'react-native';
import CommonSelectItem from "./CommonSelectItem";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';


const CommonSelectInput = ({data, otherData, onSelect, selectedValues, onDelete, info, infoData}) => {   
    return (
        <View style={styles.container}>
            {data && data.map((item, index) => {
            const infoItem = info && infoData[index];
            return (
                <CommonSelectItem
                infoText={infoItem ? infoItem.label : undefined}
                infoTitle={'Help'}
                index={index}
                key={index}
                text={item.label}
                type={'rounded-checkbox'}
                onClick={() => onSelect(item.value)}
                selected={selectedValues.indexOf(item.value) >= 0}
                />
            );
            })}
            {otherData && otherData.map((item, index) => (
                <CommonSelectItem
                    index={index}
                    key={index}
                    text={item}
                    type={'rounded-checkbox'}
                    showDelete
                    onClick={() => onSelect(item)}
                    selected={selectedValues.indexOf(item) >= 0}
                    onDelete={() => onDelete(item)}
                />
            ))}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: PR(15),
    }
});


export default CommonSelectInput;