import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIsFocused, useTheme } from "@react-navigation/native";
import CommonTimelineGraphs from "./CommonTimelineGraphs";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import GlobalStyles from "common/src/styles/GlobalStyles";
import Text from "common/src/components/CommonCustomTxt";
import cmsFile from "common/src/services/localization/en/en.json";
import { APP_SHORTCUT_NAME } from "../env.json";
import {
	TIMELINE_DISPLAY_TYPES,
	WEEK,
	MONTH,
	YEAR,
	DATE_FORMAT_MONTH,
	DATE_FORMAT,
} from "../types/index";
import { convertLangCode } from "common/src/services/translationList";
import moment from "moment";
import { widthPercentageToDP as PR } from "../styles/PixelRatio";
import { useNavigation } from "@react-navigation/native";
import {
	defineStartIndex,
	isConnectedToHCP,
	defineSeizureTypeColor,
	defineSeizureTypeOtherColor,
	displaySeizureTypeName,
	displayHeadacheTypeName,
} from "../services/utils";
import Loading from "common/src/components/Loading";
import { getTimelineEventsAction } from "common/src/store/actions/authenticationActions";
import CommonImage from "./CommonImage";

const eventsColors =
	APP_SHORTCUT_NAME === "migraine"
		? cmsFile["list.headache_colors"]
		: cmsFile["list.seizureListColors"];
const otherColors = cmsFile["list.otherSeizureColors"];
let max = 1;
const monthFormat = DATE_FORMAT_MONTH;
const isoFormat = DATE_FORMAT;

const CommonTimeline = ({ resetEventsOnDiary, updateTimelineView }) => {
	const { colors } = useTheme();
	const styles: any = generateStyleSheet(colors);
	const navigation = useNavigation();
	const { i18n, t } = useTranslation();
	const lang = convertLangCode(i18n.language);
	const isFocused = useIsFocused();
	const dispatch = useDispatch();
	const GetEventsResponse = useSelector(
		(state: any) => state.getEvents.timeline_events
	);
	const Me = useSelector((state: any) => state.getMe.response);
	const [seizureTypes, setSeizureTypes] = useState([] as any);
	const [otherColorsMapping, setOtherColorsMapping] = useState([] as any);
	const [selectedDisplayType, setSelectedDisplayType] = useState<
		"week" | "month" | "year"
	>(WEEK);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (Me && isFocused && GetEventsResponse.length === 0) {
			let now = moment();
			setIsLoading(true);
			dispatch(
				getTimelineEventsAction({
					type: "seizure,reminder,headache",
					to: now.format(isoFormat),
					from: now
						.subtract(1, `${selectedDisplayType}s`)
						.add(1, "days")
						.format(isoFormat),
					disablePopulate: true,
					callback: () => {
						setIsLoading(false);
					},
				})
			);
		}
	}, [GetEventsResponse, isFocused]);

	useEffect(() => {
		max = 1;
		sortBySeizure();
	}, [GetEventsResponse]);

	const updateSelected = (displayType) => {
		let obj;
		let now = moment();

		if (displayType === WEEK)
			obj = {
				type: "seizure,headache,reminder",
				to: now.format(isoFormat),
				from: now.subtract(1, "weeks").add(1, "days").format(isoFormat),
				disablePopulate: true,
			};
		else if (displayType === MONTH)
			obj = {
				type: "seizure,headache,reminder",
				to: now.format(isoFormat),
				from: now
					.subtract(1, "months")
					.add(1, "days")
					.format(isoFormat),
				disablePopulate: true,
			};
		else if (displayType === YEAR)
			obj = {
				type: "seizure,headache,reminder",
				to: now.format(monthFormat),
				from: now
					.subtract(1, "years")
					.add(1, "months")
					.format(monthFormat),
				groupBy: "month",
				disablePopulate: true,
			};
		setIsLoading(true);
		setSelectedDisplayType(displayType);
		updateTimelineView(displayType);
		dispatch(
			getTimelineEventsAction({
				...obj,
				callback: () => {
					setIsLoading(false);
				},
			})
		);
	};

	const sortBySeizure = () => {
		const _seizures: any = [];
		const _seizureTypes: any = [];
		const _otherColors: any = isConnectedToHCP(Me)
			? null
			: otherColorsMapping;

		let colorIndex = defineStartIndex(otherColorsMapping, otherColors);
		if (colorIndex >= otherColors.length - 1) colorIndex = 0;
		GetEventsResponse.forEach((day: any, seizureIndex) => {
			_seizures.push({
				date: day.date,
				seizures: [],
			});
			if (day.seizureTypes.length > 0) {
				const seizCount = day.seizureTypes.reduce(
					(x, y) => x + y.count,
					0
				);
				if (seizCount > max) max = seizCount;
			}

			day.seizureTypes.forEach((seizureType) => {
				let seizureTypeColor = defineSeizureTypeColor(
					seizureType.type,
					seizureType.id,
					Me.patientData,
					eventsColors
				);
				if (!seizureTypeColor) {
					if (!_otherColors)
						seizureTypeColor = GlobalStyles.global.customTypeGrey;
					else {
						let otherTypeColor = defineSeizureTypeOtherColor(
							seizureType.type,
							otherColorsMapping
						);
						if (otherTypeColor === -1) {
							seizureTypeColor =
								otherColors[colorIndex][
									"other_" + (colorIndex + 1)
								];
							_otherColors.push({
								color: seizureTypeColor,
								type: seizureType.type,
							});
							colorIndex =
								colorIndex >= otherColors.length - 1
									? 0
									: colorIndex + 1;
						} else
							seizureTypeColor =
								otherColorsMapping[otherTypeColor].color;
					}
				}
				seizureType.color = seizureTypeColor;
				_seizures[seizureIndex].seizures.push({
					date: day.date,
					name: seizureType.type,
					color: seizureTypeColor,
					count: seizureType.count,
				});
				const allSeizureTypesFound = _seizureTypes.find(
					(item) => item.name === seizureType.type
				);
				if (!allSeizureTypesFound)
					_seizureTypes.push({
						name: seizureType.type,
						color: seizureTypeColor,
					});
			});
		});

		// setSeizures(_seizures)
		setSeizureTypes(_seizureTypes);
		if (_otherColors && otherColorsMapping.length !== _otherColors.length) {
			setOtherColorsMapping(_otherColors);
		}
	};

	let format = "dddd"; // day full name
	if (selectedDisplayType === MONTH) format = "D"; // day month number
	else if (selectedDisplayType === YEAR) format = "MMMM"; // month full name

	// go to diary in a specific day
	const goToDiary = (day) => {
		resetEventsOnDiary();
		navigation.navigate("Diary", { day, format });
	};

	if (isLoading) return <Loading netConnected={true} />;
	else
		return (
			<View style={styles.container}>
				<View style={styles.selectContainer}>
					{TIMELINE_DISPLAY_TYPES.map((displayType, index) => (
						<TouchableOpacity
							key={index}
							style={[
								styles.selectBox,
								displayType ===
								selectedDisplayType.toLowerCase()
									? {}
									: { backgroundColor: "transparent" },
							]}
							onPress={() => {
								updateSelected(displayType);
							}}
						>
							<Text
								style={[
									styles.selectBoxText,
									displayType ===
									selectedDisplayType.toLowerCase()
										? {
												color: GlobalStyles[
													APP_SHORTCUT_NAME
												].color,
										  }
										: { color: colors.primary },
								]}
							>
								{t(
									"translation:dashboard.filters." +
										displayType
								)}
							</Text>
						</TouchableOpacity>
					))}
				</View>
				{!isLoading && selectedDisplayType !== MONTH && (
					<View style={styles.timelineContainer}>
						{GetEventsResponse.map((day, index) => {
							let seizureCount =
								day.seizureTypes.length > 0
									? day.seizureTypes.reduce(
											(x, y) => x + y.count,
											0
									  )
									: 0;
							const timelineLabel = moment(day.date)
								.locale(lang || "en")
								.format(format);
							return (
								<TouchableOpacity
									onPress={() => goToDiary(day)}
									key={index}
									style={styles.timelineBar}
								>
									<CommonTimelineGraphs
										day={day}
										max={max}
										seizureCount={seizureCount}
										timelineLabels={
											lang === "ko"
												? timelineLabel
												: timelineLabel[0].toUpperCase()
										}
										currentDisplayType={selectedDisplayType}
									/>
								</TouchableOpacity>
							);
						})}
						{selectedDisplayType === YEAR && (
							<View
								style={{
									height: PR(40),
									width: "100%",
									position: "absolute",
									top: PR(160),
									left: PR(-20),
									alignItems: "flex-start",
									justifyContent: "space-between",
								}}
							>
								<CommonImage
									name={"headacheIconGreen"}
									height={PR(18)}
									width={PR(18)}
								/>
								<CommonImage
									name={"pillIcon"}
									height={PR(18)}
									width={PR(18)}
								/>
							</View>
						)}
					</View>
				)}
				{!isLoading && selectedDisplayType === MONTH && (
					<View>
						<View style={styles.timelineContainer}>
							{GetEventsResponse.map((day, dayIndex) => {
								let seizureCount =
									day.seizureTypes.length > 0
										? day.seizureTypes.reduce(
												(x, y) => x + y.count,
												0
										  )
										: 0;
								// let seizureCount = calculateTotalDaySeizures(day)
								if (dayIndex > 15) return;
								return (
									<TouchableOpacity
										onPress={() => goToDiary(day)}
										key={dayIndex}
										style={styles.timelineBar}
									>
										<CommonTimelineGraphs
											day={day}
											max={max}
											seizureCount={seizureCount}
											timelineLabels={moment(day.date)
												.locale(lang || "en")
												.format(format)}
											currentDisplayType={
												selectedDisplayType
											}
										/>
									</TouchableOpacity>
								);
							})}
						</View>
						<View style={styles.timelineContainer}>
							{GetEventsResponse.map((day, dayIndex) => {
								// let seizureCount = calculateTotalDaySeizures(day)
								let seizureCount =
									day.seizureTypes.length > 0
										? day.seizureTypes.reduce(
												(x, y) => x + y.count,
												0
										  )
										: 0;
								if (dayIndex <= 15) return;
								return (
									<TouchableOpacity
										onPress={() => goToDiary(day)}
										key={dayIndex}
										style={styles.timelineBar}
									>
										<CommonTimelineGraphs
											day={day}
											max={max}
											seizureCount={seizureCount}
											timelineLabels={moment(day.date)
												.locale(lang || "en")
												.format(format)}
											currentDisplayType={
												selectedDisplayType
											}
										/>
									</TouchableOpacity>
								);
							})}
						</View>
					</View>
				)}
				<View style={styles.legendContainer}>
					{seizureTypes.map((seizure: any, index: number) => {
						return (
							<View key={index} style={styles.legendItem}>
								<View
									style={[
										styles.legendDot,
										{ backgroundColor: seizure.color },
									]}
								></View>
								<Text
									style={[
										styles.legendText,
										{
											color:
												colors.background === "#fff"
													? seizure.color
													: "white",
										},
									]}
								>
									{APP_SHORTCUT_NAME === "migraine"
										? displayHeadacheTypeName(
												seizure.name,
												cmsFile[
													"list.headache_names_1_9"
												],
												t
										  )
										: displaySeizureTypeName(
												seizure.name,
												cmsFile["list.seizureList"],
												t
										  )}
								</Text>
							</View>
						);
					})}
				</View>
			</View>
		);
};

const generateStyleSheet = (colors: Record<string, string>) => {
	return StyleSheet.create({
		container: {},
		selectContainer: {
			flexDirection: "row",
			justifyContent: "space-between",
			marginBottom: PR(20),
			backgroundColor: colors.selectTimelineContainer,
			borderRadius: PR(25),
			alignItems: "center",
		},
		timelineContainer: {
			flex: 1,
			flexDirection: "row",
			justifyContent: "space-between",
			margin: PR(10),
		},
		selectBox: {
			padding: PR(12),
			backgroundColor: colors.background,
			borderRadius: PR(25),
			margin: PR(3),
			alignItems: "center",
			flex: 1,
			flexGrow: 0.33,
		},
		selectBoxText: {
			fontFamily: GlobalStyles.global.fontFamily.Bold,
		},
		timelineBar: {
			flex: 1,
			flexDirection: "column",
		},
		timelineLabels: {
			alignSelf: "center",
			marginTop: PR(10),
		},
		legendContainer: {
			flexDirection: "column",
			marginTop: PR(10),
		},
		legendItem: {
			flex: 1,
			flexDirection: "row",
			padding: PR(10),
		},
		legendDot: {
			borderRadius: PR(10),
			width: PR(15),
			height: PR(15),
		},
		legendText: {
			marginLeft: PR(15),
		},
	});
};

export default CommonTimeline;
