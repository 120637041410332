import React, {useEffect, useState, useCallback} from "react";
import {View, StyleSheet, SafeAreaView, TouchableOpacity, ScrollView} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from "common/src/components/CommonHeader";
import CommonFooter from "common/src/components/CommonFooter";
import CommonBtn from "common/src/components/CommonBtn";
import InputField from "common/src/components/InputField";
import Text from 'common/src/components/CommonCustomTxt';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { getRescueAction } from 'common/src/store/treatments/rescueActions';
import GlobalStyles from "common/src/styles/GlobalStyles";
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import Loading from "common/src/components/Loading";
import { RootState } from 'common/src/store/reducers';
import CommonLabelForm from "common/src/components/CommonAddEvents/CommonLabelForm";
import CommonActionModal from "common/src/components/CommonActionModal";
import { RescueObject } from "common/src/store/treatments/rescueReducer";
import CommonSubTitle from "common/src/components/CommonSubtitle";
import { getListCMSData } from 'common/src/components/CommonDiary/utils';
import { SelectedList } from "common/src/components/CommonAddEvents/CommonAddHeadache";
import { isObjectOrObjectElementsEmpty } from "common/src/services/utils";

const SelectRescueMedication = ({navigation, route}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const rescueMedications = useSelector((state: RootState) => state.rescueReducer.rescueMedications);
    const ProfileResponse = useSelector((state:any) => state.getMe.response);
    const [nameOfMed, setNameOfMed] = useState<RescueObject | undefined>(route.params?.editSelection?.medication || undefined);
    const [medEffect, setMedEffect] = useState<string>(route.params?.editSelection?.effect || '');
    const [listOfSelectedRescues, setListOfSelectedRescues] = useState<Array<SelectedList>>(route.params.selectedRescues)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRescueMedModal, setRescueMedModal] = useState<boolean>(false);
    
    const getRescueMedication = useCallback(() => {
        if(rescueMedications.length === 0){
            setIsLoading(true);

            dispatch(getRescueAction({
                patientID: ProfileResponse?.result?.patients?.id,
                callback: () => {
                    setIsLoading(false)
                },
                errorCallback: () => {
                    setIsLoading(false)
                }
            }))
        }
    }, [])

    useEffect(() => {
        getRescueMedication()
    }, [getRescueMedication])

    useEffect(() => {
        let tempArray: Array<SelectedList> = [...listOfSelectedRescues];
        if(nameOfMed && medEffect) {
            const indexOfItem = tempArray.findIndex((item) => item.medication.name === nameOfMed?.name)
            if(indexOfItem > -1) {
                tempArray[indexOfItem].medication = nameOfMed
                tempArray[indexOfItem].effect = medEffect
            }
            else if (indexOfItem === -1 && !isObjectOrObjectElementsEmpty(route.params?.editSelection)) {
                const indexOfEditItem = tempArray.findIndex((item) => item.medication.name === route.params?.editSelection?.medication?.name);
                tempArray[indexOfEditItem].medication = nameOfMed
                tempArray[indexOfEditItem].effect = medEffect

            } else {
                tempArray.push({medication: nameOfMed, effect: medEffect})
            }
        }
        setListOfSelectedRescues(tempArray)
    }, [nameOfMed, medEffect])

    const confirmRescue = () => {
        route.params.returnRescues(listOfSelectedRescues)
        navigation.goBack()
    }

    const isDisable = () => (!medEffect.length || !nameOfMed);

    const buttons = [
        {title: t('translation:headache_event.headache.emergencyMedication.add.another'), type: 'tertiary', click: () => {navigation.navigate('AddEmergencyMedication', {medicationDetails: {}}); setRescueMedModal(false)}, action: 'save'}, 
        {
            title: t('translation:general.header.btn_validate'),
            type: 'primary',
            click: (res:Array<Record<string, any>>) => {
                for(let i = 0; i < res?.length; i++) {
                    if(res[i].selected) {
                        setNameOfMed(res[i].value);
                        setMedEffect("");
                    }
                }
                
                setRescueMedModal(false)
            },
            action: 'save'}
    ]
    
    if(isLoading)
        return <Loading />

    return(
        <SafeAreaView style={styles.container}>
            { isRescueMedModal &&
                <CommonActionModal 
                    testID={"nameOfRescueMedModalID"}
                    title={t('translation:modal.select.title_one')} 
                    modalType={'select-radio'} 
                    buttons={buttons} 
                    onClose={() => setRescueMedModal(false)} 
                    data={rescueMedications.map((item) => {
                        return {label:item.name, value:item}
                    })}
                />
            }
            <CommonHeader
                testIDTitle="RescueMedicationTitle"
                title={t('translation:headache_event.manageEmergencyMedication.header')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.migraine.color}
                leftClick={() => {navigation.goBack()}}
            />
            {rescueMedications.length > 0 ?
                <>
                    <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                        <CommonLabelForm label={t('translation:headache_event.manageEmergencyMedication.name')} isOptional={false}/>
                        <TouchableOpacity testID="manageEmergencyMedicationID" style={styles.summaryContainer} onPress={() =>setRescueMedModal(!isRescueMedModal)}>
                            <View style={styles.inputSummaryContainer}>
                                <Text style={styles.inputSummaryValue}>{!nameOfMed ? t('translation:headache_event.headache.emergencyMedication.namePlaceholder') : nameOfMed?.name}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <InputField
                        labelText={t('translation:headache_event.headache.emergencyMedication.effect')}
                        labelTextSize={PR(14)}
                        inputType="dropdown"
                        customStyle={{marginBottom:PR(30)}}
                        changeText={(res:string) => setMedEffect(res.replace(/["']/g,''))}
                        placeholderTxt={t('translation:headache_event.headache.emergencyMedication.effectPlaceholder')}
                        // @ts-ignore
                        data={t<string | TemplateStringsArray>('translation:list.emergencyEffects', {returnObjects: true}).map((name,i) => {
                            return {label:t('translation:list.emergencyEffects.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)).replace(/[\[\]']+/g,'')}
                        })}
                        value={getListCMSData(t, medEffect, 'list.emergencyEffects')}
                        testID="medEffect"
                    />
                </>
                :
                <View style={{width:'90%', alignSelf:'center'}}>
                    <CommonSubTitle     
                        text={t('translation:headache_event.manageEmergencyMedication.subtitle')}
                    />
                </View>
            }
            <CommonFooter>
                {rescueMedications.length > 0 ?
                    <CommonBtn 
                        testID={"selectRescueMedicationID"} 
                        type={'primary'} 
                        title={t('translation:headache_event.headache.emergencyMedication.save')} 
                        click={() => {confirmRescue()}}
                        disable={isDisable()}
                    />
                    :
                    <CommonBtn 
                        testID={"addRescueMedicationID"} 
                        type={'tertiary'} 
                        title={t('translation:headache_event.emergencyMedications.listing.add')} 
                        click={() => {navigation.navigate('AddEmergencyMedication', {medicationDetails: {}})}}
                    />
                }
            </CommonFooter>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            backgroundColor: colors.background,
            flex: 1,
        },
        summaryContainer: {
            backgroundColor: colors.inputBackground,
            borderRadius: PR(20),
        },
        inputSummaryContainer: {
            flexDirection: 'row',
            minHeight: PR(62),
            padding: PR(10),
            fontSize: PR(16),
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        inputSummaryValue: {
            flexGrow:1,
            color: colors.primary,
            marginLeft: PR(10),
            fontSize: PR(16),
            fontFamily: GlobalStyles.global.fontFamily.Medium
        },
    })
};

export default SelectRescueMedication;
