import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, View, TouchableOpacity, ScrollView} from 'react-native';
import { useTheme } from '@react-navigation/native';
import notificationEmitter, {NotificationTypes} from 'common/src/notifEmitter/notifEmitter';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonNumPad from 'common/src/components/Inputs/CommonNumPad';
import CommonPinField from 'common/src/components/Inputs/CommonPinField';
import CommonModal from 'common/src/components/CommonModal';
import CommonBtn from 'common/src/components/CommonBtn';
import Text from 'common/src/components/CommonCustomTxt';
import Loading from 'common/src/components/Loading';
import Icon from 'react-native-vector-icons/Feather';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';
import { useDispatch } from 'react-redux';
import { 
    getOTPCodeAction,
    verifyOTPCodeAction
} from 'common/src/store/mfa/mfaActions';
import { loginUserAction } from 'common/src/store/actions/authenticationActions';
import { logOutAction } from 'common/src/store/actions/authenticationActions';

const MFAValidation = ({navigation, route}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const {user_id, email, pwd, lng, first_name, email_mfa, push_notif_mfa} = route?.params;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false);
    const [isWhereToSendModalOpen, setWhereToSendModal] = useState<boolean>(false);
    const [pinInput, setPinInput] = useState<Array<string>>([]);
    const pinLength = 6;

    useEffect(() => {
        if(email_mfa && push_notif_mfa)
            setWhereToSendModal(true)
        else
            getOTPFunc();
        notificationEmitter.on(NotificationTypes.MFA_notif, (data) => {
            setPinInput([data.text[0],data.text[1],data.text[2],data.text[3],data.text[4],data.text[5]]);
        })
    },[])

    const setLogout = () => {
        dispatch(logOutAction());
        navigation.reset({
          index: 0,
          routes: [{ name: 'Welcome' }],
        });
      }

    const getOTPFunc = () => {
        dispatch(getOTPCodeAction({
            userId: user_id,
            language: lng,
            name: first_name,
            whereToSend: email_mfa ? 'email' : 'push_notif',
            callback: () => {

            }
        }))
    }

    const whereToSendFunc = (whereToSendValue) => {
        dispatch(getOTPCodeAction({
            userId: user_id,
            language: lng,
            name: first_name,
            whereToSend: whereToSendValue,
            callback: () => {

            }
        }))
        setWhereToSendModal(false)
    }

    const resendFunc = () => {
        if(email_mfa && push_notif_mfa)
            setWhereToSendModal(true)
        else
            getOTPFunc();
    }

    const changePinInput = (input: string) => {
        if(pinInput.length < pinLength)
            setPinInput([...pinInput, input])
        
    }

    const deletePinInput = () => {
        if(pinInput.length > 0)
            setPinInput(pinInput.slice(0, -1))
    }

    const verifyOTP = () => {
        dispatch(verifyOTPCodeAction({
            userId: user_id,
            otpCode: pinInput.join(""),
            language: lng,
            callback: () => {
                setPinInput([]);
                let obj = {email:email, password:pwd, navigation, language: lng}
                const setLogin = () => {
                    setIsLoading(true)
                    return loginUserAction(
                        {...obj, 
                            errorCallback: () => {
                                setIsLoading(false)
                            }
                        })
                }
                dispatch(setLogin())
            },
            errorCallback: () => {
                setPinInput([]);
            }
        }))
    }

    const getSubtitleTranslation = () => {
        if(email_mfa && push_notif_mfa)
            return t('translation:MFA.login.subtitle_general')
        else if (email_mfa)
            return t('translation:MFA.login.subtitle_email',{email})
        else    
            return t('translation:MFA.login.subtitle_push_notification')
    }

    if (isLoading)
        return <Loading netConnected={true}/>

    return(
        <SafeAreaView style={styles.container}>
            {isInfoModalOpen && 
                <CommonModal
                    testID="infoModal"
                    type={'error'}
                    title={t('translation:MFA.problem_with_registered_device_title')}
                    text={t('translation:MFA.problem_with_registered_device_description')}
                    buttons={[{
                        type: 'primary',
                        title: t('translation:modal.btn.close'),
                        click: () => setInfoModalOpen(false)
                      }]}
                    onClose={() => setInfoModalOpen(false)}
                />
            }
            {isWhereToSendModalOpen && 
                <CommonModal
                    testID="whereToSendModal"
                    type={'error'}
                    title={t('translation:MFA.modal.whereToSend.title')}
                    text={t('translation:MFA.modal.whereToSend.subtitle')}
                    buttons={[
                        {
                            type: 'tertiary',
                            title: t('translation:MFA.modal.whereToSend.email.btn'),
                            click: () => whereToSendFunc('email')
                        },
                        {
                            type: 'tertiary',
                            title: t('translation:MFA.modal.whereToSend.push_notif.btn'),
                            click: () => whereToSendFunc('push_notif')
                        }
                    ]}
                    onClose={() => setWhereToSendModal(false)}
                />
            }
            <View style={{flexDirection:'row', justifyContent: 'space-between', width: '90%', alignSelf: 'center', alignItems: 'center'}}>
                <TouchableOpacity onPress={() => setInfoModalOpen(true)} testID='icon_help' style={[styles.helpWrapper, {marginTop: PR(10), alignSelf:'flex-end'}]}>
                    <Icon 
                        name={"info"} 
                        size={PR(30)} 
                        color={GlobalStyles.migraine.color} 
                    />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setLogout()} testID='icon_logout' style={[styles.helpWrapper, {marginTop: PR(10), alignSelf:'flex-start'}]}>
                    <Icon 
                        name={"log-out"} 
                        size={PR(30)} 
                        color={GlobalStyles.migraine.color} 
                    />
                </TouchableOpacity>
            </View>
            <View testID='icon_id' style={[styles.descriptionWrapper, {marginTop: PR(20)}]}>
                <Icon 
                    name={"smile"} 
                    size={PR(60)} 
                    color={"#1de9b6"} 
                />
            </View>
            <View style={[styles.descriptionWrapper, {marginBottom: PR(20)}]}>
                <Text testID="greetings_text_id" style={[styles.greetingTxt]}>{t('translation:MFA.greetingsTxt', {name: first_name})}</Text>
            </View>
            <View style={{alignItems:'center', alignSelf:'center', width:'90%', marginBottom: PR(20)}}>
                <Text testID="opt_modal_subtitle" style={styles.subTxt}>{getSubtitleTranslation()}</Text>
            </View>
            <ScrollView 
                style={styles.scrollview}
            >
                <View style ={{height: PR(380)}}>
                <CommonPinField 
                    input={pinInput}
                    pinLength={pinLength}
                    inputAreaStyle={{marginBottom: PR(24)}}
                    showInputText={true}
                />
                <CommonNumPad 
                    setInput={changePinInput}
                    deleteInput={deletePinInput}
                    isConfirmActive={pinInput.length === pinLength ? true : false}
                    sendConfirmation={()=> verifyOTP()}
                />
                </View>
                <View style={{marginTop: PR(20)}}>
                    <CommonBtn 
                        type='tertiary'
                        title={t('translation:MFA.resend_otp')}
                        testID="resend_code"
                        click={() => resendFunc()}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            backgroundColor: colors.background,
            flex: 1,
        },
        scrollview: {
            paddingTop: PR(20),
            flex:1
        },
        descriptionWrapper:{
            alignItems:'center',
            alignSelf:'center',
            width:'90%',
            justifyContent:'space-between',
            paddingBottom: PR(10),
        },
        helpWrapper:{
            paddingTop: PR(20),
        },
        IconRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: PR(20)
        },
        descriptionTxt: {
            fontSize: PR(16),
            color: GlobalStyles.global.grey
        },
        subTxt: {
            fontSize: PR(14),
            color: colors.primary,
            fontFamily: GlobalStyles.global.fontFamily.BoldItalic
        },
        greetingTxt: {
            fontSize: PR(24),
            color: colors.primary,
            fontFamily: GlobalStyles.global.fontFamily.Bold
        },
    })
}

export default MFAValidation;