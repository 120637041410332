import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {translate, convertLangCode} from 'common/src/services/translationList';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales.min';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const MedicationHistoryDetails = ({med, navigation, allData}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const dos = med?.dosage ? med?.dosage : med?.specific_dosage ? [med?.specific_dosage] : null;
  const {t, i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  moment.locale(lang)
  let tempUnitArr:any = []
  // return the total dose
  const calculateDose = (u) => {
    let count = 0
    let unit = u
    dos.forEach(element => {
      if(unit.length > 0 && element.unit === unit){
        count = count + element.dose
        unit = element.unit
      }
    });
    if(count > 0)
      return count + ' ' + translate(t, unit, 'list.units')
    else
      return ''
  }
  // return the days of the week or everyday
  const daysOfWeek = () => {
    let week = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']
    let temArr:any = []
    med?.days.forEach((day, i) => {
      if(day === true)
        temArr.push({day:week[i], pos:i})
    })

    if(temArr.length >= 7)
      return t('translation:treatment.add_medication.step_frequency.summary.every_day')
    else {
      return temArr.map((item, i) => {
        if(i < temArr.length-1) return t('translation:general.date.days.'+item.pos+'.'+item.day)+', '
        else return t('translation:general.date.days.'+item.pos+'.'+item.day)
      })
    }
  }
  // return the total dose of every unit
  const unitFunc = () => {
    if(dos !== null && dos !== undefined){
      return (
        dos?.map((item, i) => {
          let findUnit = tempUnitArr.find(itemUnit => itemUnit === item.unit)
          if(findUnit === undefined )
          {
            tempUnitArr.push(item.unit)
            return(
              <Text key={i} style={[styles.bottomConTxt,{alignSelf:'flex-end'}]}>{calculateDose(item.unit)}</Text>
            )
          }
        })
      )
    }
  }
  return(
    <>
      <View style={[styles.container,{backgroundColor: GlobalStyles.global.black}]}>
        <View style={styles.wrapper}>
          <View style={styles.medContainer}>
            <Text style={styles.medTxt}>{med?.from ? moment(med?.from).format('MMM DD, yyyy') : null}</Text>
            <Text style={styles.medTxt}>-</Text>
            <Text style={styles.medTxt}>{med?.to ? moment(med?.to).format('MMM DD, yyyy') : t('translation:treatment.general.unknown')}</Text>
          </View>
          <TouchableOpacity onPress={() => navigation.navigate('EditMedication', {med, data:allData, type:'dose'})} style={styles.rightContainer}>
            <View style={styles.iconContainer}>
              <Icon 
                name={"edit-2"} 
                size={PR(20)} 
                color={"#fff"} 
              />
            </View>
          </TouchableOpacity>
        </View>
        <View style={[styles.medBottomContainer, {borderColor: colors.medBottomContainerBorder}]}>
          <View style={[styles.medBottomWrapper, {paddingBottom:PR(10)}]}>
            <View style={{flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
            {med?.dosage ?
              <>
                <Text style={styles.bottomConTxt}>{t('translation:treatment.drug.dose')}</Text>
                <View style={{flexDirection:'column', width:'80%'}}>
                  {unitFunc()}
                  {daysOfWeek().length > 0 &&
                    <View style={{width: '90%', alignSelf:'flex-end'}}>
                      <Text style={[styles.bottomConTxt, {alignSelf:'flex-end'}]}>{daysOfWeek()}</Text>
                    </View>
                  }
                </View>
              </>
              :
              med?.specific_dosage ?
                <>
                  <Text>{t('translation:treatment.add_medication.step_frequency.custom.timesPer')+' '+med?.specific_dosage?.every+' '+translate(t, med?.specific_dosage?.period, 'list.periodFrequencies')}</Text>
                  <View style={{flexDirection:'column'}}>
                    {unitFunc()}
                  </View>
                </>
              :
              null
            }
            </View>
            <View style={[styles.horizontalDivider]}></View>
            <View style={{flexDirection: 'row', alignItems:'center', justifyContent:'space-between', paddingTop:PR(5)}}>
              {med?.dosage ?
                <>
                  <Text style={styles.bottomConTxt}>{t('translation:treatment.general.moments')}</Text>
                  <View style={{flexDirection:'column'}}>
                    {dos !== null && dos !== undefined && dos.map((dosageItem, i) => {
                      return(
                        <View key={i} style={{flexDirection:'row',justifyContent:'space-between', alignItems:'flex-end'}}>
                          <Text style={styles.bottomConTxt}>{dosageItem.dose} {translate(t, dosageItem.unit, 'list.units')} </Text>
                          <Text style={[styles.bottomConTxt,{alignSelf:'flex-end'}]}> {translate(t, dosageItem.moment, 'list.moments')}</Text>
                        </View>
                      )
                    })}
                  </View>
                </>
                :
                <>
                  <Text style={styles.bottomConTxt}>{t('translation:general.events.remark')}</Text>
                  <View style={{flexDirection:'row',justifyContent:'space-between', alignItems:'flex-end'}}>
                    <Text style={[styles.bottomConTxt,{alignSelf:'flex-end'}]}> {med?.remarks ? med?.remarks : '-'}</Text>
                  </View>
                </>
              }
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      borderRadius: PR(20),
      width:'80%',
      alignSelf:'center', 
      marginBottom:PR(10),
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.10,
      shadowRadius: 13.97,
      elevation: 10,
    },
    wrapper: {
      flexDirection: 'row',
      alignItems:'center'
    },
    medBottomContainer: {
      backgroundColor: colors.background, 
      borderWidth: PR(1), 
      borderBottomLeftRadius: PR(20), 
      borderBottomRightRadius: PR(20), 
      paddingHorizontal:PR(20),
      paddingVertical: PR(10),
      flexDirection: 'column',
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.10,
      shadowRadius: 13.97,
      elevation: 10,
    },
    medBottomWrapper:{
      flexDirection: 'column',
      justifyContent: 'space-between',
      // alignItems:'center'
    },
    medContainer: {
      flex:1,
      flexDirection: 'row',
      alignItems:'center',
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      width: '60%'
    },
    medMomentTxt: {
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium,
      color: colors.primary,
      paddingBottom:PR(10)
    },
    medTxt: {
      color: GlobalStyles.global.white,
      marginLeft: PR(10),
      fontSize: PR(14),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    rightContainer: {
      width:'40%',
      paddingRight:PR(20),
      alignItems:'flex-end',
    },
    iconContainer: {
      alignItems:'center',
      flexDirection: 'row'
    },
    horizontalDivider: {
      borderBottomColor: GlobalStyles.global.lightGrey,
      borderBottomWidth: PR(1),
      paddingBottom:PR(5)
    },
    bottomConTxt: {
      color: colors.primary,
      fontSize: PR(14)
    }
  })
}

export default MedicationHistoryDetails;