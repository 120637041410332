import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { ThemeActions } from './themeActions';

interface ThemeReducerInterface {
    themeMode: string
}

const initialState:ThemeReducerInterface = {
    themeMode: 'light'
};

export default function (state = initialState, action: AnyAction): ThemeReducerInterface {
    if (action.type === ThemeActions.SWITCH_THEME_SUCCESS){
        return {
            ...state,
            themeMode: action.response
        }
    } 
    return state;
}

export const themeMode = (state: RootState) => state.themeReducer.themeMode;