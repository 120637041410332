import React from 'react';
import {Modal, View, StyleSheet, TouchableOpacity, TouchableWithoutFeedback,Platform, Dimensions} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';
import CommonImage from './CommonImage';

const width = Dimensions.get('window').width;

interface AddModalTypes {
  title: string,
  onClose: any,
  testID: string,
  navigation: any
}

const AddModal = ({title, onClose, testID, navigation}:AddModalTypes) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const AddEventsArray = [
    {icon: APP_SHORTCUT_NAME === 'helpi'? 'zap' : 'meh', name: APP_SHORTCUT_NAME === 'helpi' ? 'Seizure' : 'Headache', type: APP_SHORTCUT_NAME === 'helpi' ? 'seizure' : 'headache'},
    {icon:'calendar',name:'Appointment', type: 'appointment'},
    {icon:'thermometer',name:'Side Effect', type: 'side_effect'},
    {icon:'plus-square',name:'Treatment', type: 'treatment'},
    {icon:'bell',name:'Reminder', type: 'reminder'},
    {icon:'edit',name:'Note / Event', type: 'other'}
  ]
  const navigateToEventPage = (itemType) => {
    if(itemType === 'Treatment'){
      navigation.navigate('Treatments')
    } else {
      navigation.navigate('AddEvent',{itemType})
    }
    onClose()
  }
  const modalHeader = (
    <View style={styles.modalHeader}>
      <Text style={styles.title}>{title}</Text>
      <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
        <Icon name={"x"} size={PR(25)} color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}/>
      </TouchableOpacity>
    </View>
  )
  const modalBody = (
    <View style={styles.modalBody}>
      <View style={styles.bodyWrapper}>
        {AddEventsArray.map((item,index) => {
          return (
            <View key={index} style={styles.bodyItem}>
              <TouchableOpacity testID={item.name} onPress={()=>navigateToEventPage(item.name)} style={styles.bodyIcon}>
                {
                  item.type === 'headache' ? 
                    <CommonImage name="headacheIconGreen" height={PR(25)} width={PR(25)}/>
                    :
                    <Icon name={item.icon} size={PR(25)} color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}/>
                }
              </TouchableOpacity>
              <Text style={styles.bodyText}>{item.type === 'treatment' ? t('translation:general.page_title.treatment') : t('translation:journal.add_' + item.type)}</Text>
            </View>
          )
        })}
      </View>
    </View>
  )

  const modalFooter = (
    <View>
      
    </View>
  )

  const modalContainer = (
    
    <TouchableWithoutFeedback>
      <View testID={testID} style={styles.modalContainer}>
        {modalHeader}
        {modalBody}
        {modalFooter}
      </View>
    </TouchableWithoutFeedback>
  )
  return (
    <Modal
      animationType={'fade'}
      transparent={true}>
      <TouchableOpacity onPress={onClose} style={styles.modal}>
          {modalContainer}
      </TouchableOpacity>
    </Modal>
  ) 
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    closeIcon: {
      backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].closeBtn,
      borderRadius: PR(25),
      padding: PR(2)
    },
    modal: {
      backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].backgroundColorModalOpacity,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContainer: {
      width: Platform.OS === 'web' ? '65%' : width * 0.7,
      padding: PR(15),
      borderRadius: PR(15),
      backgroundColor: colors.background
    },
    modalHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: PR(5)
    },
    title: {
      fontFamily: GlobalStyles.global.fontFamily.Bold,
      fontSize: PR(18),
      color: colors.primary
    },
    modalBody:{
      minHeight: PR(75),
      paddingTop: PR(10),
      paddingBottom: PR(10)
    },
    bodyWrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    bodyItem: {
      width: '50%',
      alignItems: 'center',
      paddingBottom: PR(20)
    },
    bodyText: {
      fontSize: PR(15),
      lineHeight: PR(25),
      color: colors.primary
    },
    bodyIcon: {
      alignSelf:'center', 
      borderWidth:PR(2), 
      borderRadius:PR(23), 
      borderColor: colors.bodyIconContainer, 
      backgroundColor: colors.bodyIconContainer,
      padding: PR(7)
    }
  });
}

export default AddModal;