import React, {useEffect, useState} from 'react';
import {StyleSheet, Platform, SafeAreaView, View} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonImage from 'common/src/components/CommonImage';
import PushNotificationMFA from './PushNotificationMFA';
import Biometrics from './Biometrics';
import EmailMFA from './EmailMFA';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';
import { 
    getMfaStatusAction,
} from 'common/src/store/mfa/mfaActions';
import { useDispatch, useSelector } from 'react-redux';

const EnableDisableAuth = ({navigation, route}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const Me = useSelector((state:any) => state.getMe.response);
    const [Email_mfa_status, setEmailMfaStatus] = useState<boolean>(false);
    const [Push_notif_mfa_status, setPushNotifMfaStatus] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getMfaStatusAction({
            userId: Me.result.id,
            language: Me.result.settings.language,
            callback: (res) => {
                setEmailMfaStatus(res?.message?.email_mfa)
                setPushNotifMfaStatus(res?.message?.push_notif_mfa)
            }
        }))
    },[])

    return(
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="enable_disable_biometric_title"
                title={t('translation:settings.btn.enable_disable_biometrics')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.migraine.color}
                leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
            />
            <View style={styles.IconRow}>
              <CommonImage name="biometric" width={PR(80)} height={PR(80)}/>
            </View>
            {Platform.OS !== 'web' &&
                <Biometrics />
            }
            <PushNotificationMFA 
                FBToken={route.params.FBToken} 
                push_status={Push_notif_mfa_status} 
                email_status={Email_mfa_status}
                update_push_status={setPushNotifMfaStatus}
            />
            <EmailMFA 
                email_status={Email_mfa_status}
                push_status={Push_notif_mfa_status} 
                update_email_status={setEmailMfaStatus}
            />
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            backgroundColor: colors.background,
            flex: 1,
        },
        IconRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: PR(50),
            marginBottom: PR(20)
        }
    })
}

export default EnableDisableAuth;