import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyle from '../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import CommonModal from './CommonModal';
import { useTranslation } from 'react-i18next';

const CommonCheckBox = (props:any) => {
  const colors = useTheme().colors;
  const [checkBoxSelect, setCheckBoxSelect] = useState<boolean>(props.selected || false)
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const {t} = useTranslation();
  useEffect(() => {
    if (props.selected !== undefined && checkBoxSelect !== props.selected)
      setCheckBoxSelect(props.selected)
  },[props.selected])

  const sendBackAnswer = () => {
    setCheckBoxSelect(!checkBoxSelect)
    props.updateCheckBox(!checkBoxSelect, props.index)
  }

  const type = props.type ? props.type : 'squared';
  const checkedIcon = !!props.checkedIcon;
  const styles: any = generateStyleSheet(colors, type, checkBoxSelect);

  const callbackVisible = () => {
    setModalVisible(false);
  }

  const modalOpen = ( )=> {
      setModalVisible(true)
  } 

  return (
    <View>
      <TouchableOpacity disabled={type === 'rounded' && props.selected} testID={props.testID} onPress={props.disable ? () => {} : sendBackAnswer}>
        <View style={styles.wrapper}>
          <View style={styles.txtWrapper}>
            {props.infoText && props.infoTitle && (
                <View>
                    <TouchableOpacity style={styles.infoIcon} onPress={modalOpen}>
                        <Icon name={'info'} size={PR(24)} color={GlobalStyle[APP_SHORTCUT_NAME].color}/>
                    </TouchableOpacity>
                    {
                        modalVisible &&
                        <CommonModal
                            type={'info'}
                            title={props.infoTitle}
                            text= {props.infoText}
                            buttons={undefined} 
                            onClose={callbackVisible}
                        />
                    }
                </View>
            )}
            <Text renderAsHtml={true} style={styles.checkBoxTxt}>{props.text}</Text>
          </View>
          <View style={styles.checkBoxContainer}>
            {
              checkBoxSelect &&
                <View style={styles.selectedcheckBox}>
                  {
                    checkedIcon &&
                      <Icon name="check" size={PR(17)} color={type === 'rounded-checkbox'? GlobalStyle[APP_SHORTCUT_NAME].darkColor : GlobalStyle.global.white}/>
                  }
                </View>
            }
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const generateStyleSheet = (colors:Record<string, string>, type, selected) => {
  return StyleSheet.create({
    infoIcon: {
      paddingVertical: PR(5),
      marginRight: PR(5)
    },
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: PR(5),
        paddingHorizontal: PR(12),
        flex: 1
    },
    txtWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: 1,
        flex: 1
    },
    checkBoxContainer: {
      height: PR(24),
      width: PR(24),
      borderWidth: PR(2),
      backgroundColor: colors.background,
      borderColor: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : GlobalStyle.global.lightGrey,
      borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0,
      justifyContent: 'center',
      alignItems: 'center',
      padding: PR(2)
    },
    selectedcheckBox: {
      height: PR(16),
      width: PR(16),
      backgroundColor: type === 'rounded-checkbox'? colors.background : GlobalStyle[APP_SHORTCUT_NAME].darkColor,
      borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0
    },
    checkBoxTxt: {
      color: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : colors.primary,
      fontSize: PR(16),
      lineHeight:PR(25),
      opacity: selected ? 1 : 0.8
    }
  })
}
  
export default CommonCheckBox;