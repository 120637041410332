import React, {useState, useEffect} from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonBtn from '../CommonBtn';
import Text from '../CommonCustomTxt';
import CommonDateInput from '../CommonDateInput';
import CommonSwitchBtn from '../CommonSwitchBtn';
import GlobalStyles from '../../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import CommonFooter from '../CommonFooter';
import { useTranslation } from 'react-i18next';
import { DateTime } from "luxon";
import {convertLangCode} from 'common/src/services/translationList';
import {compareDates} from 'common/src/services/compare';
import {checkBottomScroll} from '../../services/utils';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { ThemeColors } from '../../../../../*';

interface AddTreatmentPeriod {
  getAnswers: any,
  back: any,
  periodData: any,
  newDose?: boolean,
  oldWarningData?: any
}

const CommonAddTreatmentPeriod = ({getAnswers, back, periodData, newDose, oldWarningData}:AddTreatmentPeriod) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t, i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  const [answeredQuest, setAnsweredQuest] = useState<number>(periodData?.endDateBool ? 2 :periodData?.startDate ? 1 : 0)
  const [startDate, setStartDate] = useState<string | DateTime>(periodData?.startDate || undefined)
  const [endDate, setEndDate] = useState<string>(periodData?.endDate || undefined)
  const [endDateBool, setEndDateBool] = useState<boolean>(periodData?.endDateBool || undefined)
  const [showStartDate, setShowStartDate] = useState<boolean>(periodData?.startDate ? true : false)
  const [showEndDate, setShowEndDate] = useState<boolean>(periodData?.endDate ? true : false)
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);

  useEffect(() => {
    if(newDose){
      if(oldWarningData?.to){
        setStartDate(DateTime.fromISO(oldWarningData?.to).plus({days: 1}).toFormat('dd-MM-yyyy'))
        setAnsweredQuest(1)
        setShowStartDate(true)
      } else {
        setStartDate(DateTime.now().plus({days: 1}).toFormat('dd-MM-yyyy'))
        setAnsweredQuest(1)
        setShowStartDate(true)
      }
    }
  },[])

  const getDate = (startDate: string | DateTime) => {
    let today = DateTime.now();
    return startDate ? DateTime.fromFormat(startDate.toString(), 'dd-MM-yyyy').toLocal().plus({days:30}).toFormat('dd-MM-yyyy') : today.plus({days:30}).toFormat('dd-MM-yyyy');
    }
    

  const btn1 = (questionNumber) => {
    let today = DateTime.now()
    if(questionNumber === 1){
      setStartDate(today.toFormat("dd-MM-yyyy"));
      setShowStartDate(true)
    }
    else {
      setShowEndDate(true)
      setEndDateBool(true)
      setEndDate(getDate(startDate))
    } 
    setAnsweredQuest(answeredQuest+1)
  }

  const switchToggle = () => {
    setEndDateBool(!endDateBool)
    setEndDate(getDate(startDate))
  }

  const btn2 = (questionNumber) => {
    if(questionNumber === 1)
      setShowStartDate(true)
    else 
      setEndDateBool(false)
    setAnsweredQuest(answeredQuest+1)
  }

  // update the question step
  const infoStep = () => {
    if(answeredQuest < 2) {
      return answeredQuest+1 
    }
  }

  const sendBackAnswers = () =>{
    let obj = {
      startDate: startDate,
      endDateBool: endDateBool,
      endDate: endDateBool ? endDate : null
    }
    getAnswers(obj)
  }

  const goBack = () => {
    back()
  }

  const changeStartDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setStartDate(inputDate)
    }
  }

  const changeEndDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setEndDate(inputDate)
    }
  }

  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => {
    if(startDate === undefined) return true
    else if(endDateBool === undefined) return true
    else if (endDate !== undefined && compareDates(startDate, endDate, 'dd-MM-yyyy')) return true
    else return false
  }
  return(
    <>
      <View style={styles.horizontalDiv}></View>
      <ScrollView
        testID="AddMedicationInfo_scrollView"
        scrollEventThrottle={16}
        onScroll={(event) => {
          let calc = checkBottomScroll(event.nativeEvent, PR(15));
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
        }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          {showStartDate && 
          <View style={styles.dateInput}>
            <View style={styles.dateInputLabel}>
              <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.step_1.answer')}</Text>
            </View>
            <CommonDateInput
              onChange={d => changeStartDate(d)}
              value={startDate}
              fieldStyle={styles.dateInputField}
              textStyle={styles.dateInputFieldText}
              webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
              openPicker={startDate === undefined ? showStartDate : false}
            />
          </View>
          }
          {answeredQuest > 1 &&
            <View style={endDateBool ? styles.wrapper : [styles.wrapper, {paddingBottom:PR(20)}]}>
              <Text style={styles.txt}>{t('translation:treatment.add_medication.step_period.summary.end_date_label')}</Text>
              <CommonSwitchBtn 
                isOn={endDateBool}
                size="medium"
                onToggle={()=>switchToggle()}
                testID="endDateSwitch"
              />
            </View>
          }
          { endDateBool &&
            <>
              <View style={styles.dateInput}>
                <View style={styles.dateInputLabel}>
                  <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.step_2.answer')}</Text>
                </View>
                <CommonDateInput
                  onChange={d => changeEndDate(d)}
                  value={endDate}
                  fieldStyle={styles.dateInputField}
                  textStyle={styles.dateInputFieldText}
                  webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
                  openPicker={endDate === undefined ? showEndDate : false}
                />
                {startDate !== undefined && endDate !== undefined && compareDates(startDate, endDate,'dd-MM-yyyy') &&
                  <Text style={styles.errorTxt}>{t('translation:treatment.general.bad_end_date')}</Text>
                }
              </View>
            </>
          }
          {answeredQuest < 2 &&
            <View style={styles.questContainer}>
              <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_period.step_'+infoStep()+'.label')}</Text>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"Btn1ID"} type={'secondary'} title={t('translation:treatment.add_medication.step_period.step_'+infoStep()+'.one')} click={() => btn1(infoStep())}/>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"Btn2ID"} type={'secondary'} title={t('translation:treatment.add_medication.step_period.step_'+infoStep()+'.two')} click={() => btn2(infoStep())}/>
              <View style={{marginBottom:PR(15)}}></View>
            </View>
          }
          {newDose && oldWarningData?.from &&
            <View style={styles.newDoseContainer}>
              <View style={styles.newDoseWrapper}>
                <Icon name={'info'} size={PR(25)} style={{color:GlobalStyles.global.white, paddingRight:PR(10)}}/>
                <Text style={styles.warningTxt}>
                  {t('translation:treatment.intakes.warnings.previous_dose', {from: oldWarningData?.from ? DateTime.fromISO(oldWarningData?.from, { locale: lang }).toFormat('MMM dd, yyyy') : null, dose:oldWarningData?.dose})}
                  {oldWarningData?.to &&
                    t('translation:treatment.intakes.warnings.previous_dose_end', {to: oldWarningData?.to ? DateTime.fromISO(oldWarningData?.to, { locale: lang }).toFormat('MMM dd, yyyy') : null})
                  }
                </Text>
              </View>
            </View>
          }
        </View>
      </ScrollView>
      <CommonFooter atBottom={contentHeight+PR(250)} direction={'row'} reachBottom={scrollAtBottom}>
        <View style={{flexGrow: 0.5, flex:1}}>
          <CommonBtn testID={"nextBtnID"} type={'secondary'} title={t('translation:general.previous')} click={() => {goBack()}}/>
        </View>
        <View style={{flexGrow: 0.5, flex:1}}>
          <CommonBtn testID={"previousBtnID"} type={'primary'} title={t('translation:general.next')} disable={checkDisable()}  click={() => {sendBackAnswers()}}/>
        </View>
      </CommonFooter>
      <View style={{marginBottom:PR(15)}}></View>
    </>
  )
}

const generateStyleSheet = (colors: ThemeColors) => {
  return StyleSheet.create({
    horizontalDiv: {
      borderTopWidth: 1, 
      borderTopColor: GlobalStyles.global.lightGrey,
      width: '90%',
      paddingBottom:PR(20),
      alignSelf:'center'
    },
    questContainer: {
      paddingTop: PR(20),
      width: '90%',
      alignItems: 'center',
      alignSelf:'center'
    },
    questTxt: {
      fontSize: PR(16),
      color: colors.primary
    },
    label: { 
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(12)
    },
    wrapper:{
      flexDirection:'row',
      alignItems:'center',
      alignSelf:'center',
      width:'90%',
      justifyContent:'space-between',
      flex:1,
      paddingBottom: PR(10),
    },
    txt: {
      fontSize: PR(16),
      marginLeft: PR(15),
      color: colors.primary
    },
    dateInput: {
      width:'90%',
      alignSelf:'center',
      marginBottom: PR(30)
    },
    dateInputLabel: {
      marginBottom: PR(10),
      marginLeft: PR(20),
    },
    dateInputLabelText: {
      color: colors.primary,
      fontSize: PR(12)
    },
    dateInputField: {
      backgroundColor: colors.inputDateContainer,
      borderRadius: PR(20),
      borderColor: colors.inputDateContainer,
      height: PR(62),
      paddingHorizontal: PR(20),
      paddingVertical: PR(20),
    },
    dateInputFieldText: {
      color: colors.primary,
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    errorTxt: {
      color: GlobalStyles.global.redError,
      textAlign: 'center'
    },
    btnContainer: {
      width: "100%",
      marginBottom: PR(20), 
      flexDirection: 'row',
      justifyContent:'space-between'
    },
    newDoseContainer: {
      width: '90%',
      alignSelf: 'center',
      backgroundColor: GlobalStyles.global.redError,
      borderRadius: PR(20),
      padding: PR(10),
      marginBottom:PR(15)
    },
    newDoseWrapper: {
      width: '80%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    warningTxt: {
      fontSize: PR(14),
      color: GlobalStyles.global.white
    }
  })
}

export default CommonAddTreatmentPeriod;