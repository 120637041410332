import React from 'react';
import { StyleSheet } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { TextInputMask } from 'react-native-masked-text';
import GlobalStyle from '../styles/GlobalStyles';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { ThemeColors } from '../../../../*';

export type MaskProps = {
  placeholder?: string,
  type: any,
  maskFormat?: string,
  maskType?: "BRL" | "INTERNATIONAL",
  withDDD?: boolean,
  dddMask?: string,
  value?: string,
  changeText: any,
  style?: any,
  testID?: string
}

const CommonMaskInput = (
  {
    placeholder, 
    type, 
    maskFormat, 
    maskType,
    withDDD,
    dddMask,
    value, 
    changeText,
    style,
    testID
  }:MaskProps) => {
  const updateText = (text:string) => {
    changeText(text);
  }
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);

  return(
    <>
      <TextInputMask
        placeholder={placeholder}
        placeholderTextColor={colors.primary}
        type={type}
        options={{
          format: maskFormat,
          maskType: maskType,
          withDDD: withDDD,
          dddMask: dddMask
        }}
        value={value}
        onChangeText={updateText}
        style={style === undefined ? styles.text : style}
        testID={testID}
      />
    </>
  )
}

const generateStyleSheet = (colors: ThemeColors) => {
  return StyleSheet.create({
    text: {
      color: colors.primary,
      fontFamily: GlobalStyle.global.fontFamily.Regular,
      padding: PR(10),
      fontSize: PR(14),
      flex: 1,
      flexGrow: 1,
    }
  })
}

export default CommonMaskInput;