import React, {useState, useReducer} from 'react';
import {SafeAreaView, StyleSheet, View, ScrollView} from 'react-native'; 
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubtitle from 'common/src/components/CommonSubtitle';
import InputField from 'common/src/components/InputField';
import CommonFooter from 'common/src/components/CommonFooter';
import { useDispatch, useSelector } from 'react-redux';
import { updateOnboardingQuestionsAction, logOutAction, getMeAction } from 'common/src/store/actions/authenticationActions';
import {useTranslation} from 'react-i18next';
import {checkBottomScroll, generateArrayOfYears} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useNavigation } from '@react-navigation/native';

interface Questions {
  epilepsy_begin_year?: string,
  frequency?: string
}

const OnboardingQuestions = () => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [scrollState, setScrollState] = useState();
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);

  const [questionsDetails, setQuestionsDetails] = useReducer(
    (state:Questions, newState:Questions) => ({...state, ...newState}),
    {
    epilepsy_begin_year: '',
    frequency: ''
  })
  const setEpilepsyYear = (value:string) => {
    setQuestionsDetails({epilepsy_begin_year:value})
  }
  const setFrequency = (value:string) => {
    setQuestionsDetails({frequency:value})
  }
  const setLogout = () => {
    dispatch(logOutAction());
    navigation.navigate('Welcome');
    navigation.reset({
      index: 0,
      routes: [{ name: 'Welcome' }],
    });
  }
  const next = () => {
    const obj = {
      patients:{
        epilepsy_begin_year: questionsDetails.epilepsy_begin_year,
        frequency: questionsDetails.frequency,
        onboarding: {
          endOnboarding: true,
          questionsDone: true,
          neurologistDone: true
        }
      },
      navigation:navigation,
      callback: () => {
        dispatch(getMeAction())
      }
    }
    dispatch(updateOnboardingQuestionsAction(obj))
  }
  return (
    <>
      <SafeAreaView style={styles.container}>
        <CommonHeader 
          title={t('translation:general.page_title.onboarding_questions_headache')}
          rightIconNameFirst="log-out"
          rightIconColorFirst={GlobalStyles.migraine.btnColor}
          rightClickFirst={setLogout}
          scroll={scrollState}
          disableFormsBanner={true}
        />
         <View style={styles.scrollViewWrapper}>
          <ScrollView 
            style={styles.scrollview}
            scrollEventThrottle={16}
            onScroll={(event: any) => {
              let calc = checkBottomScroll(event.nativeEvent, 0);
              if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
              else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
              setScrollState(event.nativeEvent.contentOffset.y);
            }}
          >
            <View
              onLayout={(event: any) => {
                setContentHeight(event.nativeEvent.layout.height)
              }}
            >
              <View style={{width:'90%', justifyContent:'center', alignSelf:'center' }}>
                <CommonTitle text={t('translation:onboarding.questions.headache.title')} color={colors.primary} txtAlign="left"/>
                <View style={{paddingBottom:PR(20)}}>
                  <CommonSubtitle text={t('translation:onboarding.questions.headache.text')} txtAlign="left" />
                </View>
                <CommonTitle text={t('translation:onboarding.questions.headache.subtitle')} color={colors.primary} txtAlign="left"/>
              </View>
              <InputField 
                labelText={t('translation:headache_event.tutorial_form.begin_date')}
                inputType="dropdown" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setEpilepsyYear}
                data={generateArrayOfYears()}
                testID="begin_year_of_your_headache"
                value={questionsDetails.epilepsy_begin_year === '' ? t('translation:general.select_option') : questionsDetails.epilepsy_begin_year}
              />
              <InputField 
                labelText={t('translation:headache_event.content.tutorial_form.number_headaches')}
                inputType="dropdown" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setFrequency}
                data={[
                  { label: t('translation:list.headache_frequencies.0.once a month'), value: 'list.headache_frequencies.0.once a month' },
                  { label: t('translation:list.headache_frequencies.1.less_than_once_a_month'), value: 'list.headache_frequencies.1.less_than_once_a_month' },
                  { label: t('translation:list.headache_frequencies.2.2_to_5_permonth'), value: 'list.headache_frequencies.2.2_to_5_permonth' },
                  { label: t('translation:list.headache_frequencies.3.5_to_15_permonth'), value: 'list.headache_frequencies.3.5_to_15_permonth' },
                  { label: t('translation:list.headache_frequencies.4.morethan15permonth'), value: 'list.headache_frequencies.4.morethan15permonth' }
                ]}
                testID="frequensy_of_headache"
                value={questionsDetails.frequency === '' ? t('translation:general.select_option') : t('translation:'+questionsDetails.frequency)}
              />
            </View>
          </ScrollView>
          <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
            <CommonBtn type='primary' click={next} title={t('translation:register.form.btn_register')}  testID="start_using_migraine_btn"/>
          </CommonFooter>
        </View>
      </SafeAreaView>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      backgroundColor: colors.background,
      flex: 1,
    },
    scrollViewWrapper: {
      marginTop: PR(5),
      flex: 1
    },
    scrollview: {
      paddingTop: PR(20),
      flex:1
    },
  })
};

export default OnboardingQuestions;