import React from 'react';
import { View, ScrollView, StyleSheet, SafeAreaView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonFooter from 'common/src/components/CommonFooter';
import HeadacheTypesListBtn from './HeadacheTypes/HeadacheTypesListBtn';
import { getListCMSData } from 'common/src/components/CommonDiary/utils';
import { headacheTypeObject } from 'common/src/store/headacheTypes/headacheTypesReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'common/src/store/reducers';

const OnboardingAddHeadachType = ({navigation}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const headacheTypes = useSelector((state: RootState) => state.headacheTypesReducer.headacheTypes);
  const {t} = useTranslation();
  const skip = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: 'OnboardingAddMedication' }],
    });
  }

  const addHeadacheType = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: 'AddHeadacheTypes' }],
    });
  }

  const setUpdateClick = (res:headacheTypeObject) => {
    navigation.navigate('AddHeadacheTypes', {initialType:res})
  }

  const getRegionTranslation = (array:Array<string>) => {
    return array.map(item => {
      return getListCMSData(t, item, 'list.headache_zones')
    })
  }
  
  return(
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.wrapper}>
          <CommonTitle text={t('translation:headache_event.type.onboarding_title')} size={PR(28)} color={colors.primary}/>
          <CommonSubTitle text={t('translation:headache_event.type.description')} txtAlign="center"/>
          <HeadacheTypesListBtn 
                    list={
                        headacheTypes.map(item => {
                            return(
                                {
                                    name: item.name,
                                    nature: getListCMSData(t, item.nature, 'list.headache_natures'),
                                    regionLeft: item.regions.left.length > 0 ? t('translation:headache_event.type.left_side') + " : " + getRegionTranslation(item.regions.left) : undefined,
                                    regionRight: item.regions.right.length > 0 ? t('translation:headache_event.type.right_side') + " : " + getRegionTranslation(item.regions.right) : undefined,
                                    value: item
                                }
                            )
                        }
                    )} 
                    clicked={setUpdateClick}
                />
        </View>
      </ScrollView>
      <CommonFooter>
        <CommonBtn type="secondary" click={skip} title={t('translation:onboarding.end.buttonSkip')} testID="skip"/>
          <View style={{marginBottom:PR(15)}}></View>
        <CommonBtn type="primary" click={addHeadacheType} title={t('translation:headache_event.type.add')} testID="editMyMedication"/>
      </CommonFooter>
    </SafeAreaView>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      marginTop: PR(50),
      display: "flex",
      flex: 1,
      backgroundColor: colors.background,
    },
    wrapper: {
      width: '90%',
      alignSelf: 'center'
    }
  })
}

export default OnboardingAddHeadachType;