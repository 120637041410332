import React from 'react';
import { View, SafeAreaView, StyleSheet, Platform } from 'react-native';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonBtn from 'common/src/components/CommonBtn';
import RNRestart from 'react-native-restart';
import DeviceInfo, {useDeviceName} from 'react-native-device-info';
import { useTranslation } from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import {ERROR_LOG_URL, APP_SHORTCUT_NAME} from 'common/src/env.json';
import { errorRequest } from 'common/src/services/callService';
import { useTheme } from '@react-navigation/native';
import { ThemeColors } from '../../../../*';

export class ErrorBoundary extends React.Component<any, any> {
  state = {
      error: false
  }

  static getDerivedStateFromError () {
    return { error: true };
  }

  componentDidCatch (error, errorInfo) {
    const route = this.props?.navigation?.current;
    const {
      user_id, 
      deviceName, 
      version,
    } = this.props

    let errorDetails = {
      user_id,
      application_name: APP_SHORTCUT_NAME,
      appVersion: Platform.OS === 'web' ? version : DeviceInfo.getVersion(),
      buildNumber: Platform.OS === 'web' ? 'web build' : DeviceInfo.getBuildNumber(),
      deviceInfo: {
          systemName: Platform.OS === 'web' ? navigator.appName : DeviceInfo.getSystemName(),
          systemVersion: Platform.OS === 'web' ? navigator.appVersion : DeviceInfo.getSystemVersion(),
          deviceName: Platform.OS === 'web' ? navigator.platform : deviceName
      },
      error_screen: route,
      error_message:error.message,
      errorInfo
    }
    errorRequest(ERROR_LOG_URL,errorDetails)
  }

  handleRestart = async () => {
    Platform.OS === 'web' ? window.location.reload() : RNRestart.Restart();
  }

  render () {
    const t = this.props.t;
    const colors = this.props.colors
    const styles: any = generateStyleSheet(colors);
    if (this.state.error) {
      return (
        <SafeAreaView
          style={styles.container}
        >
            <View style={styles.content}>
              <View style={{ width: '100%', alignItems:'center', marginTop: PR(20)}}>
                <Icon
                  name='alert-circle'
                  size={PR(60)}
                  color={GlobalStyles[APP_SHORTCUT_NAME].color}
                />
              </View>
              <Text style={{ fontSize: PR(32), color: colors.primary }}>{t('translation:errors.error_boundary_title')}</Text>
              <View style={{marginBottom:PR(20)}}/>
              <Text renderAsHtml={true} style={{ marginVertical: PR(10), lineHeight: PR(23), fontWeight: '500', textAlign: 'justify', color: colors.primary }}>
                {t('translation:errors.error_boundary_msg')}
              </Text>
              <View style={{marginBottom:PR(20)}}/>
              <CommonBtn testID={"restartBtn"} type={'primary'} title={t('translation:errors.error_boundary_btn')} click={() => {this.handleRestart()}}/>
            </View>
        </SafeAreaView>
      )
    } else {
      return this.props.children;
    }
  }
}

const generateStyleSheet = (colors: ThemeColors) => {
  return StyleSheet.create({
    container: {
      backgroundColor: colors.background,
      flex: 1,
    },
    content: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      width: '90%'
    }
  })
};

export default function(props) {
  const { colors } = useTheme();
  const { result } = useDeviceName();
  const { t } = useTranslation();

  return <ErrorBoundary {...props} deviceName={result} t={t} colors={colors} />;
}
