export enum ThemeActions {
    SWITCH_THEME = "SWITCH_THEME",
    SWITCH_THEME_SUCCESS = "SWITCH_THEME_SUCCESS",
}

export interface SwitchThemeInterface {
    themeMode: string,
}

export const switchThemeAction = (payload: SwitchThemeInterface) => {
    return {
        type: ThemeActions.SWITCH_THEME,
        ...payload
    };
};