import {StartProgramActionTypes, StartProgramAction, AddProgramActionTypes, AddProgramAction} from './types'
import {call, takeLatest, select, put} from "redux-saga/effects";
import { 
    GET_PROGRAMS, 
    GET_PROGRAMS_SUCCESS, 
    GET_PATIENT_PROGRAMS, 
    GET_PATIENT_PROGRAMS_SUCCESS,
    ADD_PATIENT_PROGRAMS,
    ADD_PATIENT_PROGRAMS_SUCCESS,
    UPDATE_PATIENT_PROGRAMS,
    UPDATE_PATIENT_PROGRAMS_SUCCESS,
    GET_EDUCATION_MODULES_SUCCESS,
    GET_EDUCATION_MODULES,
    GET_PROGRAM_ACTIONS_SUCCESS,
    GET_PROGRAM_ACTIONS,
    START_PROGRAM_ACTION,
    START_PROGRAM_ACTION_SUCCESS,
    ADD_PROGRAM_ACTION,
    ADD_PROGRAM_ACTION_SUCCESS,
    GET_SUBPROGRAMS,
    GET_SUBPROGRAMS_SUCCESS,
    GET_EDUCATION_MODULES_WITH_ALL_DATA_SUCCESS,
    GET_EDUCATION_MODULE_WITH_ALL_DATA
} from "./programsActions";
import {makeRequest} from "../../services/requestService";


export function* getProgramsSaga(payload:any) {
    const state = yield select()
    const id = state?.getMe?.response?.result?.patients?.id || payload.patientId
    const response = yield call(makeRequest, {
        path: `/programs/library?patient_id=${id}`,
        method: 'GET',
        ...payload
    })
    if(response){
        yield put({ type: GET_PROGRAMS_SUCCESS, response: {result:response, patientID: id} });
    }

}

export function* getPatientProgramsSaga(payload:any) {
    const state = yield select()
    const id = state?.getMe?.response?.result?.patients?.id || payload.patientId
    yield call(makeRequest, {
        path: `/patient_programs/${id}`,
        method: 'GET',
        responseAction: GET_PATIENT_PROGRAMS_SUCCESS,
        ...payload
    })
}

export function* getEducationModulesSaga(payload: any) {
    let path = "/educationmoduleswithspecificfields";
    if (payload.ids) {
        const idsParam = payload.ids.map(id => `id[]=${id}`).join('&'); 
        path += "?" + idsParam;
    }

    yield call(makeRequest, {
        path,
        method: 'GET',
        responseAction: GET_EDUCATION_MODULES_SUCCESS,
    })
}

export function* getEducationModuleWithAllDataSaga(payload: any) {
    let path = "/educationmoduleWithAllData";
    if (payload.ids) {
        const idsParam = payload.ids.map(id => `id[]=${id}`).join('&'); 
        path += "?" + idsParam;
    }

    yield call(makeRequest, {
        path,
        method: 'GET',
        responseAction: GET_EDUCATION_MODULES_WITH_ALL_DATA_SUCCESS,
        ...payload
    })
}

export function* getProgramActionsSaga(payload:any) {
    yield call(makeRequest, {
        path: `/programactions/`,
        method: 'GET',
        responseAction: GET_PROGRAM_ACTIONS_SUCCESS,
        ...payload
    })
}

export function* addPatientProgramsSaga(payload:any) {
    const {program} = payload
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    const obj = {
        patient: id,
        program
    }
    yield call(makeRequest, {
        data: obj,
        path: `/patient_programs/`,
        method: 'POST',
        responseAction: ADD_PATIENT_PROGRAMS_SUCCESS,
        ...payload
    })
}

export function* updatePatientProgramsSaga(payload:any) {
    const {obj} = payload
    yield call(makeRequest, {
        data: obj,
        path: `/patient_programs/${obj.id}`,
        method: 'PATCH',
        responseAction: UPDATE_PATIENT_PROGRAMS_SUCCESS,
        ...payload
    })
}

export function* startProgramActionSaga(payload:StartProgramActionTypes) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    let obj = {
        done: payload?.done,
        scheduledDate: payload?.scheduledDate,
        old_date: payload?.old_date,
        actionEventId: payload.actionEventId
    }
    yield call(makeRequest, {
        data: obj,
        path: `/patient_programs/${id}/actions/${payload.actionId}/start`,
        method: 'POST',
        responseAction: START_PROGRAM_ACTION_SUCCESS,
        ...payload
    })
}

export function* addProgramActionSaga(payload:AddProgramActionTypes) {
    let obj = {
        actions: payload.actionId,
        action: payload.action
    }
    yield call(makeRequest, {
        data: obj,
        path: `/patient_programs/${payload.patient_program_id}/action`,
        method: 'POST',
        responseAction: ADD_PROGRAM_ACTION_SUCCESS,
        ...payload
    })
}

export function* getSubprograms(payload) {
    yield call(makeRequest, {
        path: `/subprograms?main_program_id=${payload.mainProgramId}`,
        method: 'GET',
        responseAction: GET_SUBPROGRAMS_SUCCESS,
        ...payload
    })
}

export default function* watch() {
    yield takeLatest(GET_PROGRAMS, getProgramsSaga);
    yield takeLatest(GET_PATIENT_PROGRAMS, getPatientProgramsSaga);
    yield takeLatest(GET_EDUCATION_MODULES, getEducationModulesSaga);
    yield takeLatest(GET_EDUCATION_MODULE_WITH_ALL_DATA, getEducationModuleWithAllDataSaga);
    yield takeLatest(GET_PROGRAM_ACTIONS, getProgramActionsSaga);
    yield takeLatest(ADD_PATIENT_PROGRAMS, addPatientProgramsSaga);
    yield takeLatest(UPDATE_PATIENT_PROGRAMS, updatePatientProgramsSaga);
    yield takeLatest<StartProgramAction>(START_PROGRAM_ACTION, startProgramActionSaga);
    yield takeLatest<AddProgramAction>(ADD_PROGRAM_ACTION, addProgramActionSaga);
    yield takeLatest(GET_SUBPROGRAMS, getSubprograms);
}
