import { call, takeLatest, select, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { formActions } from "./formActions";

export function* getAvailableForms() {
    let state = yield select();
    const id = state.getMe.response.result.patients.id;
    const response = yield call(makeRequest, {
      path: `/patients/${id}/available_forms`,
      method: 'GET',
    });
    if (response) {
      yield put ({ type: formActions.GET_AVAILABLE_FORMS_SUCCESS, response: {success: true, result: response} });
    }
  }

  export default function* watch() {
    yield takeLatest(formActions.GET_AVAILABLE_FORMS, getAvailableForms);
    
}