
import { formActions } from "./formActions";
import { ADD_EVENT_SUCCESS } from '../actions'
import moment from 'moment';

export default function (state: any = {
    availableForms: [],
}, action: any) {
    const response = action.response;
    if (!response)
        return state
    if (action.type === formActions.GET_AVAILABLE_FORMS_SUCCESS) {
        if (state.availableForms.length === response.result.length)
            return state
        else
            return {
                ...state,
                availableForms: response.result
            };
    }
    if (action.type === ADD_EVENT_SUCCESS) {
        const { result: addedForm } = response;
        if (addedForm.type !== 'form')
            return {...state}
        const stateForms = [...state.availableForms];
        if (addedForm.studyParameters) {
            const formFound = state.availableForms.findIndex(item => item.form === addedForm.form.id && item.send_date === addedForm.studyParameters.send_date)
            if (formFound > -1)
                stateForms.splice(formFound, 1)
        }
        else {
            const formFound = state.availableForms.findIndex(item => item.form === addedForm.form.id)
            if (formFound > -1)
                stateForms.splice(formFound, 1)
        }
        return {...state, availableForms: stateForms}
    }

    else
        return state
};