import React, {useState} from "react";
import {View, StyleSheet, TouchableOpacity, ScrollView} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from "common/src/components/CommonHeader";
import CommonFooter from "common/src/components/CommonFooter";
import CommonBtn from "common/src/components/CommonBtn";
import Text from 'common/src/components/CommonCustomTxt';
import InputField from "common/src/components/InputField";
import CommonDeleteEventModal from 'common/src/components/CommonDeleteEvents/CommonDeleteEventModal';
import CommonRadioForm from "common/src/components/CommonAddEvents/CommonRadioForm";
import GlobalStyles from "common/src/styles/GlobalStyles";
import { useTranslation } from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useDispatch, useSelector } from "react-redux";
import Loading from "common/src/components/Loading";
import { getListCMSData } from 'common/src/components/CommonDiary/utils';
import CommonLabelForm from "common/src/components/CommonAddEvents/CommonLabelForm";
import CommonActionModal from "common/src/components/CommonActionModal";
import CommonSelectForm from "common/src/components/CommonAddEvents/CommonSelectForm";
import { deleteHeadacheTypesAction, addHeadacheTypeAction, updateHeadacheTypeAction } from "common/src/store/headacheTypes/headacheTypesActions";
import HeadMapping from './HeadMapping';
import CommonKeyboardAvoidingView from "common/src/components/CommonKeyboardAvoidingView";

const AddHeadacheTypes = ({navigation, route}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [scrollState, setScrollState] = useState<number>(0);
    const ProfileResponse = useSelector((state:any) => state.getMe.response)
    const PatientData = useSelector((state: any) => state.getMe.patient)
    const [name, setName] = useState<string>(route?.params?.initialType?.name || '');
    const [side, setSide] = useState<string>(route?.params?.initialType?.start_side || '');
    const [leftSide, setLeftSide] = useState<Array<string>>(route?.params?.initialType?.regions?.left || []);
    const [rightSide, setRightSide] = useState<Array<string>>(route?.params?.initialType?.regions?.right || []);
    const [natureOfPain, setNatureOfPain] = useState<string>(route?.params?.initialType?.nature || '');
    const [beforeHeadache, setBeforeHeadache] = useState<Array<string>>(route?.params?.initialType?.auras || []);
    const [headacheSymptoms, setHeadacheSymptoms] = useState<Array<string>>(route?.params?.initialType?.symptoms || []);
    const [remarks, setRemarks] = useState<string>(route?.params?.initialType?.remark ||'');
    const [isBeforeHeadacheModalOpen, setBeforeHeadacheModal] = useState<boolean>(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const [islocationTypeModalOpen, setLocationTypeModal] = useState<boolean>(false);
    const [modalSide, setModalSide] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false)
    const routes = navigation.getState()?.routes;
    const prevRoute = routes[routes.length - 2];

    const goBack = () => {
         if(!prevRoute) {
            navigation.reset({
                index: 0,
                routes: [{ name: 'OnboardingAddHeadachType' }],
              });
        } else navigation.goBack();
        
        
    }
    const selectedValueInModal = (value:string, array:Array<string>) => {
        if(value !== undefined) {
          let findSelected = array.find(item => item === JSON.parse(value.replace(/[\[\]']+/g,''))) 
          if(findSelected !== undefined) return true
        }
    }

    const setValueFromModal = (setFunc: (res:Array<string>)=> void, array:Array<any>) => {
        let tempArray:Array<string> = []
        const selected = array.filter(item => item.selected === true)
        if(selected.length > 0) {
            selected.forEach(item => {
                tempArray.push(JSON.parse(item.value.replace(/[\[\]']+/g,'')))
            })
        }
        setFunc(tempArray)
        setBeforeHeadacheModal(false)
        setLocationTypeModal(false)
    }

    const saveHeadacheType = () => {
        setLoading(true)
        dispatch(addHeadacheTypeAction({
            auras: beforeHeadache,
            name: name,
            nature: natureOfPain,
            start_side: side,
            symptoms: headacheSymptoms,
            remark: remarks,
            regions: {
                left: leftSide,
                right: rightSide
            },
            successMsg: t('translation:events.headache.success'),
            patientID: ProfileResponse?.result?.patients?.id,
            callback: () => {
                setLoading(false)
                if(!prevRoute)
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'OnboardingAddHeadachType' }],
                    });
                else navigation.goBack();
            },
            errorCallback: () => {
                setLoading(false)
            }
        }))
    }

    const updateHeadachType = () => {
        setLoading(true)
        dispatch(updateHeadacheTypeAction({
            auras: beforeHeadache,
            name: name,
            nature: natureOfPain,
            start_side: side,
            symptoms: headacheSymptoms,
            remark: remarks,
            regions: {
                left: leftSide,
                right: rightSide
            },
            patientID: ProfileResponse?.result?.patients?.id,
            headacheTypeID: route?.params?.initialType?.id,
            callback: () => {
                setLoading(false)
                navigation.navigate("Main")
            },
            errorCallback: () => {
                setLoading(false)
            }
        }))
    }

    const deleteHeadacheType = () => {
        setLoading(true)
        dispatch(deleteHeadacheTypesAction({
            headacheTypeID: route?.params?.initialType?.id,
            successMsg: t('translation:headache_event.type.success_delete'),
            callback: () => {
                setLoading(false)
                navigation.goBack()
                setDeleteModalVisible(false)
            },
            errorCallback: () => {
                setLoading(false)
                setDeleteModalVisible(false)
            }
        }))
    }

    const isDisable = () => (!name.length || !side.length || !natureOfPain.length)

    if(isLoading)
        return <Loading />

    const updateHeadLeftSelection = (location: string) => {
        const indexFound = leftSide.indexOf(location)
        if (indexFound > -1)
            leftSide.splice(indexFound, 1)
        else
            leftSide.push(location)
    }

    const updateHeadRightSelection = (location: string) => {
        const indexFound = rightSide.indexOf(location)
        if (indexFound > -1)
            rightSide.splice(indexFound, 1)
        else
            rightSide.push(location)
    }

    const formatHcpHeadacheTypes = () => {
        const formattedData: any = [];
        PatientData.lists.neurologist_headache_types.forEach((type) => {
            formattedData.push({
                label: type,
                value: type
            })
        })
        return formattedData
    }

    return(
        <CommonKeyboardAvoidingView>
            { isBeforeHeadacheModalOpen &&
                <CommonActionModal 
                    testID={"beforeHeadacheModalID"}
                    title={t('translation:modal.select.title_multiple')} 
                    modalType={'select-check'} 
                    buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: (res:Array<string>) => {setValueFromModal(setBeforeHeadache,res);}, action: 'save'}]} 
                    onClose={() => setBeforeHeadacheModal(false)} 
                    // @ts-ignore
                    data={t<string | TemplateStringsArray>('translation:list.headache_auras', {returnObjects: true}).map((name:string ,i:number) => {
                        return {label:t('translation:list.headache_auras.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), selected:selectedValueInModal(JSON.stringify(Object.keys(name)), beforeHeadache), pos:i}
                    })}
                    infoData="list.headache_auras_details"
                    info
                />
            }
            { islocationTypeModalOpen &&
                <CommonActionModal 
                    testID={"leftSideModalID"}
                    title={t('translation:modal.select.title_multiple')} 
                    modalType={'select-check'} 
                    buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: (res:Array<string>) => {setValueFromModal(modalSide === 'left' ? setLeftSide : setRightSide,res);}, action: 'save'}]} 
                    onClose={() => setLocationTypeModal(false)} 
                    // @ts-ignore
                    data={t<string | TemplateStringsArray>('translation:list.headache_zones', {returnObjects: true}).map((name:string ,i:number) => {
                        return {label:t('translation:list.headache_zones.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), selected:selectedValueInModal(JSON.stringify(Object.keys(name)), side === 'left' ? leftSide: rightSide), pos:i}
                    })}
                />
            }
            { isDeleteModalVisible && 
                <CommonDeleteEventModal 
                    onClose={() => { setDeleteModalVisible(false); }} 
                    onConfirm={deleteHeadacheType} 
                    confirmationText={'headache_event.type.confirm_delete'}
                /> 
            }
            {  isUpdateModalVisible &&
                <CommonDeleteEventModal 
                confirmationText={t('translation:modal.headache_type.edit_text')}
                onClose={() => {setUpdateModalVisible(false); }}
                onConfirm={updateHeadachType}                
                />
            }
            <CommonHeader
                testIDTitle="RescueMedicationTitle"
                title={t('translation:headache_event.type.add')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.migraine.color}
                leftClick={goBack}
                scroll={scrollState}
            />      
            <ScrollView
                testID="AddHeadacheType_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
                        setScrollState(event.nativeEvent.contentOffset.y);
                    else if(scrollState > 0 && event.nativeEvent.contentOffset.y === 0)
                        setScrollState(0);
                }}
            >
                {
                    PatientData && PatientData.lists && PatientData.lists.neurologist_headache_types && PatientData.lists.neurologist_headache_types.length > 0 ?
                    <CommonRadioForm
                        value={name}
                        setValue={(type) => {
                            setName(type)
                        }}
                        patientList="customHeadache"
                        dataFieldToDisplay={"label"}
                        label={t('translation:general.events.type')}
                        dataList={formatHcpHeadacheTypes()}
                        isOptional={false}
                        handleCreateOther={() => {}}
                    />
                    :
                    <InputField
                        labelText={t('translation:headache_event.type.name')}
                        labelTextSize={PR(14)}
                        inputType="string"
                        customStyle={{marginBottom:PR(30)}}
                        changeText={setName}
                        placeholderTxt={t('translation:headache_event.type.name_input_placeholder')}
                        value={name}
                        testID="name_id"
                    />
                }
                <InputField
                    labelText={t('translation:headache_event.type.start_side.label')}
                    labelTextSize={PR(14)}
                    inputType="dropdown"
                    customStyle={{marginBottom:PR(30)}}
                    changeText={(res:string) => setSide(res.replace(/["']/g,''))}
                    placeholderTxt={t('translation:headache_event.type.start_side.placeholder')}
                    // @ts-ignore
                    data={t<string | TemplateStringsArray>('translation:list.start_side', {returnObjects: true}).map((name,i) => {
                        return {label:t('translation:list.start_side.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)).replace(/[\[\]']+/g,'')}
                    })}
                    value={getListCMSData(t, side, 'list.start_side')}
                    testID="side"
                />
                <HeadMapping updateLeftSelection={updateHeadLeftSelection} updateRightSelection={updateHeadRightSelection} leftSide={leftSide} rightSide={rightSide}/>
                <CommonRadioForm
                    value={natureOfPain}
                    setValue={setNatureOfPain}
                    cmsListLink="list.headache_natures"
                    cmsListLinkInfo="list.headache_natures_details"
                    patientList="nature_inputs"
                    label={t('translation:headache_event.type.nature')}
                    isOptional={false}
                />
                <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                    <CommonLabelForm label={t('translation:headache_event.headache.form.aura')} isOptional={true}/>
                    <TouchableOpacity testID="weekFrequencyID" style={styles.summaryContainer} onPress={() =>setBeforeHeadacheModal(!isBeforeHeadacheModalOpen)}>
                        <View style={styles.inputSummaryContainer}>
                        <Text style={styles.inputSummaryValue}>{beforeHeadache.length === 0 ? t('translation:modal.select.title_multiple') : beforeHeadache.map((item):string => getListCMSData(t, item, "list.headache_auras")).join("\n") }</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                <CommonSelectForm
                    values={headacheSymptoms}
                    setValues={(res:Array<string>) => {setHeadacheSymptoms(res);}}
                    cmsListLink="list.headache_symptoms"
                    cmsListLinkInfo="list.headache_symptoms_details"
                    info
                    patientList="symptoms_inputs"
                    label={t('translation:headache_event.type.symptoms.label')}
                    isOptional={true}
                />
                <InputField
                    labelText={t('translation:general.events.remark')}
                    inputType="string"
                    customStyle={{marginBottom:PR(30)}}
                    changeText={(d:string) => {setRemarks(d)}}
                    placeholderTxt={t('translation:general.events.remark_placeholder')}
                    testID="remarks"
                    multiline={true}
                    value={remarks}
                    isOptional={true}
                />
            </ScrollView>
            <CommonFooter>
                {
                    route?.params?.initialType &&
                    <CommonBtn 
                        testID={"deleteRescueMedicationID"} 
                        type={'tertiary'} 
                        color={GlobalStyles.global.redError}
                        title={t('translation:headache_event.type.delete')} 
                        click={() => setDeleteModalVisible(true)}
                    />
                }
                <View style={{marginBottom: PR(5)}}></View>
                <CommonBtn 
                    testID={"AddNewHeadacheTypeBtnID"} 
                    type={'primary'} 
                    title={route?.params?.initialType ? t('translation:headache_event.type.edit') : t('translation:headache_event.type.page_save_button')} 
                    click={() => {route?.params?.initialType ? setUpdateModalVisible(true) : saveHeadacheType()}}
                    disable={isDisable()}
                />
            </CommonFooter>
        </CommonKeyboardAvoidingView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        summaryContainer: {
            backgroundColor: colors.inputBackground,
            borderRadius: PR(20),
        },
        inputSummaryContainer: {
            flex: 1,
            flexDirection: 'row',
            minHeight: PR(62),
            padding: PR(10),
            fontSize: PR(16),
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        inputSummaryValue: {
            flex: 1,
            flexGrow: 1,
            color: colors.primary,
            marginLeft: PR(10),
            fontSize: PR(16),
            fontFamily: GlobalStyles.global.fontFamily.Medium
        },
    })
}

export default AddHeadacheTypes;