import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, View, BackHandler, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonNumPad from 'common/src/components/Inputs/CommonNumPad';
import CommonPinField from 'common/src/components/Inputs/CommonPinField';
import CommonModal from 'common/src/components/CommonModal';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import ReactNativeBiometrics from 'react-native-biometrics';
import DeviceInfo from 'react-native-device-info';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';
import { verifyBiometricAction, verifyPinCodeAction } from 'common/src/store/biometrics/biometricsActions';
import { useDispatch, useSelector } from 'react-redux';
import { APP_SHORTCUT_NAME } from "common/src/env.json";

const rnBiometrics = new ReactNativeBiometrics()

const LoginWithBiometrics = ({navigation}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const Me = useSelector((state:any) => state.getMe.response)
    const [pinInput, setPinInput] = useState<Array<string>>([]);
    const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false)
    const pinLength = 4;

    useEffect(() => {
        biometricKey();
        const backBtnSubscription = BackHandler.addEventListener("hardwareBackPress", () => {
            if(navigation.getState().routeNames[0] === 'LoginWithBiometrics'){
                return true;
            }
            else
                return false;
        })
        return () => {
            backBtnSubscription.remove();
        };   
    }, []);

    const biometricKey = () => {
        rnBiometrics.biometricKeysExist()
            .then((resultObject) => {
                const { keysExist } = resultObject

                if (keysExist) {
                    verifySignature();
                }
            })
    }

    const verifySignature = () => {
        let deviceID = "";
        DeviceInfo.getUniqueId().then(id => {
            deviceID = id
        })
        let epochTimeSeconds = Math.round((new Date()).getTime() / 1000).toString()
        let payload = epochTimeSeconds + (Math.random() + 1).toString(36).substring(2)

        rnBiometrics.createSignature({
            promptMessage: t('translation:MFA.biometrics.modal.title'),
            payload: payload
            })
            .then((resultObject) => {
                const { success, signature } = resultObject
                if (success) {
                    dispatch(verifyBiometricAction({
                        signature,
                        payload,
                        deviceID,
                        userID: Me.result.id,
                        callback: () => {
                            if(navigation.canGoBack()){
                                navigation.goBack()
                            }
                            else {
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'Main' }],
                                });
                            }
                        },
                    }))
                }
            })
            .catch(() => {
               
            })
    }

    const changePinInput = (input: string) => {
        if(pinInput.length < pinLength)
            setPinInput([...pinInput, input])
        
    }

    const deletePinInput = () => {
        if(pinInput.length > 0)
            setPinInput(pinInput.slice(0, -1))
        
    }

    const verifyPin = () => {
        setPinInput([]);
        dispatch(verifyPinCodeAction({
            userID: Me?.result?.id,
            biometric_pin: pinInput.join(""),
            callback: () => {
                if(navigation.canGoBack()){
                    navigation.goBack()
                }
                else {
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'Main' }],
                    });
                }
            },
        }))
    }

    return(
        <SafeAreaView style={styles.container}>
            {isInfoModalOpen && 
                <CommonModal
                    testID="infoModal"
                    type={'error'}
                    title={t('translation:MFA.forgot_pin_code.title')}
                    text={t('translation:MFA.forgot_pin_code.description')}
                    buttons={[{
                        type: 'primary',
                        title: t('translation:modal.btn.close'),
                        click: () => setInfoModalOpen(false)
                      }]}
                    onClose={() => setInfoModalOpen(false)}
                />
            }
            <TouchableOpacity onPress={() => setInfoModalOpen(true)} testID='icon_help' style={[styles.helpWrapper, {marginTop: PR(10)}]}>
                <Icon 
                    name={"info"} 
                    size={PR(30)} 
                    color={GlobalStyles[APP_SHORTCUT_NAME].color} 
                />
            </TouchableOpacity>
            <View testID='icon_id' style={[styles.descriptionWrapper, {marginTop: PR(20)}]}>
                <Icon 
                    name={"smile"} 
                    size={PR(60)} 
                    color={"#1de9b6"} 
                />
            </View>
            <View style={[styles.descriptionWrapper, {marginBottom: PR(20)}]}>
                <Text testID="greetings_text_id" style={[styles.greetingTxt]}>{t('translation:MFA.greetingsTxt', {name: Me?.patientData?.first_name})}</Text>
            </View>
            <CommonPinField 
                input={pinInput}
                pinLength={pinLength}
                inputAreaStyle={{marginBottom: PR(24)}}
                showInputText={false}
            />
            <CommonNumPad 
                setInput={changePinInput}
                deleteInput={deletePinInput}
                isConfirmActive={pinInput.length === pinLength ? true : false}
                sendConfirmation={()=> verifyPin()}
            />
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            backgroundColor: colors.background,
            flex: 1,
        },
        descriptionWrapper:{
            alignItems:'center',
            alignSelf:'center',
            width:'90%',
            justifyContent:'space-between',
            paddingBottom: PR(10),
        },
        helpWrapper:{
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            width:'90%',
            justifyContent:'space-between',
            paddingTop: PR(20),
            paddingRight: PR(20)
        },
        IconRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: PR(20)
        },
        descriptionTxt: {
            fontSize: PR(16),
            color: colors.primary
        },
        subTxt: {
            fontSize: PR(12),
            color: colors.primary
        },
        greetingTxt: {
            fontSize: PR(24),
            color: colors.primary,
            fontFamily: GlobalStyles.global.fontFamily.Bold
        }
    })
};

export default LoginWithBiometrics;