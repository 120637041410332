import React, {useState} from 'react';
import {Text, useWindowDimensions, Linking, Platform} from 'react-native';
import { useTheme } from '@react-navigation/native';
import GlobalStyles from '../styles/GlobalStyles';
import CommonModal from './CommonModal';
import HTML, {defaultSystemFonts} from 'react-native-render-html'
import isHtml from 'is-html';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import {useTranslation} from 'react-i18next';

const CommonCustomTxt = React.memo(function CommonCustomTxt(props:any) {
  const { colors } = useTheme();
  const {t} = useTranslation();
  let imageWidth = props.imageWidth || PR(50);
  let text = props.children;
  // Register the font families for the render with html
  const systemFonts = [
    ...defaultSystemFonts, 
    GlobalStyles.global.fontFamily.Black, 
    GlobalStyles.global.fontFamily.BlackItalic,
    GlobalStyles.global.fontFamily.Bold,
    GlobalStyles.global.fontFamily.BoldItalic,
    GlobalStyles.global.fontFamily.Heavy,
    GlobalStyles.global.fontFamily.HeavyItalic,
    GlobalStyles.global.fontFamily.Light,
    GlobalStyles.global.fontFamily.LightItalic,
    GlobalStyles.global.fontFamily.Medium,
    GlobalStyles.global.fontFamily.MediumItalic,
    GlobalStyles.global.fontFamily.Regular, 
    GlobalStyles.global.fontFamily.RegularItalic,
    GlobalStyles.global.fontFamily.Thin,
    GlobalStyles.global.fontFamily.ThinItalic
  ];
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [link, setLink] = useState<string>("");
  const REGEX_TRUSTED_DOMAINS = /neuroventis.care|helpilepsy.com|migrainemanager.care|helpilepsy.de|epione.care/ ;
  const contentWidth = useWindowDimensions().width;
  const computeEmbeddedMaxWidth = (availableWidth) => {
    return Math.min(availableWidth, imageWidth);
  };

  const parseHtml = () => {
    text = text.replace(/<h[0-9]/g, '<div')
    text = text.replace(/<\/h[0-9]>/g, '</div>')
    return text
  }

  const externalLink = () => {
    Platform.OS === 'web' ? window.open(link, '_blank') : Linking.openURL(link)
    setModalOpen(false)
  }

  const renderersProps = {
    a: {
      onPress(_, href) {
        if(!REGEX_TRUSTED_DOMAINS.test(href)){
          setLink(href)
          setModalOpen(true)
        } else {
          Platform.OS === 'web' ? window.open(href, '_blank') : Linking.openURL(href)
        }
      }
    }
  }

  return (
    <>
      {isModalOpen && (
        <CommonModal
            type={'error'}
            title={t('translation:modal.external_link.title')}
            text={t('translation:modal.external_link.body', {link:link})}
            onClose={() => setModalOpen(false)}
            buttons={[
              {
                type: 'tertiray',
                title: t('translation:modal.external_link.back_btn'),
                click: () => setModalOpen(false)
              },
              {
                type: 'primary',
                title: t('translation:modal.external_link.follow_btn'),
                click: () => externalLink()
              }
            ]}
        />
      )
      }
      {
        isHtml(props.children) && props.renderAsHtml ?
          <HTML
            systemFonts={systemFonts}
            tagsStyles={props.tagsStyles}
            source={{ html: props.shouldParseHtml ? parseHtml() : text }}
            baseStyle={{fontFamily:GlobalStyles.global.fontFamily.Regular, color:colors.primary, ...props.style}} 
            contentWidth={contentWidth}
            computeEmbeddedMaxWidth={computeEmbeddedMaxWidth}
            renderersProps={renderersProps}
            enableExperimentalMarginCollapsing={true}
            enableExperimentalBRCollapsing={true}
            enableExperimentalGhostLinesPrevention={true}
          />
          :
          <Text {...props} style={[{fontFamily: GlobalStyles.global.fontFamily.Regular, color:colors.primary, fontSize: PR(14)}, props.style]}>
            {
              text
            }
          </Text>
      }
    </>
  )
})

export default CommonCustomTxt;