import React, {useState} from 'react';
import { StyleSheet, View, ScrollView} from 'react-native'; 
import { useTheme } from '@react-navigation/native';
import GlobalStyle from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonNeuroStep from 'common/src/components/CommonNeuroStep';
import { useDispatch, useSelector } from 'react-redux';
import { updatePatientAction, logOutAction } from 'common/src/store/actions/authenticationActions';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const NeurologistStep = ({navigation}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetMeResponse = useSelector((state:any) => state.getMe.response);
  const [scrollState, setScrollState] = useState();

  const next = (obj:any) => {
    const updatePatient = () => (updatePatientAction({obj,id:GetMeResponse.result.patients.id, navigation}));
    dispatch(updatePatient())
  }

  const setLogout = () => {
    dispatch(logOutAction());
    navigation.navigate('Welcome');
    navigation.reset({
      index: 0,
      routes: [{ name: 'Welcome' }],
    });
  }

  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader 
        rightIconNameFirst="log-out"
        rightIconColorFirst={GlobalStyle.migraine.btnColor}
        rightClickFirst={setLogout}
        title={t('translation:register.form.neurologist')}
        scroll={scrollState}
        disableFormsBanner={true}
      />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <CommonNeuroStep nextTriggered={next} app={'migraine'}/>
        </ScrollView>
      </View>
    </CommonKeyboardAvoidingView>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    scrollViewWrapper: {
      marginTop: PR(5),
      flex: 1,
    },
    scrollview: {
      paddingTop: PR(20),
      flex:1
    },
  })
}

export default NeurologistStep;