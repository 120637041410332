import React, {useEffect} from 'react';
import { View, StyleSheet, SafeAreaView, Platform, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import MedicationHistoryName from './MedicationHistoryName';
import MedicationHistoryDetails from './MedicationHistoryDetails';
import CommonBtn from 'common/src/components/CommonBtn';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getEditMedicationsHistoryActions } from 'common/src/store/actions/authenticationActions';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonFooter from 'common/src/components/CommonFooter';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';

const MedicationHistory = ({navigation, route}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const GetMedicationsHistory = useSelector((state:any) => state.getMedications.medicationsHistory)
  const GetEditMedicationsHistory = useSelector((state:any) => state.getMedications.editMedicationHistory)
  const id = route.params.id
  let countSameMed = 0
  let allMedData
  
  useEffect(() => {
      dispatch(getEditMedicationsHistoryActions(id))
  },[id])

  const addNewDoseData = () => {
    let newDoseData
    if(GetMedicationsHistory.length > 0)
      GetMedicationsHistory.forEach(med => {
        // if treatement created in Cordova do the following fix to avoid crashes
        med.intake.dosage = med?.intake?.dosage?.length ? med?.intake?.dosage : null; 
        if(med.id === id) {
          newDoseData = med
        }
      })
    return newDoseData
  }
  const assignData = (newData) => {
    if(allMedData?.intake){
      let CpyAllMedData = JSON.parse(JSON.stringify(allMedData))
      Object.assign(CpyAllMedData.intake, newData)
      return CpyAllMedData
    }
  }
  return(
    <SafeAreaView style={styles.container}>
      <CommonHeader
        title={t('translation:general.page_title.history')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles[APP_SHORTCUT_NAME].color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
        // scroll={scrollState}
        testIDTitle="history"
      />
      <ScrollView style={{flex: 1}}>
        <View>
          {GetMedicationsHistory.length > 0 && GetMedicationsHistory.map((med, i) => {
            if(med.id === id && countSameMed === 0) {
              countSameMed = countSameMed + 1;
              allMedData = med
              return(
                <View key={i}>
                  <MedicationHistoryName med={med} navigation={navigation} />
                  <View style={{marginBottom:PR(15)}}></View>
                </View>
              )
            }
          })}
          {GetEditMedicationsHistory.length > 0 && GetEditMedicationsHistory.map((med, i) => {
              // if treatement created in Cordova do the following fix to avoid crashes
              med.dosage = med?.dosage?.length ? med?.dosage : null;
              return(
                <View key={i}>
                  <MedicationHistoryDetails med={med} navigation={navigation} allData={assignData(med)} />
                </View>
              )
          })}
        </View>
       
      </ScrollView>
      <CommonFooter>
        <CommonBtn testID={"intakesHoursBtnID"} type={'secondary'} title={t('translation:medication.history.add_new_dose')} click={() => navigation.navigate('EditMedication', {data:addNewDoseData(), type:'dose', newDose:addNewDoseData()})}/>
      </CommonFooter>
    </SafeAreaView>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    btnContainer: {
      flex:1,
      alignSelf:'center', 
      width:'100%',
      marginBottom: Platform.OS === 'web' ? PR(50) : PR(20),
      marginTop: PR(15)
    }
  })
}

export default MedicationHistory;