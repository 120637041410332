import React, {useState, useEffect, useCallback} from 'react';
import {
  StyleSheet,
  ScrollView,
  View,
  SafeAreaView,
  Platform,
} from 'react-native';
import { NavigationProp, ParamListBase, useTheme, RouteProp} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CommonHeader from "common/src/components/CommonHeader";
import CommonSettingsBtn from 'common/src/components/CommonSettingsBtn';
import CommonActionModal from 'common/src/components/CommonActionModal';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonHighlightedMenuItem from 'common/src/components/CommonHighlightedMenuItem';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {useTranslation} from 'react-i18next';
import {SettingsTypes, defaultMMLang} from 'common/src/services/settings_service';
import { useDispatch, useSelector } from 'react-redux';
import { updateLanguageAction, logOutAction } from 'common/src/store/actions/authenticationActions';
import { getRescueAction } from 'common/src/store/treatments/rescueActions';
import { getHeadacheTypesAction } from 'common/src/store/headacheTypes/headacheTypesActions';
import { revokeFbTokeAction } from 'common/src/store/FB-token/fb_tokenAction';
import { switchThemeAction } from 'common/src/store/theme/themeActions';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { version } from '../../package.json';
import { RootState } from 'common/src/store/reducers';
import { hasSite } from 'common/src/services/utils';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { RootStackParams } from 'common/src/types';
import CommonSwitchBtn from 'common/src/components/CommonSwitchBtn';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';

type OwnProps = {
  navigation: NavigationProp<ParamListBase>;
  route: RouteProp<RootStackParams>;
}

const Menu = ({navigation, route}: OwnProps) => {
  const initialParams = route.params;
  const colors = useTheme().colors;
  const styles: any = generateStyleSheet(colors);
  const {i18n, t} = useTranslation();
  const lang = i18n.language;
  const dispatch = useDispatch();
  const Me = useSelector((state: any) => state.getMe.response?.result);
  const availableForms = useSelector((state: any) => state.getForms?.availableForms);
  const rescueMedications = useSelector((state: RootState) => state.rescueReducer.rescueMedications);
  const headacheTypes = useSelector((state: RootState) => state.headacheTypesReducer.headacheTypes);
  const ProfileResponse = useSelector((state:any) => state.getMe.response);
  const [displayLanguageModal, setDisplayLanguageModal] = useState<boolean>(false);
  const deviceType:string | undefined = initialParams?.params?.deviceType;
  const FBToken:string | undefined = initialParams?.params?.FBToken;
  const [themeValue, setThemeValue] = useState<string>('light');
  const [logoutDisabled, setLogoutDisabled] = useState<boolean>(false);

  const getRescueMedication = useCallback(() => {
    if(rescueMedications.length === 0){

        dispatch(getRescueAction({
            patientID: ProfileResponse?.result?.patients?.id,
            callback: () => {
            },
            errorCallback: () => {
            }
        }))
    }
}, [])

const getHeadacheTypes = useCallback(() => {
  if(headacheTypes.length === 0){

      dispatch(getHeadacheTypesAction({
          patientID: ProfileResponse?.result?.patients?.id,
          callback: () => {
          },
          errorCallback: () => {
          }
      }))
  }
}, [])

  useEffect(() => {
      getRescueMedication()
      getHeadacheTypes()
  }, [getRescueMedication, getHeadacheTypes])

    useEffect(() => {
        AsyncStorage.getItem('theme')
        .then((value:any) => {
            if (value !== null && value !== "") {
            setThemeValue(value)
            }
        });  
    }, [])
    
    const setUpdateClick = (itemName:string) => {
        if (itemName === SettingsTypes.Language) {
          setDisplayLanguageModal(true)
        } else if(itemName === 'AddEmergencyMedication') {
          navigation.navigate('AddEmergencyMedication', {medicationDetails: {}})
        }
        else {
          navigation.navigate(itemName)
        }
    }

    const updateLanguage = (values) => {
        const selectedLanguage = values.find(language => language.selected);
        
        if (!selectedLanguage) {
          return ;
        }
    
        i18n.changeLanguage(selectedLanguage.lngCode);
        dispatch(updateLanguageAction({ language: selectedLanguage.lngCode }));
        setDisplayLanguageModal(false)
    }

    const changeModalVisibility = () => {
        setDisplayLanguageModal(!displayLanguageModal)
    }

    const logout = () => {
    
        let dataToSend = {
          data:{
            device_type: deviceType,
            device_token: FBToken
          }
        }
        
        if(Platform.OS !== 'web') {
          dispatch(revokeFbTokeAction({
            data: dataToSend.data,
            callback: () => {
              setLogoutDisabled(true);
              dispatch(logOutAction());
            },
            errorCallback: () => {
              setLogoutDisabled(false);
            }
          }))
        } else {
          setLogoutDisabled(true);
          dispatch(logOutAction({
            errorCallback: () => {
              setLogoutDisabled(false);
            }
          }));
        }
    }

    const switchTheme = (value:string) => {
        AsyncStorage.setItem('theme', value)
        dispatch(switchThemeAction({
            themeMode: value
        }))
        setThemeValue(value)
    }

    return(
        <SafeAreaView style={styles.wrapper}>
            {
                displayLanguageModal &&
                    <CommonActionModal 
                        testID={"LanguageModalID"} 
                        title={t('translation:settings.select_language_title')}
                        modalType={'select-radio'} 
                        buttons={[{title: t('translation:general.header.btn_save'), 
                        type: 'primary', click: updateLanguage, action: 'save'}]} 
                        onClose={changeModalVisibility} 
                        data={defaultMMLang(lang)}
                    />
            }
            <CommonHeader
                title="Menu"
                disableFormsBanner={true}
                mainPage={true}
                addActionBtnChildren={
					<CommonSwitchBtn 
						isOn={themeValue === 'light'}
						size={PR(20) > 17 ? "medium" : "small"}
						onToggle={() => switchTheme(themeValue === 'light' ? 'dark' : 'light')}
						testID="themeSwitch"
						icon={<Icon name={themeValue === 'light' ? 'sun' : 'moon'} size={PR(15)} />}
						onColor={GlobalStyles[APP_SHORTCUT_NAME].color}
						offColor={GlobalStyles[APP_SHORTCUT_NAME].color}
					/>
                  }
            />
            <ScrollView 
                testID="menu_scrollView"
            >
             <CommonSettingsBtn 
                listNames={
                  [
                    {name:t('translation:settings.btn.profile'), value: SettingsTypes.Profile},
                    {name:t('translation:settings.titles.physicians'), value: SettingsTypes.My_neurologists},
                    {name:t('translation:settings.btn.language'), value: SettingsTypes.Language},
                    {name:t('translation:headache_event.manageEmergencyMedication.header'), value: rescueMedications.length > 0 ? SettingsTypes.AddRescueMedication : SettingsTypes.AddEmergencyMedication},
                    {name:t('translation:settings.titles.headaches'), value: headacheTypes.length > 0 ? SettingsTypes.HeadacheTypesList : SettingsTypes.AddHeadacheTypes},
                  ]
                } 
                clicked={setUpdateClick}
            />   
            {lang === "nl" && 
              <CommonSettingsBtn 
                listNames={
                  [
                    {name:t('translation:headache_event.patient.organisation.title'), value: SettingsTypes.PatientOrganisation},
                  ]
                } 
                clicked={setUpdateClick}
              />   
            }
            { Me?.hasStudy && 
                <CommonHighlightedMenuItem 
                  listNames={
                    [
                      {name:t('translation:general.page_title.studies'), value: SettingsTypes.Studies}
                    ]
                  } 
                  isHighlighted={Me?.hasStudy && hasSite(availableForms)}
                  highlightedColor={GlobalStyles.global.redError}
                  highlightedIcon='!'
                  clicked={setUpdateClick}
                />   
            }
            <CommonSettingsBtn 
              listNames={
                [
                  {name:t('translation:general.page_title.settings'), value: SettingsTypes.Settings},
                ]
              } 
              clicked={setUpdateClick}
            />   
            </ScrollView>
            <View>
              <CommonBtn type="tertiary" txtColor="#F43535" click={logout} disable={logoutDisabled} title={t('translation:settings.logout')} testID="logout_btn"/>
            </View>
            <View style={styles.infoSection}>
                <Text style={styles.versionText}>
                    {t('translation:settings.about.app_version')} - {version}
                </Text>
          </View>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:any) => {
    return StyleSheet.create({
      wrapper: {
        backgroundColor: colors.background,
        flex: 1,
      },
      infoSection: {
        marginTop: PR(30),
        display: "flex",
        flexDirection: "column",
        alignContent: "center"
      },
      versionText: {
        color: "#bbb",
        fontSize: PR(13),
        textAlign: "center",
        marginBottom: PR(40)
      },
    })
};

export default Menu;