import React from 'react';
import {View, StyleSheet} from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import CommonImage from './CommonImage';

interface CommonTimelineGraphsTypes {
  day: DayObjectTypes,
  max: number,
  seizureCount: number,
  timelineLabels?: string,
  impactLabels?: string,
  currentDisplayType?: string
}

interface DayObjectTypes {
  date: string,
  seizures?: Array<SeizuresArrayObjectTypes>,
  seizureTypes: Array<any>,
  rescueMedicationCount?: number
}

interface SeizuresArrayObjectTypes {
  color: string,
  count: number,
  date: string,
  name: string
}

const CommonTimelineGraphs = ({day, max, seizureCount,timelineLabels, impactLabels, currentDisplayType}:CommonTimelineGraphsTypes) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);

  const displaySeizuresPerPeriod = (seizure, size, count, sIndex) => {
    const extraStyles = {
        height: PR(125) / max * seizure.count,
        backgroundColor: seizure.color,
        borderTopRightRadius: sIndex === 0 && max === count ? PR(5) : 0, 
        borderTopLeftRadius: sIndex === 0 && max === count ? PR(5) : 0, 
        borderBottomLeftRadius: sIndex === size - 1 ? PR(5) : 0, 
        borderBottomRightRadius: sIndex === size - 1 ? PR(5) : 0
    }
    return (
        <View key={sIndex} style={[styles.timeLineBarFilled, extraStyles]}>
            { extraStyles.height > PR(15) &&
                <Text style={styles.timelineEventsCount}>{seizure.count}</Text>
            }
        </View>
    )
}
  // display the total seizure of the day or month
  const displayTotalSeizures = () => {
    return (
        <>
            {day.seizureTypes?.length > 0 &&
                <Text style={[styles.timelineLabels, {color: colors.primary}]}>{seizureCount}</Text> 
            }
        </>
    )
  }

  const displayRescueMedication = () => {
    if (typeof(day.rescueMedicationCount) !== 'number' || day.rescueMedicationCount <= 0)
      return
    if (currentDisplayType === 'year') {
      return  (
          <Text style={[styles.rescueBadge, {color: GlobalStyles[APP_SHORTCUT_NAME].color}]}>{day.rescueMedicationCount}</Text> 
      )
    }
    else
      return <CommonImage name={'pillIcon'} height={PR(18)} width={PR(18)}/>
  }

  return (
    <>
      <View style={{height: PR(125)}}>
        { day.seizureTypes?.length === 0 ? // if there is not a seizure at all, display empty bars
          <View style={styles.timeLineBarEmpty}></View>
          :
          max > seizureCount && // if there is a seizure and the total seizure of the day is smaller than the maximum seizure total display a grey bar behind seizures
          <View style={[styles.timeLineBarEmpty, {height: PR(125 - (125 / max * seizureCount)), borderBottomLeftRadius: 0, borderBottomRightRadius: 0}]}></View>
        }
        { // display seizures if exist
          day.seizureTypes?.length > 0 && day.seizureTypes?.map((seizure, sIndex) => {
            return (displaySeizuresPerPeriod(seizure, day.seizureTypes?.length, seizureCount, sIndex))
          })
        }
      </View>
      {timelineLabels &&
        <Text style={styles.timelineLabels}>{timelineLabels}</Text>
      }
      {impactLabels &&
        <Text style={styles.impactLabels}>{impactLabels}</Text>
      }
      {displayTotalSeizures()}
      <View style={{alignItems:"center"}}>
        {displayRescueMedication()}
      </View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    timelineBar: {
      flex: 1,
      flexDirection: 'column'
    },
    timeLineBarEmpty: {
        height: PR(125), 
        width: '80%', 
        maxWidth: PR(50),
        backgroundColor: colors.timeLineBarEmpty, 
        alignSelf: 'center', 
        borderRadius: PR(5)
    },
    timeLineBarFilled: {
        height: PR(125),
        width: '80%',
        maxWidth: PR(50),
        alignSelf: 'center',
        alignItems: 'center', 
        justifyContent: 'center',
    },
    timelineLabels: {
      alignSelf: 'center',
      marginTop: PR(10),
      color: colors.primary
    },
    timelineEventsCount: {
      zIndex: 99,
      color: 'white'
    },
    impactLabels: {
      alignSelf: 'center',
      marginTop: PR(10),
      fontSize: PR(10),
      fontFamily: GlobalStyles.global.fontFamily.Regular,
      color: colors.primary
    },
    rescueBadge: {
      alignSelf: 'center',
    },
  })
}

export default CommonTimelineGraphs;