export enum MFAActions {
    CHECK_LOGIN_CREDENTIALS = 'CHECK_LOGIN_CREDENTIALS',
    VERIFY_PASSWORD = "VERIFY_PASSWORD",
    ENABLE_PUSH_NOTIFICATION_MFA = "ENABLE_PUSH_NOTIFICATION_MFA",
    DISABLE_PUSH_NOTIFICATION_MFA = "DISABLE_PUSH_NOTIFICATION_MFA",
    VERIFY_OTP_CODE = "VERIFY_OTP_CODE",
    GET_OTP_CODE = "GET_OTP_CODE",
    GET_MFA_STATUS = "GET_MFA_STATUS",
    GET_OTP_FOR_REGISTRATION = "GET_OTP_FOR_REGISTRATION",
    VERIFY_PASSWORD_FOR_EMAIL = "VERIFY_PASSWORD_FOR_EMAIL",
    ENABLE_EMAIL_MFA = "ENABLE_EMAIL_MFA",
    DISABLE_EMAIL_MFA = "DISABLE_EMAIL_MFA",
}

export interface CheckPassInterface {
    email: string,
    password: string,
    language: string,
    callback: (res:any) => void;
}

export interface VerifyPasswordInterface {
    userId: string,
    password: string,
    deviceFBToken: string,
    callback: () => void;
    errorCallback: () => void;
}

export interface EnablePushNotificationMFAInterface {
    userId: string,
    deviceFBToken: string,
    callback: () => void;
    errorCallback: () => void;
}

export interface DisablePushNotificationMFAInterface {
    userId: string,
    password: string,
    callback: () => void;
    errorCallback: () => void;
}

export interface VerifyOTPCodeInterface {
    userId: string,
    otpCode: string,
    language: string,
    callback: () => void;
    errorCallback: (res?: any) => void;
}

export interface GetOTPCodeInterface {
    userId: string,
    language: string,
    name: string,
    whereToSend: string,
    deviceFBToken?: string,
    callback: () => void;
}

export interface GetPushNotificationStatusInterface {
    userId: string,
    language: string,
    callback?: (res:{message:boolean}) => void; 
}

export interface GetMfaStatusInterface {
    userId: string,
    language: string,
    callback?: (res:
        {message:{
            email_mfa: boolean,
            push_notif_mfa: boolean
        }}
    ) => void; 
}

export interface GetOTPForRegistrationInterface {
    userId: string,
    callback?: () => void;
    errorCallback?: () => void;
}

export interface VerifyPasswordForEmailInterface {
    userId: string,
    password: string,
    email: string,
    callback: () => void;
    errorCallback: () => void;
}

export interface EnableEmailMFAInterface {
    userId: string,
    email: string,
    callback: () => void;
    errorCallback: () => void;
}

export interface DisableEmailMFAInterface {
    userId: string,
    callback: () => void;
    errorCallback: () => void;
}

export const checkCredentialsAction = (payload: CheckPassInterface) => {
    return {
        type: MFAActions.CHECK_LOGIN_CREDENTIALS,
        ...payload
    };
};

export const verifyPasswordAction = (payload: VerifyPasswordInterface) => {
    return {
        type: MFAActions.VERIFY_PASSWORD,
        ...payload
    };
};

export const enablePushNotificationMFAAction = (payload: EnablePushNotificationMFAInterface) => {
    return {
        type: MFAActions.ENABLE_PUSH_NOTIFICATION_MFA,
        ...payload
    };
};

export const disablePushNotificationMFAAction = (payload: DisablePushNotificationMFAInterface) => {
    return {
        type: MFAActions.DISABLE_PUSH_NOTIFICATION_MFA,
        ...payload
    };
};

export const verifyOTPCodeAction = (payload: VerifyOTPCodeInterface) => {
    return {
        type: MFAActions.VERIFY_OTP_CODE,
        ...payload
    };
};

export const getOTPCodeAction = (payload: GetOTPCodeInterface) => {
    return {
        type: MFAActions.GET_OTP_CODE,
        ...payload
    }
}

export const getMfaStatusAction = (payload: GetMfaStatusInterface) => {
    return {
        type: MFAActions.GET_MFA_STATUS,
        ...payload
    }
}

export const getOTPForRegistrationAction = (payload: GetOTPForRegistrationInterface) => {
    return {
        type: MFAActions.GET_OTP_FOR_REGISTRATION,
        ...payload
    }
}

export const verifyPasswordForEmailAction = (payload: VerifyPasswordForEmailInterface) => {
    return {
        type: MFAActions.VERIFY_PASSWORD_FOR_EMAIL,
        ...payload
    };
};

export const enableEmailMFAAction = (payload: EnableEmailMFAInterface) => {
    return {
        type: MFAActions.ENABLE_EMAIL_MFA,
        ...payload
    };
};

export const disableEmailMFAAction = (payload: DisableEmailMFAInterface) => {
    return {
        type: MFAActions.DISABLE_EMAIL_MFA,
        ...payload
    };
};