import * as types from '../actions';
import { isObject } from "lodash";

export default function(state:any = {
    notifications: [],
  }, action:any) {
  const response = action.response;
  if (!response)
    return state
  if (action.type === types.GET_NOTIFICATIONS_SUCCESS) {
    if (state.notifications.length === response.result.length)
      return state
    else
      return { 
          ...state, 
          notifications: response.result
      };
  }
  else if (action.type === types.ADD_NOTIFICATIONS_SUCCESS) {
    let addedNotifications = [...state.notifications]
    addedNotifications.push(response.result)
    return { 
      ...state, 
      notifications: addedNotifications
  };
  }
  else if (action.type === types.DELETE_NOTIFICATIONS_SUCCESS) {
    let updatedNotifications = [...state.notifications]
    const index = updatedNotifications.findIndex(notif => notif.id === response.result.id)
    if (index === -1)
      return state

    updatedNotifications.splice(index, 1)
    return {
      ...state,
      notifications: updatedNotifications
    }
  }
  else if(action.type === types.UPDATE_MEDICATION_INTAKE_SUCCESS) {
    return {...state, notifications: []}
  } 
  else if (action.type === types.UPDATE_MOOD_AND_SLEEP_SUCCESS) {
    return {...state, notifications: []}
  }
  // else if (action.type === types.ADD_EVENT_SUCCESS) {
  //   // const result = response.result;
  //   // if (result.type !== 'form')
  //   //   return state
  //   // let notificationCpy = JSON.parse(JSON.stringify(state.notifications))
  //   // let notificationIndex = notificationCpy.findIndex(notif =>
  //   //     (notif.type === 'form_to_fill' || notif.type === 'form_to_fill_reminder') &&
  //   //     ((isObject(notif.data.form) ? notif.data.form.id : notif.data.form ) === result.form)
  //   //   )
  //   // if (notificationIndex === -1)
  //   //   return state

  //   // notificationCpy.splice(notificationIndex, 1)
  //   // return {
  //   //   ...state,
  //   //   notifications: notificationCpy
  //   // }
  // }
  else
    return state
};