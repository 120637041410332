import React, {useState} from "react";
import {View, StyleSheet, SafeAreaView, Platform, TouchableOpacity, ScrollView} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from "common/src/components/CommonHeader";
import CommonFooter from "common/src/components/CommonFooter";
import CommonBtn from "common/src/components/CommonBtn";
import HeadacheTypesListBtn from "./HeadacheTypesListBtn";
import GlobalStyles from "common/src/styles/GlobalStyles";
import { getListCMSData } from 'common/src/components/CommonDiary/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { RootState } from 'common/src/store/reducers';
import { headacheTypeObject } from 'common/src/store/headacheTypes/headacheTypesReducer';

const HeadacheTypesList = ({navigation}) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const { t } = useTranslation();
    const headacheTypes = useSelector((state: RootState) => state.headacheTypesReducer.headacheTypes);
    const [scrollState, setScrollState] = useState<number>(0);

    const setUpdateClick = (res:headacheTypeObject) => {
        navigation.navigate('AddHeadacheTypes', {initialType:res})
    }

    const getRegionTranslation = (array:Array<string>) => {
        return array.map(item => {
            return getListCMSData(t, item, 'list.headache_zones')
        })
    }

    return(
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="headacheTypesListTitle"
                title={t('translation:headache_event.type.listing.header')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.migraine.color}
                leftClick={() => {navigation.goBack()}}
                scroll={scrollState}
            />
            <ScrollView
                testID="headacheTypesList_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
                        setScrollState(event.nativeEvent.contentOffset.y);
                    else if(scrollState > 0 && event.nativeEvent.contentOffset.y === 0)
                        setScrollState(0);
                }}
                style={{
                    width: '90%',
                    alignSelf: 'center'
                }}
            >
                <HeadacheTypesListBtn 
                    list={
                        headacheTypes.map(item => {
                            return(
                                {
                                    name: item.name,
                                    nature: getListCMSData(t, item.nature, 'list.headache_natures'),
                                    regionLeft: item.regions.left.length > 0 ? t('translation:headache_event.type.left_side') + " : " + getRegionTranslation(item.regions.left) : undefined,
                                    regionRight: item.regions.right.length > 0 ? t('translation:headache_event.type.right_side') + " : " + getRegionTranslation(item.regions.right) : undefined,
                                    value: item
                                }
                            )
                        }
                    )} 
                    clicked={setUpdateClick}
                />
            </ScrollView>
            <CommonFooter>
                <CommonBtn 
                    testID={"AddNewHeadacheTypeBtnID"} 
                    type={'primary'} 
                    title={t('translation:headache_event.type.add')} 
                    click={() => {navigation.navigate('AddHeadacheTypes')}}
                />
            </CommonFooter>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
      container: {
        backgroundColor: colors.background,
        flex: 1,
      },
    })
}

export default HeadacheTypesList;