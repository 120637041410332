import { isObject } from "lodash";
import { getRequest } from '../callService';
import moment from "moment";
import {API_URL, APP_SHORTCUT_NAME} from 'common/src/env.json';

const DEFAULT_TEXT = "dashboard.notifications.ok";
const ANSWER_TEXT = "dashboard.notifications.answer";
const ADD_TEXT = "dashboard.add";
const GO_TEXT = "dashboard.go";
const UPDATE_TEXT = "dashboard.update";
const TAKE_TEXT = "dashboard.notifications.take";
const READ_TEXT = "dashboard.notifications.read";
const ASK_DATA_SHARING = "ask_data_sharing";
const VIEW_TEXT = "dashboard.notifications.view";
const FEATURED_EDUCATION = 'featured_education';
const GO_PROGRAM_OVERVIEW = 'goto_programOverview';
const NEW_PROGRAM_ACTION = 'new_program';

export const NOTIFICATIONS_TYPES = [
    {
        type: "notif_medication",
        btnTitle: TAKE_TEXT,
        icon: 'medicalIcon',
    },
    {
        type: "sleep",
        btnTitle: ANSWER_TEXT,
        icon: 'moonIcon',
    },
    {
        type: "mood",
        btnTitle: ANSWER_TEXT,
        icon: 'sunIcon',
    },
    {
        type: "goto_journal",
        btnTitle: GO_TEXT,
        icon: 'logsIcon',
    },
    {
        type: "goto_treatment",
        btnTitle: GO_TEXT,
        icon: 'antibioticIcon',
    },
    {
        type: "goto_settings",
        btnTitle: GO_TEXT,
        icon: 'settingsIcon',
    },
    {
        type: "update_profile",
        btnTitle: UPDATE_TEXT,
        icon: 'settingsIcon',
    },
    {
        type: "add_seizure",
        btnTitle: ADD_TEXT,
        icon: 'seizureIcon',
    },
    {
        type: "add_side_effect",
        btnTitle: ADD_TEXT,
        icon: 'sideEffectIcon',
    },
    {
        type: "add_treatment",
        btnTitle: ADD_TEXT,
        icon: 'antibioticIcon',
    },
    {
        type: "appointment",
        btnTitle: DEFAULT_TEXT,
        icon: 'calendarIcon',
    },
    {
        type: "add_next_appointment",
        btnTitle: ADD_TEXT,
        icon: 'calendarIcon',
    },
    {
        type: "add_last_appointment",
        btnTitle: ADD_TEXT,
        icon: 'calendarIcon',
    },
    {
        type: "add_reminder",
        btnTitle: ADD_TEXT,
        icon: 'medicalIcon',
    },
    {
        type: "add_other_event",
        btnTitle: ADD_TEXT,
        icon: 'infoIcon',
    },
    {
        type: "add_physician",
        btnTitle: ADD_TEXT,
        icon: 'chatIcon',
    },
    {
        type: "form_to_fill",
        btnTitle: ANSWER_TEXT,
        icon: 'logsIcon',
    },
    {
        type: "form_to_fill_reminder",
        btnTitle: ANSWER_TEXT,
        icon: 'logsIcon',
    },
    {
        type: 'link',
        btnTitle: READ_TEXT,
        icon: 'infoIcon'
    },
    {
        type: 'nightwatch_report',
        btnTitle: GO_TEXT,
        icon: 'logsIcon'
    },
    {
        type: 'nightwatch_seizure',
        btnTitle: GO_TEXT,
        icon: 'seizureIcon'
    },
    {
        type: 'nightwatch_alarm',
        btnTitle: DEFAULT_TEXT,
        icon: 'infoIcon'
    },
    {
        type: 'nightwatch_unlink',
        btnTitle: DEFAULT_TEXT,
        icon: 'infoIcon'
    },
    {
        type: "form_to_fill_reminder",
        btnTitle: ANSWER_TEXT,
        icon: 'infoIcon',
    },
    {
        type: "action_reminder",
        btnTitle: VIEW_TEXT,
        icon: 'actionIcon',
    },
    {
        type: "new_program",
        btnTitle: GO_TEXT,
        icon: 'programsIconFilledBlack',
    },
    {
        type: "default",
        btnTitle: DEFAULT_TEXT,
        icon: 'infoIcon',
    }
]

const GO_TYPES = [
    "goto_journal",
    "goto_settings",
    "goto_treatment",
    "update_setting",
    ASK_DATA_SHARING,
    "nightwatch_seizure",
    "nightwatch_report",
    "action_reminder"
]

const ADD_TYPES = [
    "add_seizure",
    "add_side_effect",
    "add_treatment",
    "add_last_appointment",
    "add_next_appointment",
    "add_reminder",
    "add_other_event",
    "add_physician",
    "matched_seizure"
]

const ANSWER_TYPES = [
    "mood",
    "sleep",
    "form_to_fill",
    "form_to_fill_reminder"
]

export const checkNotificationType = async (notification, navigation, patient) => {

    const notifType = {
        exists: false,
        action: () => {}
    }

    if (notification.type === 'form_to_fill' || notification.type === 'form_to_fill_reminder') {
        notifType.exists = true;
        const formId = isObject(notification.data.form) ? notification.data.form.id : notification.data.form;
        const questionnaire = {
            formId: formId,
            expirationDate: notification.data.expiration,
            site: notification.data.site,
            sentDate: notification.data.send_date,
            targetDate: notification.data.targetDate,
            dailyIndex: notification.data.dailyIndex
        }
        notifType.action = () => {navigation.navigate('StudyForm', {formId , questionnaire })};
        return notifType;
    }
    
    else if (GO_TYPES.indexOf(notification.type) > -1 || ADD_TYPES.indexOf(notification.type) > -1) {
        notifType.exists = true;
        switch (notification.type){
            case 'goto_journal':
                notifType.action = () => {navigation.navigate('Main', {screen: 'Diary'})};
                break;
            case 'goto_treatment':
                notifType.action = () => {navigation.navigate('Main', {screen: 'Treatments'})};
                break;
            case 'goto_settings':
                notifType.action = () => {navigation.navigate('Settings')};
                break;
            case 'update_profile':
                notifType.action = () => {navigation.navigate('Profile')};
                break;
            case 'matched_seizure':
            case 'add_seizure':
                notifType.action = () => {navigation.navigate('AddEvent', {itemType: 'Seizure'})};
                break;
            case 'add_side_effect':
                notifType.action = () => {navigation.navigate('AddEvent', {itemType: 'Side Effect'})};
                break;
            case 'add_treatment':
                notifType.action = () => {navigation.navigate('Main', {screen: 'Treatments'})};
                break;
            case 'add_next_appointment':
                notifType.action = () => {navigation.navigate('AddEvent', {itemType: 'Appointment'})};
                break;
            case 'add_last_appointment':
                notifType.action = () => {navigation.navigate('AddEvent', {itemType: 'Appointment'})};
                break;
            case 'add_reminder':
                notifType.action = () => {navigation.navigate('AddEvent', {itemType: 'Reminder'})};
                break;
            case 'add_other_event':
                notifType.action = () => {navigation.navigate('AddEvent', {itemType: 'Note / Event'})};
                break;
            case 'add_physician':
                notifType.action = () => {navigation.navigate('My_neurologists')};
                break;
            case 'nightwatch_seizure':
                const NWEventSeizure  = await getRequest(`${API_URL}/patients/${patient.id}/events/${notification.event_id}`);
                if (!NWEventSeizure.success)
                    break;
                notifType.action = () => {navigation.navigate('AddEvent', {itemType: 'Seizure', initialData: NWEventSeizure.result})};
                break;
            case 'nightwatch_report':
                const NWEventReport  = await getRequest(`${API_URL}/patients/${patient.id}/events/${notification.event_id}`);
                if (!NWEventReport.success)
                    break;
                notifType.action = () => {navigation.navigate('NightwatchEvent', {initialData: NWEventReport.result})};
                break;
            case ASK_DATA_SHARING:
                notifType.action = () => { return APP_SHORTCUT_NAME === "helpi" ? navigation.navigate('DataSharingSignedIn', { nextAction: 'Home' }) : navigation.navigate('DataSharingRequest', { nextAction: 'Home' }) };
                break;
            case 'action_reminder':
                notifType.action = () => {return navigation.navigate('Diary', { day: moment().format('DD-MM-YYYY') })};
                break;   
            default:
              break;
        }
        return notifType
    }
    else
        return notifType;
}