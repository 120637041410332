import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { RescueActions } from './rescueActions';

interface RescueReducerInterface {
    rescueMedications: Array<RescueObject>
}

export interface RescueObject {
    createdAt: string
    deleted: boolean
    dosage: number
    id: string
    name: string
    patient: string
    unit: string
    updatedAt: string

}

const initialState:RescueReducerInterface = {
    rescueMedications: []
};

export default function (state = initialState, action: AnyAction): RescueReducerInterface {
    if (action.type === RescueActions.ADD_RESCUE_MEDICATION_SUCCESS){
        return {
            ...state,
            rescueMedications: [...state.rescueMedications, action.response.result]
        }
    } 
    else if(action.type === RescueActions.GET_RESCUE_MEDICATION_SUCCESS){
        return {
            ...state,
            rescueMedications: action.response.result
        }
    } 
    else if(action.type === RescueActions.DELETE_RESCUE_MEDICATION_SUCCESS){
        let filterrescueMedications = state.rescueMedications.filter(item => item.id !== action.response.result.id)
        return {
            ...state,
            rescueMedications: filterrescueMedications
        }
    }
    else if(action.type === RescueActions.UPDATE_RESCUE_MEDICATION_SUCCESS){
        let findRescueMed = state.rescueMedications.findIndex(item => item.id === action.response.result.id)
        let rescueMedCopy = [...state.rescueMedications]
        if(findRescueMed > -1){
            rescueMedCopy[findRescueMed] = JSON.parse(JSON.stringify(action.response.result))
        }
        return {
            ...state,
            rescueMedications: rescueMedCopy
        }
    }
    return state;
}

export const rescueMedications = (state: RootState) => state.rescueReducer.rescueMedications;