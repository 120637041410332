import React, {useState, useEffect} from 'react';
import {StyleSheet, View, TextInput} from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from '../../CommonCustomTxt';
import CommonRadioForm from '../../CommonAddEvents/CommonRadioForm';
import GlobalStyles from '../../../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import {translate} from 'common/src/services/translationList';
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';

const MonthlyQuartely = ({activateBtn, getData, period, savedMQData, treatmentType}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const [unit, setUnit] = useState<string>(savedMQData?.MQUnit || '');
  const [dose, setDose] = useState<string>(savedMQData?.MQDose || '');
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false)

  useEffect(() => {
    if(unit.length > 0 && parseFloat(dose) > 0){
      setShowErrorMsg(false)
      activateBtn(false)
      let obj = {MQUnit: unit, MQDose: dose, period}
      getData(obj)
    } else {
      activateBtn(true)
      setShowErrorMsg(true)
    }
  },[unit, dose])

  const updateDose = (value) => {
    setDose(value.replace(/,/g, '.'))
  }
  return (
    <>
      <View style={styles.questContainer}>
        <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_frequency.step_6.question')}</Text>
        <View style={{marginBottom:15}}></View>
        <View  style={styles.momentsContainer}>
          <View style={{width: '50%'}}>
            <Text style={styles.momentsTxt}>{t('translation:treatment.drug.specificDosageTitle')}</Text>
          </View>
          <CommonRadioForm
            value={unit.length === 0 ? t('translation:treatment.drug.unit') : treatmentType === 'drug' ? unit : translate(t, unit, 'list.other_units')}
            setValue={setUnit}
            cmsListLink={treatmentType === 'drug' ? 'list.units': 'list.other_units'}
            patientList='unit_inputs'
            removeStyle={true}
            customWidth={'30%'}
          />
          <View style={{width:'20%'}}>
            <TextInput
                onChangeText={(e) => updateDose(e.trim().replace(/[^0-9.|, ]/g, ''))}
                placeholder={t('translation:treatment.drug.dose')}
                placeholderTextColor={GlobalStyles.global.grey}
                autoCorrect={false}
                style={styles.inputField}
                value={dose}
                keyboardType="numeric"
                testID={"doseID"}
            />
          </View>
        </View>
        {showErrorMsg &&
          <View style={{alignSelf:'center'}}>
            <Text style={styles.errorTxt}>{t('translation:treatment.add_medication.errors.invalid_intake')}</Text>
          </View>
        }
      </View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    questContainer: {
      paddingTop: PR(20),
      width: '90%',
      alignItems: 'center',
      alignSelf:'center'
    },
    questTxt: {
      fontSize: PR(16),
      color: colors.primary
    },
    momentsContainer: {
      flexDirection:'row', 
      width:'90%', 
      alignItems:'center', 
      alignSelf:'center', 
      justifyContent:'space-between', 
      paddingVertical:PR(10),
      borderBottomWidth: PR(1),
      borderBottomColor: GlobalStyles.global.lightGrey
    },
    momentsTxt: {
      fontSize: PR(14),
      color: colors.primary
    },
    inputField: {
      flex: 1,
      flexGrow: 1,
      color: colors.primary,
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    errorTxt: {
      color: GlobalStyles.global.redError,
      fontSize: PR(14)
    },
  })
}

export default MonthlyQuartely;