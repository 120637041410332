import React, { useState, useEffect, useCallback } from "react";
import { StyleSheet, View, TouchableOpacity, Platform } from "react-native";
import { useTheme } from "@react-navigation/native";
import GlobalStyles from "../../styles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import CommonTimeInput from "../CommonTimeInput";
import CommonDateInput from "../CommonDateInput";
import CommonSlider from "../CommonSlider";
import Text from "common/src/components/CommonCustomTxt";
import CommonActionModal from "../CommonActionModal";
import CommonSelectForm from "./CommonSelectForm";
import CommonLabelForm from "./CommonLabelForm";
import Icon from "react-native-vector-icons/Feather";
import InputField from "../InputField";
import { widthPercentageToDP as PR } from "../../styles/PixelRatio";
import { getListCMSData } from "common/src/components/CommonDiary/utils";
import { changeEndDateToNextDay, dateTimeEvents } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { headacheTypeObject } from "../../store/headacheTypes/headacheTypesReducer";
import { getHeadacheTypesAction } from "../../store/headacheTypes/headacheTypesActions";
import { compareDatesByMinute } from "../../services/compare";
import RescueSelectedList from "../../../../migraine/src/modules/AddMedication/RescueSelectedList";
import { RescueObject } from "../../store/treatments/rescueReducer";

interface OwnProps {
	isSubmitButtonActive: boolean;
	AddHeadacheData: any;
	initialData: any;
	isFormUpdated: (data: boolean) => void;
	disabled: (data: boolean) => void;
	navigation: any;
}

export type SelectedList = {
	medication: RescueObject;
	effect: string;
};

const CommonAddHeadache = ({
	AddHeadacheData,
	isSubmitButtonActive,
	initialData,
	isFormUpdated,
	disabled,
	navigation,
}: OwnProps) => {
	const { colors } = useTheme();
	const styles: any = generateStyleSheet(colors);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const ProfileResponse = useSelector((state: any) => state.getMe.response);
	const headacheTypes = useSelector(
		(state: RootState) => state.headacheTypesReducer.headacheTypes
	);
	const [startDate, setStartDate] = useState<string>(
		(initialData?.date
			? DateTime.fromISO(initialData?.date, {
					zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			  })
			: DateTime.now()
		).toFormat("dd-MM-yyyy")
	);
	const [startTime, setStartTime] = useState<string>(
		(initialData?.date
			? DateTime.fromISO(initialData?.date, {
					zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			  })
			: DateTime.now()
		).toFormat("HH:mm")
	);
	const [endDate, setEndDate] = useState<string>(
		(initialData?.end_date
			? DateTime.fromISO(initialData?.end_date, {
					zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			  })
			: changeEndDateToNextDay(startDate, startTime)
		).toFormat("dd-MM-yyyy")
	);
	const [endTime, setEndTime] = useState<string>(
		(initialData?.end_date
			? DateTime.fromISO(initialData?.end_date, {
					zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			  })
			: DateTime.fromFormat(
					startDate + startTime,
					"dd-MM-yyyyHH:mm"
			  ).plus({ hour: 4 })
		).toFormat("HH:mm")
	);
	const [intensity, setIntensity] = useState<string | undefined>(
		initialData?.intensity
	);
	const [triggersType, setTriggersType] = useState<Array<string>>(
		initialData?.triggers || []
	);
	const [headacheSymptoms, setHeadacheSymptoms] = useState<Array<string>>(
		initialData?.symptoms || []
	);
	const [beforeHeadache, setBeforeHeadache] = useState<Array<string>>(
		initialData?.auras || []
	);
	const [lifeImpacts, setLifeImpacts] = useState<Array<string>>(
		initialData?.life_impact || []
	);
	const [isBeforeHeadacheModalOpen, setBeforeHeadacheModal] =
		useState<boolean>(false);
	const [isLifeImpactsModalOpen, setLifeImpactsModal] =
		useState<boolean>(false);
	const [isHeadacheTypeModalOpen, setHeadacheTypeModal] =
		useState<boolean>(false);
	const [startDateError, setStartDateError] = useState<string | undefined>();
	const [endDateError, setEndDateError] = useState<string | undefined>();
	const [startTimeError, setStartTimeError] = useState<string | undefined>();
	const [endTimeError, setEndTimeError] = useState<string | undefined>();
	const [rescues, setRescues] = useState<Array<SelectedList>>(
		initialData?.rescues || []
	);
	const [headacheType, setHeadacheType] = useState<headacheTypeObject | null>(
		initialData?.headache || null
	);
	const [remarks, setRemarks] = useState<string>(initialData?.remark);
	const routes = navigation.getState()?.routes;
	const prevRoute = routes[routes.length - 1];
	let tempStartDate = dateTimeEvents(startDate, startTime);
	let tempEndDate = dateTimeEvents(endDate, endTime);

	const isDisabled = useCallback(() => {
		return (
			compareDatesByMinute(
				endDate + endTime,
				startDate + startTime,
				"dd-MM-yyyyHH:mm"
			) || !intensity
		);
	}, [intensity, startDate, endDate, startTime, endTime]);

	const sendDataBack = useCallback(() => {
		let headacheTypeObj = {
			type: "headache",
			startDate: tempStartDate,
			endDate: tempEndDate,
			intensity,
			triggersType,
			headacheSymptoms,
			beforeHeadache,
			lifeImpacts,
			rescues,
			headache: headacheType,
			headache_id: headacheType?.id,
			remarks,
			name: headacheType?.name,
			name_obj: headacheType,
			prevRoute: prevRoute?.name,
			msg: t("translation:events.headache.success"),
		};

		let obj = {
			type: "headache",
			startDate: tempStartDate,
			endDate: tempEndDate,
			intensity,
			triggersType,
			headacheSymptoms,
			beforeHeadache,
			lifeImpacts,
			rescues,
			headache: headacheType,
			remarks,
			prevRoute: prevRoute?.name,
			msg: t("translation:events.headache.success"),
		};

		AddHeadacheData(headacheType ? headacheTypeObj : obj);
	}, [
		isSubmitButtonActive,
		intensity,
		startDate,
		startTime,
		endDate,
		endTime,
		triggersType,
		headacheSymptoms,
		beforeHeadache,
		lifeImpacts,
		remarks,
		rescues,
		headacheType,
	]);

	const getHeadacheTypes = useCallback(() => {
		if (headacheTypes.length === 0)
			dispatch(
				getHeadacheTypesAction({
					patientID: ProfileResponse?.result?.patients?.id,
					callback: () => {},
				})
			);
	}, []);

	useEffect(() => {
		getHeadacheTypes();
	}, [getHeadacheTypes]);

	useEffect(() => {
		if (isSubmitButtonActive) sendDataBack();
	}, [sendDataBack]);

	useEffect(() => {
		disabled(isDisabled());
	}, [isDisabled]);

	const selectedValueInModal = (value: string, array: Array<string>) => {
		if (value !== undefined) {
			let findSelected = array.find(
				(item) => item === JSON.parse(value.replace(/[\[\]']+/g, ""))
			);
			if (findSelected !== undefined) return true;
		}
	};

	const setValueFromModal = (
		setFunc: (res: Array<string>) => void,
		array: Array<any>
	) => {
		let tempArray: Array<string> = [];
		const selected = array.filter((item) => item.selected === true);
		if (selected.length > 0) {
			selected.forEach((item) => {
				tempArray.push(JSON.parse(item.value.replace(/[\[\]']+/g, "")));
			});
		}
		setFunc(tempArray);
		setBeforeHeadacheModal(false);
		setLifeImpactsModal(false);
	};

	const setValueFromHeadacheTypeModal = (
		setFunc: (res: headacheTypeObject) => void,
		array: Array<any>
	) => {
		const selected = array.filter((item) => item.selected === true);
		if (selected.length > 0) {
			selected.forEach((item) => {
				setFunc(item.value);
			});
		}
		if (selected && selected.length > 0) {
			const headacheTypeSelected = selected[0].value;
			setBeforeHeadache(headacheTypeSelected.auras);
			setHeadacheSymptoms(headacheTypeSelected.symptoms);
		}
		setHeadacheTypeModal(false);
	};

	const headacheTypeBtns = [
		{
			title: t("translation:headache_event.type.add"),
			type: "tertiary",
			click: () => {
				navigation.navigate("AddHeadacheTypes");
				setHeadacheTypeModal(false);
			},
		},
		{
			title: t("translation:general.header.btn_validate"),
			type: "primary",
			click: (res: Array<string>) => {
				setValueFromHeadacheTypeModal(setHeadacheType, res);
				isFormUpdated(true);
			},
			action: "save",
		},
	];

	return (
		<>
			{isHeadacheTypeModalOpen && (
				<CommonActionModal
					testID={"beforeHeadacheModalID"}
					title={t("translation:modal.select.title_multiple")}
					modalType={"select-radio"}
					buttons={headacheTypeBtns}
					onClose={() => setHeadacheTypeModal(false)}
					data={headacheTypes.map((item) => {
						return {
							label: item.name,
							value: item,
							selected: item.name === headacheType?.name,
						};
					})}
				/>
			)}
			{isBeforeHeadacheModalOpen && (
				<CommonActionModal
					testID={"beforeHeadacheModalID"}
					title={t("translation:modal.select.title_multiple")}
					modalType={"select-check"}
					buttons={[
						{
							title: t("translation:general.header.btn_validate"),
							type: "primary",
							click: (res: Array<string>) => {
								setValueFromModal(setBeforeHeadache, res);
								isFormUpdated(true);
							},
							action: "save",
						},
					]}
					onClose={() => setBeforeHeadacheModal(false)}
					// @ts-ignore
					data={t<string | TemplateStringsArray>(
						"translation:list.headache_auras",
						{ returnObjects: true }
					).map((name: string, i: number) => {
						return {
							label: t(
								"translation:list.headache_auras." +
									i +
									"." +
									Object.keys(name)
							),
							value: JSON.stringify(Object.keys(name)),
							selected: selectedValueInModal(
								JSON.stringify(Object.keys(name)),
								beforeHeadache
							),
							pos: i,
						};
					})}
					info
					infoData={"list.headache_auras_details"}
				/>
			)}
			{isLifeImpactsModalOpen && (
				<CommonActionModal
					testID={"lifeImpactsModalID"}
					title={t("translation:modal.select.title_multiple")}
					modalType={"select-check"}
					buttons={[
						{
							title: t("translation:general.header.btn_validate"),
							type: "primary",
							click: (res: Array<string>) => {
								setValueFromModal(setLifeImpacts, res);
								isFormUpdated(true);
							},
							action: "save",
						},
					]}
					onClose={() => setLifeImpactsModal(false)}
					// @ts-ignore
					data={t<string | TemplateStringsArray>(
						"translation:list.life_impacts",
						{ returnObjects: true }
					).map((name: string, i: number) => {
						return {
							label: t(
								"translation:list.life_impacts." +
									i +
									"." +
									Object.keys(name)
							),
							value: JSON.stringify(Object.keys(name)),
							selected: selectedValueInModal(
								JSON.stringify(Object.keys(name)),
								lifeImpacts
							),
							pos: i,
						};
					})}
					info
					infoData={"list.life_impacts_details"}
				/>
			)}
			<View style={styles.dateInput}>
				<View style={styles.dateInputLabel}>
					<Text style={styles.dateInputLabelText}>
						{t("translation:headache_event.headache.start_date")}
					</Text>
				</View>
				<CommonDateInput
					onChange={(value: string) => {
						const formattedEndDate = DateTime.fromFormat(
							value,
							"yyyy-MM-dd"
						)
							.toLocal()
							.toFormat("dd-MM-yyyy");
						setStartDate(formattedEndDate);
						isFormUpdated(true);
						if (
							compareDatesByMinute(
								endDate + endTime,
								formattedEndDate + startTime,
								"dd-MM-yyyyHH:mm"
							)
						) {
							setStartDateError(
								t("translation:errors.startDateError")
							);
						} else {
							setEndDateError(undefined);
							setStartDateError(undefined);
						}
					}}
					value={startDate}
					fieldStyle={styles.dateInputField}
					textStyle={styles.dateInputFieldText}
					maxDate={new Date()}
					webInputStyle={{
						backgroundColor: colors.inputBackground,
						border: "none",
						width: "100%",
						color: colors.primary,
						fontSize: PR(16),
						fontFamily: GlobalStyles.global.fontFamily.Medium,
					}}
					errorMessage={startDateError}
				/>
			</View>
			<View style={styles.dateInput}>
				<View style={styles.dateInputLabel}>
					<Text style={styles.dateInputLabelText}>
						{t("translation:headache_event.headache.start_time")}
					</Text>
				</View>
				<CommonTimeInput
					onChange={(time: string) => {
						setStartTime(time);
						isFormUpdated(true);
						if (
							compareDatesByMinute(
								endDate + endTime,
								startDate + time,
								"dd-MM-yyyyHH:mm"
							)
						) {
							setStartTimeError(
								t("translation:errors.startTimeError")
							);
						} else {
							setStartTimeError(undefined);
							setEndTimeError(undefined);
						}
					}}
					value={startTime}
					fieldStyle={styles.dateInputField}
					textStyle={styles.dateInputFieldText}
					inputDate={startDate}
					webInputStyle={{
						backgroundColor: colors.inputBackground,
						border: "none",
						width: "100%",
						color: colors.primary,
						fontSize: PR(16),
						fontFamily: GlobalStyles.global.fontFamily.Medium,
					}}
					errorMessage={startTimeError}
				/>
			</View>
			<View style={styles.dateInput}>
				<View style={styles.dateInputLabel}>
					<Text style={styles.dateInputLabelText}>
						{t("translation:headache_event.headache.end_date")}
					</Text>
					<Text style={styles.dateInputLabelTextHint}>
						{t("translation:headache_event.headache.end_date_hint")}
					</Text>
				</View>
				<CommonDateInput
					onChange={(value: string) => {
						const formattedEndDate = DateTime.fromFormat(
							value,
							"yyyy-MM-dd"
						)
							.toLocal()
							.toFormat("dd-MM-yyyy");
						setEndDate(formattedEndDate);
						isFormUpdated(true);
						if (
							compareDatesByMinute(
								formattedEndDate + endTime,
								startDate + startTime,
								"dd-MM-yyyyHH:mm"
							)
						) {
							setEndDateError(
								t("translation:errors.startDateError")
							);
						} else {
							setEndDateError(undefined);
							setStartDateError(undefined);
						}
					}}
					value={endDate}
					fieldStyle={styles.dateInputField}
					textStyle={styles.dateInputFieldText}
					maxDate={new Date()}
					webInputStyle={{
						backgroundColor: colors.inputBackground,
						border: "none",
						width: "100%",
						color: colors.primary,
						fontSize: PR(16),
						fontFamily: GlobalStyles.global.fontFamily.Medium,
					}}
					errorMessage={endDateError}
				/>
			</View>
			<View style={styles.dateInput}>
				<View style={styles.dateInputLabel}>
					<Text style={styles.dateInputLabelText}>
						{t("translation:headache_event.headache.end_time")}
					</Text>
				</View>
				<CommonTimeInput
					onChange={(time: string) => {
						setEndTime(time);
						isFormUpdated(true);
						if (
							compareDatesByMinute(
								endDate + time,
								startDate + startTime,
								"dd-MM-yyyyHH:mm"
							)
						) {
							setEndTimeError(
								t("translation:errors.startTimeError")
							);
						} else {
							setStartTimeError(undefined);
							setEndTimeError(undefined);
						}
					}}
					value={endTime}
					fieldStyle={styles.dateInputField}
					textStyle={styles.dateInputFieldText}
					inputDate={endDate}
					webInputStyle={{
						backgroundColor: colors.inputBackground,
						border: "none",
						width: "100%",
						color: colors.primary,
						fontSize: PR(16),
						fontFamily: GlobalStyles.global.fontFamily.Medium,
					}}
					errorMessage={endTimeError}
				/>
			</View>
			<View style={styles.dateInput}>
				<View style={styles.dateInputLabel}>
					<Text style={styles.dateInputLabelText}>
						{t(
							"translation:headache_event.headache.form.intensity"
						)}
					</Text>
					<Text style={styles.dateInputLabelTextHint}>
						{t(
							"translation:headache_event.headache.form.intensity_range"
						)}
					</Text>
				</View>
				<CommonSlider
					min={0}
					max={10}
					updateValue={(res: string) => {
						setIntensity(res);
						isFormUpdated(true);
					}}
					initialValue={intensity || "0"}
					disable={false}
				/>
			</View>
			<View
				style={{
					width: "90%",
					alignSelf: "center",
					marginBottom: PR(30),
				}}
			>
				<CommonLabelForm
					label={t("translation:headache_event.headache.form.type")}
					isOptional={true}
				/>
				<TouchableOpacity
					testID="headache_form_typeID"
					style={styles.summaryContainer}
					onPress={() => {
						headacheTypes.length > 0
							? setHeadacheTypeModal(true)
							: navigation.navigate("AddHeadacheTypes");
					}}
				>
					<View
						style={[
							styles.inputSummaryContainer,
							{ flexDirection: "row", alignItems: "center" },
						]}
					>
						<Text
							style={[styles.inputSummaryValue, { width: "80%" }]}
						>
							{headacheType
								? headacheType?.name
								: t(
										"translation:general.events.type_placeholder"
								  )}
						</Text>
						{headacheType && (
							<TouchableOpacity
								style={{ width: "10%" }}
								onPress={() => setHeadacheType(null)}
							>
								<Icon
									name="trash-2"
									size={PR(15)}
									color={GlobalStyles.global.redError}
								/>
							</TouchableOpacity>
						)}
					</View>
				</TouchableOpacity>
			</View>
			<View
				style={{
					width: "90%",
					alignSelf: "center",
					marginBottom: PR(30),
				}}
			>
				<CommonLabelForm
					label={t(
						"translation:headache_event.manageEmergencyMedication.header"
					)}
					isOptional={true}
				/>
				{rescues.length > 0 ? (
					<View
						testID="manageEmergencyMedicationID"
						style={styles.summaryContainer}
					>
						<View
							style={[
								styles.inputSummaryContainer,
								{
									flexDirection: "column",
									alignItems:
										Platform.OS === "web"
											? "none"
											: "center",
								},
							]}
						>
							<RescueSelectedList
								list={[...rescues]}
								clicked={(res) => {
									navigation.navigate(
										"SelectRescueMedication",
										{
											editSelection: res,
											selectedRescues:
												rescues.length > 0
													? [...rescues]
													: [],
											returnRescues: (res) =>
												setRescues(res),
										}
									);
								}}
								deleteFunc={(res) => {
									setRescues(res);
								}}
							/>
						</View>
					</View>
				) : (
					<TouchableOpacity
						testID="manageEmergencyMedicationID"
						style={styles.summaryContainer}
						onPress={() =>
							navigation.navigate("SelectRescueMedication", {
								selectedRescues:
									rescues.length > 0 ? [...rescues] : [],
								returnRescues: (res) => setRescues(res),
							})
						}
					>
						<View
							style={[
								styles.inputSummaryContainer,
								{ flexDirection: "row", alignItems: "center" },
							]}
						>
							<Text
								style={[
									styles.inputSummaryValue,
									{ width: "80%" },
								]}
							>
								{t(
									"translation:headache_event.headache.emergencyMedication.addOne"
								)}
							</Text>
						</View>
					</TouchableOpacity>
				)}
				{rescues.length > 0 && (
					<TouchableOpacity
						testID="manageEmergencyMedicationID"
						onPress={() =>
							navigation.navigate("SelectRescueMedication", {
								selectedRescues:
									rescues.length > 0 ? [...rescues] : [],
								returnRescues: (res) => setRescues(res),
							})
						}
					>
						<Text
							style={[
								styles.inputSummaryValue,
								{
									alignSelf: "flex-end",
									color: GlobalStyles.migraine.color,
								},
							]}
						>
							{t(
								"translation:headache_event.headache.emergencyMedication.addOne"
							)}
						</Text>
					</TouchableOpacity>
				)}
			</View>
			<CommonSelectForm
				values={triggersType}
				setValues={(res: Array<string>) => {
					setTriggersType(res);
					isFormUpdated(true);
				}}
				cmsListLink="list.headache_triggers"
				patientList="triggers_inputs"
				label={t("translation:events.seizure.triggers")}
				isOptional={true}
			/>
			<View
				style={{
					width: "90%",
					alignSelf: "center",
					marginBottom: PR(30),
				}}
			>
				<CommonLabelForm
					label={t("translation:headache_event.headache.form.aura")}
					isOptional={true}
				/>
				<TouchableOpacity
					testID="weekFrequencyID"
					style={styles.summaryContainer}
					onPress={() =>
						setBeforeHeadacheModal(!isBeforeHeadacheModalOpen)
					}
				>
					<View style={styles.inputSummaryContainer}>
						<Text style={styles.inputSummaryValue}>
							{beforeHeadache.length === 0
								? t("translation:modal.select.title_multiple")
								: beforeHeadache
										.map((item): string =>
											getListCMSData(
												t,
												item,
												"list.headache_auras"
											)
										)
										.join("\n")}
						</Text>
					</View>
				</TouchableOpacity>
			</View>
			<CommonSelectForm
				info={true}
				cmsListLinkInfo="list.headache_symptoms_details"
				values={headacheSymptoms}
				setValues={(res: Array<string>) => {
					setHeadacheSymptoms(res);
					isFormUpdated(true);
				}}
				cmsListLink="list.headache_symptoms"
				patientList="symptoms_inputs"
				label={t("translation:headache_event.type.symptoms.label")}
				isOptional={true}
			/>
			<View
				style={{
					width: "90%",
					alignSelf: "center",
					marginBottom: PR(30),
				}}
			>
				<CommonLabelForm
					label={t(
						"translation:headache_event.headache.life_impacts"
					)}
					isOptional={true}
				/>
				<TouchableOpacity
					testID="life_impactsID"
					style={styles.summaryContainer}
					onPress={() => setLifeImpactsModal(!isLifeImpactsModalOpen)}
				>
					<View style={styles.inputSummaryContainer}>
						<Text style={styles.inputSummaryValue}>
							{lifeImpacts.length === 0
								? t("translation:modal.select.title_multiple")
								: lifeImpacts
										.map((item): string =>
											getListCMSData(
												t,
												item,
												"list.life_impacts"
											)
										)
										.join("\n")}
						</Text>
					</View>
				</TouchableOpacity>
			</View>
			<InputField
				labelText={t("translation:general.events.remark")}
				inputType="string"
				customStyle={{ marginBottom: PR(30) }}
				changeText={(d: string) => {
					setRemarks(d);
					isFormUpdated(true);
				}}
				placeholderTxt={t(
					"translation:general.events.remark_placeholder"
				)}
				testID="remarks"
				multiline={true}
				value={remarks}
				isOptional={true}
			/>
		</>
	);
};

const generateStyleSheet = (colors: Record<string, string>) => {
	return StyleSheet.create({
		dateInput: {
			width: "90%",
			alignSelf: "center",
			marginBottom: PR(30),
		},
		dateInputLabel: {
			marginBottom: PR(10),
			marginLeft: PR(20),
		},
		dateInputLabelText: {
			color: colors.primary,
			fontSize: PR(12),
		},
		dateInputLabelTextHint: {
			color: colors.primary,
			fontSize: PR(10),
			fontFamily: GlobalStyles.global.fontFamily.RegularItalic,
		},
		dateInputField: {
			backgroundColor: colors.inputBackground,
			borderRadius: PR(20),
			borderColor: "#F2F4F5",
			height: PR(62),
			paddingHorizontal: PR(20),
			paddingVertical: PR(20),
		},
		dateInputFieldText: {
			fontSize: PR(16),
			fontFamily: GlobalStyles.global.fontFamily.Medium,
			color: colors.primary,
		},
		summaryContainer: {
			backgroundColor: colors.inputBackground,
			borderRadius: PR(20),
		},
		inputSummaryContainer: {
			flex: 1,
			flexDirection: "row",
			minHeight: PR(62),
			padding: PR(10),
			fontSize: PR(16),
			justifyContent: "space-between",
			alignItems: "center",
		},
		inputSummaryValue: {
			flex: 1,
			flexGrow: 1,
			color: colors.primary,
			marginLeft: PR(10),
			fontSize: PR(16),
			fontFamily: GlobalStyles.global.fontFamily.Medium,
		},
	});
};

export default CommonAddHeadache;
