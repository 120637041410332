export default {
    globalFontFamily: {
        fontFamily: 'arial',
    },
    global: {
        fontFamily: {
            Black: 'FilsonPro-Black',
            BlackItalic: 'FilsonPro-BlackItalic',
            Bold: 'FilsonPro-Bold',
            BoldItalic: 'FilsonPro-BoldItalic',
            Heavy: 'FilsonPro-Heavy',
            HeavyItalic: 'FilsonPro-HeavyItalic',
            Light: 'FilsonPro-Light',
            LightItalic: 'FilsonPro-Light',
            Medium: 'FilsonPro-Medium',
            MediumItalic: 'FilsonPro-MediumItalic',
            Regular: 'FilsonPro-Regular',
            RegularItalic: 'FilsonPro-RegularItalic',
            Thin: 'FilsonPro-Thin',
            ThinItalic: 'FilsonPro-ThinItalic'
        },
        black: '#333333',
        white: '#fff',
        grey: '#aaaeb3',
        lightGrey: '#EBEBEB',
        greyToBlack: '#202224',
        footerGrey: '#F2F4F5',
        yellow: '#FFB714',
        redError: '#FD2F2F',
        redErrorBackground: '#FECDCD',
        ligthGreen: '#1EEAB7',
        customTypeGrey: '#C1C7D1',
        actionGreen: '#3B95A7',
        greenSuccess: '#5AC25E',
        blueInProgress: '#49ACF5'

    },
    helpi: {
        color: '#4C0EA7',
        btnColor: '#6D14EE',
        lightColor: '#C5A1F8',
        ultraLightColor: '#6D14EE1A',
        closeBtn: '#8C7FE51A',
        darkColor: '#570FBE',
        backgroundColorOpacity: "#FFFFFFBE",
        backgroundColorModal: "#000038d9",
        backgroundColorModalOpacity: "rgba(0, 0, 56, 0.9)",
        inactiveColor: '#A772F5',
        lightBackgroundColor: '#8C7FE5',
        primary: '#6D14EE',
        progressBarBackgroundColor: '#DBD6FF'
    },
    migraine: {
        color: '#06AB8D',
        btnColor: '#069a7e',
        lightColor: '#F2FAF9',
        ultraLightColor: '#cdEEE8',
        closeBtn: '#8C7FE51A',
        darkColor: '#046754',
        backgroundColorOpacity: "#38bca3",
        backgroundColorModal: "#123129" ,
        backgroundColorModalOpacity: "rgba(18, 49, 41, 0.9)",
        lightBackgroundColor: "#83d5c5",
    },
    colors: {
        helpi_light:{
            primary: '#333333',
            background: '#fff',
            inputBackground: '#F2F4F5',
            inputBackgroundError: '#EBEBEB',
            footerBackground: '#F2F4F5',
            shadowColor: '#3333331A',
            selectTimelineContainer: '#6D14EE1A',
            timeLineBarEmpty: '#EBEBEB',
            tabBarInactiveTintColor: '#C5A1F8',
            tabBarActiveTintColor: '#570FBE',
            diaryDateContainer: '#ffffff',
            inputDateContainer: '#F2F4F5',
            bodyIconContainer: '#EBEBEB',
            acceptanceContainer: '#EBEBEB',
            acceptanceTxt: '#333333',
            medBottomContainerBorder: '#fff',
            intakeTotalBackground: '#EBEBEB',
            subtitleColor: '#aaaeb3'
        },
        migraine_light:{
            primary: '#333333',
            background: '#fff',
            inputBackground: '#F2F4F5',
            inputBackgroundError: '#EBEBEB',
            footerBackground: '#F2F4F5',
            shadowColor: '#3333331A',
            selectTimelineContainer: '#e6fffd',
            timeLineBarEmpty: '#EBEBEB',
            tabBarInactiveTintColor: '#046754',
            tabBarActiveTintColor: '#6acdba',
            diaryDateContainer: '#ffffff',
            inputDateContainer: '#F2F4F5',
            bodyIconContainer: '#EBEBEB',
            acceptanceContainer: '#EBEBEB',
            acceptanceTxt: '#333333',
            medBottomContainerBorder: '#fff',
            intakeTotalBackground: '#EBEBEB',
            subtitleColor: '#aaaeb3'
        },
        migraine_dark:{
            primary: '#fff',
            background: '#202224',
            inputBackground: '#333333',
            inputBackgroundError: '#474747',
            footerBackground: '#2c2c2c',
            shadowColor: '#ffffff1A',
            selectTimelineContainer: '#333333',
            timeLineBarEmpty: '#333333',
            tabBarInactiveTintColor: '#ffffff66',
            tabBarActiveTintColor: '#6acdba',
            diaryDateContainer: '#333333',
            inputDateContainer: '#333333',
            bodyIconContainer: '#333333',
            acceptanceContainer: '#EBEBEB66',
            acceptanceTxt: '#aaaeb3',
            medBottomContainerBorder: '#333333',
            intakeTotalBackground: '#2c2c2c',
            subtitleColor: '#E6E6E6'
        }
    }
}