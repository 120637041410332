import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import { useTheme } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
// @ts-ignore
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import CommonImage from './CommonImage';
import CommonModal from 'common/src/components/CommonModal';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { addEventAction } from 'common/src/store/actions/authenticationActions';
import { updateEvent } from 'common/src/store/events/eventActions';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { timezoneBannerExist } from 'common/src/store/timezoneBanner/timezoneBannerReducer';
import { updateTimezoneBannerAction } from 'common/src/store/timezoneBanner/timezoneBannerActions';

const { height } = Dimensions.get('window');

const CommonHeader = (props?:any) => {
  const colors = useTheme().colors;
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const TimezoneBannerRes = useSelector(timezoneBannerExist);
  const disableFormsBanner = props.disableFormsBanner || false;
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const availableForms = useSelector((state: any) => state.getForms?.availableForms);
  const [formsToAnswer, setFormsToAnswer] = useState([] as any);
  const [scrollState, setScrollState] = useState({ changeHeader:false });
  const actionBtn = !!props.addActionBtnChildren || false

  const styles: any = generateStyleSheet(actionBtn, colors);

  useEffect(() => {
    if(props.scroll !== undefined){
      setScrollPage(props.scroll)
    }
  },[props.scroll]);
  const setScrollPage = (event: any) => {
    let indexOfNextScreen = 0
    indexOfNextScreen = Math.ceil(event / height);
    if (indexOfNextScreen > 0) {
      setScrollState({
        ...scrollState,
        changeHeader: true
      });
    } else {
      setScrollState({
        ...scrollState,
        changeHeader: false
      });
    }
  };

  useEffect(() => {
    if (availableForms)
      setFormsToAnswer(availableForms);
  }, [availableForms])

  const openForm = () => {
    const questionnaire = {
      formId: formsToAnswer[0].form,
      expirationDate: formsToAnswer[0].expiration,
      site: formsToAnswer[0].site,
      sentDate: formsToAnswer[0].send_date,
      targetDate: formsToAnswer[0].targetDate,
      dailyIndex: formsToAnswer[0].dailyIndex
    }
    navigation.navigate('StudyForm', {formId: formsToAnswer[0].form, questionnaire})
  }

  const callbackVisible = () => {
    setWarningModal(false);
  }

  const goToTimezone = () => {
    navigation.navigate('Timezone')
    setWarningModal(false);
  }

  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.editTimezone'),
      click: goToTimezone
    },
    {
      type: 'primary',
      title: t('translation:modal.btn.ok'),
      click: callbackVisible
    }
  ]

  return (
    <>
      { warningModal &&
        <CommonModal testID="warningModalID" type={'info'} title={t('translation:general.page_title.timezone')} text={t('translation:modal.timezoneWarning.text')} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      {
        TimezoneBannerRes &&
          <View style={styles.timezoneBanner} >
            <View style={{flexDirection:'row', justifyContent:'space-between', width:'90%'}}>
              <TouchableOpacity onPress={() => setWarningModal(true)} style={{width: '95%'}}>
                <Text style={{color: GlobalStyles.global.white, textAlign: 'center'}}>{t('translation:general.messages.timezoneBanner')}</Text>
              </TouchableOpacity>
              <TouchableOpacity style={{marginLeft:PR(20), width: '5%'}} onPress={() => dispatch(updateTimezoneBannerAction({bannerExist: false}))}>
                <Icon name={'x'} size={PR(15)} color={GlobalStyles.global.white} />
              </TouchableOpacity>
            </View>
          </View>
      }
      {
        !disableFormsBanner && formsToAnswer.length > 0 &&
          <TouchableOpacity style={styles.formsBanner} onPress={openForm}>
            <Text renderAsHtml={true} style={{color: GlobalStyles.global.white}}>{t('translation:general.messages.formsToAnswer').replace('$num', formsToAnswer.length.toString())}</Text>
          </TouchableOpacity>
      }
      <View style={!scrollState.changeHeader?styles.container: styles.container_scroll}>
        {props.leftClick ?
          <View style={styles.secondary_header}>
            <TouchableOpacity testID='back-btn' style={styles.leftContainer} onPress={props.leftClick}>
                <Text renderAsHtml={true} numberOfLines={1} style={styles.LeftBtn}>{props.leftBtnTxt}</Text>
                <Icon name={props.leftIconName} size={PR(25)} color={props.leftIconColor} />
            </TouchableOpacity>
            <View style={{width:'80%'}}>
              <Text renderAsHtml={true} numberOfLines={2} style={styles.secondary_title} testID={props.testIDTitle}>{props.title}</Text>
            </View>
            <View style={styles.rightContainer}>
              <TouchableOpacity onPress={props.rightClickFirst}>
                <Icon name={props.rightIconNameFirst} size={PR(28)} color={props.rightIconColorFirst} />
              </TouchableOpacity>
              <TouchableOpacity onPress={props.rightClickFirst}>
                <Text style={styles.btn} testID={props.testIDRightSecond}>{props.rightBtnTxtFirst}</Text>
              </TouchableOpacity>
              { props.addActionBtnChildren }
            </View>
          </View>
          :
          <View style={styles.main_header}>
            <View style={styles.main_header_title_container}>
              <Text renderAsHtml={true} numberOfLines={2} style={scrollState.changeHeader && !props.rightClick? styles.main_title_scroll : props.mainPage ? [styles.main_title,{fontSize: PR(36)}] : styles.main_title} testID={props.testIDTitle}>{props.title}</Text>
            </View>
            <View style={styles.rightContainer}>
              <TouchableOpacity style={{paddingHorizontal:PR(10)}} onPress={props.rightClickFirst}>
                <Text renderAsHtml={true}style={styles.btn} testID={props.testIDRightFirst}>{props.rightBtnTxtFirst}</Text>
              </TouchableOpacity>
              <TouchableOpacity style={{paddingHorizontal:PR(10)}} onPress={props.rightClickFirst}>
                {props.imageName !== undefined ?
                  <CommonImage name={props.imageName} height={PR(30)} width={PR(30)}/>
                  :
                  <Icon name={props.rightIconNameFirst} size={PR(28)} color={props.rightIconColorFirst} />
                }
              </TouchableOpacity>
              <TouchableOpacity onPress={props.rightClickSecond}>
              {props.secondImageName !== undefined ?
                  <CommonImage name={props.secondImageName} height={PR(30)} width={PR(30)}/>
                  :
                  <Icon name={props.rightIconNameSecond} size={props?.rightIconSize || PR(28)} color={props.rightIconColorSecond} />
                }
              </TouchableOpacity>
              <TouchableOpacity onPress={props.rightClickSecond}>
                <Text renderAsHtml={true} style={styles.btn} testID={props.testIDRightSecond}>{props.rightBtnTxtSecond}</Text>
              </TouchableOpacity>
              { props.addActionBtnChildren }
            </View>
          </View>        
        }
      </View>
    </>
  );
};

const generateStyleSheet = (actionBtn:boolean, colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      width: "100%",
      alignSelf: 'center',
      backgroundColor: colors.background,
      minHeight: PR(60),
    },
    container_scroll: {
      width: '100%',
      alignSelf: 'center',
      backgroundColor: colors.background,
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.53,
      shadowRadius: 13.97,
      elevation: 21,
    },
    main_header: {
      minHeight: PR(60),
      width: '90%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems:'flex-end',
      alignSelf: 'center'
    },
    secondary_header:{
      minHeight: PR(60),
      width: '90%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems:'center',
      alignSelf:'center',
    },
    main_header_title_container: {
      width: actionBtn ? '70%' : '80%',
      justifyContent: 'flex-end',
    },
    rightContainer: {
      flex:1,
      flexDirection: 'row',
      width: PR(100),
      justifyContent: 'flex-end',
      alignItems: 'center',
      alignSelf:'center'
    },
    leftContainer: {
      flex:1,
      flexDirection: 'row',
      width: PR(100),
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    main_title: {
      width: '100%',
      fontSize: PR(32),
      fontFamily: GlobalStyles.global.fontFamily.Bold,
      color: colors.primary,
      marginBottom: PR(13)
    },
    main_title_scroll: {
      width: '100%',
      fontSize: PR(24),
      fontFamily: GlobalStyles.global.fontFamily.Bold,
      color: colors.primary,
      marginBottom: PR(13)
    },
    secondary_title: {
      fontSize: PR(24),
      fontFamily: GlobalStyles.global.fontFamily.Bold,
      color: colors.primary
    },
    btn: {
      fontSize: PR(16),
      color: GlobalStyles[APP_SHORTCUT_NAME].color,
      fontFamily: GlobalStyles.global.fontFamily.Bold
    },
    LeftBtn: {
      fontSize: PR(16),
      color: GlobalStyles.global.grey,
      fontFamily: GlobalStyles.global.fontFamily.Bold
    },
    formsBanner: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#1eeab7',
      paddingVertical: PR(6),
      width: '100%'
    },
    formsErrorBanner: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: GlobalStyles.global.redError,
      paddingVertical: PR(6),
      width: '100%'
    },
    timezoneBanner: {
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: GlobalStyles.global.yellow,
      paddingVertical: PR(6),
      width: '100%'
    },
  })
};

export default CommonHeader;