import React, {useEffect, useState} from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import CommonCheckBox from 'common/src/components/CommonCheckBox';

interface OwnTypes {
    type: 'radio' | 'check',
    data: Array<Record<string, string>>,
    onSelect: (res:any) => void,
    disable?: boolean,
    style?: Record<string,any>,
    infoData?: Array<any>
}

const CommonSelect = ({type, data, onSelect, disable, style, infoData}:OwnTypes) => {
    const [filterList, setFilterList] = useState(data);

    useEffect(() => {
        setFilterList(data)
    },[data])

    const updateList = (selected, index) => {
        let _updatedList: any = [...filterList]
        if (type === 'check') {
            _updatedList[index].selected = selected;
        }
        else if (type === 'radio') {
            _updatedList.map((item, i) => {
                item.selected = false
                if (i === index && selected !== false)
                    item.selected = true;
            })  
        }
        setFilterList(_updatedList);
        onSelect(_updatedList)
    }

    const renderSelectList = (item, index, infoItem) => (
        <CommonCheckBox
            index={index}
            infoTitle={'Help'}
            infoText={infoItem?.label || undefined}
            text={item.label}
            type={type === 'check'? 'rounded-checkbox' : 'rounded'}
            updateCheckBox={updateList}
            selected={item.selected}
            testID={item.label}
            checkedIcon={type === 'check' ? true : false}
            disable={disable}
        />
    )

    return (
        <View style={[styles.container, style ? style : {}]}>
            <ScrollView>
                {filterList.map((list,i) => {
                    const infoItem = infoData && infoData[i];
                    return <View key={i}>{renderSelectList(list, i, infoItem)}</View>
                })}
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%'
    }
})

export default CommonSelect;