export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';

export const POST_REPORTS = 'POST_REPORTS';
export const POST_REPORTS_SUCCESS = 'POST_REPORTS_SUCCESS';

export const GET_MIO_REPORT = 'GET_MIO_REPORT';

interface PostReportsTypes{
    startDate: string,
    endDate: string,
    navigation: any
}

export interface MioReportInterface {
    patientId: string,
    startDate: string,
    endDate: string,
    callback: (res:any) => void;
    errorCallback: () => void;
}

export const getReportsAction = () => ({
        type: GET_REPORTS,
    }
);

export const postReportsAction = (payload:PostReportsTypes) => ({
    type: POST_REPORTS,
    ...payload
});

export const getMioReportAction = (payload:MioReportInterface) => ({
    type: GET_MIO_REPORT,
    ...payload
})