import { getEventsAction, addEventMoodAndSleepAction, updateMoodAndSleepAction } from 'common/src/store/actions/authenticationActions';
import { updateEvent } from 'common/src/store/events/eventActions';
import { DateTime } from "luxon";

// scroll to the specific day
export const moveToElement = async (dayPos: string, eventsArr: any, scrollRef, animated) => {
  let itemPos = eventsArr.findIndex(item => item.date === dayPos)
  if(eventsArr.length >= itemPos && itemPos !== -1) {
    scrollRef?.scrollToIndex({
      animated: animated,
      index: itemPos
    })
  }
}
// checking if we are at the top of the scrollView
export const isCloseToTop = ({layoutMeasurement, contentOffset, contentSize}) => {
  return contentOffset.y == 0;
}

export const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height;
}

export const filtersData = [
  {label: 'seizure', value: 'seizures'},
  {label: 'appointment', value: 'appointments'},
  {label: 'side_effect', value: 'side_effects'},
  {label: 'other', value: 'others'},
  {label: 'reminder', value: 'reminders'},
  {label: 'mood', value: 'mood'},
  {label: 'sleep', value: 'sleep'},
  {label: 'all', value: 'all_events'}
]

export const filtersMMData = [
  {label: 'headache', value: 'headaches'},
  {label: 'appointment', value: 'appointments'},
  {label: 'side_effect', value: 'side_effects'},
  {label: 'other', value: 'others'},
  {label: 'reminder', value: 'reminders'},
  {label: 'mood', value: 'mood'},
  {label: 'sleep', value: 'sleep'},
  {label: 'all', value: 'all_events'}
]

// send request to the server to get more events/days for the next or previous weeks
export const weekCreation = (prevNext, isFocused, events, dispatch) => {
  if(prevNext === 'next' && isFocused){
    let obj = {
      from:DateTime.fromISO(events[events.length - 1].date).plus({days: 1}).toFormat('yyyy-MM-dd'),
      to:DateTime.fromISO(events[events.length - 1].date).plus({days: 7}).toFormat('yyyy-MM-dd')+'T23:59:59',
      prevNext
    }
    dispatch(getEventsAction(obj));
    return true
  }
  else if(prevNext === 'prev' && isFocused){
    // @ts-ignore
    let obj = {
      from:DateTime.fromISO(events[0].date).minus({days: 7}).toFormat('yyyy-MM-dd'), 
      to:DateTime.fromISO(events[0].date).minus({days: 1}).toFormat('yyyy-MM-dd')+'T23:59:59',
      prevNext
    }
    dispatch(getEventsAction(obj));
    return true
  } else {
    return [0,7]
  }
}

export const saveForm = (type, event, dispatch) => {
  if (type === 'edit')
    dispatch(updateEvent(event))
}

// send request in order to change the mood or sleep
export const saveMoodAndSleep = (moodAndSleepModalId, moodAndSleepModalType, moodAndSleepModalValue, moodAndSleepModalDate, moodAndSleepModalEmptyData, dispatch) => {
  let obj = {
    eventID: moodAndSleepModalId, 
    data:{
      type:moodAndSleepModalType,
      value: (moodAndSleepModalValue*0.1).toFixed(1)
    }
  }
  let data = {
    date: moodAndSleepModalDate,
    value: (moodAndSleepModalValue*0.1).toFixed(1),
    type: moodAndSleepModalType
  }
  if(moodAndSleepModalEmptyData){
    dispatch(addEventMoodAndSleepAction({data}))
  }
  else
    dispatch(updateMoodAndSleepAction(obj));
}

export const onWeekViewClick = (clickEvent, events, scrollPos) => {
  moveToElement(clickEvent, events, scrollPos, true)
}

