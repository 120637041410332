import React, {useState, useEffect} from 'react';
import {SafeAreaView, StyleSheet, View, ScrollView, TouchableOpacity, ActivityIndicator} from 'react-native'; 
import { useTheme } from '@react-navigation/native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonFooter from 'common/src/components/CommonFooter';
import Text from 'common/src/components/CommonCustomTxt';
import InputField from 'common/src/components/InputField';
import Icon from 'react-native-vector-icons/Feather';
import { getDataSharingAction, logOutAction } from 'common/src/store/actions/authenticationActions';
import { validateEmailAction } from 'common/src/store/verifyEmail/verifyEmailActions';
import { 
    getOTPForRegistrationAction,
    verifyOTPCodeAction,
} from 'common/src/store/mfa/mfaActions';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParams } from "common/src/types";

const VerifyEmail = () => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const Me = useSelector((state:any) => state.getMe.response);
    const Patient = useSelector((state:any) => state.getMe.patient);
    const navigation:StackNavigationProp<RootStackParams, 'Welcome'> = useNavigation();
    const [pinInput, setPinInput] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getOTPFunc();
    },[])


    const getOTPFunc = () => {
        setIsLoading(true);
        dispatch(getOTPForRegistrationAction({
            userId: Me?.result?.id,
            callback: () => {
                setIsLoading(false)
            },
            errorCallback: () => {
                setIsLoading(false)
            }
        }))
    }

    const verifyOTP = () => {
        setIsLoading(true);
        dispatch(verifyOTPCodeAction({
            userId: Me?.result?.id,
            otpCode: pinInput,
            language: Me?.result?.settings?.language,
            callback: () => {
                setPinInput("");
                dispatch(validateEmailAction({
                    id: Me?.result?.id,
                    patientId: Patient?.id || Patient?._id
                }))
                if (Patient.azmm) {
                    if (Patient.azmmValidation === false)
                        return navigation.navigate('Azmm', { nextAction: 'OnboardingQuestions' });
                    else
                        return navigation.navigate('OnboardingQuestions');
                }
                else
                    dispatch(getDataSharingAction({
                        callback: (result: any) => {
                            if(result?.length > 0)
                                for (let i = 0; result[i]; i++) {
                                if(!result[i].isActive) continue;
                                else return navigation.navigate('DataSharingRequest', { nextAction: 'OnboardingQuestions' })

                                }
                            navigation.reset({index: 0,routes: [{ name: 'NeurologistStep'}]});
                            setIsLoading(false);
                        }
                    }))
            },
            errorCallback: () => {
                setPinInput("");
                setIsLoading(false);
            }
        }))
    }

    const setLogout = () => {
        dispatch(logOutAction());
        navigation.reset({
          index: 0,
          routes: [{ name: 'Welcome' }],
        });
      }

    const next = () => {
        verifyOTP();
    }

    const resendEmail = () => {
        getOTPFunc();
    }

    const checkDisable = () => (!pinInput.length)

    return (
        <SafeAreaView style={styles.container}>
            <CommonHeader 
                title={t('translation:general.page_title.verify_email')}
                rightIconNameFirst="log-out"
                rightIconColorFirst={GlobalStyles.migraine.btnColor}
                rightClickFirst={setLogout}
                disableFormsBanner={true}
                testIDTitle='verify_email_title_id'
            />
            <ScrollView 
                style={styles.scrollview}
            >
                <View style={styles.txtWrapper}>
                    <Icon 
                        name="mail" 
                        size={PR(50)} 
                        color={GlobalStyles.migraine.darkColor}
                    />

                    <Text testID="description_id" renderAsHtml={true} style={styles.txtStyle}>{t('translation:MFA.registration.description', {email:Me?.result?.email.replace(/(\w{1})[\w.-]+@([\w.]+\w)/, "$1*****@$2")})}</Text>
                    <InputField 
                        labelText={t('translation:MFA.login.title')}
                        inputType="number" 
                        customStyle={{marginBottom:PR(30)}}
                        changeText={setPinInput}
                        placeholderTxt={t('translation:MFA.registration.placeholder.email')}
                        testID="otp_code_id"
                        value={pinInput}
                    />
                    <View testID='resend_container_id' style={{flexDirection: 'column', alignItems: 'center'}}>
                        <Text style={styles.notReceivedTxt}>{t('translation:MFA.login.did_not_receive_otp')} </Text>
                        <TouchableOpacity onPress={resendEmail}>
                            <Text style={styles.resendBtn}>{t('translation:MFA.login.btn.resend')}</Text>
                        </TouchableOpacity>
                    </View>
                    {isLoading &&
                        <View style={{paddingTop:PR(20)}}>
                            <ActivityIndicator size="small" color={GlobalStyles.migraine.color} />
                        </View>
                    }
                </View>
            </ScrollView>
            <CommonFooter>
                <CommonBtn 
                    type='primary' 
                    click={next} 
                    title={t('translation:MFA.login.btn.verify')}  
                    testID="next_verify_email_btn"
                    disable={checkDisable()}
                    />
            </CommonFooter>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
        backgroundColor: colors.background,
        flex: 1,
        },
        scrollview: {
            paddingTop: PR(20),
            flex:1
        },
        txtWrapper: {
            width: '90%',
            alignItems: 'center',
            alignSelf: 'center',
        },
        txtStyle: {
            marginTop: PR(10),
            marginBottom: PR(20),
            fontSize: PR(14),
            textAlign: 'justify',
            color: colors.primary,
            lineHeight:PR(25)
        },
        notReceivedTxt: {
            fontSize: PR(16),
            letterSpacing: 0,
            fontFamily: GlobalStyles.global.fontFamily.Regular,
        },
        resendBtn: {
            color: GlobalStyles.migraine.btnColor,
            fontSize: PR(16),
            letterSpacing: 0,
            fontFamily: GlobalStyles.global.fontFamily.Medium,
            textDecorationLine: 'underline'
        }
    })
};

export default VerifyEmail;