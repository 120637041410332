import React, {useEffect, useState} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import { useTheme } from '@react-navigation/native';
import notificationEmitter, {NotificationTypes} from 'common/src/notifEmitter/notifEmitter';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonSwitchBtn from 'common/src/components/CommonSwitchBtn';
import CommonSimpleModal from 'common/src/components/CommonSimpleModal';
import CommonModal from 'common/src/components/CommonModal';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonNumPad from 'common/src/components/Inputs/CommonNumPad';
import CommonPinField from 'common/src/components/Inputs/CommonPinField';
import InputField from 'common/src/components/InputField';
import Text from 'common/src/components/CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';
import { 
    verifyPasswordAction,
    enablePushNotificationMFAAction, 
    disablePushNotificationMFAAction, 
    verifyOTPCodeAction,
    getOTPCodeAction,
} from 'common/src/store/mfa/mfaActions';
import {resetErrorModalAction} from 'common/src/store/actions/authenticationActions';
import { useDispatch, useSelector } from 'react-redux';

type OwnProps = {
    FBToken: string,
    push_status: boolean,
    email_status: boolean,
    update_push_status: (res: boolean) => void;
}

const PushNotificationMFA = ({FBToken, push_status, email_status, update_push_status}: OwnProps) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const Me = useSelector((state:any) => state.getMe.response);
    const errorMessages = useSelector((state:any) => state.modal.errors);
    const [enablePushMFA, setPushMFA] = useState<boolean>(false);
    const [enableEmailMFA, setEmailMFA] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [isPasswordModalOpen, setPasswordModal] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState<boolean>(false);
    const [isOTPFieldEnabled, setOTPField] = useState<boolean>(false);
    const [pinInput, setPinInput] = useState<Array<string>>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const pinLength = 6;

    useEffect(() => {
        setPushMFA(push_status)
        setEmailMFA(email_status)
        notificationEmitter.on(NotificationTypes.MFA_notif, (data) => {
            setPinInput([data.text[0],data.text[1],data.text[2],data.text[3],data.text[4],data.text[5]]);
        })
    },[push_status, email_status])

    const triggerPushNotificationMFASwitcher = () => {
        if(!enablePushMFA && !enableEmailMFA)
            setWarningModal(true)
        else
            setPasswordModal(true)
    }

    const changePinInput = (input: string) => {
        if(pinInput.length < pinLength)
            setPinInput([...pinInput, input])
        
    }

    const deletePinInput = () => {
        if(pinInput.length > 0)
            setPinInput(pinInput.slice(0, -1))
        
    }

    const getOTPFunc = () => {
        dispatch(getOTPCodeAction({
            userId: Me.result.id,
            language: Me.result.settings.language,
            name: Me.result.settings.first_name,
            whereToSend: 'push_notif',
            deviceFBToken: Platform.OS === 'web' ? 'broswer-without-token' : FBToken,
            callback: () => {
            }
        }))
    }

    const verifyPassword = () => {
        dispatch(verifyPasswordAction({
            userId: Me.result.id,
            password: password,
            deviceFBToken: Platform.OS === 'web' ? 'broswer-without-token' : FBToken,
            callback: () => {
                setPasswordModal(false)
                setPassword('')
                setOTPField(true)
                setPinInput([]);
            },
            errorCallback: () => {
                setPasswordModal(false)
                setPassword('')
                setPinInput([]);
            }
        }))
    }

    const verifyOTP = () => {
        dispatch(verifyOTPCodeAction({
            userId: Me.result.id,
            otpCode: pinInput.join(""),
            language: Me.result.settings.language,
            callback: () => {
                if(enablePushMFA)
                    disablePushNotifMFA()
                else
                    enablePushNotifMFA()
                setPinInput([]);
                closeOTPModal();
            },
            errorCallback: (res) => {
                setErrorMsg(res)
                setPinInput([]);
            }
        }))
    }

    const enablePushNotifMFA = () => {
        dispatch(enablePushNotificationMFAAction({
            userId: Me.result.id,
            deviceFBToken: FBToken,
            callback: () => {
                setPushMFA(true)
                update_push_status(true)
                setPasswordModal(false)
                setPassword('')
                closeOTPModal()
            },
            errorCallback: () => {
                setPasswordModal(false)
                setPassword('')
            }
        }))
    }

    const disablePushNotifMFA = () => {
        dispatch(disablePushNotificationMFAAction({
            userId: Me.result.id,
            password: password,
            callback: () => {
                setPushMFA(false)
                update_push_status(false)
                setPasswordModal(false)
                setPassword('')
                closeOTPModal()
            },
            errorCallback: () => {
                setPasswordModal(false)
                setPassword('')
            }
        }))
    }

    const closeOTPModal = () => {
        setOTPField(false); 
        setErrorMsg(""); 
        dispatch(resetErrorModalAction({ message: errorMessages[0] }));
    }

    
    return(
        <View>
            {isPasswordModalOpen &&
                <CommonSimpleModal
                        title={t('translation:MFA.modal.password.title')}
                        onClose={()=>setPasswordModal(false)}
                    >
                    <View style = {{height: PR(180)}}>
                        <InputField 
                            labelText={t('translation:login.form.password')}
                            inputType="password"  
                            customStyle={{marginBottom:PR(30)}}
                            changeText={setPassword} 
                            placeholderTxt={t('translation:login.form.email_placeholder')}
                            testID="Password"
                            value={password}
                        />
                        <CommonBtn testID="validate_pass" type="primary" click={verifyPassword} title={t('translation:MFA.validate.password')}  />
                    </View>
                </CommonSimpleModal>
            }
            {warningModal && 
                <CommonModal
                    testID="securityWarningModal"
                    type={'error'}
                    title={t('translation:MFA.security.warning.title')}
                    text={t('translation:MFA.security.warning.enable.email.subtitle')}
                    buttons={[{
                        type: 'primary',
                        title: t('translation:modal.btn.close'),
                        click: () => setWarningModal(false)
                      }]}
                    onClose={() => setWarningModal(false)}
                />
            }
            <View pointerEvents={(Platform.OS === 'web' && !enablePushMFA) ? 'none' : 'auto'} testID='pushNotifMFAContainerId' style={[styles.mfaContainer, {marginTop: PR(10), borderColor: GlobalStyles.migraine.color, opacity: (Platform.OS === 'web' && !enablePushMFA) ? 0.5 : 1}]}>
                <View style={styles.wrapper}>
                    <Text testID="unlock_text" style={styles.txt}>{t('translation:MFA.push_notification.title')}</Text>
                    <CommonSwitchBtn 
                        isOn={enablePushMFA}
                        size="small"
                        onToggle={()=>triggerPushNotificationMFASwitcher()}
                        testID="enableDisablepushNotifMFASwitch"
                    />
                </View>
                <View style={styles.horizontalDivider}></View>
                <View style={{alignSelf:'center', paddingTop:PR(10), width: '90%'}}>
                    <Text testID="enableDisablepushNotifMFADescription" style={styles.subTxt}>{t('translation:MFA.push_notification.description', {app_name: "MigraineManager"})}</Text>
                    {Platform.OS === 'web' && !enablePushMFA &&
                        <Text testID="enableDisablepushNotifMFADisclaimer" style={styles.subTxt}>{t('translation:MFA.push_notification.description.disclaimer')}</Text>
                    }
                </View>
            </View>
            {isOTPFieldEnabled && 
                <CommonSimpleModal
                    title={t('translation:MFA.modal.otp.title')}
                    onClose={()=> closeOTPModal()}
                >
                    <View style={{alignItems:'center', alignSelf:'center', width:'90%'}}>
                        <Text testID="opt_modal_subtitle" style={styles.subTxt}>{t('translation:MFA.login.subtitle_push_notification')}</Text>
                    </View>
                    {!!errorMsg &&
                        <View style={{alignItems:'center', alignSelf:'center', width:'90%'}}>
                            <Text renderAsHtml={true} testID="error_subtitle" style={styles.errorTxt}>{errorMsg}</Text>
                        </View>
                    }
                    <View style = {{height: PR(380)}}>
                        <CommonPinField 
                            input={pinInput}
                            pinLength={pinLength}
                            inputAreaStyle={{marginBottom: PR(24)}}
                            showInputText={true}
                        />
                        <CommonNumPad 
                            setInput={changePinInput}
                            deleteInput={deletePinInput}
                            isConfirmActive={pinInput.length === pinLength ? true : false}
                            sendConfirmation={()=> verifyOTP()}
                        />
                    </View>
                    <View style={{marginTop: PR(20)}}>
                        <CommonBtn 
                            type='tertiary'
                            title={t('translation:MFA.resend_otp')}
                            click={() => getOTPFunc()}
                        />
                    </View>
                </CommonSimpleModal>
            }
        </View>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        wrapper:{
            flexDirection:'row',
            alignItems:'center',
            alignSelf:'center',
            width:'90%',
            justifyContent:'space-between',
            paddingBottom: PR(10),
        },
        mfaContainer: {
            minHeight: PR(62),
            padding: PR(10),
            width: '90%',
            borderRadius: PR(20),
            borderWidth: 1,
            borderColor: GlobalStyles.migraine.color ,
            alignSelf:'center'
        },
        horizontalDivider: {
            borderBottomColor: GlobalStyles.migraine.lightColor,
            borderBottomWidth: PR(1),
            width: '50%',
            alignSelf: 'center'
        },
        txt: {
            fontSize: PR(16),
            marginLeft: PR(15),
            color: colors.primary
        },
        subTxt: {
            fontSize: PR(12),
            color: GlobalStyles.global.grey
        },
        errorTxt: {
            fontSize: PR(12),
            color: GlobalStyles.global.redError
        }
    })
}

export default PushNotificationMFA;