import React, {useState} from 'react';
import {StyleSheet, View, Platform, ScrollView} from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import CommonHeader from './CommonHeader';
import CommonImage from './CommonImage';
import GlobalStyles from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import { isIphoneX } from 'react-native-iphone-x-helper';
import DeviceInfo from 'react-native-device-info';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { version } from '../../../helpilepsy/package.json';
import {APP_SHORTCUT_NAME} from 'common/src/env.json'
import { RootState } from '../store/reducers';

const CommonAbout = (props:any) => {
  const {t} = useTranslation();
  const colors = useTheme().colors;
  const styles: any = generateStyleSheet(colors);
  const ThemeMode = useSelector((state:RootState) => state.themeReducer.themeMode)
  const [scrollState, setScrollState] = useState<number>(0);

  const getManufacturerTranslation = () => {
    if(APP_SHORTCUT_NAME === "helpi")
      return "settings.about.manufacturer_date_";
    else return "settings.about.manufacturer_date_mm_";
  }

  return (
    
    <View style={styles.container}>
      <CommonHeader
        testIDTitle="about_title"
        title={t('translation:settings.about.title')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles[APP_SHORTCUT_NAME].color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : props.navigation.goBack()}}
        scroll={scrollState}
        disableFormsBanner={true}
      />
      <ScrollView
        scrollEventThrottle={16}
        onScroll={(event: any) => {
          if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
            setScrollState(event.nativeEvent.contentOffset.y);
          else if(scrollState > 0 && event.nativeEvent.contentOffset.y === 0)
            setScrollState(0);
        }}
      >
        <View style={styles.wrapper}>
          <Text style={styles.title}>{props.AppName}</Text>
          <View style={styles.subWrapperRow}>
          <CommonImage name={ThemeMode === 'light' ? 'manufacturer_dateIcon': 'manufacturer_date_lightIcon'} height={PR(49)} width={PR(49)}/>
          <View style={styles.subWrapperColumn}>
            <Text renderAsHtml={true} style={styles.subWrapperTxt}>{t(`translation:${getManufacturerTranslation() + props.version}`,{version: props.version})}</Text>
          </View>
          </View>
          <View style={styles.subWrapperRow}>
            <CommonImage name={ThemeMode === 'light' ? 'manufacturerIcon': 'manufacturer_lightIcon'} height={PR(49)} width={PR(49)}/>
            <View style={{marginLeft:PR(20)}}>
              <Text renderAsHtml={true} style={styles.subWrapperTxt}>{t('translation:settings.about.cAddressV')}</Text>
            </View>
          </View>
          {APP_SHORTCUT_NAME === 'helpi' &&
            <View style={[styles.subWrapperRow, {marginBottom:0}]}>
              <CommonImage name={'fdaLabel'} uri={t(`translation:settings.about.udi.${version}.image`)}height={PR(49)} width={PR(49)}/>
              <View style={{marginLeft:PR(20)}}>
                  <Text renderAsHtml={true} style={styles.subWrapperTxt}>{t(`translation:settings.about.udi.${version}.text`)}</Text>
              </View>
            </View>
          }
        </View>
        <View>
          <View style={styles.wrapper}>
            <Text style={styles.title}>{t('translation:settings.about.intended_use_title')}</Text>
            <Text renderAsHtml={true} style={styles.subTxt}>{t('translation:settings.about.intended_use_1_9')}</Text>
          </View>
        </View>
        
        <View style={styles.footer}>
          <View style={styles.footerRow}>
            <CommonImage name={ThemeMode === 'light' ? 'ceIcon': 'ce_lightIcon'} height={PR(49)} width={PR(35)}/>
            <View style={styles.subFooterRow}>
                <Text style={styles.subFooterTitle}>{props.footerTitle}</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.background,
      paddingTop: isIphoneX()|| DeviceInfo.hasDynamicIsland() ? PR(60) : Platform.OS === 'ios'? PR(20):0
    },
    wrapper: {
      margin: PR(20),
      width:'90%',
      alignSelf:'center',
    },
    title: {
      fontSize: PR(20),
      fontFamily: GlobalStyles.global.fontFamily.Bold,
      color: colors.primary,
      marginBottom: PR(20)
    },
    subWrapperRow: {
      flexDirection: 'row',
      marginBottom: PR(20),
      width: '80%',
      alignItems: 'center'
    },
    subWrapperColumn: {
      flexDirection: 'column',
      marginLeft: PR(20)
    },
    subWrapperTxt: {
      fontSize: PR(14),
      color: colors.primary
    },
    subTxt: {
      color: colors.primary,
      textAlign: 'left',
      fontSize: PR(16),
      marginBottom: PR(10),
    },
    footer:{
      justifyContent:'center',
      alignSelf:'center',
      width: '100%',
      height: PR(144),
      backgroundColor: colors.footerBackground
    },
    footerRow: {
      width: '90%',
      alignSelf:'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
  },
    subFooterRow: {
      flexDirection: 'row',
      alignItems:'center'
    },
    subFooterTitle: {
      fontSize: PR(18),
      fontFamily:GlobalStyles.global.fontFamily.Bold,
      color: colors.primary
    }
  })
}

export default CommonAbout

