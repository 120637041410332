import React, {useState} from "react";
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from "common/src/components/CommonHeader";
import CommonFooter from "common/src/components/CommonFooter";
import CommonBtn from "common/src/components/CommonBtn";
import Text from 'common/src/components/CommonCustomTxt';
import InputField from "common/src/components/InputField";
import CommonDeleteEventModal from 'common/src/components/CommonDeleteEvents/CommonDeleteEventModal';
import GlobalStyles from "common/src/styles/GlobalStyles";
import { addRescueAction, deleteRescueAction, updateRescueAction } from "common/src/store/treatments/rescueActions";
import { useTranslation } from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useDispatch, useSelector } from "react-redux";
import Loading from "common/src/components/Loading";
import CommonKeyboardAvoidingView from "common/src/components/CommonKeyboardAvoidingView";

const AddEmergencyMedication = ({navigation, route}) => {
    const { colors } = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ProfileResponse = useSelector((state:any) => state.getMe.response)
    const [dosage, setDosage] = useState<number | undefined>(route?.params?.medicationDetails?.dosage);
    const [unit, setUnit] = useState<string>(route?.params?.medicationDetails?.unit || "");
    const [nameOfTreat, setNameOfTreatment] = useState<string>(route?.params?.medicationDetails?.name || '');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const routes = navigation.getState()?.routes;
    const prevRoute = routes[routes.length - 2];  
    const styles: any = generateStyleSheet(colors, nameOfTreat);

    const goToDrugsScreen = () => {
        navigation.navigate('Drugs', {onReturn: (item) => {
          setNameOfTreatment(item)
        }})
    }

    const isDisable = () => (!dosage || !unit.length || !nameOfTreat.length)

    const saveRescue = () => {
        setLoading(true)
        dispatch(addRescueAction({
            //@ts-ignore
            dosage,
            name: nameOfTreat,
            unit,
            patientID: ProfileResponse?.result?.patients?.id,
            callback: () => {
               
                setLoading(false);
                setDosage(undefined);
                setUnit("")
                setNameOfTreatment("");
                if(prevRoute?.name === 'Main') navigation.navigate('AddRescueMedication', {medicationDetails: {}});
                else navigation.goBack();
            },
            errorCallback: () => {
                setLoading(false)
            }
        }))
    }

    const updateRescue = () => {
        setLoading(true)
        dispatch(updateRescueAction({
            //@ts-ignore
            dosage,
            name: nameOfTreat,
            unit,
            rescueID: route?.params?.medicationDetails?.id,
            patientID: ProfileResponse?.result?.patients?.id,
            callback: () => {
                setLoading(false)
                navigation.goBack();
            },
            errorCallback: () => {
                setLoading(false)
            }
        }))
    }

    const deleteRescue = () => {
        dispatch(deleteRescueAction({
            rescueID: route?.params?.medicationDetails?.id,
            patientID: ProfileResponse?.result?.patients?.id,
            callback: () => {
                setLoading(false)
                setDeleteModalVisible(false)
                navigation.goBack();

            },
            errorCallback: () => {
                setLoading(false)
                setDeleteModalVisible(false)
            }
        }))
    }

    if(isLoading)
        return <Loading />

    return (
        <CommonKeyboardAvoidingView>
            { isDeleteModalVisible && 
                <CommonDeleteEventModal 
                    onClose={() => { setDeleteModalVisible(false); }} 
                    onConfirm={deleteRescue} 
                    confirmationText={'headache_event.manageEmergencyMedication.deleteContent'}
                /> 
            }
            <CommonHeader
                testIDTitle="RescueMedicationTitle"
                title={t('translation:headache_event.manageEmergencyMedication.header')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.migraine.color}
                leftClick={() => {navigation.goBack()}}
            />
            <View style={{width:'90%',alignSelf:'center', marginBottom:30}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.name')}</Text>
              <TouchableOpacity testID="intakeID" style={styles.summaryContainer} onPress={() => goToDrugsScreen()}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{nameOfTreat.length === 0 ? t('translation:treatment.add_medication.step_info.summary.name_placeholder') : nameOfTreat}</Text>
                </View>
              </TouchableOpacity>
            </View>
            <InputField
              labelText={t('translation:headache_event.manageEmergencyMedication.dosage')}
              labelTextSize={PR(14)}
              inputType="number"
              customStyle={{marginBottom:PR(30)}}
              changeText={(res:string) => setDosage(parseInt(res))}
              placeholderTxt={'E.g 1000'}
              value={dosage ? dosage.toString() : ""}
              testID="minimum_dosage"
            />
            <InputField
                labelText={t('translation:treatment.drug.unit')}
                labelTextSize={PR(14)}
                inputType="dropdown"
                customStyle={{marginBottom:PR(30)}}
                changeText={(res:string) => setUnit(res.replace(/["']/g,''))}
                placeholderTxt={t('translation:treatment.drug.unity_placeholder')}
                // @ts-ignore
                data={t<string | TemplateStringsArray>('translation:list.units', {returnObjects: true}).map((name,i) => {
                    return {label:t('translation:list.units.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)).replace(/[\[\]']+/g,'')}
                })}
                value={unit}
                testID="unit"
            />
            <CommonFooter>
                {
                    Object.keys(route?.params?.medicationDetails).length !== 0 &&
                    <CommonBtn 
                        testID={"deleteRescueMedicationID"} 
                        type={'tertiary'} 
                        color={GlobalStyles.global.redError}
                        title={t('translation:headache_event.manageEmergencyMedication.delete')} 
                        click={() => setDeleteModalVisible(true)}
                    />
                }
                <View style={{marginBottom: PR(5)}}></View>
                <CommonBtn 
                    testID={"saveRescueMedicationID"} 
                    type={'primary'} 
                    title={t('translation:headache_event.manageEmergencyMedication.save')} 
                    click={() => {Object.keys(route?.params?.medicationDetails).length !== 0 ? updateRescue() : saveRescue() }}
                    disable={isDisable()}
                />
            </CommonFooter>
        </CommonKeyboardAvoidingView>
    )
}

const generateStyleSheet = (colors:Record<string, string>, nameOfTreat: string | undefined) => {
    return StyleSheet.create({
        inputField: {
            flex: 1,
            flexGrow: 1,
            color: colors.primary,
            marginLeft: PR(10),
            fontSize: PR(16),
            fontFamily: GlobalStyles.global.fontFamily.Medium
        },
        label: { 
            marginBottom: PR(10),
            marginLeft: PR(20),
            color: colors.primary,
            fontSize: PR(12)
        },
        summaryContainer: {
            backgroundColor: colors.inputBackground,
            borderRadius: PR(20),
        },
        inputSummaryContainer: {
            flexDirection: 'row',
            minHeight: PR(62),
            padding: PR(10),
            fontSize: PR(16),
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        inputSummaryValue: {
            color: nameOfTreat ? colors.primary : GlobalStyles.global.grey,
            marginLeft: PR(20),
            padding: PR(10),
            fontSize: PR(16),
            fontFamily: GlobalStyles.global.fontFamily.Medium
        },
    })
}

export default AddEmergencyMedication;