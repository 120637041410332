import React, {useState} from 'react';
import {
    StyleSheet,
    SafeAreaView,
    View,
    ScrollView
  } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from "common/src/components/CommonHeader";
import CommonNotifications from 'common/src/components/CommonNotifications';
import CommonModal from 'common/src/components/CommonModal';
import CommonActionModal from 'common/src/components/CommonActionModal';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { checkNotificationType } from 'common/src/services/notifications';
import { useSelector, useDispatch } from 'react-redux';
import { addEventAction } from 'common/src/store/actions/authenticationActions';
import { updateEvent } from 'common/src/store/events/eventActions';
import { updateMoodAndSleepAction, updateMedicationIntake, addEventMoodAndSleepAction } from 'common/src/store/actions/authenticationActions';
import { useTranslation } from 'react-i18next';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';

const Notifications = ({navigation}) => {
    const colors = useTheme().colors;
    const styles: any = generateStyleSheet(colors);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Patient = useSelector((state: any) => state.getMe.patient);
    const [moodAndSleepModal, setMoodAndSleepModal]: any = useState(null);
    const [moodAndSleepValue, setMoodAndSleepValue] = useState(5);
    const [moodAndSleepData, setMoodAndSleepData]: any = useState(null);
    const [medicationIntakeModal, setMedicationIntakeModal] = useState(false);
    const [medicationIntakeData, setMedicationIntakeData] = useState(null);

    const updateMedicationIntakeModal = () => {
        setMedicationIntakeModal(!medicationIntakeModal)
    }

    const notificatioNService = async (notification) => {
        const typeFound: any = await checkNotificationType(notification, navigation, Patient)
        if (typeFound.exists)
          typeFound.action();
        else {
          switch (notification.type) {
                case 'notif_medication':
                    setMedicationIntakeData(notification);
                    setMedicationIntakeModal(true);
                    break;
                case 'mood':
                    setMoodAndSleepData({
                        eventID: notification.event_id,
                        data: {
                        type: 'mood'
                        }
                    })
                    setMoodAndSleepModal('mood');
                    break;
                case 'sleep':
                    setMoodAndSleepData({
                        eventID: notification.event_id,
                        data: {
                        type: 'sleep'
                        }
                    })
                    setMoodAndSleepModal('sleep');
                    break;
                default:
                break;
            }
        }
    }

    const saveMoodAndSleep = () => {
        let obj = { ...moodAndSleepData }
        obj.data.value = (moodAndSleepValue * 0.1).toFixed(1);
    
        dispatch(addEventMoodAndSleepAction(obj));
    
        setMoodAndSleepValue(5)
        setMoodAndSleepData(null)
        setMoodAndSleepModal(null)
    }

    const moodAndSleepModalBtn = [{
        type: 'primary',
        title: t('translation:general.header.btn_save'),
        click: saveMoodAndSleep
    }]

    const medicationIntakeModalBtn = [
        {
          type: 'tertiary',
          title: t('translation:modal.notifications.edit'),
          click: () => {
            updateMedicationIntakeModal();
            navigation.navigate('AddEvent', { itemType: 'Reminder' })
          }
        },
        {
          type: 'primary',
          title: t('translation:general.header.btn_save'),
          action: 'save',
          click: (notification, taken_date) => {
            const obj = {
              eventId: notification.event_id,
              taken: true,
              ...(taken_date && { taken_date })
            }
            dispatch(updateMedicationIntake(obj));
            updateMedicationIntakeModal();
          }
        }
    ]

    return(
        <SafeAreaView style={styles.wrapper}>
            {
            medicationIntakeModal &&
                <CommonActionModal
                    testID='medicationIntakeModal'
                    modalType='intakes'
                    title={t('translation:general.info_popup.medication_types.drug.title')}
                    onClose={updateMedicationIntakeModal}
                    buttons={medicationIntakeModalBtn}
                    data={medicationIntakeData}
                />
            }
            {
            moodAndSleepModal &&
                <CommonModal
                    testID="moodAndSleepModal"
                    type={'modal'}
                    title={moodAndSleepModal === 'mood' ? t('translation:widget.daily_reminder.mood') : t('translation:widget.daily_reminder.sleep_quality')}
                    moodAndSleep={true}
                    buttons={moodAndSleepModalBtn}
                    moodAndSleepValue={setMoodAndSleepValue}
                    onClose={() => { setMoodAndSleepModal(null); setMoodAndSleepValue(5) }}
                    setValue={moodAndSleepValue}
                    route={navigation}
                />
            }
            <CommonHeader
                title={'Notifications'} 
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.migraine.color}
                leftClick={() => {navigation.goBack()}}
                testIDTitle="notifications_title"
            />
            <ScrollView style={{ flex: 1 }}>
                <View style={{ marginBottom: PR(25) }}>
                    <View style={styles.sectionHeader}>
                        <CommonNotifications action={notificatioNService} navigation={navigation} />
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}
const generateStyleSheet = (colors:any) => {
    return StyleSheet.create({
      wrapper: {
        backgroundColor: colors.background,
        flex: 1,
      },
      sectionHeader: {
        width: '90%',
        alignSelf: 'center',
        paddingVertical: PR(6),
      },
    })
}

export default Notifications;