import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import Text from '../CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

const CommonLabelForm = ({ label, isOptional }) => {
    const colors = useTheme().colors;
    const styles: any = generateStyleSheet(colors);
    const {t} = useTranslation();
    const text = isOptional ? `${label} (${t('translation:events.seizure.optional')})` : label;

    return (
        <View style={styles.container}>
            <Text style={styles.label}>{text}</Text>
        </View>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            flexDirection: 'row'
        },
        label: {
            marginBottom: PR(10),
            marginLeft: PR(20),
            color: colors.primary,
            fontSize: PR(12)
        }
    })
}

export default CommonLabelForm