import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from './CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';
import GlobalStyles from '../styles/GlobalStyles';
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonMedicationsHistoryCurrentFuture = ({med,dos,from, id, navigation}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const medDefaultReason = APP_SHORTCUT_NAME === 'helpi' ? 'epilepsy' : 'headache'
  const today = DateTime.now();
  const translate = (key, list) => {
    // @ts-ignore
    let index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
    Object.keys(itemType).toString() === key)
    if (index > -1) 
      return t('translation:'+list+'.'+index+'.'+key)
    else 
      return key
  }

  const calculateDose = () => {
    let count = 0
    let unit = ''
    dos.forEach(element => {
      count = count + element.dose
      unit = element.unit
    });
    if(count > 0)
      return count + ' ' + unit
    else
      return ''
  }

  const compareDates = (start, end) => {
    if(start) {
      const d1 = DateTime.fromFormat(start, 'yyyy-MM-dd');
      const d2 = end
      if (d2.diff(d1, 'days') > 0){
        return false
      }
      else {
        return true
      }
    }
  }
  const findTheDiffernce = (start, end) => {
    const d1 = DateTime.fromFormat(start, 'yyyy-MM-dd');
    const d2 = end
    let tempDaysDiff = d2.diff(d1,'months')
    let MathTempMonth = Math.abs(parseInt(tempDaysDiff.months))
    let MathTempYear = Math.abs(parseInt(tempDaysDiff.as('years')))
    let MathTempDay = Math.abs(parseInt(tempDaysDiff.as('days')))
    if(MathTempMonth < 1 && MathTempDay > 0)
      return t('translation:treatment.timeDiff_label', {diff: MathTempDay}) + ' ' + t('translation:modal.duration.days_label')
    else if(MathTempMonth === 1)
      return t('translation:treatment.timeDiff_label', {diff: MathTempMonth}) + ' ' + t('translation:report.globals.month')
    else if(MathTempMonth >= 12 && Math.abs(parseInt(tempDaysDiff.months)) < 24)
      return t('translation:treatment.timeDiff_label', {diff: MathTempYear}) + ' ' + t('translation:report.globals.year')
    else if(MathTempMonth >= 24)
      return t('translation:treatment.timeDiff_label', {diff: MathTempYear}) + ' ' + t('translation:report.globals.years')
    else if(MathTempDay === 0)
      return t('translation:treatment.timeDiff_label', {diff: Math.abs(parseInt(tempDaysDiff.as('hours')))}) + ' ' + t('translation:modal.duration.hours_label')
    else
      return t('translation:treatment.timeDiff_label', {diff: MathTempMonth}) + ' ' + t('translation:web_dashboard.timeline.month')
  }
  return (
    <>
      <View 
        style={[styles.container,
        {backgroundColor: med.reason === medDefaultReason ? GlobalStyles[APP_SHORTCUT_NAME].color : GlobalStyles.global.yellow}
        ]}
      >
        <TouchableOpacity onPress={()=> navigation.navigate('MedicationHistory', {id:id})} style={styles.wrapper}>
          <View style={styles.medContainer}>
            <View >
              <Text style={styles.medMomentTxt}>{med.name+' '+calculateDose()}</Text>
            </View>
            <View style={{paddingBottom:PR(5)}}>
              <Text style={styles.medTxt}>{translate(med.reason, 'list.medication_reasons')}</Text>
            </View>
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.iconContainer}>
              <Text style={[styles.medTxt]}>{t('translation:treatment.general.from')+' '+DateTime.fromISO(from).toFormat('MMM dd yyyy')}</Text>
              <Icon 
                name={"chevron-right"} 
                size={PR(20)} 
                color={"#fff"} 
              />
            </View>
          </View>
        </TouchableOpacity>
        {compareDates(from, today) &&
          <View style={[styles.medBottomContainer, {borderColor: colors.background}]}>
            <Text renderAsHtml={true} style={styles.medTxt}>{findTheDiffernce(from, today)}</Text>
          </View>
        }
      </View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      borderRadius: PR(20),
      width:'90%',
      alignSelf:'center', 
      marginBottom:PR(10),
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.10,
      shadowRadius: 13.97,
      elevation: 10,
    },
    medBottomContainer: {
      backgroundColor:GlobalStyles.global.ligthGreen, 
      // borderWidth: 1, 
      borderBottomLeftRadius: PR(20), 
      borderBottomRightRadius: PR(20), 
      padding:PR(10),
      flexDirection: 'column',
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.10,
      shadowRadius: 13.97,
      elevation: 10,
    },
    wrapper: {
      flexDirection: 'row',
      alignItems:'center'
    },
    medContainer: {
      flex:1,
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      width: '60%'
    },
    medMomentTxt: {
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium,
      color: GlobalStyles.global.white,
      paddingBottom:PR(10)
    },
    medTxt: {
      color: GlobalStyles.global.white,
      marginLeft: PR(10),
      fontSize: PR(14),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    rightContainer: {
      width:'40%',
      paddingRight:PR(10),
      alignItems:'flex-end',
    },
    iconContainer: {
      alignItems:'center',
      flexDirection: 'row'
    },
    horizontalDivider: {
      borderBottomColor: colors.background,
      borderBottomWidth: PR(1),
    }
  })
}

export default CommonMedicationsHistoryCurrentFuture;