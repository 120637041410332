import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { HeadacheTypeActions } from './headacheTypesActions';

interface HeadacheTypeReducerInterface {
    headacheTypes: Array<headacheTypeObject>
}

export interface headacheTypeObject {
    createdAt: string
    deleted: boolean
    auras: Array<string>
    id: string
    name: string
    patient_id: string
    nature: string
    remark: string | null
    start_side: string
    symptoms: Array<string>
    regions: regionsObject
    updatedAt: string
}

interface regionsObject {
    left: Array<string>
    right: Array<string>
}

const initialState:HeadacheTypeReducerInterface = {
    headacheTypes: []
};

export default function (state = initialState, action: AnyAction): HeadacheTypeReducerInterface {
    if (action.type === HeadacheTypeActions.ADD_HEADACHE_TYPE_SUCCESS){
        return {
            ...state,
            headacheTypes: [...state.headacheTypes, action.response.result]
        }
    } 
    else if(action.type === HeadacheTypeActions.UPDATE_HEADACHE_TYPE_SUCCESS){
        let findHeadacheType = state.headacheTypes.findIndex(item => item.id === action.response.result.id)
        let headacheTypeCopy = [...state.headacheTypes]
        if(findHeadacheType > -1){
            headacheTypeCopy[findHeadacheType] = JSON.parse(JSON.stringify(action.response.result))
        }
        return {
            ...state,
            headacheTypes: headacheTypeCopy
        }
    }
    else if(action.type === HeadacheTypeActions.GET_HEADACHE_TYPES_SUCCESS){
        return {
            ...state,
            headacheTypes: action.response.result
        }
    } 
    else if(action.type === HeadacheTypeActions.DELETE_HEADACHE_TYPES_SUCCESS){
        let filterHeadacheTypes = state.headacheTypes.filter(item => item.id !== action.response.result.id)
        return {
            ...state,
            headacheTypes: filterHeadacheTypes
        }
    }
    return state;
}

export const headacheTypes = (state: RootState) => state.headacheTypesReducer.headacheTypes;