import { call, takeLatest, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { 
    RescueActions, 
    AddRescueInterface,
    GetRescueInterface,
    DeleteRescueInterface,
    UpdateRescueInterface 
} from "./rescueActions";

export function* addRescueSaga(payload: AnyAction & AddRescueInterface) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientID}/rescue`,
        method: 'POST',
        responseAction: RescueActions.ADD_RESCUE_MEDICATION_SUCCESS,
        data: {
            dosage: payload.dosage,
            name: payload.name,
            unit: payload.unit
        },
        ...payload
    });
}

export function* getRescueSaga(payload: AnyAction & GetRescueInterface) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientID}/rescue`,
        method: 'GET',
        responseAction: RescueActions.GET_RESCUE_MEDICATION_SUCCESS,
        ...payload
    });
}

export function* deleteRescueSaga(payload: AnyAction & DeleteRescueInterface) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientID}/rescue/${payload.rescueID}`,
        method: 'DELETE',
        responseAction: RescueActions.DELETE_RESCUE_MEDICATION_SUCCESS,
        ...payload
    });
}

export function* updateRescueSaga(payload: AnyAction & UpdateRescueInterface) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientID}/rescue/${payload.rescueID}`,
        method: 'PUT',
        responseAction: RescueActions.UPDATE_RESCUE_MEDICATION_SUCCESS,
        data: {
            dosage: payload.dosage,
            name: payload.name,
            unit: payload.unit
        },
        ...payload
    });
}

export default function* watch() {
    yield takeLatest(RescueActions.ADD_RESCUE_MEDICATION, addRescueSaga);
    yield takeLatest(RescueActions.GET_RESCUE_MEDICATION, getRescueSaga);
    yield takeLatest(RescueActions.DELETE_RESCUE_MEDICATION, deleteRescueSaga);
    yield takeLatest(RescueActions.UPDATE_RESCUE_MEDICATION, updateRescueSaga);
}