import i18n, {
    LanguageDetectorAsyncModule,
    Services,
    InitOptions,
  } from 'i18next';
import {initReactI18next} from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as RNLocalize from 'react-native-localize';
import en from './en';
import {languages} from './languages';
import {defaultLang, defaultMMLang} from 'common/src/services/settings_service';
import {APP_SHORTCUT_NAME} from '../../env.json';

let deviceLanguage = RNLocalize.getLocales()[0].languageCode;
let getTheExistingLangList = APP_SHORTCUT_NAME === 'migraine' ? defaultMMLang(deviceLanguage) : defaultLang(deviceLanguage);
let deviceLangExistInList = getTheExistingLangList.find(item => item.selected === true)
if(!deviceLangExistInList)
  deviceLanguage = 'en'

const checkOnlineStatus = async () => {
  try {
    const online = await fetch("https://i18n.neuroventis.care/");
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};
const tranFunc = async () => {
  let isInternetReachable = await checkOnlineStatus();
  let LangCode:any = await AsyncStorage.getItem('APP_LANG');
  if(LangCode === null)
    LangCode = deviceLanguage
  const getLang = async (code) => {
    if(isInternetReachable) {
      let test = (await languages(code)).res
      return {translation:test}
    } else return en
  }

  const getLangCode = async (code) => {
    if(code === 'can-en')
      code = 'en'
    if(LangCode === 'can-fr')
      code = 'fr'
    if(LangCode === null)
      code = 'en'
    return code
  }
    
  const AVAILABLE_LANGUAGES = {
    [await getLangCode(LangCode)]: await getLang(LangCode)
  };
  const OFFLINE_AVAILABLE_LANGUAGES = {
    en
  }
  const AVALAILABLE_LANG_CODES = isInternetReachable? Object.keys(AVAILABLE_LANGUAGES) : Object.keys(OFFLINE_AVAILABLE_LANGUAGES);
  const languageDetector: LanguageDetectorAsyncModule = {
    type: 'languageDetector',
    // If this is set to true, your detect function receives a callback function that you should call with your language,
    //useful to retrieve your language stored in AsyncStorage for example
    async: true,
    init: (
      _services: Services,
      _detectorOptions: object,
      _i18nextOptions: InitOptions,
    ) => {
      /* use services and options */
    },
    detect: (callback: (lng: string) => void) => {
      AsyncStorage.getItem('APP_LANG', (err, lng) => {
        // Error fetching stored data or no language was stored
        if (err || !lng) {
          if (err) {
            console.log('Error fetching "APP_LANG" from async store', err);
          } else {
            console.log(
              'No language is set, choosing the best available or English as fallback',
            );
          }
          const bestLng = RNLocalize.findBestAvailableLanguage(
            AVALAILABLE_LANG_CODES,
          );
  
          callback(bestLng?.languageTag ?? 'en');
          return;
        }
        isInternetReachable ? callback(lng) : callback('en');
      });
    },
    cacheUserLanguage: (lng: string) => {
      if(LangCode !== lng)
        tranFunc()
      AsyncStorage.setItem('APP_LANG', lng);
      AsyncStorage.removeItem(lng);
    },
  };
  
  i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: isInternetReachable ? AVAILABLE_LANGUAGES : OFFLINE_AVAILABLE_LANGUAGES,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'common',
    fallbackLng: await getLangCode(LangCode),
    initImmediate: false
  });
}
tranFunc();