import React,{useState, useRef, useEffect} from 'react';
import {View, SafeAreaView, ScrollView, Dimensions, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CommonImage from 'common/src/components/CommonImage';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import {convertLangCode} from 'common/src/services/translationList';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const { width, height } = Dimensions.get('window');

const Tutorial = (props:any) => {
  const {colors} = useTheme();
  const scrollRef = useRef();
  const {t, i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  const [sliderState, setSliderState] = useState({ currentPage: 0 });
  const [tutorialAlreadyDone, setTutorialAlreadyDone] = useState<boolean>(false)

  useEffect(() => {
    AsyncStorage.getItem('tutorialIsDone')
      .then((value:any) => {
        if (value === 'true') {
          setTutorialAlreadyDone(true);
        }
      });
  },[])

  //  this function is called when we scroll from one slide to another
  const setSliderPage = (event: any) => {
    const { currentPage } = sliderState;
    const { x } = event.nativeEvent.contentOffset;
    let indexOfNextScreen = 0
    if (Platform.OS === 'web' && width > 450) {
      indexOfNextScreen = Math.ceil(x / 650);
    } else {
      indexOfNextScreen = Math.ceil(x / width);
    }
    if (indexOfNextScreen !== currentPage) {
      setSliderState({
        ...sliderState,
        currentPage: indexOfNextScreen,
      });
    }
  };
  // this function send us to the welcome screen when we finish with the tutorial 
  // and reset the history in order to don't go back to the tutorial again
  const setTutorialDone = () => {
    if(props.route.params.param && props.route.params.param !== 'false' || tutorialAlreadyDone) {
      props.navigation.navigate('Settings');
    } else {
      AsyncStorage.setItem('tutorialIsDone', 'true');
      props.navigation.reset({
        index: 0,
        routes: [{ name: 'Welcome' }],
      });
    }
  }
  const { currentPage: pageIndex } = sliderState;
  return(
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.background }}>
      {/* calling the commonHeader with the right props according to the state */}
      {
        sliderState.currentPage === 2 ?
          <CommonHeader 
            title={t('translation:general.page_title.tutorial')} 
            rightIconNameSecond='arrow-right-circle'
            rightIconColorSecond={GlobalStyles.migraine.color}
            rightIconSize={PR(35)}
            rightClickSecond={setTutorialDone} 
            testIDRightSecond="Lets go" 
            disableFormsBanner={true}
          />
          :
          <CommonHeader 
            title={t('translation:general.page_title.tutorial')} 
            disableFormsBanner={true} 
          />
      }
      <ScrollView
        // @ts-ignore
        ref={scrollRef}
        testID="scroll"
        style={{flex: 1 }}
        horizontal={true}
        scrollEventThrottle={16}
        pagingEnabled={true}
        showsHorizontalScrollIndicator={false}
        onScroll={(event: any) => {
          setSliderPage(event);
        }}
      >
        <View style={styles.container}>
          {lang === 'en' ?
            <CommonImage name={'migraineTut1'} height={height * 0.7} width={Platform.OS === 'web'? 500 : width}/>
            :
            <CommonImage uri={'https://epione-files.s3.eu-central-1.amazonaws.com/tutorial_migraine_pics/tuto'+lang[0].toUpperCase()+ lang.substr(1).toLowerCase()+'1@2x.png'} name={'migraineTut1'} height={height*0.70} width={Platform.OS === 'web'? 500 : width}/>
          }
        </View>
        <View style={styles.container}>
          {lang === 'en' ?
            <CommonImage name={'migraineTut2'} height={height*0.80} width={Platform.OS === 'web'? 500 : width}/>
            :
            <CommonImage uri={'https://epione-files.s3.eu-central-1.amazonaws.com/tutorial_migraine_pics/tuto'+lang[0].toUpperCase()+ lang.substr(1).toLowerCase()+'2@2x.png'} name={'migraineTut2'} height={height*0.80} width={Platform.OS === 'web'? 500 : width}/>
          }
        </View>
        <View style={styles.container}>
          {lang === 'en' ?
            <CommonImage name={'migraineTut3'} height={height*0.68} width={Platform.OS === 'web'? 500 : width}/>
            :
            <CommonImage uri={'https://epione-files.s3.eu-central-1.amazonaws.com/tutorial_migraine_pics/tuto'+lang[0].toUpperCase()+ lang.substr(1).toLowerCase()+'3@2x.png'} name={'migraineTut3'} height={height*0.70} width={Platform.OS === 'web'? 500 : width}/>
          }
        </View>
      </ScrollView>
      <View style={styles.paginationWrapper}>
        {Array.from(Array(3).keys()).map((key, index) => (
          // @ts-ignore
          <TouchableOpacity key={index} onPress={()=> scrollRef.current?.scrollTo({x: index*(Platform.OS === 'web' && width > 450 ? 650 : width)})}><View style={[styles.paginationDots, { opacity: pageIndex === index ? 1 : 0.2 }]} key={index} /></TouchableOpacity>
        ))}
      </View>
    </SafeAreaView>
  )
};

const styles = StyleSheet.create({
  container: {
    width: Platform.OS === 'web' && width > 450 ? 650 : width,
    // marginTop: 5
  },
  paginationWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  paginationDots: {
    marginBottom: PR(70),
    height: PR(10),
    width: PR(10),
    borderRadius: PR(10 / 2),
    backgroundColor: GlobalStyles.migraine.color,
    marginLeft: PR(10),
  },
});


export default Tutorial;