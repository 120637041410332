import * as types from '../actions';
import moment from 'moment';
import {DELETE_EVENT_SUCCESS, RESET_EVENTS_SUCCESS, UPDATE_EVENT_SUCCESS, GET_IMPACT_EVENTS_SUCCESS, GET_TRIGGERS_EVENTS_SUCCESS, GET_AURAS_EVENTS_SUCCESS} from "../events/eventActions";
import { EventSet, GenericRecord } from 'common/src/types';
import { ADD_PROGRAM_ACTION_SUCCESS, START_PROGRAM_ACTION_SUCCESS } from '../programs/programsActions';

const getIndexesInEventSet = (event: GenericRecord, eventsSet: EventSet[]): [number, number] => {
  let indexInSet = -1;
  if (validateDateFormat(eventsSet[0].date, 'yyyy-MM-DD'))
    indexInSet = eventsSet.findIndex(events => events.date === moment(event.date).format('yyyy-MM-DD'));
  else
    indexInSet = eventsSet.findIndex(events => events.date === moment(event.date).format('yyyy-MM'));
  if (indexInSet === -1) {
    return [-1, -1];
  }

  const eventsOfSameType: GenericRecord[] = eventsSet[indexInSet][`${event.type}s`];
  if (eventsOfSameType.length === 0) {
    return [-1, -1];
  }

  const indexToReplace = eventsOfSameType.findIndex(e => (event.id || event._id) === (e.id || e._id));
  if (indexToReplace === -1) {
    return [-1, -1];
  }

  return [indexInSet, indexToReplace];
}

const replaceInEventsSet = (event: GenericRecord, eventsSet: EventSet[]) => {
  const [indexInSet, indexToReplace] = getIndexesInEventSet(event, eventsSet);
  if (indexInSet === -1 || indexToReplace === -1) {
    return eventsSet;
  }
  
  eventsSet[indexInSet][`${event.type}s`][indexToReplace] = event;
  return eventsSet;
}

const deleteInEventsSet = (event: GenericRecord, eventsSet: EventSet[]) => {
  const [indexInSet, indexToReplace] = getIndexesInEventSet(event, eventsSet);
  if (indexInSet === -1 || indexToReplace === -1) {
    return eventsSet;
  }

  const eventsOfSameType: GenericRecord[] = eventsSet[indexInSet][`${event.type}s`];
  eventsSet[indexInSet][`${event.type}s`] = eventsOfSameType.filter(e => (event.id || event._id) !== (e.id || e._id));
  return eventsSet;
};

const validateDateFormat = (date, format) => {
  return moment(date, format, true).isValid()
}

export default function(state:any = {
  events: [],
  timeline_events: [],
  impact_seizure_events: [],
  impact_headache_events: [],
  impact_triggers_events: [],
  impact_auras_events: [],
  impact_mood_events: [],
  impact_sleep_events: [],
  impact_adherence_events: []
} , action:any = '') {
  

  const response = action.response;
  switch(action.type) {
    case types.GET_EVENTS_SUCCESS:
      if(state.events.length === 0) {
        if(response.result){
          let sortedEvents = response.result
          sortedEvents.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
          return { 
            ...state, 
            events: sortedEvents
          };
        } else {
          return {
            ...state
          }
        }
      }
      else {
        if(response.result){
          let newEvents = [...state.events, ...response.result]
          newEvents.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
          if(response.prevNext === 'prev' && newEvents.length >= 21)
            newEvents.splice(21, newEvents.length)
          else if(response.prevNext === 'next' && newEvents.length >= 21)
            newEvents.splice(0, 7)
          return { 
            ...state, 
            events: newEvents
          };
        } else {
          return {
            ...state
          }
        }
      }
    case types.GET_NIGHTWATCH_EVENTS_SUCCESS:
      let nightWatchDate = state.events.findIndex(item => item.date === moment(response.result[0].date).format('yyyy-MM-DD'))
      if(nightWatchDate > -1) {
        state.events[nightWatchDate] = response.result[0]
      }
      let UpdatedWithNW = [...state.events]
      return {
        ...state, 
        events: UpdatedWithNW
      }
    case GET_IMPACT_EVENTS_SUCCESS:
      const {result: impactEvent } = response
      if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'seizure'){
        if(state.impact_seizure_events.length === 0) {
          return { 
            ...state, 
            impact_seizure_events: impactEvent
          };
        } else {
          let newImpactSeizureEvents = [...state.impact_seizure_events]
          impactEvent.forEach(seizure => {
            if(newImpactSeizureEvents.filter(item => item.id === seizure.id).length === 0)
              newImpactSeizureEvents.push(seizure)
          })
          return { 
            ...state, 
            impact_seizure_events: newImpactSeizureEvents
          };
        }
      }
      else if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'headache'){
        if(state.impact_headache_events.length === 0) {
          return { 
            ...state, 
            impact_headache_events: impactEvent
          };
        } else {
          let newImpactHeadacheEvents = [...state.impact_headache_events]
          impactEvent.forEach(seizure => {
            if(newImpactHeadacheEvents.filter(item => item.id === seizure.id).length === 0)
              newImpactHeadacheEvents.push(seizure)
          })
          return { 
            ...state, 
            impact_headache_events: newImpactHeadacheEvents
          };
        }
      }
      else if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'mood'){
        if(state.impact_mood_events.length === 0) {
          return { 
            ...state, 
            impact_mood_events: impactEvent
          };
        } else {
          let newImpactMoodEvents = [...state.impact_mood_events]
          impactEvent.forEach(mood => {
            if(newImpactMoodEvents.filter(item => item.id === mood.id).length === 0)
              newImpactMoodEvents.push(mood)
          })
          return { 
            ...state, 
            impact_mood_events: newImpactMoodEvents
          };
        } 
      }
      else if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'sleep'){
        if(state.impact_sleep_events.length === 0) {
          return { 
            ...state, 
            impact_sleep_events: impactEvent
          };
        } else {
          let newImpactSleepEvents = [...state.impact_sleep_events]
          impactEvent.forEach(sleep => {
            if(newImpactSleepEvents.filter(item => item.id === sleep.id).length === 0)
              newImpactSleepEvents.push(sleep)
          })
          return { 
            ...state, 
            impact_sleep_events: newImpactSleepEvents
          };
        } 
      }
      else if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'reminder'){
        if(state.impact_adherence_events.length === 0) {
          return { 
            ...state, 
            impact_adherence_events: impactEvent
          };
        } else {
          let newImpactAdherenceEvents = [...state.impact_adherence_events]
          impactEvent.forEach(adh => {
            if(newImpactAdherenceEvents.filter(item => item.id === adh.id).length === 0)
              newImpactAdherenceEvents.push(adh)
          })
          return { 
            ...state, 
            impact_adherence_events: newImpactAdherenceEvents
          };
        } 
      } else return; // TODO: extend the condition for the mood and sleep for the impact
    case GET_TRIGGERS_EVENTS_SUCCESS:
      const {result: triggersEvents } = response;
      if(triggersEvents && triggersEvents.length > 0){
        if(state.impact_triggers_events.length === 0) {
          return { 
            ...state, 
            impact_triggers_events: triggersEvents
          };
        } else {
          let newImpactTriggersEvents = [...state.impact_triggers_events]
          triggersEvents.forEach(seizure => {
            if(newImpactTriggersEvents.filter(item => item.id === seizure.id).length === 0)
            newImpactTriggersEvents.push(seizure)
          })
          return { 
            ...state, 
            impact_triggers_events: newImpactTriggersEvents
          };
        }
      } else return;
    
    case GET_AURAS_EVENTS_SUCCESS:
      const {result: aurasEvents } = response;
      if(aurasEvents && Object.keys(aurasEvents).length > 0) {
        if(state.impact_auras_events.length === 0) {
          const aurasEventsArr = [aurasEvents];
          
          return {
            ...state,
            impact_auras_events: aurasEventsArr
          };

        } else {
          let newImpactAurasevents = [...state.impact_auras_events]
          newImpactAurasevents.push(aurasEvents);

          return {
            ...state,
            impact_auras_events: newImpactAurasevents
          };
        }
      } else return;
      
    case types.ADD_EVENT_SUCCESS: 
      const { result: addedEvent } = response;
      const dataSent = response.dataSent
      const stateEvents = [...state.events];
      const stateTimelineEvents = [...state.timeline_events];
      const addedEventDate = moment(addedEvent.date).format('YYYY-MM-DD');
      const dateFound = stateEvents.find(item => item.date === addedEventDate);
      if (!dateFound && addedEvent.type !== 'seizure' && addedEvent.type !== "headache")
        return {...state}
      if (dateFound) {
        dateFound.events.push(addedEvent)
        if (dateFound.summary[addedEvent.type])
          dateFound.summary[addedEvent.type].count++
        else
          dateFound.summary[addedEvent.type] = {
            count: 1
          }
      }
      if (addedEvent.type !== 'seizure' && addedEvent.type !== "headache")
        return {...state, events: stateEvents}
      const timelineDateFound = stateTimelineEvents.find(item => item.date === addedEventDate);
      if (!timelineDateFound)
        return {...state, events: stateEvents}
      const seizureTypeFound = timelineDateFound.seizureTypes.find(item => item.id === addedEvent.seizure_type_id)
      if (seizureTypeFound)
        seizureTypeFound.count++;
      else {
        timelineDateFound.seizureTypes.push({
          type: addedEvent.seizure_type,
          id: addedEvent.seizure_type_id,
          count: 1
        })
      }
      return {...state, events: stateEvents, timeline_events: [], impact_seizure_events: [], impact_triggers_events: [], impact_auras_events: [], impact_headache_events: []}

      
    case types.GET_TIMELINE_EVENTS_SUCCESS:
      return { 
        ...state, 
        timeline_events: response.result.reverse()
      };
    case types.ADD_EVENT_MOOD_AND_SLEEP_SUCCESS:
      const addedMoodSleep = response.result
      const _msEventStored =  [...state.events]
      const _msDateFound = _msEventStored.find((item) => item.date === addedMoodSleep.date)

      if (!_msDateFound)
        return {...state, impact_mood_events: [], impact_sleep_events: []}
    
      const _moodSleepEventFound = _msDateFound.events.find(item => item.type === addedMoodSleep.type)
      if (_moodSleepEventFound)
        return {...state, impact_mood_events: [], impact_sleep_events: []}

      _msDateFound.events.push(addedMoodSleep); 
      
      return {...state, events: _msEventStored, impact_mood_events: [], impact_sleep_events: []}
    case types.UPDATE_MOOD_AND_SLEEP_SUCCESS:
      const updatedMoodSleep = response.result
      const msEventStored =  [...state.events]
      const msDateFound = msEventStored.find((item) => item.date === updatedMoodSleep.date)
      
      if (!msDateFound)
        return {...state, impact_mood_events: [], impact_sleep_events: []}
      
      const moodSleepEventFound = msDateFound.events.find(item => item.type === updatedMoodSleep.type)
      if (!moodSleepEventFound || moodSleepEventFound.id !== updatedMoodSleep.id)
        return {...state, impact_mood_events: [], impact_sleep_events: []}
      
      moodSleepEventFound.value = updatedMoodSleep.value
      return {...state, events: msEventStored, impact_mood_events: [], impact_sleep_events: []}
    // Triggered when add treatment
    case types.UPDATE_DIARY_MEDICATION_SUCCESS: 
      if(response.updateMedData){
        let updateNameMed = [...state.events]
        let findAllDays = state.events.map((item,i) => {
          if(moment(item.date) >= moment(response.medData.intake.from) && moment(item.date).format('yyyy-MM-DD') <= moment().format('yyyy-MM-DD') )
            return i
          else 
            return null
        }).filter(i => i !== null)
        if(findAllDays.length > -1 ){
          findAllDays.forEach(day => {
            const findReminders = updateNameMed[day].reminders.map((item, i) => {
              if(item.medication.id === response.medData.id)
                return i
              else 
                return null
            }).filter(i => i !== null)
            if(findReminders.length > -1){
              findReminders.forEach(reminder =>{
                updateNameMed[day].reminders[reminder].medication.name = response.medData.name
                updateNameMed[day].reminders[reminder].reminder.name = response.medData.name
              })
            }
          })
        }
        return {...state, events: updateNameMed}
      } else {
        let findFromDay = state.events.findIndex(item => item.date === moment(response.result[0].date).format('yyyy-MM-DD'))
        if(findFromDay > -1 ){
          state.events[findFromDay] = response.result[0]
        }
        let updateMed = [...state.events]
        return {...state, events: updateMed}
      }
    case types.UPDATE_MEDICATION_INTAKE_SUCCESS:
      const {result: medicationEventUpdated} = response;
      const _eventsCpy = [...state.events];
      const _timelineEventsCpy = [...state.timeline_events];
      const medicationDateFound = _eventsCpy.find(item => item.date === moment(medicationEventUpdated.date).format('YYYY-MM-DD'));
      const timelineMedicationDateFound = _timelineEventsCpy.find(item => item.date === moment(medicationEventUpdated.date).format('YYYY-MM-DD'))
      if (medicationDateFound) {
        const medicationEventToUpdate = medicationDateFound.events.find(item => item.id === medicationEventUpdated.id)

        if (medicationEventToUpdate) {
          medicationEventToUpdate.taken_date =  medicationEventUpdated.taken_date
          if (medicationEventToUpdate.taken !== medicationEventUpdated.taken) {
            const updateOnTakenReminder = !medicationEventToUpdate.taken && medicationEventUpdated.taken ? 1 : -1
            medicationEventToUpdate.taken =  medicationEventUpdated.taken
            medicationDateFound.summary.reminder.taken += updateOnTakenReminder
          }
        }
      }
      if (timelineMedicationDateFound) {
        const timelineMedicationEventToUpdate = timelineMedicationDateFound.reminders.find(item => item.id === medicationEventUpdated.id)

        if (timelineMedicationEventToUpdate) {
          timelineMedicationEventToUpdate.taken =  medicationEventUpdated.taken
          timelineMedicationEventToUpdate.taken_date =  medicationEventUpdated.taken_date
        }
      }

      return {...state, events: _eventsCpy, timeline_events: _timelineEventsCpy, impact_adherence_events: []}
    case RESET_EVENTS_SUCCESS:
      return {...state, events: []}
    case UPDATE_EVENT_SUCCESS:
      const { result: event } = response;
      let storedEvents = [...state.events];
      const updatedEventDate = moment(event.date).format('YYYY-MM-DD');
      const dateSent = moment(response.dataSent.old_date).format('YYYY-MM-DD');

      if (updatedEventDate === dateSent) {
        const dateFound = storedEvents.find(item => item.date === updatedEventDate);
        if (!dateFound)
          return {...state}
        const eventIndexFound = dateFound.events.findIndex(item => item.id === event.id)
        if (eventIndexFound === -1)
          return {...state}              
        dateFound.events[eventIndexFound] = event;
      }
      else {
        const dateFound = storedEvents.find(item => item.date === updatedEventDate);
        if (dateFound) {
          dateFound.events.push(event)
          if (dateFound.summary[event.type])
            dateFound.summary[event.type].count++;
          else
            dateFound.summary[event.type] = {
              count: 1
            }
        }
        const dateToRemoveEventFound = storedEvents.find(item => item.date === dateSent);
        if (dateToRemoveEventFound) {
          const eventToRemoveIndex = dateToRemoveEventFound.events.findIndex(item => item.id === event.id)
          dateToRemoveEventFound.events.splice(eventToRemoveIndex, 1);
          if (dateToRemoveEventFound.summary[event.type]) {
            if (dateToRemoveEventFound.summary[event.type].count === 1)
              delete dateToRemoveEventFound.summary[event.type];
            else
              dateToRemoveEventFound.summary[event.type].count--;
          }
        }
      }
      if (event.type === 'seizure' || event.type === "headache")
        return {...state, events: storedEvents, timeline_events: [], impact_seizure_events: [], impact_triggers_events: [], impact_auras_events: [], impact_headache_events: []}
      return { ...state, events: storedEvents}
    case START_PROGRAM_ACTION_SUCCESS:
      const { result: actionEvent } = response;
      if (actionEvent.type === "mood" || actionEvent.type === "sleep")
        return {...state}
      const findActionDate = state.events.findIndex(item => item.date === moment(actionEvent.date).format('yyyy-MM-DD'))
      if (findActionDate === -1)
        return {...state}

      const resultActionType = actionEvent.type+'s'
      // update the schedule date
      if( response.dataSent.old_date && (moment(actionEvent.date).format('yyyy-MM-DD') !== moment(response.dataSent.old_date).format('yyyy-MM-DD'))){
        let eventsCpyUpdate = [...state.events];
        let CopyEvent = {...actionEvent}
        CopyEvent.date = response.dataSent.old_date

        deleteInEventsSet(CopyEvent, state.events as EventSet[]);

        if(findActionDate > -1) {
          eventsCpyUpdate[findActionDate][resultActionType].push(actionEvent)
        }
        return { ...state, events: eventsCpyUpdate}
      } else { // update the action event for the day that already exist
        let findAction: number = -1
        let eventsCpyAction = [...state.events];
        if (findActionDate > -1)
          findAction = eventsCpyAction[findActionDate][resultActionType].findIndex(item => (item._id || item.id) === (actionEvent._id || actionEvent.id))

        if (actionEvent.actionData && actionEvent.actionData.type === 'habit') {
          let shouldNotUpdate = false
          for (let i = 0; eventsCpyAction[i]; i++) {
            const date = eventsCpyAction[i];
            if (date.program_actions.length === 0)
              break;
            for (let j = 0; date.program_actions[j]; j++) {
              const event = date.program_actions[j];
              
              if (event.action === actionEvent.action) {
                if (event.isActionCompleted === actionEvent.isActionCompleted) {
                  shouldNotUpdate = true
                  break;
                }
                else
                  event.isActionCompleted = actionEvent.isActionCompleted
              }
            }
            if (shouldNotUpdate)
              break;
          }
        }

        if(findAction > -1) 
          eventsCpyAction[findActionDate][resultActionType][findAction] = actionEvent
        else
          eventsCpyAction[findActionDate][resultActionType].push(actionEvent)

        return { ...state, events: eventsCpyAction}
      }
    case DELETE_EVENT_SUCCESS:
      const { result: eventToDelete } = response;
      let _stateEvents = [...state.events];
      const deletedEventDate = moment(eventToDelete.date).format('YYYY-MM-DD');
      const _dateFound = _stateEvents.find(item => item.date === deletedEventDate);
      if (!_dateFound)
        return {...state}
      const eventToDeleteIndex = _dateFound.events.findIndex(item => item.id === eventToDelete.id)
      if (eventToDeleteIndex === -1)
        return {...state}
      _dateFound.events.splice(eventToDeleteIndex, 1)
      if (_dateFound.summary[eventToDelete.type]) {
        if (_dateFound.summary[eventToDelete.type].count > 1)
          _dateFound.summary[eventToDelete.type].count--
        else
          delete _dateFound.summary[eventToDelete.type]
      }
      if (eventToDelete.type === 'seizure' || eventToDelete.type === "headache"){
        return {...state, events: _stateEvents, timeline_events: [], impact_seizure_events: [], impact_triggers_events: [], impact_auras_events: [], impact_headache_events: []}
      }
      return {...state, events: _stateEvents}


      // let removedEvents = deleteInEventsSet(eventToDelete, state.events as EventSet[]);
      // let eventsInTimeline = [...state.timeline_events];

      // if (eventToDelete.type === 'headache') {
      //   eventsInTimeline = deleteInEventsSet(eventToDelete, eventsInTimeline);
      //   return {...state, timeline_events: [...eventsInTimeline]}
      // }
      // return { ...state, events: [...removedEvents], timeline_events: [...eventsInTimeline], impact_seizure_events: [], impact_triggers_events: [], impact_auras_events: []};
    case types.UPDATE_NIGHTWATCH_EVENT_SUCCESS:
      // Nightwatch update event endpoint does not return the updated event
      const updatedNightwatchEvent = response.dataSent.report;
      return { 
        ...state,
        events: [...replaceInEventsSet(updatedNightwatchEvent, state.events as EventSet[])]
      };
    case ADD_PROGRAM_ACTION_SUCCESS:
      const { result: patientProgram, dataSent: data } = response;
      const action = data.action

      if (!action || !action.id) {
        return state
      }

      const actionFound = patientProgram.actions.find(item => item.id === action.id)
      if (!actionFound) {
        const eventsCopy = [...state.events]
        eventsCopy.forEach((day) => {
          day.program_actions.forEach((event) => {
            if (event.action === action.id && !event.isFromDeletedAction)
              event.isFromDeletedAction = true
          })
        })
        return {...state, events: eventsCopy}
      }
      return state
    case types.LOGIN_TO_NIGHTWATCH_SUCCESS: 
      return {...state, events: []}
    default:
      return state;
  }
}