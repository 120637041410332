import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, TouchableOpacity, View, ScrollView, Platform } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from 'common/src/components/CommonHeader';
import { useTranslation } from 'react-i18next';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { APP_SHORTCUT_NAME } from '../env.json';
import Icon from 'react-native-vector-icons/Feather'
import CommonStudyOverview from 'common/src/components/CommonStudyOverview'
import { updatePatientSiteAction, addEventAction, getPatientSitesAction } from 'common/src/store/actions/authenticationActions';
import { updateEvent } from 'common/src/store/events/eventActions';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import Loading from 'common/src/components/Loading';

const Studies = ({ navigation }) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Patient = useSelector((state: any) => state.getMe.patient);
    const [sites, setSites] = useState([] as any);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(getPatientSitesAction({
            patientId: Patient.id, callback: (res) => {
                populateStudies(res)
                setIsLoading(false)
            }
        }))
    }, [])

    const populateStudies = (patientSites) => {
        let patientStudies: any = []
        patientSites.map((site: any) => {
            const patientData = site.patients[0];
            patientStudies.push({
                patientId: Patient.id,
                siteName: site.name,
                siteId: site._id,
                study: site.study,
                patientSiteData: patientData,
                contactEmail: site.contactEmail,
                contactBlock: site.contacts
            })
        })
        setSites(patientStudies);
    }

    const updatePatientSite = (data) => {
        dispatch(updatePatientSiteAction(data))
    }

    const NavigateToStudy = (site) => {
        navigation.navigate('StudyOverview', { study: site.study[0], patientId: site.patientId, siteId: site.siteId, patientSiteData: site.patientSiteData, updatePatientSite: updatePatientSite, contactEmail: site.contactEmail, contactBlock: site.contactBlock })
    }

    if (isLoading)
        return <Loading netConnected={true} />

    else if (sites.length === 1) {
        return (<CommonStudyOverview study={sites[0].study[0]} patientId={sites[0].patientId} navigation={navigation} siteId={sites[0].siteId} patientSiteData={sites[0].patientSiteData} updatePatientSite={updatePatientSite} contactEmail={sites[0].contactEmail} contactBlock={sites[0].contactBlock} />)
    }
    else
        return (
            <>
                <View style={styles.container}>
                    <CommonHeader 
                        title={t('translation:general.page_title.studies')} 
                        leftIconName="arrow-left"
                        leftIconColor={GlobalStyles.migraine.color}
                        leftClick={() => {navigation.goBack()}}
                    />
                    <View style={{ marginBottom: PR(25) }}>
                        <View style={styles.sectionHeader}>
                            <Text style={styles.sectionHeaderText}>
                                {t('translation:studies.studiesHome.general.title')}
                            </Text>
                        </View>
                    </View>
                    {
                        sites.length > 1 &&
                        <ScrollView style={{ marginBottom: Platform.OS === 'web' ? PR(35) : 0 }}>
                            {
                                sites.map((site) => (
                                    <TouchableOpacity style={styles.siteContainer} onPress={() => NavigateToStudy(site)}>
                                        <View style={styles.textContainer}>
                                            <Text style={styles.title}>{site.siteName}</Text>
                                            <Text style={styles.subtitle}>{site.study[0].name.en}</Text>
                                        </View>
                                        <View style={styles.iconContainer}>
                                            <Icon name="chevron-right" size={PR(25)} color={GlobalStyles.global.white} />
                                        </View>
                                    </TouchableOpacity>
                                ))
                            }
                        </ScrollView>
                    }
                </View>
            </>
        )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: colors.background
        },
        sectionHeader: {
            width: '90%',
            alignSelf: 'center',
            paddingVertical: PR(6),
        },
        sectionHeaderText: {
            color: colors.primary,
            fontSize: PR(20),
            fontFamily: GlobalStyles.global.fontFamily.Bold,
            letterSpacing: 0,
            marginBottom: PR(15)
        },
        siteContainer: {
            width: '90%',
            borderRadius: PR(10),
            backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].lightBackgroundColor,
            marginBottom: PR(20),
            alignSelf: 'center',
            flexDirection: 'row'
        },
        textContainer: {
            padding: PR(20),
            flexDirection: 'column',
            flexGrow: 0.9,
            flex: 1
        },
        iconContainer: {
            justifyContent: 'center',
            alignContent: 'center',
            flex: 1,
            flexGrow: 0.1
        },
        title: {
            color: GlobalStyles.global.white,
            fontFamily: GlobalStyles.global.fontFamily.Bold,
            fontSize: PR(18),
            marginBottom: PR(5)
        },
        subtitle: {
            color: GlobalStyles.global.white
        }
    })
}

export default Studies