import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { headacheTypeObject } from 'common/src/store/headacheTypes/headacheTypesReducer';

interface OwnProps {
    list: Array<list>
    clicked: (res:headacheTypeObject) => void
}

interface list {
    name: string,
    nature: string,
    regionLeft?: string,
    regionRight?: string,
    value: headacheTypeObject
}

const HeadacheTypesListBtn = ({list, clicked}: OwnProps) => {
  const colors = useTheme().colors;
  const styles: any = generateStyleSheet(colors);

  const clickedBtn = (res:headacheTypeObject) => {
    clicked(res)
  }
  return (
    <>
      {list.map((item, key) => {
        return (
          <TouchableOpacity testID={`headache-types-item-${item.name}`} style={styles.item} key={key} onPress={() => {
            // TODO redirect to account page
            clickedBtn(item.value)
          }}>
            <View style={styles.itemColumn}>
                <View style={styles.innerItem}>
                    <Text numberOfLines={1} style={[styles.itemText, {color: GlobalStyles.migraine.color}]}>
                        {item.name}
                    </Text>
                    <Text numberOfLines={1} style={[styles.itemText, {paddingLeft: PR(2), paddingRight: PR(2)}]}>
                        - 
                    </Text>
                    <Text numberOfLines={1} style={[styles.itemText, {color: GlobalStyles.global.grey}]}>
                        {item.nature}
                    </Text>
                </View>
                {item.regionLeft &&
                    <Text numberOfLines={1} style={styles.itemText}>
                        {item.regionLeft}
                    </Text>
                }
                {item.regionRight &&
                    <Text numberOfLines={1} style={styles.itemText}>
                        {item.regionRight}
                    </Text>
                }
            </View>
            <Icon name={"chevron-right"} size={PR(25)} color={colors.primary} style={{opacity:0.5}} />
          </TouchableOpacity>
        )
      })}
    </>
  );
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    item: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: 'center'
    },
    innerItem: {
        paddingVertical: PR(5),
        display: "flex",
        flexDirection: "row",
    },
    itemColumn: {
        paddingVertical: PR(10),
        alignSelf: 'center',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: '90%'
    },
    itemText: {
      fontSize: PR(18),
      letterSpacing: 0,
      color: colors.primary
    },
  });
}

export default HeadacheTypesListBtn;