import React, {useState, useEffect} from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonTitle from '../CommonTitle';
import CommonActionModal from '../CommonActionModal';
import CommonBtn from '../CommonBtn';
import CommonDateInput from '../CommonDateInput';
import CommonModal from '../CommonModal';
import InputField from '../InputField';
import Text from '../CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from '../../styles/GlobalStyles';
import CommonFooter from '../CommonFooter';
import {compareDates} from 'common/src/services/compare';
import { useTranslation } from 'react-i18next';
import {translate, convertLangCode} from '../../services/translationList';
import { DateTime } from "luxon";
import moment from 'moment';
import {checkBottomScroll} from '../../services/utils';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

interface EditReviewTypes {
  data:any,
  doRequest: any,
  back:any,
  deleteData:any,
  newDose?: boolean,
  oldWarningData?: any,
  medData?: any
}

const CommonEditReview = ({data, doRequest, back, deleteData, newDose, oldWarningData, medData}: EditReviewTypes) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t, i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [reasonTreat, ] = useState<string>(data?.reason || '')
  const [typeTreat, ] = useState<string>(data?.treatment_type || '')
  const [typeIntake, ] = useState<string>(data?.intake_type || '')
  const [nameOfTreat,] = useState<string>(data?.name || '')
  const [startDate, setStartDate] = useState<string | null>()
  const [frequencyIntake, setFrequencyIntake] = useState<string>()
  const [endDate, setEndDate] = useState<string | null>()
  const [times, setTimes] = useState<number>()
  const [remarks, setRemarks] = useState<string>(data?.intake?.remarks)
  const [weekModal, setWeekModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [weekDays, setWeekDays] = useState<Array<any>>(data?.week_frequency || []);
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const dosageExist = data?.intake?.dosage && data?.intake?.dosage !== [] ? data?.intake?.dosage : null;
  let tempUnitArr:any = []

  useEffect(() => {
    if(data?.intake?.specific_dosage?.period) {
      setFrequencyIntake(t('translation:treatment.add_medication.step_frequency.custom.timesPer')+' '+data?.intake?.specific_dosage?.every+' '+translate(t,data?.intake?.specific_dosage?.period, 'list.periodFrequencies')+' '+data?.intake?.specific_dosage?.dose+' '+data?.intake?.specific_dosage?.unit)
      setTimes(1)
    } 
  },[])

  useEffect(() => {
    if(moment(data?.intake?.from, 'DD-MM-yyyy').format('DD-MM-yyyy') === data?.intake?.from) {
      setStartDate(data?.intake?.from ? data?.intake?.from : null)
      setEndDate(data?.intake?.to ? data?.intake?.to : null)
    } else {
      setStartDate(data?.intake?.from ? moment(data?.intake?.from).format('DD-MM-yyyy') : null)
      setEndDate(data?.intake?.to ? moment(data?.intake?.to).format('DD-MM-yyyy') : null)
    }
  },[data])
  const booleanDaysArr = (weekArr) => {
    let daysBooleanArr: Array<boolean> = []
    let arr = [0,1,2,3,4,5,6]
    arr.forEach((item) => {
      if(weekArr.find(day => day.pos === item)) daysBooleanArr.push(true)
      else daysBooleanArr.push(false)
    })
    return daysBooleanArr
  }

  const goBack = (freq) => {
    let obj
    if(['daily', 'weekly'].indexOf(freq) >= 0)
      obj = {
        frequency: freq,
        dosage: data?.intake?.dosage,
        week_frequency: weekDays,
        typeTreat: typeTreat
      }
    else if(['monthly', 'quarterly'].indexOf(freq) >= 0)
      obj = {
        frequency: freq,
        MQDose: data?.intake?.specific_dosage?.dose.toString(),
        MQUnit: data?.intake?.specific_dosage?.unit,
        period: 'monthly',
        typeTreat: typeTreat
      }
    else if(freq === 'newDose'){
      obj = {
        goback:'back',
        typeTreat: typeTreat,
        frequency: data?.frequency,
        from: oldWarningData?.from,
        dose: oldWarningData?.dose
      }
    }
    else
      obj = {
        frequency: freq,
        MQDose: data?.intake?.specific_dosage?.dose.toString(),
        MQUnit: data?.intake?.specific_dosage?.unit,
        every: data?.intake?.specific_dosage?.every.toString(),
        otherPeriod:data?.intake?.specific_dosage?.period,
        period: 'monthly',
        timeline: data?.intake?.specific_dosage?.period,
        typeTreat: typeTreat
      }
    back(obj)
  }

  // update week day selection
  const updateWeekDays = (values) =>{
    let arr:any = []
    values.map((item:any) => {
      if(item.selected !== undefined && item.selected){
        arr.push({day:JSON.parse(item.value.replace(/[\[\]']+/g,'')), pos:item.pos})
      }
    })
    setWeekDays(arr)
    setWeekModal(!weekModal)
  }

  // close modal
  const changeWeekModalVisibility = () => {
    setWeekModal(!weekModal)
  }

  const callbackVisible = () => {
    setWarningModal(false);
  }

  const sendBackDelete = () => {
    Object.assign(data.intake, {id:medData.id, medication: medData.medication, patient: medData.patient})
    setWarningModal(false);
    deleteData(data.intake)
  }

  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackVisible
    },
    {
    type: 'primary',
    title: t('translation:modal.btn.confirm'),
    click: sendBackDelete
    }
  ]

  const createWeekArr = () => {
    let week = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']
    let temArr:any = []
    data?.intake?.days.forEach((day, i) => {
      if(day === true)
        temArr.push({day:week[i], pos:i})
    })
    setWeekDays(temArr)
    return temArr
  }

  // checking which days are selected inside the modal
  const selectedDay = (propsDay) => {
    if(weekDays.length === 0)
      createWeekArr()
    if(propsDay !== undefined) {
      let findDay = weekDays.find(days => days.day === JSON.parse(propsDay.replace(/[\[\]']+/g,''))) 
      if(findDay !== undefined) return true
    }
  }

  const frequencyOfWeek = () =>{
    if(weekDays.length === 0)
      createWeekArr()
    if(weekDays.length >= 7){
      return t('translation:treatment.add_medication.step_frequency.summary.every_day')
    }
    else {
      return weekDays.map((item, i) => {
        if(i < weekDays.length-1) return t('translation:general.date.days.'+item.pos+'.'+item.day)+', '
        else return t('translation:general.date.days.'+item.pos+'.'+item.day)
      })
    }
  }

  const calculateDose = (u) => {
    let count = 0
    let unit = u
    data?.intake?.dosage.forEach(element => {
      if(unit.length > 0 && element.unit === unit){
        count = count + element.dose
        unit = element.unit
      }
    });
    if(count > 0)
      return count + ' ' + translate(t, unit, 'list.units')
    else
      return ''
  }

  const start_date = (e) => {
    if(DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      e = DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setStartDate(e)
    }
    
  }

  const end_date = (e) => {
    if(DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      e = DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setEndDate(e)
    }
  }

  const sendBack = () => {
    data.intake.from = startDate
    data.intake.to = endDate
    let obj = data
    obj.intake.from = startDate;
    obj.intake.to = endDate;
    obj.intake.days = booleanDaysArr(weekDays || []);
    obj.intake.week_frequency = frequencyOfWeek();
    obj.intake.end_known = endDate ? true : false;
    if(times !== undefined && data?.intake?.specific_dosage?.every === undefined)
      Object.assign(obj, {times, every: data?.intake?.specific_dosage?.every});
    else if(times !== undefined && data?.intake?.specific_dosage?.every !== undefined)
      Object.assign(obj, {times});
    else if(remarks)
      obj.intake.remarks = remarks
    doRequest(obj)
  }

  const checkDisable = () => {
    if(!startDate || !endDate)
      return false
    else if(compareDates(startDate, endDate, 'dd-MM-yyyy'))
      return true
  }

  return(
    <>
      { warningModal &&
        <CommonModal testID="warningModalID" type={'info'} title={t('translation:modal.titles.warning')} text={t('translation:treatment.intakes.confirm_delete')} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      { weekModal &&
        <CommonActionModal 
          testID={"weekModalID"}
          title={t('translation:modal.select.title_multiple')} 
          modalType={'select-check'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: updateWeekDays, action: 'save'}]} 
          onClose={changeWeekModalVisibility} 
          // @ts-ignore
          data={t<string | TemplateStringsArray>('translation:general.date.days', {returnObjects: true}).map((name,i) => {
            return {label:t('translation:general.date.days.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), selected:selectedDay(JSON.stringify(Object.keys(name))), pos:i}
          })}
        />
      }
      <CommonTitle text={t('translation:treatment.add_medication.step_review.title')} color={colors.primary}/>
      <ScrollView
        testID="Review_medicationScroll_ID"
        scrollEventThrottle={16}
        onScroll={(event) => {
          let calc = checkBottomScroll(event.nativeEvent, PR(15));
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
        }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.reason')}</Text>
              <Text style={[styles.label, {fontSize:PR(10)}]}>{t('translation:general.form.not_editable')}</Text>
            </View>
            <View testID="typeOfTreatmentID" style={styles.summaryContainer}>
              <View style={styles.inputSummaryContainer}>
                <Text style={styles.inputSummaryValue}>{reasonTreat.length === 0 ? '-' : translate(t, reasonTreat, 'list.medication_reasons')}</Text>
              </View>
            </View>
          </View>
          <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.type')}</Text>
              <Text style={[styles.label, {fontSize:PR(10)}]}>{t('translation:general.form.not_editable')}</Text>
            </View>
            <View testID="typeOfTreatmentID" style={styles.summaryContainer}>
              <View style={styles.inputSummaryContainer}>
                <Text style={styles.inputSummaryValue}>{typeTreat.length === 0 ? '-' : translate(t, typeTreat, 'list.medication_types')}</Text>
              </View>
            </View>
          </View>
          {typeIntake.length > 0 && typeTreat === 'drug' &&
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.intake')}</Text>
                <Text style={[styles.label, {fontSize:PR(10)}]}>{t('translation:general.form.not_editable')}</Text>
              </View>
              <View testID="typeOfTreatmentID" style={styles.summaryContainer}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{typeIntake.length === 0 ? '-' : translate(t, typeIntake, 'list.intake_types')}</Text>
                </View>
              </View>
            </View>
          }
          <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.name')}</Text>
                <Text style={[styles.label, {fontSize:PR(10)}]}>{t('translation:general.form.not_editable')}</Text>
              </View>
            <View testID="intakeID" style={styles.summaryContainer}>
              <View style={styles.inputSummaryContainer}>
                <Text style={styles.inputSummaryValue}>{nameOfTreat}</Text>
              </View>
            </View>
          </View>
          {dosageExist &&
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_frequency.summary.week_frequency')}</Text>
              <TouchableOpacity testID="weekFrequencyID" style={styles.summaryContainer} onPress={() =>setWeekModal(!weekModal)}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{frequencyOfWeek()}</Text>
                </View>
              </TouchableOpacity>
            </View>
          } 
          {dosageExist &&
            <>
              <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                <Text style={styles.label}>{t('translation:treatment.add_medication.step_frequency.step_3.intake_moment')}</Text>
                <TouchableOpacity testID="intakeMomentID" style={styles.summaryContainer} onPress={()=> goBack(weekDays.length === 7 ? 'daily' : 'weekly')}>
                  <View style={styles.intakeSummaryContainer}>
                    {data?.intake?.dosage.map((item, i) => {
                      return (
                        <View key={i} style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                          <Text style={styles.intakeLabel}>{translate(t, item.moment, 'list.moments')}</Text>
                          <Text style={[styles.intakeLabel,{paddingRight:PR(15)}]}>{item.dose + ' ' + translate(t,item.unit,'list.units')}</Text>
                        </View>
                      )
                    })}
                  </View>
                  <View style={styles.intakeBottomContainer}>
                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', backgroundColor:colors.intakeTotalBackground}}>
                      <Text style={[styles.intakeLabel,{fontFamily:GlobalStyles.global.fontFamily.Bold}]}>{t('translation:treatment.add_medication.step_frequency.step_3.total')}</Text>
                      <View style={{flexDirection:'column', alignItems:'center'}}>
                        {data?.intake?.dosage?.map((item, i) => {
                          let findUnit = tempUnitArr.find(itemUnit => itemUnit === item.unit)
                          if(findUnit === undefined )
                          {
                            tempUnitArr.push(item.unit)
                            return(
                              <Text key={i} style={[styles.intakeLabel,{fontFamily:GlobalStyles.global.fontFamily.Bold, paddingRight:15}]}> {calculateDose(item.unit)+' '}</Text>
                            )
                          }
                          })
                        }
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </>
          }
          {frequencyIntake && 
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_review.frequency')}</Text>
              <TouchableOpacity testID="intakeFrequencyID" style={styles.summaryContainer} onPress={()=> goBack(data?.intake?.specific_dosage?.every === 1 ? 'monthly' : data?.intake?.specific_dosage?.every === 3 ? 'quarterly' : 'other')}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{frequencyIntake}</Text>
                </View>
              </TouchableOpacity>
            </View>
          }
          {remarks && 
            <InputField 
            labelText={t('translation:general.events.remark')}
            inputType="string" 
            customStyle={{marginBottom:PR(30)}}
            changeText={setRemarks}
            placeholderTxt={t('translation:general.events.remark_placeholder')} 
            testID="remarksID"
            value={remarks}
          />
          }
          <View style={styles.dateInput}>
            <View style={styles.dateInputLabel}>
              <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.summary.start_date_label')}</Text>
            </View>
            <CommonDateInput
              onChange={d => start_date(d)}
              value={startDate}
              fieldStyle={styles.dateInputField}
              textStyle={styles.dateInputFieldText}
              webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
            />
          </View>
          <View style={styles.dateInput}>
            <View style={styles.dateInputLabel}>
              <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.summary.end_date_label')}</Text>
            </View>
            <CommonDateInput
              onChange={d => end_date(d)}
              value={endDate}
              fieldStyle={styles.dateInputField}
              textStyle={styles.dateInputFieldText}
              webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
              isValueDeletable={endDate ? true : false}
              deleteDate={setEndDate}
            />
            {endDate && compareDates(startDate, endDate, 'dd-MM-yyyy') &&
              <Text style={styles.errorTxt}>{t('translation:treatment.general.bad_end_date')}</Text>
            }
          </View>
          {newDose && oldWarningData?.from &&
            <View style={styles.newDoseContainer}>
              <View style={styles.newDoseWrapper}>
                <Icon name={'info'} size={PR(25)} style={{color:GlobalStyles.global.white, paddingRight:PR(10)}}/>
                <Text style={styles.warningTxt}>
                  {t('translation:treatment.intakes.warnings.previous_dose', {from: oldWarningData?.from ? DateTime.fromISO(oldWarningData?.from, { locale: lang }).toFormat('MMM dd, yyyy') : null, dose:oldWarningData?.dose})}
                  {oldWarningData?.to &&
                    t('translation:treatment.intakes.warnings.previous_dose_end', {to: oldWarningData?.to ? DateTime.fromISO(oldWarningData?.to, { locale: lang }).toFormat('MMM dd, yyyy') : null})
                  }
                </Text>
              </View>
            </View>
          }
          <View style={{marginBottom:PR(15)}}></View>
        </View>
      </ScrollView>
      {newDose ?
      <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
        <CommonBtn
          testID={"deleteBtnID"} 
          type={'secondary'} 
          title={t('translation:general.previous')}
          click={() => goBack('newDose')}
        />
        <View style={{marginBottom:PR(15)}}></View>
        <CommonBtn
          testID={"updateBtnID"} 
          type={'primary'} 
          title={t('translation:treatment.add_medication.step_review.add_dose')}
          click={() => {sendBack()}}
          disable={checkDisable()}
        />
      </CommonFooter>
      :
      <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
        <CommonBtn
          testID={"deleteBtnID"} 
          type={'tertiary'} 
          title={t('translation:treatment.add_medication.step_review.delete_dose')}
          color={GlobalStyles.global.redError}
          click={() => {setWarningModal(true)}}
        />
        <View style={{marginBottom:PR(15)}}></View>
        <CommonBtn
          testID={"updateBtnID"} 
          type={'primary'} 
          title={t('translation:treatment.add_medication.step_review.edit_dose')}
          click={() => {sendBack()}}
          disable={checkDisable()}
        />
      </CommonFooter>
      }
      <View style={{marginBottom:PR(15)}}></View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    dateInput: {
      width:'90%',
      alignSelf:'center',
      marginBottom: PR(30)
    },
    dateInputLabel: {
      marginBottom: PR(10),
      marginLeft: PR(20),
    },
    dateInputLabelText: {
      color: colors.primary,
      fontSize: PR(12)
    },
    dateInputField: {
      backgroundColor: colors.inputDateContainer,
      borderRadius: PR(20),
      borderColor: colors.inputDateContainer,
      height: PR(62),
      paddingHorizontal: PR(20),
      paddingVertical: PR(20),
    },
    dateInputFieldText: {
      color: colors.primary,
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    label: { 
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(12)
    },
    summaryContainer: {
      backgroundColor: colors.inputBackground,
      borderRadius: PR(20),
    },
    intakeSummaryContainer: {
      flex: 1,
      flexDirection: 'column',
      minHeight: PR(62),
      padding: PR(10),
      justifyContent: 'space-between',
      marginTop:PR(10)
    },
    intakeBottomContainer: {
      backgroundColor:colors.intakeTotalBackground, 
      borderBottomLeftRadius: PR(20), 
      borderBottomRightRadius: PR(20), 
      padding:PR(10),
      flexDirection: 'column',
      paddingBottom: PR(20)
    },
    intakeLabel: {
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(16),
      alignSelf:'flex-end'
    },
    inputSummaryContainer: {
      flex: 1,
      flexDirection: 'row',
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputSummaryValue: {
      flex: 1,
      flexGrow: 1,
      color: colors.primary,
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    errorTxt: {
      color: GlobalStyles.global.redError,
      textAlign: 'center'
    },
    btnContainer: {
      width: "100%",
      marginBottom: PR(20)
    },
    newDoseContainer: {
      width: '90%',
      alignSelf: 'center',
      backgroundColor: GlobalStyles.global.redError,
      borderRadius: PR(20),
      padding: PR(10)
    },
    newDoseWrapper: {
      width: '80%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    warningTxt: {
      fontSize: PR(14),
      color: GlobalStyles.global.white
    }
  })
}

export default CommonEditReview;