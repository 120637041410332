import React, {useState} from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonExpandContainer from '../CommonExpand/CommonExpandContainer';
import CommonImpactSeizuresGraph from '../CommonImpact/CommonImpactSeizuresGraph';
import CommonImpactHeadachesGraph from '../CommonImpact/CommonImpactHeadachesGraph';
import CommonImpactTriggersGraph from './CommonImpactTriggersGraph';
import CommonImpactAurasGraph from './CommonImpactAurasGraph';
import CommonImpactMoodSleepAdhGraph from './CommonImpactMoodSleepAdh/CommonImpactMoodSleepAdhGraph';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from '../CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useTranslation } from 'react-i18next';

type OwnProps = {
    data: DataTypes[],
    app?: string
}

interface DataTypes {
    itemType: string,
    itemTitle: string,
    icon?: string,
    Me?: MeTypes,
    Events?: any
}

interface MeTypes {
    patientData: Record<string,any>,
    result: Record<string,any>
}

const CommonImpactContainer = ({data, app}:OwnProps) => {
    const {t} = useTranslation();
    const colors = useTheme().colors;
    const styles: any = generateStyleSheet(colors);
    const [expandedSection, setExpandedSection] = useState<number>(-1)

    const updateDisplayList = (expandedIndex) => {
        setExpandedSection(expandedSection === expandedIndex ? -1 : expandedIndex)
    }

    return(
        <>
            {data.map((item:DataTypes, index:number) => {
                return(
                    <View key={index}>
                        <CommonExpandContainer 
                            dataItem={item}
                            isExpanded={expandedSection === index ? true : false} 
                            updateDisplayList={updateDisplayList} 
                            index={index}
                            children={
                                <>
                                {item.itemType === 'seizures' && item?.Events && item?.Me &&
                                    <>
                                        <CommonImpactSeizuresGraph 
                                            Me={item.Me}
                                            Events={item.Events}
                                        />
                                    </>
                                } 
                                {item.itemType === 'headache' && item?.Events && item?.Me &&
                                    <>
                                        <CommonImpactHeadachesGraph 
                                            Me={item.Me}
                                            Events={item.Events}
                                        />
                                    </>
                                } 
                                {item.itemType === 'triggers' && item?.Events.length > 0 && 
                                    <>
                                        <CommonImpactTriggersGraph
                                            app={app}
                                            Events={item.Events}
                                            triggersAurasType={item.itemType}
                                        />
                                    </>
                                }
                                {item.itemType === 'triggers' && item?.Events.length === 0 &&
                                    <Text style={styles.bottomPlaceholder}>{ app === 'headache' ? t('translation:programs.impact.triggers_headache_auras_place') : t('translation:programs.impact.triggers.placeholder')}</Text>
                                }
                                {item.itemType === 'auras' && item?.Events.length > 0 &&
                                    <>
                                        <CommonImpactAurasGraph 
                                            app={app}
                                            Events={item.Events}
                                            eventType={item.itemType}
                                        />
                                    </>
                                } 
                                {item.itemType === 'auras' && item?.Events.length === 0 &&
                                    <Text style={styles.bottomPlaceholder}>{t('translation:programs.impact.auras.placeholder')}</Text>
                                }
                                {item.itemType === 'mood' && item?.Events && 
                                    <>
                                        <CommonImpactMoodSleepAdhGraph 
                                            Events={item.Events}
                                            eventType={item.itemType}
                                        />
                                    </>
                                } 
                                {item.itemType === 'sleep' && item?.Events && 
                                    <>
                                        <CommonImpactMoodSleepAdhGraph 
                                            Events={item.Events}
                                            eventType={item.itemType}
                                        />
                                    </>
                                }
                                {item.itemType === 'reminder' && item?.Events && 
                                    <>
                                        <CommonImpactMoodSleepAdhGraph 
                                            Events={item.Events}
                                            eventType={item.itemType}
                                        />
                                    </>
                                }
                                </>
                            }
                        />
                    </View>
                )
            })}
        </>
    )
}
const generateStyleSheet = (colors: Record<string, string>) => {
    return StyleSheet.create({
        bottomPlaceholder: {
            fontSize: PR(12),
            fontFamily: GlobalStyles.global.fontFamily.Regular,
            color: colors.primary,
            opacity: 0.8,
            textAlign:'center'
        },
    })
};

export default CommonImpactContainer;