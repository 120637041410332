import React from "react"
import { Text, View } from "react-native"
import { useTheme } from '@react-navigation/native';
import PinViewStyle from "./Style/PinViewStyle.js"
import GlobalStyles from "common/src/styles/GlobalStyles"
import { APP_SHORTCUT_NAME } from '../../env.json';


type OwnTypes = {
    input: Array<string>,
    pinLength: number,
    style?: any,
    inputAreaStyle?: object,
    showInputText: boolean
}

const CommonPinField = ({input, pinLength, style, inputAreaStyle, showInputText}:OwnTypes) => {
    const { colors } = useTheme();

    const ViewInput = ({
        showInputText = false,
        inputTextStyle,
        size = 30,
        customStyle,
        text,
        inputFilledStyle = { backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor },
        inputEmptyStyle = { backgroundColor: colors.background },
        testID
      }) => {
        if (showInputText) {
          return (
            <View
              testID={testID}
              style={[
                PinViewStyle.inputView,
                { width: size, height: size, borderRadius: size / 2, alignItems: "center", justifyContent: "center" },
                text ? inputFilledStyle : inputEmptyStyle,
                customStyle,
              ]}>
              <Text style={[PinViewStyle.inputText, inputTextStyle]}>{text}</Text>
            </View>
          )
        } else {
          return (
            <View
              testID={testID}
              style={[
                PinViewStyle.inputView,
                { width: size, height: size, borderRadius: size / 2 },
                text ? inputFilledStyle : inputEmptyStyle,
                customStyle,
              ]}
            />
          )
        }
      }
    return (
        <View style={[PinViewStyle.pinView, style]}>
            <View style={[PinViewStyle.inputContainer, inputAreaStyle]}>
                {/* @ts-ignore */}
                {Array.apply(null, { length: pinLength }).map((e, i) => (
                    <ViewInput
                        inputTextStyle={{color: colors.background}}
                        showInputText={showInputText}
                        text={input[i]}
                        customStyle={{}}
                        key={"input-view-" + i}
                        testID={`pin_field_id_${i}`}
                    />
                ))}
            </View>
        </View>
    )
}

export default CommonPinField;