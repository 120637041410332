import React, {useState, useEffect} from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonTitle from '../CommonTitle';
import CommonActionModal from '../CommonActionModal';
import CommonBtn from '../CommonBtn';
import CommonRadioForm from '../CommonAddEvents/CommonRadioForm';
import CommonDateInput from '../CommonDateInput';
import InputField from '../InputField';
import Text from '../CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import CommonFooter from '../CommonFooter';
import { useTranslation } from 'react-i18next';
import {translate} from '../../services/translationList';
import {compareDates} from 'common/src/services/compare';
import {checkBottomScroll} from '../../services/utils';
import {DateTime} from 'luxon';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { ThemeColors } from '../../../../../*';

const CommonTreatmentReview = ({data, back, doRequest}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const [reasonTreat, setReasonTreat] = useState<string>(data.infoAnswers.reasonTreat)
  const [typeTreat, setTypeTreat] = useState<string>(data.infoAnswers.typeTreat)
  const [typeIntake, setTypeIntake] = useState<string>(data?.infoAnswers?.typeIntake)
  const [nameOfTreat,] = useState<string>(data.infoAnswers.nameOfTreat)
  const [startDate, setStartDate] = useState<string>(data.periodAnswers.startDate)
  const [frequencyIntake, setFrequencyIntake] = useState<string>()
  const [endDate, setEndDate] = useState<string>(data.periodAnswers.endDate)
  const [times, setTimes] = useState<number>()
  const [every, setEvery] = useState<number>()
  const [remarks, setRemarks] = useState<string>(data?.frequencyAnswers?.remarks)
  const [weekModal, setWeekModal] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);

  useEffect(() => {
    if(data.frequencyAnswers?.frequency === 'monthly') {
      setFrequencyIntake(t('translation:treatment.add_medication.step_frequency.custom.timesPer')+' '+1+' '+translate(t,'month', 'list.periodFrequencies')+' '+data.frequencyAnswers?.MQDose+' '+data.frequencyAnswers?.MQUnit)
      setTimes(1)
      setEvery(1)
    }
    else if(data.frequencyAnswers?.frequency === 'quarterly'){
      setFrequencyIntake(t('translation:treatment.add_medication.step_frequency.custom.timesPer')+' '+3+' '+translate(t,'month', 'list.periodFrequencies')+' '+data.frequencyAnswers?.MQDose+' '+data.frequencyAnswers?.MQUnit)
      setTimes(1)
      setEvery(3)
    }
    else if(data.frequencyAnswers?.frequency === 'other') {
      setFrequencyIntake(t('translation:treatment.add_medication.step_frequency.custom.timesPer')+' '+data.frequencyAnswers?.every+' '+translate(t,data.frequencyAnswers?.timeline, 'list.periodFrequencies')+' '+data.frequencyAnswers?.MQDose+' '+data.frequencyAnswers?.MQUnit)
      setTimes(1)
    }
      
  },[])

  // update week day selection
  const updateWeekDays = (values) =>{
    let arr:any = []
    values.map((item:any) => {
      if(item.selected !== undefined && item.selected){
        arr.push({day:JSON.parse(item.value.replace(/[\[\]']+/g,'')), pos:item.pos})
      }
    })
    data.frequencyAnswers.week_frequency = arr
    setWeekModal(!weekModal)
  }

  // close modal
  const changeWeekModalVisibility = () => {
    setWeekModal(!weekModal)
  }

  // checking which days are selected inside the modal
  const selectedDay = (propsDay) => {
    if(propsDay !== undefined) {
      let findDay = data?.frequencyAnswers?.week_frequency?.find(days => days.day === JSON.parse(propsDay.replace(/[\[\]']+/g,''))) 
      if(findDay !== undefined) return true
    }
  }

  const frequencyOfWeek = () => {
    let result:any
    if(data.frequencyAnswers.week_frequency.length >= 7) {
      result = t('translation:treatment.add_medication.step_frequency.summary.every_day')
    }
    else {
      result = data.frequencyAnswers.week_frequency.map((item, i) => {
        if(i < data.frequencyAnswers.week_frequency.length-1) return t('translation:general.date.days.'+item.pos+'.'+item.day)+', '
        else return t('translation:general.date.days.'+item.pos+'.'+item.day)
      })
    }
    return result
  }

  const reason_treat = (e) => {
    setReasonTreat(e)
    data.infoAnswers.reasonTreat = e
  }

  const type_treat = (e) => {
    setTypeTreat(e)
    data.infoAnswers.typeTreat = e
  }

  const type_intake = (e) => {
    setTypeIntake(e)
    data.infoAnswers.typeIntake = e
  }

  const start_date = (e) => {
    if(DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      e = DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setStartDate(e)
      data.periodAnswers.startDate = e
    }
  }

  const end_date = (e) => {
    if(DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      e = DateTime.fromFormat(e, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setEndDate(e)
      data.periodAnswers.endDate = e
    }
  }

  const goBack = (step) => {
    back(step)
  }

  const sendBack = () => {
    let obj = data
    if(times !== undefined && data.frequencyAnswers.every === undefined)
      Object.assign(obj.frequencyAnswers, {times, every});
    else if(times !== undefined && data.frequencyAnswers.every !== undefined)
      Object.assign(obj.frequencyAnswers, {times});
    else if(remarks !== undefined)
      obj.frequencyAnswers.remarks = remarks
    doRequest(obj)
  }

  const checkDisable = () => {
    if(endDate === null)
      return false
    else if(compareDates(startDate, endDate, 'dd-MM-yyyy'))
      return true
  }
  return(
    <>
      { weekModal &&
        <CommonActionModal 
          testID={"weekModalID"}
          title={t('translation:modal.select.title_multiple')} 
          modalType={'select-check'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: updateWeekDays, action: 'save'}]} 
          onClose={changeWeekModalVisibility} 
          // @ts-ignore
          data={t<string | TemplateStringsArray>('translation:general.date.days', {returnObjects: true}).map((name,i) => {
            return {label:t('translation:general.date.days.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), selected:selectedDay(JSON.stringify(Object.keys(name))), pos:i}
          })}
        />
      }
      <CommonTitle text={t('translation:treatment.add_medication.step_review.title')} color={colors.primary}/>
      <ScrollView
        testID="Review_medicationScroll_ID"
        scrollEventThrottle={16}
        onScroll={(event) => {
          let calc = checkBottomScroll(event.nativeEvent, PR(15));
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
        }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          <CommonRadioForm
            value={reasonTreat}
            setValue={reason_treat}
            cmsListLink='list.medication_reasons'
            patientList='reason_inputs'
            label={t('translation:treatment.add_medication.step_info.summary.reason')}
          />
          <CommonRadioForm
            value={typeTreat}
            setValue={type_treat}
            cmsListLink='list.medication_types'
            patientList='treatment_type_inputs'
            label={t('translation:treatment.add_medication.step_info.summary.type')}
          />
          {typeIntake.length > 0 && typeTreat === 'drug' &&
            <CommonRadioForm
              value={typeIntake}
              setValue={type_intake}
              cmsListLink='list.intake_types'
              patientList='intake_type_inputs'
              label={t('translation:treatment.add_medication.step_info.summary.intake')}
            />
          }
          <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
            <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.name')}</Text>
            <TouchableOpacity testID="intakeID" style={styles.summaryContainer} onPress={() => goBack(0)}>
              <View style={styles.inputSummaryContainer}>
                <Text style={styles.inputSummaryValue}>{nameOfTreat}</Text>
              </View>
            </TouchableOpacity>
          </View>
          {data?.frequencyAnswers?.week_frequency && data?.frequencyAnswers?.week_frequency !== undefined &&
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_frequency.summary.week_frequency')}</Text>
              <TouchableOpacity testID="weekFrequencyID" style={styles.summaryContainer} onPress={() =>setWeekModal(!weekModal)}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{frequencyOfWeek()}</Text>
                </View>
              </TouchableOpacity>
            </View>
          }
          {data?.frequencyAnswers?.dosage &&
            <>
              <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                <Text style={styles.label}>{t('translation:treatment.add_medication.step_frequency.step_3.intake_moment')}</Text>
                <TouchableOpacity testID="intakeMomentID" style={styles.summaryContainer} onPress={() => goBack(1)}>
                  <View style={styles.intakeSummaryContainer}>
                    {data.frequencyAnswers.dosage.map((item, i) => {
                      return (
                        <View key={i} style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                          <Text style={styles.intakeLabel}>{translate(t, item.moment, 'list.moments')}</Text>
                          <Text style={[styles.intakeLabel,{paddingRight:PR(15),}]}>{item.dose + ' ' + translate(t,item.unit,'list.units')}</Text>
                        </View>
                      )
                    })}
                  </View>
                  <View style={styles.intakeBottomContainer}>
                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', backgroundColor:colors.footerBackground}}>
                      <Text style={[styles.intakeLabel,{fontFamily:GlobalStyles.global.fontFamily.Bold}]}>{t('translation:treatment.add_medication.step_frequency.step_3.total')}</Text>
                      <Text style={[styles.intakeLabel,{fontFamily:GlobalStyles.global.fontFamily.Bold, paddingRight:PR(15)}]}>{data.frequencyAnswers.totalDose}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </>
          }
          {data?.frequencyAnswers?.frequency !== 'daily' && data?.frequencyAnswers?.frequency !== 'weekly' && data?.frequencyAnswers?.frequency !== undefined && 
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_review.frequency')}</Text>
              <TouchableOpacity testID="intakeFrequencyID" style={styles.summaryContainer} onPress={() => goBack(1)}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{frequencyIntake}</Text>
                </View>
              </TouchableOpacity>
            </View>
          }
          
          {remarks !== undefined && 
            <InputField 
            labelText={t('translation:general.events.remark')}
            inputType="string" 
            customStyle={{marginBottom:PR(30)}}
            changeText={setRemarks}
            placeholderTxt={t('translation:general.events.remark_placeholder')} 
            testID="remarksID"
            value={remarks}
          />
          }
          <View style={styles.dateInput}>
            <View style={styles.dateInputLabel}>
              <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.summary.start_date_label')}</Text>
            </View>
            <CommonDateInput
              onChange={d => start_date(d)}
              value={startDate}
              fieldStyle={styles.dateInputField}
              textStyle={styles.dateInputFieldText}
              webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
            />
          </View>
          <View style={styles.dateInput}>
            <View style={styles.dateInputLabel}>
              <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.summary.end_date_label')}</Text>
            </View>
            <CommonDateInput
              onChange={d => end_date(d)}
              value={endDate}
              fieldStyle={styles.dateInputField}
              textStyle={styles.dateInputFieldText}
              webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
              isValueDeletable={endDate ? true : false}
              deleteDate={(d) => {setEndDate(d); data.periodAnswers.endDate = d}}
            />
            {endDate && compareDates(startDate, endDate, 'dd-MM-yyyy') &&
              <Text style={styles.errorTxt}>{t('translation:treatment.general.bad_end_date')}</Text>
            }
          </View>
        </View>
      </ScrollView>
      <CommonFooter atBottom={contentHeight} direction={'row'} reachBottom={scrollAtBottom}>
        <View style={{flexGrow: 0.5, flex:1}}>
          <CommonBtn testID={"nextBtnID"} type={'secondary'} title={t('translation:general.previous')} click={() => {goBack(2)}}/>
        </View>
        <View style={{flexGrow: 0.5, flex:1}}>
        <CommonBtn testID={"confirmBtnID"} type={'primary'} title={t('translation:general.confirm')} disable={checkDisable()} click={()=> {sendBack()}}/>
        </View>
      </CommonFooter>
      <View style={{marginBottom:15}}></View>
    </>
  )
}

const generateStyleSheet = (colors: ThemeColors) => {
  return StyleSheet.create({
    horizontalDiv: {
      borderTopWidth: 1, 
      borderTopColor: GlobalStyles.global.lightGrey,
      width: '90%',
      paddingBottom:PR(20),
      alignSelf:'center'
    },
    dateInput: {
      width:'90%',
      alignSelf:'center',
      marginBottom: PR(30)
    },
    dateInputLabel: {
      marginBottom: PR(10),
      marginLeft: PR(20),
    },
    dateInputLabelText: {
      color: colors.primary,
      fontSize: PR(12)
    },
    dateInputField: {
      backgroundColor: colors.inputDateContainer,
      borderRadius: PR(20),
      borderColor: colors.inputDateContainer,
      height: PR(62),
      paddingHorizontal: PR(20),
      paddingVertical: PR(20),
    },
    dateInputFieldText: {
      color: colors.primary,
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    label: { 
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(12)
    },
    summaryContainer: {
      backgroundColor: colors.inputBackground,
      borderRadius: PR(20),
    },
    intakeSummaryContainer: {
      flex: 1,
      flexDirection: 'column',
      minHeight: PR(62),
      padding: PR(10),
      justifyContent: 'space-between',
      marginTop:PR(10)
    },
    intakeBottomContainer: {
      backgroundColor: colors.footerBackground, 
      borderBottomLeftRadius: PR(20), 
      borderBottomRightRadius: PR(20), 
      padding:PR(10),
      flexDirection: 'column',
      paddingBottom: PR(20)
    },
    intakeLabel: {
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(16)
    },
    inputSummaryContainer: {
      flex: 1,
      flexDirection: 'row',
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputSummaryValue: {
      flex: 1,
      flexGrow: 1,
      color: colors.primary,
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    errorTxt: {
      color: GlobalStyles.global.redError,
      textAlign: 'center'
    },
    btnContainer: {
      width: "100%",
      marginBottom: PR(20), 
      flexDirection: 'row',
      justifyContent:'space-between'
    }
  })
}

export default CommonTreatmentReview;