import React, {useEffect, useState, useReducer} from 'react';
import {View, StyleSheet, Platform, ScrollView, TouchableOpacity, Animated} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonModal from 'common/src/components/CommonModal';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubtitle from 'common/src/components/CommonSubtitle';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonSwitchBtn from 'common/src/components/CommonSwitchBtn';
import CommonFooter from 'common/src/components/CommonFooter';
import Loading from './Loading';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { updateMoodAndSleepNotificationsAction} from 'common/src/store/actions/authenticationActions';
import {MoodAndSleepIsEnabled} from 'common/src/types';
import {useTranslation} from 'react-i18next';
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonTimeInput from "common/src/components/CommonTimeInput";
import { APP_SHORTCUT_NAME } from 'common/src/env.json';
import { ThemeColors } from '../../../../*';
import CommonKeyboardAvoidingView from './CommonKeyboardAvoidingView';

const MoodAndSleepNotifications = ({navigation}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetMeResponse = useSelector((state:any) => state.getMe.response)
  const [scrollState, setScrollState] = useState();
  const [scrollAtBottom, setScrollAtBottom] = useState(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [sleepNotif, setSleepNotif] = useState<string>(GetMeResponse ? GetMeResponse.result?.settings?.notifications?.sleep_time: false);
  const [moodNotif, setMoodNotif] = useState<string>(GetMeResponse ? GetMeResponse.result?.settings?.notifications?.mood_time: false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useReducer(
    (state:MoodAndSleepIsEnabled, newState:MoodAndSleepIsEnabled) => ({...state, ...newState}),
    {
      switchBtn1: GetMeResponse ? GetMeResponse.result?.settings?.notifications?.sleep : '',
      switchBtn2: GetMeResponse ? GetMeResponse.result?.settings?.notifications?.mood : ''
    }
  )

  useEffect(() => {
    if(sleepNotif && sleepNotif > '23:59')
      setSleepNotif('00:00')
    if(moodNotif && moodNotif > '23:59')
      setMoodNotif('00:00')
  },[sleepNotif,moodNotif])

  const openModal = () => {
    setModalVisible(true);
  }

  const save = () => {
    setIsLoading(true);
    let obj = {
      success_msg: t('translation:moodsleep.success'),
      navigation,
      data:{
        settings:{
          notifications:{
            mood:isEnabled.switchBtn2,
            mood_time: moodNotif,
            mood_time_date: new Date(),
            sleep: isEnabled.switchBtn1,
            sleep_time: sleepNotif,
            sleep_time_date: new Date()
          }
        }
      },
      callback: () => {
        setIsLoading(false);
      },
      errorCallback: () => {
        setIsLoading(true);
      }
    }
    dispatch(updateMoodAndSleepNotificationsAction(obj))
  }

  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }

  const confirmModalBtn = [
    {
      type: 'primary',
      title: t('translation:modal.btn.ok'),
      click: callbackVisible
    }
  ]
  const infoTxt = t('translation:moodsleep.help')
  const subtitle = t('translation:moodsleep.info_subtitle')

  if(isLoading)
    return <Loading />

  return (
    <>
      { modalVisible &&
        <CommonModal type={'info'} title={t('translation:modal.titles.help')} text={infoTxt} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      <CommonKeyboardAvoidingView>
        <CommonHeader
          testIDTitle="moodSleep_title"
          title={t('translation:moodsleep.page_title')}
          leftIconName="arrow-left"
          leftIconColor={GlobalStyles[APP_SHORTCUT_NAME].color}
          leftClick={() => {navigation.goBack()}}
          scroll={scrollState}
        />
        <View style={styles.scrollViewWrapper}>
          <ScrollView 
            testID="profile_scrollView"
            style={styles.scrollview}
            scrollEventThrottle={16}
            onScroll={(event: any) => {
              let calc = checkBottomScroll(event.nativeEvent, 0);
              if (!scrollAtBottom && calc)
                  setScrollAtBottom(true)
              else if (scrollAtBottom && !calc)
                  setScrollAtBottom(false)
              setScrollState(event.nativeEvent.contentOffset.y);
            }}
          >
            <View
              onLayout={(event: any) => {
                setContentHeight(event.nativeEvent.layout.height)
              }}
            >
              <View style={styles.IconRow}>
                <View style={{position: 'relative'}}>
                <View style={styles.titleIcon}>
                  <Icon name={'bell'} size={PR(40)} color={GlobalStyles[APP_SHORTCUT_NAME].color}/>
                </View>
                <TouchableOpacity onPress={openModal} style={styles.titleInfo}>
                  <Icon name={"info"} size={PR(25)} color={GlobalStyles[APP_SHORTCUT_NAME].color} />
                </TouchableOpacity>
                </View>
              </View>
              <View style={styles.titleWrapper}>
                <CommonTitle text={t('translation:moodsleep.info_title')} color={GlobalStyles[APP_SHORTCUT_NAME].color}/>
                <CommonSubtitle text={subtitle}/>
              </View>
              <View style={isEnabled.switchBtn1 ? styles.wrapper : [styles.wrapper, {paddingBottom:PR(20)}]}>
                <View style={{flex:1}}>
                  <Text style={styles.txt}>{t('translation:moodsleep.sleep_label')}</Text>
                </View>
                <CommonSwitchBtn 
                  isOn={isEnabled.switchBtn1}
                  size={PR(20) > 17 ? "medium" : "small"}
                  onToggle={(e) => setIsEnabled({switchBtn1:e})}
                  testID="sleepSwitch"
                />
              </View>
              {isEnabled.switchBtn1 &&
                <Animated.View>
                  <View style={styles.dateInput}>
                    <View style={styles.dateInputLabel}>
                      <Text style={styles.dateInputLabelText}>{t('translation:moodsleep.sleep_time')}</Text>
                    </View>
                    <CommonTimeInput
                      onChange={setSleepNotif}
                      value={sleepNotif}
                      fieldStyle={styles.dateInputField}
                      textStyle={styles.dateInputFieldText}
                      webInputStyle={{
                        backgroundColor: colors.inputBackground, 
                        border: "none", 
                        width: "100%", 
                        color: colors.primary,
                        fontSize: PR(16),
                        fontFamily: GlobalStyles.global.fontFamily.Medium
                      }}
                    />
                  </View>
                </Animated.View>
              }
              <View style={styles.wrapper}>
                <View style={{flex:1}}>
                  <Text style={styles.txt}>{t('translation:moodsleep.mood_label')}</Text>
                </View>
                <CommonSwitchBtn 
                  isOn={isEnabled.switchBtn2}
                  size={PR(20) > 17 ? "medium" : "small"}
                  onToggle={(e) => setIsEnabled({switchBtn2:e})}
                  testID="moodSwitch"
                />
              </View>
              {isEnabled.switchBtn2 &&
                <Animated.View>
                  <View style={styles.dateInput}>
                    <View style={styles.dateInputLabel}>
                      <Text style={styles.dateInputLabelText}>{t('translation:moodsleep.mood_time')}</Text>
                    </View>
                    <CommonTimeInput
                      onChange={setMoodNotif}
                      value={moodNotif}
                      fieldStyle={styles.dateInputField}
                      textStyle={styles.dateInputFieldText}
                      webInputStyle={{
                        backgroundColor: colors.inputBackground, 
                        border: "none", 
                        width: "100%", 
                        color: colors.primary,
                        fontSize: PR(16),
                        fontFamily: GlobalStyles.global.fontFamily.Medium
                      }}
                    />
                  </View>
                </Animated.View>
              }
            </View>
          </ScrollView>
          <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}> 
            <CommonBtn type="primary" click={save} title={t('translation:moodsleep.save')} testID="save_notifications"/>
          </CommonFooter>
        </View>
      </CommonKeyboardAvoidingView>
    </>
  )
}

const generateStyleSheet = (colors: ThemeColors) => {
  return StyleSheet.create({
    scrollViewWrapper: {
      marginTop: PR(5),
      flex: 1,
    },
    scrollview: {
      paddingTop: PR(20),
      flex:1,
      
    },
    titleWrapper: {
      margin: PR(30)
    },
    IconRow: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wrapper:{
      flexDirection:'row',
      alignItems:'center',
      alignSelf:'center',
      width:'90%',
      justifyContent:'space-between',
      flex:1,
      paddingBottom: PR(10),
    },
    txt: {
      fontSize: PR(16),
      marginLeft: PR(15),
      color: colors.primary
    },
    titleIcon: {
      alignSelf:'center', 
      borderWidth:PR(2), 
      borderRadius:PR(30), 
      borderColor: colors.bodyIconContainer, 
      backgroundColor: colors.bodyIconContainer,
      padding: PR(7)
    },
    titleInfo: {
      position: 'absolute',
      top: '35%',
      right: PR(-35)
    },
    dateInput: {
      width:'90%',
      alignSelf:'center',
      marginBottom: PR(30)
    },
    dateInputLabel: {
      marginBottom: PR(10),
      marginLeft: PR(20),
    },
    dateInputLabelText: {
      color: colors.primary,
      fontSize: PR(12)
    },
    dateInputField: {
      backgroundColor: colors.inputBackground,
      borderRadius: PR(20),
      borderColor: colors.inputBackground,
      height: PR(62),
      paddingHorizontal: PR(20),
      paddingVertical: PR(20),
    },
    dateInputFieldText: {
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium,
      color: colors.primary
    },
  })
};

export default MoodAndSleepNotifications;