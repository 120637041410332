import { takeLatest, put } from "redux-saga/effects";
import { AnyAction } from "redux";
import { 
    ThemeActions,
    SwitchThemeInterface
} from "./themeActions";

export function* switchThemeSaga(payload: AnyAction & SwitchThemeInterface) {
    yield put({ type: ThemeActions.SWITCH_THEME_SUCCESS, response: payload.themeMode });
}

export default function* watch() {
    yield takeLatest(ThemeActions.SWITCH_THEME, switchThemeSaga);
    
}