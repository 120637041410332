import * as types from './index';

// Those actions will be dispatched when a user is trying to register, login etc... 
// and those actions will be available throughout our project which we will listen to in our sagas.;

export interface GetNotificationInterface {
  callback?: (res: Array<Record<string, string>>) => void;
  errorCallback?: () => void;
}

export const loginUserAction = (user: any) => ({
  type: types.LOGIN_USER,
  user
}
);

export const registerUserAction = (user: any) => ({
  type: types.REGISTER_USER,
  user
}
);

export const getMeAction = (payload?: any) => ({
  type: types.GET_ME,
  ...payload
}
);

export const allSetAction = (value: any) => ({
  type: types.ALL_SET,
  value
}
);

export const passwordForgottenAction = (user: any) => ({
  type: types.PASSWORD_FORGOTTEN,
  user
}
);

export const updatePatientAction = (user: any) => ({
  type: types.UPDATE_PATIENT,
  user
}
);

export const updateAzmmAction = (payload: any) => ({
  type: types.UPDATE_AZMM,
  ...payload
})

export const updateConsentAction = (user: any) => ({
  type: types.UPDATE_CONSENT,
  user
}
);

export const updateOnboardingQuestionsAction = (user: any) => ({
  type: types.UPDATE_ONBOARDING_QUESTIONS,
  ...user
}
);

export const updateAddressAction = (user: any) => ({
  type: types.UPDATE_ADDRESS,
  user
}
);

export const updateMoodAndSleepAction = (user: any) => ({
  type: types.UPDATE_MOOD_AND_SLEEP,
  user
}
);

export const updateMoodAndSleepNotificationsAction = (user: any) => ({
  type: types.UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS,
  user
}
);

export const updateRemindersAction = (user: any) => ({
  type: types.UPDATE_REMINDERS,
  user
}
);

export const updateMedicationIntake = (obj: any) => ({
  type: types.UPDATE_MEDICATION_INTAKE,
  obj
});

export const updateVersionAction = (obj: any) => ({
  type: types.UPDATE_VERSION,
  obj
})

export const addNeurologistAction = (user: any) => ({
  type: types.ADD_NEUROLOGIST,
  user
}
);

export const addEventAction = (payload: any) => ({
  type: types.ADD_EVENT,
  ...payload
}
);

export const addEventMoodAndSleepAction = (payload: any) => ({
  type: types.ADD_EVENT_MOOD_AND_SLEEP,
  ...payload
}
);

export const changePasswordAction = (user: any) => ({
  type: types.CHANGE_PASSWORD,
  user
}
);

export const getNeurologistAction = () => ({
  type: types.GET_NEUROLOGIST
}
);

export const getEventsAction = (obj: any) => ({
  type: types.GET_EVENTS,
  obj
}
);

export const getNightWatchEventsAction = (obj: any) => ({
  type: types.GET_NIGHTWATCH_EVENTS,
  obj
}
);

export const getTimelineEventsAction = (obj: any) => ({
  type: types.GET_TIMELINE_EVENTS,
  obj
})

export const getNotificationsAction = (payload: GetNotificationInterface) => ({
  type: types.GET_NOTIFICATIONS,
  ...payload
})

export const deleteNotificationsAction = (obj: any) => ({
  type: types.DELETE_NOTIFICATIONS,
  obj
})

export const addNotificationsAction = (obj: any) => ({
  type: types.ADD_NOTIFICATIONS,
  obj
})

export const getSeizureVideosActions = () => ({
  type: types.GET_SEIZUREVIDEOS
}
);

export const getMedicationsActions = (obj?: any) => ({
  type: types.GET_MEDICATION,
  obj
}
);

export const getMedicationsHistoryActions = (obj?: any) => ({
  type: types.GET_MEDICATION_HISTORY,
  obj
}
);

export const getEditMedicationsHistoryActions = (id: any) => ({
  type: types.GET_EDIT_MEDICATION_HISTORY,
  id
}
);

export const deleteNeurologistAction = (id: any) => ({
  type: types.DELETE_NEUROLOGIST,
  id
}
);

export const logOutAction = (payload?: any) => ({
  type: types.LOGOUT_USER,
  ...payload
}
);

export const removeAccountAction = (payload) => ({
  type: types.REMOVE_ACCOUNT,
  ...payload
}
);

export const resetSuccessModalAction = (payload) => ({
  type: types.SUCCESS_RESET,
  ...payload
}
);

export const resetErrorModalAction = (payload) => ({
  type: types.ERROR_RESET,
  ...payload
}
);
export const updatePatientSiteAction = (obj: any) => ({
  type: types.UPDATE_PATIENT_SITE,
  obj
});

export const updateDataSharingAction = (obj: any) => ({
  type: types.UPDATE_DATA_SHARING,
  ...obj
});

export const getDataSharingAction = (obj: any) => ({
  type: types.GET_DATA_SHARING,
  ...obj
});

export const updateLanguageAction = (payload: any) => ({
  type: types.UPDATE_LANGUAGE,
  ...payload,
});

export const resetSeizureVideoAction = () => ({
  type: types.RESET_SEIZUREVIDEOS
});

export const getPatientSitesAction = (payload: any) => ({
  type: types.GET_PATIENT_SITES,
  ...payload
})