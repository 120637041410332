import React, { ReactNode } from "react";
import { KeyboardAvoidingView, Platform, SafeAreaView, StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";
import { ThemeColors } from "../../../../*";

type OwnProps = {
    children: ReactNode;
}

const CommonKeyboardAvoidingView = ({ children }: OwnProps) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    return (
        <SafeAreaView style={ styles.safeAreaContainer }>
            <KeyboardAvoidingView style={styles.keyboardAvoidingViewContainer} behavior={ Platform.OS==='ios'?"padding":"height" }>
                {children}
            </KeyboardAvoidingView>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors: ThemeColors) => {
    return StyleSheet.create({
        safeAreaContainer: {
            flex: 1,
            backgroundColor: colors.background,
        },
        keyboardAvoidingViewContainer: {
            display: "flex",
            flex:1,
        }
    })
};

export default CommonKeyboardAvoidingView;