import { call, takeLatest, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import {
    VerifyEmailActions,
    ValidateEmailInterface,
} from "./verifyEmailActions";

export function* validateEmailSaga(payload: AnyAction & ValidateEmailInterface) {
    const emailValidationResponse = yield call(makeRequest, {
        path: `/validate/email`,
        method: 'POST',
        data: { id: payload.id },
        ...payload
    });
    if (emailValidationResponse.isEmailValidated) {
        const patientResponse = yield call(makeRequest, {
            path: `/patients/${payload.patientId}/v2`,
            method: 'GET',
            ...payload
        });
        yield put({
            type: VerifyEmailActions.VALIDATE_EMAIL_SUCCESS,
            response: {success: true, result: emailValidationResponse, patientData: patientResponse}
          });
        return {success: true, result: emailValidationResponse, patientData: patientResponse}
    }
    yield put({
        type: VerifyEmailActions.VALIDATE_EMAIL_SUCCESS,
        response: {success: true, result: emailValidationResponse}
      });
    return {success: true, result: emailValidationResponse}
}

export default function* watch() {
    yield takeLatest(VerifyEmailActions.VALIDATE_EMAIL, validateEmailSaga);
}
