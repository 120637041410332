import React from 'react';
import { useTheme } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import GlobalStyle from 'common/src/styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const Tab = createBottomTabNavigator();

type OwnProps = {
    children: React.ReactNode;
}


const CommonTabNavigation = ({children}: OwnProps) => {
    const { colors } = useTheme();

    return (
        <Tab.Navigator
            screenOptions={{
                headerShown: false,
                tabBarActiveTintColor: colors.tabBarActiveTintColor,
                tabBarInactiveTintColor: colors.tabBarInactiveTintColor,
                tabBarStyle: {
                    backgroundColor: colors.background,
                    borderTopColor: GlobalStyle[APP_SHORTCUT_NAME].darkColor,
                },
                tabBarIconStyle: {
                    marginBottom: PR(-5)
                },
                tabBarLabelStyle: {
                    marginBottom: PR(5)
                },
                tabBarLabelPosition: 'below-icon'
            }}
            >
            {children}
        </Tab.Navigator>
    )
}

export default CommonTabNavigation;