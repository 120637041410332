import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonTimeline from 'common/src/components/CommonTimeline';
import CommonMedicationAdherence from 'common/src/components/CommonMedicationAdherence';
import CommonBtn from 'common/src/components/CommonBtn';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import { updatePatientSiteAction, getPatientSitesAction, getDataSharingAction, getTimelineEventsAction } from 'common/src/store/actions/authenticationActions';
import { getAvailableFormsAction } from 'common/src/store/forms/formActions';
import { resetEvents } from 'common/src/store/events/eventActions';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useUpdateTimezoneAndVersion } from "common/src/hooks/timezone_version/useUpdateTimezoneAndVersion";
import { useUpdateTimezoneBanner } from "common/src/hooks/timezone_version/useUpdateTimezoneBanner";
import { useAppState } from "common/src/hooks/AppState/useAppState";
import CommonModal from 'common/src/components/CommonModal';
import { WEEK } from 'common/src/types/index';
import { getNotificationsAction } from 'common/src/store/actions/authenticationActions';
import { userLanguage } from 'common/src/store/reducers/getMeReducer';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { version } from "../../package.json";

const Home = ({ navigation }) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const Me = useSelector((state: any) => state.getMe.response?.result);
  const Patient = useSelector((state: any) => state.getMe.patient);
  const GetNotifications = useSelector((state: any) => state.getNotifications.notifications);
  const DataSharing = useSelector((state: any) => state.getMe.dataSharing)
  const language = useSelector(userLanguage);
  const [selectedTimeline, setSelectedTimeline] = useState(WEEK)
  const [medAdherenceModal, setMedAdherenceModal] = useState(false);
  const [notifsNumber, setNotifsNumber] = useState<number>(0);
  const updateTimezoneVersion = useUpdateTimezoneAndVersion({ version });
  const updateTimezoneBanner = useUpdateTimezoneBanner();
  const appState = useAppState();

  useEffect(() => {
    appState;
    updateTimezoneVersion;
    updateTimezoneBanner;
  }, []);

  useEffect(() => {
    if (Me)
      dispatch(getNotificationsAction({
        callback: (res: Array<Record<string, string>>) => {
          setNotifsNumber(res?.length || 0)
        },
        errorCallback: () => {
          setNotifsNumber(0)
        }
      }));
  }, [GetNotifications])

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    navigation.addListener('focus', () => {
      dispatch(getAvailableFormsAction());
    })
  }, [navigation])


  useEffect(() => {
    if (DataSharing?.length === 0 && Me.hasStudy && Me.showStudyDisclaimer)
      return getPatientsSites();
    if (Patient && Patient.azmm && Patient.azmmValidation === false)
      return navigation.navigate('Azmm', { nextAction: 'Home' })
  }, [Me, Patient])

  useEffect(() => {
    dispatch(getDataSharingAction({
      callback: (dataSharing) => {
        if (dataSharing?.length > 0) {
          const activeDataSharing = dataSharing.find(ds => ds.isActive);
          if (activeDataSharing) {
            return navigation.navigate('DataSharingRequest', { callback: updatePatientStudyDisclaimer, nextAction: 'Home' })
          }
        }
      }
    }))
  }, [])

  const getPatientsSites = () => {
    dispatch(getPatientSitesAction({
      patientId: Patient.id, callback: (sites) => {
        const site = sites[0]
        return navigation.navigate('StudyDisclaimer', { siteId: site._id, study: site.study[0], callback: updatePatientStudyDisclaimer })
      }
    }))
  }
  const updatePatientStudyDisclaimer = (siteId: string) => {
    dispatch(updatePatientSiteAction({ siteId, patientId: Patient.id, data: { hideDisclaimer: true } }))
    navigation.navigate('Home')
  }

  const updateTimelineView = (displayType: string) => {
    setSelectedTimeline(displayType)
  }

  const updateMedAdherenceModal = () => {
    setMedAdherenceModal(!medAdherenceModal)
  }

  const resetEventsDiary = () => {
    dispatch(resetEvents());
  }


  return (
    <>
      <SafeAreaView style={styles.container}>
        {
          medAdherenceModal &&
          <CommonModal
            type="info"
            title={t('translation:modal.titles.help')}
            text={t('translation:dashboard.medication_adherence.popup.text')}
            onClose={updateMedAdherenceModal}
            buttons={[{ title: t('translation:modal.btn.ok'), click: updateMedAdherenceModal }]} />
        }
        <CommonHeader
          title={t('translation:general.page_title.dashboard')}
          testIDTitle="Home"
          mainPage={true}
          addActionBtnChildren={
            <>
              {notifsNumber > 0 &&
                <View style={{
                  position: 'absolute',
                  bottom: Platform.OS === 'android' ? PR(20) : PR(15),
                  height: PR(20),
                  width: notifsNumber > 9 ? PR(25) : PR(20),
                  right: PR(15),
                  borderRadius: PR(40),
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: GlobalStyles.global.redError,
                  zIndex: 999,
                }}>
                  <Text style={{ color: '#fff' }}>{notifsNumber > 9 ? '9+' : notifsNumber}</Text>
                </View>
              }
              <TouchableOpacity
                onPress={() => navigation.navigate('Notifications')}
              >
                <Icon name={'bell'} color={colors.primary} size={PR(25)} />
              </TouchableOpacity>
            </>
          }
        />
        <ScrollView style={{ flex: 1 }}>
          <View style={{ marginTop: PR(25), marginBottom: PR(25) }}>
            <View style={styles.sectionHeader}>
              <View style={{ flexDirection: 'row', width: '101%', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                <View style={{ width: '70%' }}>
                  <Text style={styles.sectionHeaderText}>
                    {t("translation:dashboard.headaches.graph_title")}
                  </Text>
                </View>
                <View style={{ flex: 1, alignItems: 'center' }}>
                  <CommonBtn
                    type="secondary"
                    testID="see_more_graphs"
                    title={t('translation:dashboard.more_graphs_btn')}
                    click={() => navigation.navigate('MoreGraphs')}
                    size='small'
                  />
                </View>
              </View>
              <CommonTimeline
                resetEventsOnDiary={resetEventsDiary}
                updateTimelineView={updateTimelineView}
              />
            </View>
          </View>
          <View style={{ marginTop: PR(25), marginBottom: PR(25) }}>
            <View style={styles.sectionHeader}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.sectionHeaderText}>
                  {t('translation:dashboard.medication_adherence.title')}
                </Text>
                <TouchableOpacity style={{ marginLeft: PR(10) }} onPress={updateMedAdherenceModal} >
                  <Icon name="info" size={PR(20)} color={colors.primary} />
                </TouchableOpacity>
              </View>
              <CommonMedicationAdherence selectedDisplayType={selectedTimeline} />
            </View>
          </View>
          <View style={{ marginBottom: PR(75) }}></View>
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

const generateStyleSheet = (colors: Record<string, string>) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.background,
    },
    sectionHeader: {
      width: '90%',
      alignSelf: 'center',
      paddingVertical: PR(6),
    },
    sectionHeaderText: {
      color: colors.primary,
      fontSize: PR(20),
      fontFamily: GlobalStyles.global.fontFamily.Bold,
      letterSpacing: 0,
      marginBottom: PR(12)
    },
  });
}

export default Home;