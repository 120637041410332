import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { getListCMSData } from 'common/src/components/CommonDiary/utils';
import { useTranslation } from 'react-i18next';
import { SelectedList } from 'common/src/components/CommonAddEvents/CommonAddHeadache';

interface OwnProps {
    list: Array<SelectedList>
    clicked: (res:SelectedList) => void
    deleteFunc: (res:Array<SelectedList>) => void
}

const RescueSelectedList = ({list, clicked, deleteFunc}: OwnProps) => {
    const { t } = useTranslation();
    const colors = useTheme().colors;
    const styles: any = generateStyleSheet(colors);

    const clickedBtn = (item: SelectedList) => {
        clicked(item);
    }

    const deleteBtn = (key: number) => {
        list.splice(key, 1);
        deleteFunc(list);
    }

    return (
        <>
            {list.map((item, key) => {
                return (
                    <TouchableOpacity testID={`rescue-selected-item-${item.medication.name}`} style={styles.item} key={key} onPress={() => {
                        clickedBtn(item)
                    }}>
                        <View style={styles.itemColumn}>
                            <View style={styles.innerItem}>
                                <Text numberOfLines={1} style={[styles.itemText, {color: GlobalStyles.migraine.color, width: "90%"}]}>
                                    {item.medication.name}
                                </Text>
                            </View>
                            <Text numberOfLines={1} style={[styles.itemText, {fontSize: PR(13)}]}>
                                {getListCMSData(t, item.effect, 'list.emergencyEffects')}
                            </Text>
                        </View>
                        <TouchableOpacity style={{width: '10%'}} onPress={() => deleteBtn(key)}>
                            <Icon name="trash-2" size={PR(15)} color={GlobalStyles.global.redError}/>
                        </TouchableOpacity>
                    </TouchableOpacity>
                )
            })}
        </>
    );
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    item: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: 'center'
    },
    innerItem: {
        paddingVertical: PR(5),
        display: "flex",
        flexDirection: "row",
    },
    itemColumn: {
        paddingVertical: PR(10),
        alignSelf: 'center',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: '90%'
    },
    itemText: {
      fontSize: PR(18),
      letterSpacing: 0,
      color: colors.primary
    },
  });
}

export default RescueSelectedList;