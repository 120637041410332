import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { APP_SHORTCUT_NAME } from '../../env.json';

interface MedicationsTypes {
  name: string,
  id: string,
  navigation: any
}

const ExistingMedicationsList = ({name,id, navigation}:MedicationsTypes) => {
  return(
    <>
      <TouchableOpacity style={styles.container} onPress={() => navigation.navigate('MedicationHistory', {id:id})}>
        <View style={styles.wrapper}>
          <View style={{width:'80%'}}>
            <Text style={styles.medMomentTxt}>{name}</Text>
          </View>
          <View style={{width:'20%', alignItems:'flex-end'}}>
            <Icon 
              name={"chevron-right"} 
              size={PR(20)} 
              color={"#fff"} 
            />
          </View>
        </View>
      </TouchableOpacity>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: PR(20),
    width:'90%',
    alignSelf:'center', 
    marginBottom:PR(10),
    backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].color
  },
  wrapper: {
    flexDirection: 'row',
    alignItems:'center',
    padding:PR(10)
  },
  medMomentTxt: {
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium,
    color: GlobalStyles.global.white
  },
})

export default ExistingMedicationsList;