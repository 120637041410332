export enum HeadacheTypeActions {
    ADD_HEADACHE_TYPE = "ADD_HEADACHE_TYPE",
    ADD_HEADACHE_TYPE_SUCCESS = "ADD_HEADACHE_TYPE_SUCCESS",
    GET_HEADACHE_TYPES = "GET_HEADACHE_TYPES",
    GET_HEADACHE_TYPES_SUCCESS = "GET_HEADACHE_TYPES_SUCCESS",
    UPDATE_HEADACHE_TYPE = "UPDATE_HEADACHE_TYPE",
    UPDATE_HEADACHE_TYPE_SUCCESS = "UPDATE_HEADACHE_TYPE_SUCCESS",
    DELETE_HEADACHE_TYPES = "DELETE_HEADACHE_TYPES",
    DELETE_HEADACHE_TYPES_SUCCESS = "DELETE_HEADACHE_TYPES_SUCCESS"
}

export interface AddHeadacheTypeInterface {
    name: string,
    auras: Array<string>,
    nature: string,
    start_side: string,
    symptoms: Array<string>,
    patientID: string,
    remark: string,
    regions: regionsObject,
    successMsg: string,
    callback: () => void;
    errorCallback?: () => void;
}
interface regionsObject {
    left: Array<string>
    right: Array<string>
}

export interface UpdateHeadacheTypeInterface {
    name: string,
    auras: Array<string>,
    nature: string,
    start_side: string,
    symptoms: Array<string>,
    patientID: string,
    headacheTypeID: string,
    remark: string,
    regions: regionsObject,
    callback: () => void;
    errorCallback?: () => void;
}

export interface GetHeadacheTypesInterface {
    patientID: string,
    callback: () => void;
    errorCallback?: () => void;
}

export interface DeleteHeadacheTypesInterface {
    headacheTypeID: string,
    successMsg: string,
    callback: () => void;
    errorCallback?: () => void;
}

export const addHeadacheTypeAction = (payload: AddHeadacheTypeInterface) => {
    return {
        type: HeadacheTypeActions.ADD_HEADACHE_TYPE,
        ...payload
    };
};

export const getHeadacheTypesAction = (payload: GetHeadacheTypesInterface) => {
    return {
        type: HeadacheTypeActions.GET_HEADACHE_TYPES,
        ...payload
    }
}

export const updateHeadacheTypeAction = (payload: UpdateHeadacheTypeInterface) => {
    return {
        type: HeadacheTypeActions.UPDATE_HEADACHE_TYPE,
        ...payload
    }
}


export const deleteHeadacheTypesAction = (payload: DeleteHeadacheTypesInterface) => {
    return {
        type: HeadacheTypeActions.DELETE_HEADACHE_TYPES,
        ...payload
    }
}