import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { View, Platform, Text } from 'react-native';
import Home from './Home';
import Diary from './Diary';
import Treatments from './Treatments';
import Menu from './Menu';
import Icon from 'react-native-vector-icons/Feather';
import CommonTabNavigation from 'common/src/components/CommonTabNavigation';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import AddHeadacheFromTab from './AddHeadacheFromTab';
import { useSelector } from 'react-redux';
import { hasSite } from 'common/src/services/utils';
import { RouteProp } from '@react-navigation/native';
import { RootStackParams } from 'common/src/types';
import { useTranslation } from 'react-i18next';
import CommonImage from 'common/src/components/CommonImage';

const Tab = createBottomTabNavigator();

type OwnProps = {
    route: RouteProp<RootStackParams>;
  }

const TabNavigation = ({route}:OwnProps) => {
    const Me = useSelector((state: any) => state.getMe.response?.result);
    const availableForms = useSelector((state: any) => state.getForms?.availableForms);
    const { t } = useTranslation();
        
    return (
        <CommonTabNavigation>
            <Tab.Screen
                name='Home'
                component={Home}
                options={{
                    tabBarLabel: t('translation:general.page_title.dashboard'),
                    tabBarIcon: ({ color, size }) => (
                        <Icon name="home" color={color} size={size} />
                    )
                }}
            />
            <Tab.Screen
                name= "Diary"
                component={Diary}
                options={{
                    tabBarLabel: t('translation:general.page_title.journal'),
                    tabBarIcon: ({ color, size }) => (
                            <Icon name="book" color={color} size={size} />
                    )
                }}
            />
            
            <Tab.Screen 
                name="AddHeadacheFromTab" 
                component={AddHeadacheFromTab}
                initialParams={{itemType: 'Headache'}}
                options={{
                    unmountOnBlur: true,
                    tabBarLabel: '',
                    tabBarIcon: () => (
                        <View
                            style={{
                                position: 'absolute',
                                bottom: Platform.OS === "web" ? PR(2) : PR(-10), // space from bottombar
                                height: PR(70),
                                width: PR(70),
                                borderRadius: PR(70),
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: GlobalStyles.migraine.color
                            }}
                        >
                            <CommonImage name="headacheIconWhite" height={PR(40)} width={PR(40)}/>
                        </View>
                    ),
                    tabBarStyle: {
                        display: "none",
                    },
                }}
            />
            <Tab.Screen 
                name="Treatments"
                component={Treatments} 
                options={{
                    tabBarLabel:t('translation:general.page_title.medication'),
                    tabBarIcon: ({ color, size }) => (
                        <Icon name="folder-plus" color={color} size={size} />
                    )
                }}
            />
            <Tab.Screen 
                name='Menu'
                component={Menu}
                initialParams={route}
                options={{
                    tabBarLabel: t('translation:general.page_title.menu'),
                    tabBarIcon: ({ color, size }) => (
                        Me?.hasStudy && hasSite(availableForms) ?
                            <>
                                <View style={{
                                    position: 'absolute',
                                    bottom: Platform.OS === 'android' ? PR(20) : PR(15),
                                    height: PR(20),
                                    width: PR(20),
                                    right: PR(15),
                                    borderRadius: PR(40),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: GlobalStyles.global.redError,
                                    zIndex: 999,
                                }}>
                                    <Text style={{color:'#fff'}}>{"!"}</Text>
                                </View>
                                <Icon name="menu" color={color} size={size} />
                            </>
                            :
                            <Icon name="menu" color={color} size={size} />
                    )
                }}
            />
        </CommonTabNavigation>
    )
}

export default TabNavigation