import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@react-navigation/native';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Text from 'common/src/components/CommonCustomTxt';
import calculateMedicationAdherence from './calculate-medication-adherence';

const CommonMedicationAdherence = ({selectedDisplayType}) => {
    const { colors } = useTheme();
    const {t} = useTranslation()
    const [medicationAdherence, setMedicationAdherence] = useState(0)
    const [hasMedication, setHasMedication] = useState(false);
    const GetEventsResponse = useSelector(state => state.getEvents.timeline_events);

    useEffect(()=> {
        if (GetEventsResponse) {
            const patientMedication = checkMedication(GetEventsResponse)
            if (patientMedication) {
                setMedicationAdherence(
                    calculateMedicationAdherence(GetEventsResponse)
                );
                setHasMedication(patientMedication);
            }
        }
    }, [GetEventsResponse])

    const checkMedication = (eventDates: any[]) => {
        return eventDates
            .filter(date => Array.isArray(date.reminders) && date.reminders.length > 0).length > 0
    }

    return (
        <View>
            {
                hasMedication ?
                    <Text style={{color: colors.primary}}>{t('translation:dashboard.medication_adherence.text.' + selectedDisplayType) + ' ' + medicationAdherence}%</Text>
                    :
                    <Text style={{color: colors.primary}}>{t('translation:treatment.general.no_medication')}</Text>
            }
        </View>
    )
}

export default CommonMedicationAdherence