import React, {useState} from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonSelect from '../CommonSelect';
import CommonBtn from '../CommonBtn';
import Text from '../CommonCustomTxt';
import InputField from '../InputField';
import Daily from './FrequencyQuestions/Daily';
import GlobalStyles from '../../styles/GlobalStyles';
import SeveralTimes from './FrequencyQuestions/SeveralTimes';
import MonthlyQuartely from './FrequencyQuestions/MonthlyQuartely';
import Other from './FrequencyQuestions/Other';
import Icon from 'react-native-vector-icons/Feather';
import CommonFooter from '../CommonFooter';
import { useTranslation } from 'react-i18next';
import { DateTime } from "luxon";
import {convertLangCode} from 'common/src/services/translationList';
import {checkBottomScroll} from '../../services/utils';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

interface TreatFreaquencyTypes {
  getAnswers: any,
  freqStep: string,
  freqData: any,
  back:any,
  newDose?: boolean,
  editTreat?: boolean
}

const CommonAddTreatmentFequency = ({getAnswers, freqStep, freqData, back, newDose, editTreat}:TreatFreaquencyTypes) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t, i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [steps, setSteps] = useState<string>(freqStep)
  const [button, setButton] = useState<boolean>(freqData?.frequency? false : true)
  const [currentData, setCurrentData] = useState<string>(freqData?.frequency || '');
  const [dailyData, setDailyData] = useState<any>();
  const [weeklyData, setWeeklyData] = useState([]);
  const [dataMQ, setData_MQ] = useState<any>();
  const [otherData, setOtherData] = useState<any>();
  const [dailyStep, setDailyStep] = useState<string>('daily');
  const [dF, setDF] = useState<boolean>(freqData?.dosage ? true : false)
  const [dFValue, setDFValue] = useState<string>();
  const [remarks, setRemarks] = useState<string | undefined>(freqData?.remarks || undefined);
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false); 
  
  const preSelected = (value) => {
    if(JSON.parse(value.replace(/[\[\]']+/g,'')) === currentData) {
      return true
    }
  }

  const updateData = (updatedData) => {
    updatedData.map((item:any) => {
      if(item.selected !== undefined && item.selected){
        setCurrentData(JSON.parse(item.value.replace(/[\[\]']+/g,'')))
        setButton(false)
        getAnswers({step: 1})
        setDailyStep('daily')
        setDF(editTreat && freqData?.dosage ? true : false)
      }
    })
  }

  const dailyFrequency = (data) => {
    setDFValue(data)
  }

  const weeklyInfo = (data) => {
    setWeeklyData(data)
    setDailyStep('weekly')
    setCurrentData('daily')
  }

  const MQ_data = (data) => {
    setData_MQ(data)
  }

  const other_data = (data) => {
    setOtherData(data)
    setCurrentData('monthly')
  }

  const sendBackAnswers = (prop?) =>{
    setSteps(currentData)
    setButton(!button)
    let obj:any = {}
    if(dailyData !== undefined){
      obj = dailyData
      if(remarks !== undefined) 
        Object.assign(obj, {frequency:dailyStep, remarks});
      else
        Object.assign(obj, {frequency:dailyStep});
      getAnswers(obj)
    }
    else if(dFValue !== undefined){
      setDF(true)
    }
    else if(otherData !== undefined && dataMQ !== undefined){
      obj = otherData
      if(remarks !== undefined) 
        Object.assign(obj, dataMQ, {remarks});
      else
        Object.assign(obj, dataMQ);
      getAnswers(obj)
    }
    else if(dataMQ !== undefined && otherData === undefined){
      obj = dataMQ
      if(remarks !== undefined)
        Object.assign(obj, {frequency: dataMQ.period, remarks});
      else
        Object.assign(obj, {frequency: dataMQ.period});
      getAnswers(obj)
    }
    else if(prop === 'no') getAnswers({remarks})

    // 
  }

  const goBack = (currentStep?) => {
    if(steps === 'drug'){
      back()
    }
    else {
      setSteps('drug')
      setCurrentData(dailyStep === 'weekly' ? dailyStep : currentStep)
      setButton(false)
      setDF(false)
      setDFValue(undefined)
      setDailyData(undefined)
    }
    
  }
  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => (button)
  
  return(
    <>
      <ScrollView
        scrollEventThrottle={16}
        onScroll={(event) => {
          let calc = checkBottomScroll(event.nativeEvent, PR(15));
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
        }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          <View style={styles.horizontalDiv}></View>
          {steps === 'drug' &&
            <View style={styles.questContainer}>
              <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_frequency.step_0.question')}</Text>
              <View style={{marginBottom:PR(15)}}></View>
              {/* @ts-ignore */}
              <CommonSelect type={'radio'} data={t<string | TemplateStringsArray>('translation:list.medicationFrequencies', {returnObjects: true}).map((name,i) => {
                return {label:t('translation:list.medicationFrequencies'+'.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), position: i, selected: preSelected(JSON.stringify(Object.keys(name)))}
              })} onSelect={updateData}/>
              <View style={{marginBottom:PR(15)}}></View>
            </View>
          }
          { ['device', 'diet', 'exercices', 'custom'].indexOf(steps) >= 0 &&
            <View style={styles.questContainer}>
              <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_frequency.step_4.label')}</Text>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"yesID"} type={'secondary'} title={t('translation:treatment.add_medication.step_frequency.step_4.one')} click={() => {
                setSteps('drug')
              }}/>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn
                testID={"anotherReasonID"}
                type={'secondary'}
                title={t('translation:treatment.add_medication.step_frequency.step_4.two')}
                click={() => {
                  sendBackAnswers('no')
                }}
              />
              <View style={{marginBottom:PR(15)}}></View>
              <InputField 
                labelText={t('translation:general.events.remark')}
                inputType="string" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setRemarks}
                placeholderTxt={t('translation:general.events.remark_placeholder')} 
                testID="remarksID"
                value={remarks}
              />
            </View>
          }
          {steps === "daily" &&
            <Daily activateBtn={setButton} treatmentType={freqStep} step={dailyStep} boolDF={dF} checkDF={dailyFrequency} getData={setDailyData} weeklyDays={weeklyData} savedData={freqData}/>
          }
          {steps === "weekly" &&
            <SeveralTimes activateBtn={setButton} getData={weeklyInfo} savedWeekDays={freqData?.week_frequency}/>
          }
          {steps === "monthly" &&
            <MonthlyQuartely activateBtn={setButton} treatmentType={freqStep} getData={MQ_data} period="monthly" savedMQData={freqData}/>
          }
          {steps === "quarterly" &&
            <MonthlyQuartely activateBtn={setButton} treatmentType={freqStep} getData={MQ_data} period="quarterly" savedMQData={freqData}/>
          }
          {steps === "other" &&
            <Other activateBtn={setButton} getData={other_data} savedOtherData={freqData}/>
          }
          {newDose && freqData?.from && freqData?.dose !== '' &&
            <View style={styles.newDoseContainer}>
              <View style={styles.newDoseWrapper}>
                <Icon name={'info'} size={PR(25)} style={{color:GlobalStyles.global.white, paddingRight:10}}/>
                <Text style={styles.warningTxt}>
                  {t('translation:treatment.intakes.warnings.previous_dose', {from: freqData?.from ? DateTime.fromISO(freqData?.from, { locale: lang }).toFormat('MMM dd, yyyy') : null, dose:freqData?.dose.filter(Boolean)})}
                  {freqData?.to &&
                    t('translation:treatment.intakes.warnings.previous_dose_end', {to: freqData?.to ? DateTime.fromISO(freqData?.to, { locale: lang }).toFormat('MMM dd, yyyy') : null})
                  }
                </Text>
              </View>
            </View>
          }
        </View>
      </ScrollView>
      <CommonFooter atBottom={contentHeight+PR(250)} direction={'row'} reachBottom={scrollAtBottom}>
        <View style={{flexGrow: 0.5, flex:1}}>
          <CommonBtn testID={"previousBtnID"} type={'secondary'} title={t('translation:general.previous')} click={() => {goBack(currentData)}}/>
        </View>
        <View style={{flexGrow: 0.5, flex:1}}>
          <CommonBtn testID={"nextBtnID"} type={'primary'} title={t('translation:general.next')} disable={checkDisable()}  click={() => {sendBackAnswers()}}/>
        </View>
      </CommonFooter>
      <View style={{marginBottom:PR(15)}}></View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    horizontalDiv: {
      borderTopWidth: PR(1), 
      borderTopColor: GlobalStyles.global.lightGrey,
      width: '90%',
      paddingBottom:PR(20),
      alignSelf:'center'
    },
    questContainer: {
      paddingTop: PR(20),
      width: '90%',
      alignItems: 'center',
      alignSelf:'center'
    },
    questTxt: {
      fontSize: PR(16),
      color: colors.primary
    },
    label: { 
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(12)
    },
    newDoseContainer: {
      width: '90%',
      alignSelf: 'center',
      backgroundColor: GlobalStyles.global.redError,
      borderRadius: PR(20),
      padding: PR(10),
      marginBottom:PR(15)
    },
    newDoseWrapper: {
      width: '80%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    warningTxt: {
      fontSize: PR(14),
      color: GlobalStyles.global.white
    }
  })
}

export default CommonAddTreatmentFequency;