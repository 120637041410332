import {DateTime} from 'luxon';

export const compareDates = (start, end, format) => {
  const d1 = DateTime.fromFormat(start, format);
  const d2 = DateTime.fromFormat(end, format);
  const diff = d2.diff(d1, 'days')
  if (diff.days > 0)
    return false
  else return true
}

export const compareDatesByMinute = (date1: string, date2: string, format: string): boolean => {
  const d1 = DateTime.fromFormat(date1, format);
  const d2 = DateTime.fromFormat(date2, format);
  const diff = d2.diff(d1, "minutes");
  return diff.minutes > 0;
}

export const isDatesLessThanNinety = (start, end, format) => {
  const d1 = DateTime.fromFormat(start, format);
  const d2 = DateTime.fromFormat(end, format);
  const res = d2.diff(d1, ['days'])
  return res.days < 90 ? true : false
}

export const isMoreThanOneYear = (start, end, format) => {
  const d1 = DateTime.fromFormat(start, format);
  const d2 = DateTime.fromFormat(end, format);
  const res = d2.diff(d1, ['days'])
  return res.days > 365 ? true : false
}

export const compareMoodAndSleepDates = (start, end, format) => {
  const d1 = DateTime.fromFormat(start, format);
  const d2 = DateTime.fromFormat(end, format);
  if (d2.diff(d1, 'days') > 0)
    return true
  else return false
}

export const compareDatesForEvents = (start, end, format) => {
  const d1 = DateTime.fromFormat(start, format);
  const d2 = DateTime.fromFormat(end, format);
  if (d2.diff(d1, 'days') >= 0)
    return false
  else return true
}

export const areDatesEqual = (start, end, format) => {
  const d1 = DateTime.fromFormat(start, format);
  const d2 = DateTime.fromFormat(end, format);
  const diff = d2.diff(d1, 'days')
  return diff.days === 0
}

export const countReportCreated = (start,end, format, t) => {
  const d1 = DateTime.fromFormat(start, format);
  const d2 = DateTime.fromFormat(end, format);
  const diff = d2.diff(d1, ["years", "months", "days"])
  if(diff.years === 0 && diff.months === 0 && diff.days === 0)
    return t('translation:web_dashboard.general.today')
  else if(diff.years > 0 && diff.months === 0)
    return diff.years+' '+ t('translation:report.globals.years_ago')
  else if(diff.years > 0 && diff.months > 0)
    return diff.years+' '+ (diff.years > 1 ? t('translation:report.globals.years'): t('translation:report.globals.year')) + " & " + diff.months +' '+ t('translation:report.globals.months_ago')
  else if(diff.years === 0 && diff.months > 0 && diff.days === 0)
    return diff.months +' '+ t('translation:report.globals.months_ago')
  else if(diff.years === 0 && diff.months > 0 && diff.days > 0)
    return diff.months +' '+ (diff.months > 1 ? t('translation:report.globals.months'): t('translation:report.globals.month')) + " & " + diff.days +' '+ t('translation:report.globals.days_ago')
  else 
    return diff.days +' '+ t('translation:report.globals.days_ago')
}

export const timeDiff = (medDate, date1, date2) => {
  let firstDate = DateTime.fromFormat(medDate, 'yyyy-MM-dd')
  firstDate = firstDate.set({hour: date1.split(':')[0]}).set({minutes: date1.split(':')[1]}).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const d1:any = new Date(firstDate)
  const d2:any = new Date(date2)
  const difference = DateTime.fromJSDate(d2).diff(DateTime.fromJSDate(d1), ["hours", "minutes"])
  let diff = difference.toObject()
  let hh = diff.hours
  let mm = diff.minutes
  let h
  let m
  if(hh < 10 && hh >= 0){
    h = '0'+Math.abs(hh)
  }
  else if(hh > -10 && hh <= 0){
    h = '0'+Math.abs(hh)
  } else {
    h = Math.abs(hh)
  }
  if(Math.abs(mm) < 10){
    m = '0'+Math.abs(Math.round(mm))
  } else{
    m = Math.abs(Math.round(mm))
  }
  if(mm < 0 || hh < 0)
    h = '-'+h
  else
    h = '+'+h
  
  if(hh === 0 && m === '00')
    return;
  else 
    return '('+h+":"+m+')'
}