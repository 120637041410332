import React from 'react';
import {View, StyleSheet} from 'react-native';
import CommonSelectItem from "./CommonSelectItem";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';


const CommonRadioInput = ({data, otherData, onSelect, selectedValue, onDelete, objectsData, dataFieldToDisplay, info, infoData}) => {

    return (
        <View style={styles.container}>
            {data && [
                
              ...data.map((item, index) => {
                const infoItem = info && infoData[index];
                return (
                    <View key={index}>
                      <CommonSelectItem
                          infoText={infoItem ? infoItem.label : undefined}
                          infoTitle={'Help'}                          
                          index={index}
                          text={item.label}
                          onClick={() => onSelect(item.value)}
                          selected={item.value === selectedValue}
                      />
                  </View>
                )
              }),
                ...objectsData.map((item, index) => (
                    <View key={index}>
                        <CommonSelectItem
                            index={index}
                            text={item[dataFieldToDisplay]}
                            onClick={() => onSelect(item.value)}
                            selected={item.value === selectedValue}
                        />
                    </View>
                )),
            ]}
            {otherData && otherData.map((item, index) => (
                <View key={index}>
                    <CommonSelectItem
                        index={index}
                        text={item}
                        showDelete
                        onClick={() => onSelect(item)}
                        selected={item === selectedValue}
                        onDelete={() => onDelete(item)}
                    />
                </View>
            ))}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: PR(15),
    }
});


export default CommonRadioInput;