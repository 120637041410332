import React from 'react';
import AddEvent from './AddEvent';

const AddHeadacheFromTab = ({navigation}) => {
    const initialData = {
        params: {
            itemType: 'Headache'
        }
    }
    return(
        <AddEvent navigation={navigation} route={initialData}/>
    )
}

export default AddHeadacheFromTab;