import React, { useState } from 'react';
import { View, StyleSheet, ScrollView, SafeAreaView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import Text from '../CommonCustomTxt';
import CommonImage from '../CommonImage';
import CommonBtn from '../CommonBtn';
import CommonHeader from '../CommonHeader';
import CommonAcceptanceCheckbox from '../CommonAcceptanceCheckbox';
import GlobalStyles from '../../styles/GlobalStyles';
import CommonRefuseDataSharing from './CommonRefuseDataSharing';
import { User } from '../../types';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import {APP_SHORTCUT_NAME } from '../../env.json';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { ThemeColors } from '../../../../../*';

interface OwnProps {
    patient?: User;
    doctor: User;
    logout: () => void;
    updateDataSharing: (accepted: boolean, doctorId: string) => void;
}

const CommonDataSharing = ({ doctor, logout, updateDataSharing }: OwnProps) => {
    const {t} = useTranslation();
    const [checked, setChecked] = useState(false);
    const [showRefuseDialog, setShowRefuseDialog] = useState(false);
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const updateDataValidation = (value) => {
        setChecked(value)
    }

    const diseaseName = APP_SHORTCUT_NAME === 'migraine' ? 'headache' : 'epilepsy'
    const logoURI = doctor.emailTemplate ? t(`translation:server.neuro_custom.${doctor.emailTemplate}.logo_${diseaseName}.value`) : undefined;
    const logoName = logoURI ? "" : "doctorDefaultLogo";

    return (
        <SafeAreaView style={styles.container}>
            { showRefuseDialog && <CommonRefuseDataSharing onClose={() => { setShowRefuseDialog(false); }} onConfirm={() => { setShowRefuseDialog(false); updateDataSharing(false, doctor.id); }} /> }
            <CommonHeader
                testIDTitle="datasharing"
                title={t('translation:general.page_title.neurologist')}
                rightIconNameFirst="log-out"
                rightIconColorFirst={GlobalStyles[APP_SHORTCUT_NAME].btnColor}
                rightClickFirst={logout}
                disableFormsBanner={true}
            />
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{marginBottom: PR(170)}}
            >
                <View style={{width: '90%', alignSelf:'center',}}>
                    <CommonImage name={logoName} uri={logoURI} height={PR(125)} width={PR(225)} style={{alignSelf: 'center', margin: PR(10)}}/>
                    <Text renderAsHtml={true} style={styles.text}>{t('translation:onboarding.neurologist.invite_title').replace('$firstname', doctor.first_name).replace('$lastname', doctor.last_name)}</Text>
                    <CommonAcceptanceCheckbox testID="acceptanceCheckbox" text={t('translation:onboarding.neurologist.accept_share_data')} updateValidation={updateDataValidation} checked={false}/>
                </View>
            </ScrollView>
            <View style={styles.btnContainer}>
                <View style={{marginBottom: PR(10)}}>
                    <CommonBtn type="secondary" title={t('translation:onboarding.neurologist.btn_not_share_data')} click={() => {setShowRefuseDialog(true);}}/>
                </View>
                <CommonBtn type="primary" title={t('translation:onboarding.neurologist.btn_share_data')} click={() => {updateDataSharing(true, doctor.id)}} disable={!checked}/>
            </View>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors: ThemeColors) => {
    return StyleSheet.create({
        container: {
            backgroundColor: colors.background,
            flex: 1,
        },
        text: {
            fontSize: PR(22),
            marginBottom: "10%"
        },
        btnContainer: {
            flex: 1,
            position: 'absolute',
            width: '100%',
            alignSelf: 'center',
            bottom: PR(35)
        }
    })
} 

export default CommonDataSharing;