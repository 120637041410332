export enum DigaRegistrationActions {
    CHECK_EMAIL_EXISTANCE = "CHECK_EMAIL_EXISTANCE",
    VERIFY_DIGA_CODE = "VERIFY_DIGA_CODE",
    REGISTER_PATIENT_TO_PROGRAM = "REGISTER_PATIENT_TO_PROGRAM"
}

export interface CheckEmailInterface {
    email: string,
    callback?: () => void;
    errorCallback?: () => void;
}

export interface VerifyDigaCodeInterface {
    digaCode: string,
    callback?: () => void;
    errorCallback?: () => void;
}

export interface RegistePatientToProgramInterface {
    patientId: string,
    programId: string,
    callback?: () => void;
    errorCallback?: () => void;
}

export const checkEmailExistanceAction = (payload: CheckEmailInterface) => {
    return {
        type: DigaRegistrationActions.CHECK_EMAIL_EXISTANCE,
        ...payload
    };
};

export const verifyDigaCodeAction = (payload: VerifyDigaCodeInterface) => {
    return {
        type: DigaRegistrationActions.VERIFY_DIGA_CODE,
        ...payload
    };
};

export const registerPatientToProgramAction = (payload: RegistePatientToProgramInterface) => {
    return {
        type: DigaRegistrationActions.REGISTER_PATIENT_TO_PROGRAM,
        ...payload
    }
}