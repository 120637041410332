import React, { useState } from 'react';
import {View, TouchableOpacity, Platform} from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from './CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from '../styles/GlobalStyles';
import { APP_SHORTCUT_NAME } from '../env.json';
import { StyleSheet } from 'react-native';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonAcceptanceCheckbox = ({text, updateValidation, checked, testID, disabled= false}) => {
    const colors = useTheme().colors;
    const [isChecked, setIsChecked] = useState(checked);

    const styles = generateStyle(colors, isChecked, disabled);

    const updateCheck = () => {
        updateValidation(!isChecked)
        setIsChecked(!isChecked)
    }

    return (
        <>
            <TouchableOpacity disabled={disabled} style={styles.container} onPress={updateCheck} testID={testID}>
                <View style={styles.textContainer}>
                    <Text renderAsHtml={true} style={styles.text}>{text}</Text>
                </View>
                <View style={styles.iconContainer}>
                    <Icon name="check" size={PR(12)} color={isChecked ? GlobalStyles[APP_SHORTCUT_NAME].color : colors.acceptanceContainer}/>
                </View>
            </TouchableOpacity>
        </>
    )
}

const generateStyle = (colors:Record<string, string>, isChecked:boolean, disabled:boolean) => {
    return StyleSheet.create({
        container: {
            flexDirection: "row",
            borderWidth: PR(2),
            padding: PR(15),
            borderColor: isChecked ? GlobalStyles[APP_SHORTCUT_NAME].color : colors.acceptanceContainer,
            borderRadius: PR(10),
            opacity: disabled && Platform.OS === 'android' ? 0.4 : disabled && Platform.OS !== 'android' ? 0.2 : 1
        },
        textContainer: {
            flex: 1,
            flexGrow: 1
        },
        text: {
            width: '100%',
            fontSize: PR(16),
            color: colors.acceptanceTxt
        },
        iconContainer: {
            alignSelf: 'flex-end',
            justifyContent: "center",
            alignItems: "center",
            borderWidth: PR(2),
            borderRadius: PR(25),
            borderColor: isChecked ? GlobalStyles[APP_SHORTCUT_NAME].color : colors.acceptanceContainer,
            padding: PR(2),
            maxHeight: PR(20)
        }
    })
}

export default CommonAcceptanceCheckbox
