import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonSettingsBtn = (props:any) => {
  const colors = useTheme().colors;
  const styles: any = generateStyleSheet(colors);
  const clickedBtn = (itemName) => {
    
    props.clicked(itemName)
  }
  return (
    <>
      {props.listNames.map((item, key) => {
        return (
          <TouchableOpacity testID={`setting-item-${item.value}`} key={key} style={styles.item} onPress={() => {
            // TODO redirect to account page
            clickedBtn(item.value)
          }}>
            <Text numberOfLines={1} style={styles.itemText}>
              {item.name}
            </Text>
            <Icon name={"chevron-right"} size={PR(25)} color={colors.primary} style={{opacity:0.5}} />
          </TouchableOpacity>
        )
      })}
    </>
  );
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    item: {
      paddingVertical: PR(10),
      width: '90%',
      alignSelf: 'center',
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row"
    },
    itemText: {
      fontSize: PR(18),
      letterSpacing: 0,
      width: '90%',
      color: colors.primary
    },
  });
}

export default CommonSettingsBtn;