import { call, takeLatest } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { 
    HeadacheTypeActions, 
    AddHeadacheTypeInterface,
    GetHeadacheTypesInterface,
    DeleteHeadacheTypesInterface,
    UpdateHeadacheTypeInterface
} from "./headacheTypesActions";

export function* addHeadacheTypeSaga(payload: AnyAction & AddHeadacheTypeInterface) {
    yield call(makeRequest, {
        path: `/headaches`,
        method: 'POST',
        responseAction: HeadacheTypeActions.ADD_HEADACHE_TYPE_SUCCESS,
        data: {
            name: payload.name,
            auras: payload.auras,
            nature: payload.nature,
            regions: payload.regions,
            start_side: payload.start_side,
            symptoms: payload.symptoms,
            remark: payload.remark,
            patient_id: payload.patientID,
        },
        successMessage: payload.successMsg,
        ...payload
    });
}

export function* updateHeadacheTypeSaga(payload: AnyAction & UpdateHeadacheTypeInterface) {
    yield call(makeRequest, {
        path: `/headaches/${payload.headacheTypeID}`,
        method: 'PATCH',
        responseAction: HeadacheTypeActions.UPDATE_HEADACHE_TYPE_SUCCESS,
        data: {
            name: payload.name,
            auras: payload.auras,
            nature: payload.nature,
            regions: payload.regions,
            start_side: payload.start_side,
            symptoms: payload.symptoms,
            remark: payload.remark,
            patient_id: payload.patientID,
        },
        ...payload
    });
}

export function* getHeadacheTypesSaga(payload: AnyAction & GetHeadacheTypesInterface) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientID}/headaches`,
        method: 'GET',
        responseAction: HeadacheTypeActions.GET_HEADACHE_TYPES_SUCCESS,
        ...payload
    });
}

export function* deleteHeadacheTypeSaga(payload: AnyAction & DeleteHeadacheTypesInterface) {
    yield call(makeRequest, {
        path: `/headaches/${payload.headacheTypeID}`,
        method: 'DELETE',
        responseAction: HeadacheTypeActions.DELETE_HEADACHE_TYPES_SUCCESS,
        successMessage: payload.successMsg,
        ...payload
    });
}

export default function* watch() {
    yield takeLatest(HeadacheTypeActions.ADD_HEADACHE_TYPE, addHeadacheTypeSaga);
    yield takeLatest(HeadacheTypeActions.GET_HEADACHE_TYPES, getHeadacheTypesSaga);
    yield takeLatest(HeadacheTypeActions.DELETE_HEADACHE_TYPES, deleteHeadacheTypeSaga);
    yield takeLatest(HeadacheTypeActions.UPDATE_HEADACHE_TYPE, updateHeadacheTypeSaga);
}