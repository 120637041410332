import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, ScrollView, SafeAreaView, TouchableOpacity, Platform, Animated } from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonTitle from 'common/src/components/CommonTitle';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import CommonMedications from 'common/src/components/CommonMedications';
import CommonMedicationsHistoryCurrentFuture from 'common/src/components/CommonMedicationsHistoryCurrentFuture';
import CommonMedicationHistoryPast from 'common/src/components/CommonMedicationsHistoryPast';
import CommonFooter from 'common/src/components/CommonFooter';
import CommonSimpleModal from 'common/src/components/CommonSimpleModal';
import Icon from 'react-native-vector-icons/Feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMedicationsActions, getMedicationsHistoryActions } from 'common/src/store/actions/authenticationActions';
import { getRescueAction } from 'common/src/store/treatments/rescueActions';
import { DateTime } from "luxon";
import { checkBottomScroll } from 'common/src/services/utils';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import Loading from 'common/src/components/Loading';
import { APP_SHORTCUT_NAME } from '../env.json';
import { RootState } from 'common/src/store/reducers';

const Timeline = ['current.label', 'past_and_future.label']

const Treatments = ({ navigation }) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const today = DateTime.now();
  const medications = useSelector((state: any) => state.getMedications.medications)
  const medicationsHistory = useSelector((state: any) => state.getMedications.medicationsHistory)
  const rescueMedications = useSelector((state: RootState) => state.rescueReducer.rescueMedications);
  const ProfileResponse = useSelector((state:any) => state.getMe.response)
  const [selectedDisplayType, setSelected] = useState<string>('current.label');
  const [past, setPast] = useState<Array<any>>([]);
  const [currentFuture, setCurrentFuture] = useState<Array<any>>([]);
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [addModal, setAddModal] = useState<boolean>(false);
  const [showText, setShowTxt] = useState<string>("");
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setIsLoading(true)
    dispatch(getMedicationsActions({
      callback: () => {
        dispatch(getMedicationsHistoryActions({
          callback: () => {
            setIsLoading(false)
          }
        }));
      }
    }));
    dispatch(getRescueAction({
      patientID: ProfileResponse?.result?.patients?.id,
      callback: () => {
        setIsLoading(false)
      },
      errorCallback: () => {
        setIsLoading(false)
      }
    }))
  }, [])

  useEffect(() => {
    if (medicationsHistory.length > 0)
      splitMedications();
    else {
      setPast([])
      setCurrentFuture([])
    }
  }, [medicationsHistory])

  // split Medication History data to past and currentFuture
  const splitMedications = () => {
    let pastArr: any = []
    let curFutArr: any = []
    medicationsHistory.forEach(item => {
      if (!item.intake)
        pastArr.push(item)
      else if (item?.intake?.to && today.toFormat('yyyy-MM-dd') > item?.intake?.to) {
        pastArr.push(item)
      } else {
        curFutArr.push(item)
      }
    })
    pastArr.sort((a) => (a.reason !== 'headache') ? 1 : -1)
    setPast(pastArr)
    curFutArr.sort((a) => (a.reason !== 'headache') ? 1 : -1)
    setCurrentFuture(curFutArr)
  }

  const popIn = () => {
    Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
    }).start(() => popOut());
  };

  const popOut = () => {
    setTimeout(() => {
      Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
      }).start();
    }, 10000);
  };

  const instantPopOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };

  const addNewRescueBtn = () => {
    setAddModal(false);

    if (rescueMedications.length > 0)
      navigation.navigate('AddRescueMedication')
    else
      navigation.navigate('AddEmergencyMedication', {medicationDetails: {}});
  }

  const addNewTreatmentBtn = () => {
    setAddModal(false);

    if (medications.length > 0)
      navigation.navigate('AddMedication', { medications })
    else
      navigation.navigate('AddTreatments')
  }

  const editIntakeHours = () => {
    navigation.navigate('AddEvent', { itemType: 'Reminder' })
  }

  const changeDisplayList = () => {
    const newDisplayType = selectedDisplayType === 'current.label' ? 'past_and_future.label' : 'current.label';
    setSelected(newDisplayType)
  }

  if (isLoading)
    return <Loading netConnected={true} />
  else
    return (
      <SafeAreaView style={styles.container}>
        {addModal &&
          <CommonSimpleModal
              title={t('translation:headache_event.medicationType.title')}
              onClose={()=>setAddModal(false)}
            >
              <View>
                <View style={{marginBottom:PR(15), alignItems:'center'}}>
                  <Text>{t('translation:headache_event.medicationType.subtitle')}</Text>
                </View>
                <View style={{flexDirection:'row', alignItems:'center', alignSelf:'center', justifyContent:'space-between'}}>
                  <CommonBtn 
                    testID={"AddNewRescueBtnID"} 
                    type={'primary'} 
                    title={t('translation:headache_event.medicationType.emergencyButton')} 
                    click={() => addNewRescueBtn()}
                  />
                  <TouchableOpacity 
                    style={{marginLeft:PR(10)}}
                    onPress={() => {popIn(); setShowTxt(t('translation:headache_event.medicationType.helpEmergency'))}}
                  >
                    <Icon name="info" size={PR(22)} color={GlobalStyles.migraine.color}/>
                  </TouchableOpacity>
                </View>
                <View style={{marginBottom:PR(15)}}></View>
                <View style={{flexDirection:'row', alignItems:'center', alignSelf:'center', justifyContent:'space-between'}}>
                  <CommonBtn 
                    testID={"AddNewTreatmentBtnID"} 
                    type={'secondary'} 
                    title={t('translation:headache_event.medicationType.recurringButton')} 
                    click={() => {addNewTreatmentBtn()}}
                  />
                  <TouchableOpacity 
                    style={{marginLeft:PR(10)}}
                    onPress={() => {popIn(); setShowTxt(t('translation:headache_event.medicationType.helpRecurring'))}}
                  >
                    <Icon name="info" size={PR(22)} color={GlobalStyles.migraine.color}/>
                  </TouchableOpacity>
                </View>
                <Animated.View style={[styles.toastContainer, {opacity: fadeAnim}]}>
                  <View style={{width:'80%'}}>
                    <Text renderAsHtml={true} style={styles.text}>{showText}</Text>
                  </View>
                  <TouchableOpacity 
                    onPress={() => {instantPopOut()}}
                  >
                    <Icon name="x" size={PR(30)} color={GlobalStyles.global.redError}/>
                  </TouchableOpacity>
                </Animated.View>
            </View>
          </CommonSimpleModal>
        }
        <CommonHeader
          testIDTitle="treatmentsTitle"
          title={t('translation:general.page_title.treatment')}
          secondImageName="MMAddIconFilled"
          rightIconNameSecond="plus-circle"
          rightIconColorSecond={GlobalStyles[APP_SHORTCUT_NAME].btnColor}
          rightClickSecond={() => { setAddModal(!addModal) }}
        />
        <View style={styles.selectWrapper}>
          <View style={styles.selectContainer}>
            {
              Timeline.map((displayType, index) => (
                <TouchableOpacity key={index} testID={displayType === 'current.label' ? 'currentID' : 'pastFutureID'} style={[styles.selectBox, displayType === selectedDisplayType ? {} : { backgroundColor: 'transparent' }]} onPress={changeDisplayList}>
                  <Text style={[styles.selectBoxText, displayType === selectedDisplayType ? { color: GlobalStyles[APP_SHORTCUT_NAME].color } : { color: colors.primary }]}>{t('translation:treatment.detail.' + displayType)}</Text>
                </TouchableOpacity>
              ))
            }
          </View>
        </View>
        <ScrollView
          testID="treatment_scrollView"
          scrollEventThrottle={16}
          onScroll={(event) => {
            let calc = checkBottomScroll(event.nativeEvent, 0);
            if (!scrollAtBottom && calc)
              setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
              setScrollAtBottom(false)
          }}
        >
          <View
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            {/* Display current medications */}
            {
              selectedDisplayType === 'current.label' ?
                <>
                  {medications.length > 0 ? medications.map((item, index) => {
                    if (item) {
                      let med = { name: item?.name, reason: item?.reason }
                      let dos = !item?.intake?.dosage && !item?.intake?.specific_dosage ? [] : !item?.intake?.dosage ? [item?.intake?.specific_dosage] : item?.intake?.dosage
                      let from = item?.intake?.from
                      let to = item?.intake?.to === undefined ? null : item?.intake?.to
                      return (
                        <View key={index}>
                          <CommonMedications
                            navigation={navigation}
                            med={med}
                            dos={dos}
                            from={from}
                            to={to}
                            id={item?.id}
                          />
                        </View>
                      )
                    }
                  })
                    :
                    <CommonTitle color={colors.primary} text={t('translation:treatment.general.no_medication')} txtAlign='center' />
                  }
                </>
                :
                <>
                  {/* display current & future medications */}
                  {currentFuture.length === 0 && past.length === 0 ?
                    <CommonTitle color={colors.primary} text={t('translation:treatment.general.no_past_medication')} txtAlign='center' />
                    :
                    <>
                      {currentFuture.length > 0 &&
                        <>
                          <CommonTitle color={colors.primary} text={t('translation:treatment.general.future_use')} txtAlign='center' />
                          {currentFuture.map((item, index) => {
                            let med = { name: item.name, reason: item.reason }
                            let dos = !item?.intake?.dosage && !item?.intake?.specific_dosage ? [] : !item?.intake?.dosage ? [item?.intake?.specific_dosage] : item?.intake?.dosage
                            let from = item?.intake?.from
                            return (
                              <View key={index}>
                                <CommonMedicationsHistoryCurrentFuture
                                  navigation={navigation}
                                  med={med}
                                  dos={dos}
                                  from={from}
                                  id={item.id}
                                />
                              </View>
                            )
                          })}
                        </>
                      }
                      {/* display past medications */}
                      {past.length > 0 &&
                        <>
                          <CommonTitle color={colors.primary} text={t('translation:treatment.general.past_medication')} txtAlign='center' />
                          {past.map((item, index) => {
                            let med = { name: item.name, reason: item.reason }
                            let dos = !item?.intake?.dosage && !item?.intake?.specific_dosage ? [] : !item?.intake?.dosage ? [item?.intake?.specific_dosage] : !item?.intake?.dosage ? [] : item?.intake?.dosage
                            let to = item?.intake?.to
                            return (
                              <View key={index}>
                                <CommonMedicationHistoryPast
                                  navigation={navigation}
                                  med={med}
                                  dos={dos}
                                  to={to}
                                  id={item.id}
                                />
                              </View>
                            )
                          })}
                        </>
                      }
                    </>
                  }
                </>
            }
          </View>
        </ScrollView>
        <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
          <CommonBtn testID={"intakesHoursBtnID"} type={'tertiary'} title={t('translation:treatment.general.edit_intake')} click={() => { editIntakeHours() }} />
          <View style={{marginBottom: PR(20)}}></View>
        </CommonFooter>
        <View style={{ marginBottom: Platform.OS === 'web' ? PR(45) : 0 }}></View>
      </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      backgroundColor: colors.background,
      flex: 1,
    },
    selectWrapper: {
      width: '90%',
      alignSelf: 'center',
      height: PR(45),
      marginBottom: PR(10),
    },
    selectContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: colors.selectTimelineContainer,
      borderRadius: PR(50),
      alignItems: 'center',
    },
    selectBox: {
      padding: PR(9),
      backgroundColor: colors.background,
      borderRadius: PR(50),
      margin: PR(5),
      alignItems: 'center',
      flex: 1,
      flexGrow: 0.5
    },
    selectBoxText: {
      fontFamily: GlobalStyles.global.fontFamily.Bold
    },

    btnContainer: {
      flex: 1,
      alignSelf: 'center',
      width: '100%',
      marginBottom: Platform.OS === 'web' ? PR(50) : PR(20),
      marginTop: PR(15)
    },
    text: {
      color: "white",
      fontSize: PR(12),
      fontWeight: "bold",
      textAlign: "center"
    },
    toastContainer: {
      position:'absolute',
      bottom:PR(-200), 
      alignSelf:'center',
      alignItems:'center', 
      borderRadius:PR(20), 
      backgroundColor: '#333333', 
      flexDirection: 'row', 
      justifyContent:'space-evenly',
      paddingTop: PR(5),
      paddingBottom: PR(5)
    }
  })
};

export default Treatments