import { RootState } from '.';
import * as types from '../actions';
import {UPDATE_TIMEZONE_SUCCESS} from '../timezones/changeTimezoneActions';
import { VerifyEmailActions } from '../verifyEmail/verifyEmailActions';
import moment from 'moment';

export default function(state:any = [] , action:any = '') {
  const response = action.response;
  switch(action.type) {
    case types.GET_ME_SUCCESS:
      const patient = response.patientData || response?.result?.patients
      const patientForms = patient.forms || []
      const settings = response.result?.settings || {};
      const accountTimezone = response.result?.settings?.timezone;
      const consent = response.result?.consent;
      const analyticConsent = response.result?.analyticConsent;
      const isEmailValidated = response.result?.isEmailValidated;
      const onBoarding = patient.onboarding;
      return { ...state, response, patient, settings, consent, isEmailValidated, onBoarding, accountTimezone, patientForms, analyticConsent };
    case types.UPDATE_PATIENT_SUCCESS:
      state.response.result.patients = response.result
      return {...state, patient: response.result}
    case types.UPDATE_ONBOARDING_QUESTIONS_SUCCESS:
      const onboardings = response.result.onboarding;
      return {...state, onBoarding: onboardings};
    case types.UPDATE_REMINDERS_SUCCESS:
      let copyMe = {...state.response}
      copyMe.result.patients = response.result
      return {...state, response: copyMe, patient: response.result, onBoarding: response.result.onboarding}
    case types.UPDATE_CONSENT_SUCCESS:
      const updateConsent = response.result.consent
      const updateAnalyticConsent = response.result?.analyticConsent;
      return {...state, consent: updateConsent, patient: state.response?.result?.patients, analyticConsent: updateAnalyticConsent};
    case types.UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS_SUCCESS:
      return {...state, response, patient: response?.result?.patients}
    case VerifyEmailActions.VALIDATE_EMAIL_SUCCESS: 
      const updateIsEmailValidated = response.result.isEmailValidated
      if (response.patientData)
        return {...state, isEmailValidated: updateIsEmailValidated, patient: response.patientData}
      return {...state, isEmailValidated: updateIsEmailValidated}
    case types.UPDATE_PATIENT_SITE_SUCCESS:
      const updatedSite = response.result
      const siteIndex = state.patientSites.findIndex(item => item._id === updatedSite.id)
      if (siteIndex === -1)
        return state
      const sitesCpy = JSON.parse(JSON.stringify(state.patientSites));
      const study = sitesCpy[siteIndex].study;
      sitesCpy[siteIndex] = {...updatedSite, study: study, _id: updatedSite.id,}
      return {...state, patientSites: sitesCpy}
    case types.UPDATE_SETTINGS_SUCCESS:
      const updatedSettings = response.result.settings;
      return { ...state, settings: updatedSettings };
    case UPDATE_TIMEZONE_SUCCESS:
      return { ...state, response:response, accountTimezone: response.dataSent.settings.timezone };
    default:
      return state;
    
    case types.GET_PATIENT_SITES_SUCCESS:
      return {...state, sites: response.result}
    case types.GET_DATA_SHARING_SUCCESS:
      return {...state, dataSharing: response.result}
    case types.UPDATE_DATA_SHARING_SUCCES:
      const onboarding = {...state.onBoarding}
      onboarding.neurologistDone = true;
      return {...state, dataSharing: [], onBoarding: onboarding};
  }
}

export const userLanguage = (state: RootState) => state.getMe?.settings?.language;
