import { useEffect } from "react";
import { Platform, AppState } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getPinStatusAction } from "../../store/biometrics/biometricsActions";
import ReactNativeBiometrics from "react-native-biometrics";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { getNotificationsAction } from "../../store/actions/authenticationActions";
import { getUniqueEventAction } from "../../store/treatments/treatmentsActions";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import type { StackNavigationProp } from '@react-navigation/stack';

const rnBiometrics = new ReactNativeBiometrics({allowDeviceCredentials:true})

/* 
This hook activates the app state in order to check whenever the app goes to background and come back to foreground
and also detects if the app needs pin code or biometrics after comes to the foreground and the inactive time is more than 60 secs
*/

export const useAppState = () => {
    const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
    const { i18n } = useTranslation();
    const { language: userLanguage } = i18n;
    const dispatch = useDispatch();
    const Me = useSelector((state:any) => state.getMe.response);
    const today = DateTime.now();
    let goInactiveTimestampt:Date = new Date();
    let goActiveTimestampt:Date = new Date();
    let isUnlockPageActive = false;
    // when that app comes back from background to foreground trigger the get notification request
    useEffect(() => {
        const subscription = AppState.addEventListener("change", handleNextAppState);
        return () => {
            subscription.remove();
        };
    }, []);

    const handleNextAppState = (nextAppState) => {
        if(nextAppState.match(/inactive|background/)){
            goInactiveTimestampt = new Date();
        } 
        else if(nextAppState === "active"){
            goActiveTimestampt = new Date();
        }

        if(Platform.OS !== 'web' ) {
            dispatch(getPinStatusAction(
                {
                    userID: Me?.result?.id,
                    language: userLanguage,
                    callback: (res) => {
                        isUnlockPageActive = res.message
                    }
                }));
            rnBiometrics.biometricKeysExist()
                .then((resultObject) => {
                    const { keysExist } = resultObject
                    const inactiveTime = Math.floor((goActiveTimestampt.getTime() - goInactiveTimestampt.getTime())/1000); // inactive time to seconds
                    if ((keysExist || isUnlockPageActive) && nextAppState === "active" && inactiveTime >= 60) {
                        navigation.navigate('LoginWithBiometrics')
                    } 
                })
        }
        let dateObj = {
            from:today.toFormat('yyyy-MM-dd')+'T00:00:01', 
            to:today.toFormat('yyyy-MM-dd')+'T23:59:59',
        }
        if(nextAppState === "active"){
            dispatch(getNotificationsAction({}));
            dispatch(getUniqueEventAction(dateObj));
        }
    }
}