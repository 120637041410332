import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import { useTheme } from '@react-navigation/native';
import notificationEmitter, {NotificationTypes} from 'common/src/notifEmitter/notifEmitter';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonSwitchBtn from 'common/src/components/CommonSwitchBtn';
import CommonSimpleModal from 'common/src/components/CommonSimpleModal';
import CommonModal from 'common/src/components/CommonModal';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonNumPad from 'common/src/components/Inputs/CommonNumPad';
import CommonPinField from 'common/src/components/Inputs/CommonPinField';
import InputField from 'common/src/components/InputField';
import Text from 'common/src/components/CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';
import { 
    verifyPasswordForEmailAction,
    enableEmailMFAAction, 
    disableEmailMFAAction, 
    verifyOTPCodeAction,
    getOTPForRegistrationAction
} from 'common/src/store/mfa/mfaActions';
import {resetErrorModalAction} from 'common/src/store/actions/authenticationActions';
import { useDispatch, useSelector } from 'react-redux';

type OwnProps = {
    email_status: boolean,
    push_status: boolean,
    update_email_status: (res: boolean) => void;
}

const EmailMFA = ({email_status, push_status, update_email_status}:OwnProps) => {
    const { colors } = useTheme();
    const styles: any = generateStyleSheet(colors);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const Me = useSelector((state:any) => state.getMe.response);
    const errorMessages = useSelector((state:any) => state.modal.errors);
    const [enableEmailMFA, setEmailMFA] = useState<boolean>(false);
    const [enablePushMFA, setPushMFA] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [isPasswordModalOpen, setPasswordModal] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState<boolean>(false);
    const [oneFactorWarning, setOneFactorWarning] = useState<boolean>(false);
    const [isOTPFieldEnabled, setOTPField] = useState<boolean>(false);
    const [pinInput, setPinInput] = useState<Array<string>>([]);
    const pinLength = 6;

    useEffect(() => {
        setEmailMFA(email_status)
        setPushMFA(push_status)
        notificationEmitter.on(NotificationTypes.MFA_notif, (data) => {
            setPinInput([data.text[0],data.text[1],data.text[2],data.text[3],data.text[4],data.text[5]]);
        })
    },[email_status, push_status])

    const triggerEmailMFASwitcher = () => {
        if(enablePushMFA && enableEmailMFA)
            setWarningModal(true)
        else
            setPasswordModal(true)
    }

    const changePinInput = (input: string) => {
        if(pinInput.length < pinLength)
            setPinInput([...pinInput, input])
        
    }

    const deletePinInput = () => {
        if(pinInput.length > 0)
            setPinInput(pinInput.slice(0, -1))
        
    }

    const getOTPFunc = () => {
        dispatch(getOTPForRegistrationAction({
            userId: Me.result.id
        }))
    }

    const verifyPassword = () => {
        dispatch(verifyPasswordForEmailAction({
            userId: Me.result.id,
            password: password,
            email: Me.result.email,
            callback: () => {
                setPasswordModal(false)
                setPassword('')
                setOTPField(true)
                setPinInput([]);
            },
            errorCallback: () => {
                setPasswordModal(false)
                setPassword('')
                setPinInput([]);
            }
        }))
    }

    const verifyOTP = () => {
        dispatch(verifyOTPCodeAction({
            userId: Me.result.id,
            otpCode: pinInput.join(""),
            language: Me.result.settings.language,
            callback: () => {
                if(enableEmailMFA)
                    disablePushNotifMFA()
                else
                    enablePushNotifMFA()
                setPinInput([]);
                setOTPField(false)
            },
            errorCallback: () => {
                setPinInput([]);
            }
        }))
    }

    const enablePushNotifMFA = () => {
        dispatch(enableEmailMFAAction({
            userId: Me.result.id,
            email: Me.result.email,
            callback: () => {
                setEmailMFA(true)
                update_email_status(true)
                setPasswordModal(false)
                setPassword('')
                setOTPField(false)
            },
            errorCallback: () => {
                setPasswordModal(false)
                setPassword('')
            }
        }))
    }

    const disablePushNotifMFA = () => {
        dispatch(disableEmailMFAAction({
            userId: Me.result.id,
            callback: () => {
                setEmailMFA(false)
                update_email_status(false)
                setPasswordModal(false)
                setPassword('')
                setOTPField(false)
                dispatch(resetErrorModalAction({ message: errorMessages[0] }));
            },
            errorCallback: () => {
                setPasswordModal(false)
                setPassword('')
            }
        }))
    }

    return(
        <View>
            {isPasswordModalOpen &&
                <CommonSimpleModal
                        title={t('translation:MFA.modal.password.title')}
                        onClose={()=>setPasswordModal(false)}
                    >
                    <View style = {{height: PR(180)}}>
                        <InputField 
                            labelText={t('translation:login.form.password')}
                            inputType="password"  
                            customStyle={{marginBottom:PR(30)}}
                            changeText={setPassword} 
                            placeholderTxt={t('translation:login.form.email_placeholder')}
                            testID="Password"
                            value={password}
                        />
                        <CommonBtn testID="validate_pass" type="primary" click={verifyPassword} title={t('translation:MFA.validate.password')}  />
                    </View>
                </CommonSimpleModal>
            }
            {warningModal && 
                <CommonModal
                    testID="securityWarningModal"
                    type={'error'}
                    title={t('translation:MFA.security.warning.title')}
                    text={t('translation:MFA.security.warning.disable.push.subtitle')}
                    buttons={[{
                        type: 'primary',
                        title: t('translation:modal.btn.close'),
                        click: () => setWarningModal(false)
                      }]}
                    onClose={() => setWarningModal(false)}
                />
            }

            {oneFactorWarning && 
                <CommonModal
                    testID="oneFactorWarningModal"
                    type={'error'}
                    title={t('translation:MFA.security.warning.title')}
                    text={t('translation:MFA.modal.single_factor_warning_body')}
                    buttons={
                        [
                            {
                                type: 'secondary',
                                title: t('translation:modal.btn.yes'),
                                testIDBtn: 'yes_id',
                                click: () => {triggerEmailMFASwitcher(); setOneFactorWarning(false); }
                            },
                            {
                                type: 'primary',
                                title: t('translation:modal.btn.no'),
                                testIDBtn: 'no_id',
                                click: () => setOneFactorWarning(false)
                            }
                        ]
                    }
                    onClose={() => setOneFactorWarning(false)}
                />
            }
            <View testID='emailMFAContainerId' style={[styles.mfaContainer, {marginTop: PR(10), borderColor: GlobalStyles.migraine.color}]}>
                <View style={styles.wrapper}>
                    <Text testID="unlock_text" style={styles.txt}>{t('translation:MFA.email.title')}</Text>
                    <CommonSwitchBtn 
                        isOn={enableEmailMFA}
                        size="small"
                        onToggle={()=> {enableEmailMFA ?  setOneFactorWarning(true) : triggerEmailMFASwitcher()}}
                        testID="enableDisableEmailMFASwitch"
                    />
                </View>
                <View style={styles.horizontalDivider}></View>
                <View style={{alignSelf:'center', paddingTop:PR(10), width: '90%'}}>
                    <Text testID="enableDisableEmailMFADescription" style={styles.subTxt}>{t('translation:MFA.email.description', {app_name: "MigraineManager"})}</Text>
                </View>
            </View>
            {isOTPFieldEnabled && 
                <CommonSimpleModal
                    title={t('translation:MFA.modal.otp.title')}
                    onClose={()=>setOTPField(false)}
                >
                    <View style={{alignItems:'center', alignSelf:'center', width:'90%'}}>
                        <Text testID="opt_modal_subtitle" style={styles.subTxt}>{t('translation:MFA.login.subtitle_email',{email: Me?.result?.email.replace(/(\w{1})[\w.-]+@([\w.]+\w)/, "$1*****@$2")}) }</Text>
                    </View>
                    {!!errorMessages?.length &&
                        <View style={{alignItems:'center', alignSelf:'center', width:'90%'}}>
                            <Text renderAsHtml={true} testID="error_subtitle" style={styles.errorTxt}>{t(errorMessages[0]) || errorMessages[0]}</Text>
                        </View>
                    }
                    <View style = {{height: PR(380)}}>
                        <CommonPinField 
                            input={pinInput}
                            pinLength={pinLength}
                            inputAreaStyle={{marginBottom: PR(24)}}
                            showInputText={true}
                        />
                        <CommonNumPad 
                            setInput={changePinInput}
                            deleteInput={deletePinInput}
                            isConfirmActive={pinInput.length === pinLength ? true : false}
                            sendConfirmation={()=> verifyOTP()}
                        />
                    </View>
                    <View style={{marginTop: PR(20)}}>
                        <CommonBtn 
                            type='tertiary'
                            title={t('translation:MFA.resend_otp')}
                            click={() => getOTPFunc()}
                            testID={'resend_otp_id'}
                        />
                    </View>
                </CommonSimpleModal>
            }
        </View>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
        wrapper:{
            flexDirection:'row',
            alignItems:'center',
            alignSelf:'center',
            width:'90%',
            justifyContent:'space-between',
            paddingBottom: PR(10),
        },
        mfaContainer: {
            minHeight: PR(62),
            padding: PR(10),
            width: '90%',
            borderRadius: PR(20),
            borderWidth: 1,
            borderColor: GlobalStyles.migraine.color ,
            alignSelf:'center'
        },
        horizontalDivider: {
            borderBottomColor: GlobalStyles.migraine.lightColor,
            borderBottomWidth: PR(1),
            width: '50%',
            alignSelf: 'center'
        },
        txt: {
            fontSize: PR(16),
            marginLeft: PR(15),
            color: colors.primary
        },
        subTxt: {
            fontSize: PR(12),
            color: GlobalStyles.global.grey
        },
        errorTxt: {
            fontSize: PR(12),
            color: GlobalStyles.global.redError
        }
    })
}

export default EmailMFA;