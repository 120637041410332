/**
 * Common types, consts and interfaces.
 */

/**
 * Consts
 */
export const WEEK = "week";
export const MONTH = "month";
export const YEAR = "year";
export const TIMELINE_DISPLAY_TYPES = [WEEK, MONTH, YEAR];

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_MONTH = "YYYY-MM";

export const FORM_STATUS_OVER = "over";
export const FORM_STATUS_NOT_YET = "not_yet";
export const FORM_STATUS_DONE = "done";
export const FORM_STATUS_SENT = "sent";
export const FORM_STATUS_WITHDRAWN = "withdrawn";

/**
 * Interfaces
 */
export interface LocalisationListItem {
  label: string;
  value: string;
}

export interface BasicRecord {
  id: string;
  _id?: string;
  createdAt: string;
  updatedAt?: string;
  deleted?: boolean;
}

export interface GenericRecord extends BasicRecord {
    date: string;
    type: string;
}
export interface Intake extends GenericRecord {
    date_last_intake?: string;
    date_next_intake?: string;
    days?: boolean[];
    dosage?: any;
    from?: string;
    medication: string;
    patient: string;
    specific_dosage?: object;
    to?: any;
};
export interface Treatment extends GenericRecord {
  intake: Intake;
  intake_type: string;
  name: string;
  patient: string;
  reason: string;
  treatment_type: string;
}

interface IndexedContent { 
  readonly index: number
}

interface ChapterContent {
  readonly index: number;
  readonly hasCondition?: boolean;
  readonly conditions?: ContentCondition[];
}

export interface EducationModule extends BasicRecord {
  readonly name: string;
  readonly title: Record<string, string>,
  readonly actions?: string[];
  readonly chapters?: EducationModuleChapter[];
  readonly imageUri?: string;
}

export interface EducationModuleChapter extends IndexedContent {
  readonly id: string;
  readonly title: Record<string, string>;
  readonly pages?: EducationModulePage[];
}

export interface EducationModulePage extends IndexedContent {
  readonly form?: string;
  readonly content?: EducationChapterContent[];
}

export interface ParagraphContentChapter extends ChapterContent {
  readonly contentType: 'paragraph';
  readonly paragraph: Record<string, string>;
}
export interface ImageContentChapter extends ChapterContent {
  readonly contentType: 'image';
  readonly image: string;
  readonly width: string;
  readonly imageCaption?: Record<string, string>;
}

export interface VideoContentChapter extends ChapterContent {
  readonly contentType: 'video';
  readonly video: string;
  readonly videoCaption?: Record<string, string>;
}

export interface QuestionnaireContentChapter extends ChapterContent {
  readonly contentType: 'questionnaire';
  readonly questionnaireForm: string;
}

export interface ActionsContentChapter extends ChapterContent {
  readonly contentType: 'action';
  readonly action: Array<string>;
  readonly actionCaption?: Record<string, string>
  readonly actions: Array<Record<string, any>>
}

export interface QuestionContentChapter extends ChapterContent {
  readonly contentType: 'question';
  readonly question: EducationQuestion;
}

export interface ContentCondition {
  questionId: string;
  conditionType: ContentConditionTypes;
  answerValue: string;
}

export interface EducationModuleProps {
  readonly educationModule: EducationModule;
}

export interface EducationChapterProps {
  readonly chapter: EducationModuleChapter;
  readonly educationModuleId: string;
  readonly programAction?: Array<ProgramAction>
}

export interface Program extends BasicRecord {
  readonly educationModules: ProgramEducationModule[];
  goal?: Record<string, string>;
  readonly introduction: Record<string, string>;
  readonly subTitle: Record<string, string>;
  readonly title: Record<string, string>;
}

export interface ProgramEducationModule {
  readonly id: string;
  readonly isFeatured: boolean;
  readonly featuredAfterDays: number;
  readonly featuredDuringDays: number;
}

export interface ProgramAction {
  readonly id: string;
  readonly name: string;
  readonly title: Record<string, string>,
  readonly description?: Record<string, string>,
  readonly type: string;
  readonly imageUri?: string;
  readonly isInternalTraining: boolean;
  readonly actionBtn?: Record<string, string>;
  readonly actionBtnNavigation?: ActionNavigation;
  readonly frequency?: string;
  readonly repetition?: number;
  event?: EventAction
  events: Array<Record<string, any>>
}

interface ActionNavigation {
  readonly Nav: keyof RootStackParams;
  readonly params?: string;
  readonly value: string;
}

interface AddEventNavigation {
  readonly itemType?: string;
  readonly initialData?: Record<string, Record<string, object>>
  readonly checklistData?: Record<string, object>
}

export interface EventAction {
  patient: string,
  action: string,
  date: string | null,
  done: boolean | null,
  doneAt: string | null,
  type?: string
}

export interface ProgramActionProps {
  readonly programAction: ProgramAction;
  handleActionSchedule: ( action: ProgramAction, actionId:string, date?:string, time?:string, old_date?:string) => void;
  removeAction: (action: ProgramAction) => void;
}


export interface ProgramActionSchedule {
  readonly next_action_at
}
export interface EventSet {
  date: string;
  seizures?: GenericRecord[];
  mood?: GenericRecord[];
  sleep?: GenericRecord[];
}

export interface NightwatchReport extends GenericRecord {
  nightwatch_report_id: string;
  nightwatch_sid: string;
  remark: string;
}

export interface EventComponent<EventType> {
  itemType: string;
  initialData?: EventType;
}

export interface EditEventComponent<EventType> extends Omit<EventComponent<EventType>, 'initialData'> {
  initialData: EventType;
}

export interface DataSharingRequest {
  nextAction: 'Home' | 'PrivacyConsent' | 'StudyDisclaimer' | 'OnboardingQuestions';
  reloadMe?: boolean;
  siteId?: string;
  study?: object;
}

export interface Azmm {
  nextAction: 'Home' | 'StudyDisclaimer' | 'OnboardingQuestions';
}

export interface User {
  id: string;
  emailTemplate: string;
  first_name: string;
  last_name: string;
}

export interface ImagePageProps {
  uri: string;
  title?: string;
}

export interface Form extends BasicRecord {
  readonly name: string;
  readonly label: string;
  readonly title: Record<string, string>;
  readonly questions: FormQuestion[];
}

export interface FormQuestion {
  readonly answer_type: string;
  readonly index: string;
  readonly questionText: Record<string, string>;
  readonly skippable?: boolean;
  readonly conditional?: boolean;
  readonly scaleFrom?: string;
  readonly scaleTo?: string;
  answer?: any;
}

export interface EducationQuestion extends Omit<FormQuestion, 'index'> {
  readonly id: string;
}

export interface FormSelectAnswer {
  label: string;
  value: string;
  selected: boolean;
}

export interface PatientProgram extends BasicRecord {
  patient: string;
  program: string;
  introductionDone: boolean;
  subProgram?: string;
  education?: PatientEducationTrack;
  questionnaires?: Record<string, object>;
  actions?: Array<string>;
  startedDate?: string;
}

export interface EducationTrack {
  status: EducationTrackStatus;
  chapters: Record<string, ChapterTrack>;
  completedDate?: string;
}

export interface ChapterTrack {
  status: EducationTrackStatus;
  lastPage: number;
}

export interface ReducerAction<T, R> {
  type: T;
  response: R;
}

export interface ProgressBarStyleProps {
  progressBarColor: string;
  progressBarBackground: string;
}

export interface AddContactProps {
  name?: string,
  relationship?:string,
  phone?: string,
  callback: (contact:ContactCallback) => void;
}

interface ContactCallback {
  name?: string,
  relationship?:string,
  phone?: string,
}

export interface AppointmentChecklistTypes {
  checklistDate?: string,
  worryAndFears?: string,
  positiveElements?: string,
  sideEffectDropdown?: string,
  satisfactionTreatment?: string,
  discussTreatmentChange?: string,
  sideEffectType?: Array<string>,
  reasonToChange?: string
}

/**
 * Types
 */
export type CommonButtonType = 'primary' | 'secondary' | 'tertiary' | 'success';

export type EducationTrackStatus = 'not_started' | 'in_progress' | 'completed';

export type DeleteEventAction = () => void;

export type RootStackParams = {
  DataSharingRequest: DataSharingRequest;
  Home: undefined;
  NightwatchEvent: EditEventComponent<NightwatchReport>;
  PrivacyConsent: undefined;
  EducationModule: EducationModuleProps;
  EducationChapter: EducationChapterProps;
  ImagePage: ImagePageProps;
  ProgramAction: ProgramActionProps;
  AddEvent: ActionNavigation | AddEventNavigation;
  AddTreatments: any;
  Settings: undefined;
  AddContact: AddContactProps;
  MoodSleepNotifications: any;
  Welcome: any;
  OnboardingQuestions: any;
  NeurologistStep: any;
  StudyDisclaimer: any;
  Menu: undefined;
  Azmm: Azmm;
};

export type PatientEducationTrack = Record<string, EducationTrack>;

export type EducationChapterContent = ParagraphContentChapter | ImageContentChapter | VideoContentChapter | QuestionnaireContentChapter | ActionsContentChapter | QuestionContentChapter;

/**
 * Enums
 */

export enum ContentConditionTypes {
  Equals = 'eq',
  Contains = 'contains',
  GreaterThan = 'gt',
  GreaterThanEquals = 'gte',
  LessThan = 'lt',
  LessThanEquals = 'lte',
}

export interface DiaryFilterTypes {
  label: string,
  selected: boolean,
  value: string
}

interface DiaryitemTypes {
  date: string
}

export interface DiaryViewableItemTypes {
  index: number,
  isViewable: boolean,
  item: DiaryitemTypes,
  key: string
}

export interface AddressTypes {
  streetAndNumber?: string,
  zipcode?: string,
  city?: string,
  country?: string
}

export interface MoodAndSleepIsEnabled {
  switchBtn1?: boolean,
  switchBtn2?: boolean
}