import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonHeader from '../CommonHeader';
import CommonTitle from '../CommonTitle';
import CommonBtn from '../CommonBtn'
import CommonSubtitle from '../CommonSubtitle';
import CommonModal from '../CommonModal';
import CommonApointment from './CommonAppointment';
import CommonAddSeizure from './CommonAddSeizure';
import CommonAddSideEffect from './CommonAddSideEffect'
import CommonAddNote from './CommonAddNote';
import CommonReminder from './CommonReminder';
import CommonAddHeadache from './CommonAddHeadache';
import Text from '../CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {useTranslation} from 'react-i18next';
import { DateTime } from "luxon";
import CommonDateInput from "../CommonDateInput";
import CommonTimeInput from "../CommonTimeInput";
import CommonFooter from '../CommonFooter';
import {checkBottomScroll, dateTimeEvents} from '../../services/utils';
import {compareDatesForEvents} from '../../services/compare';
import { DeleteEventAction, AppointmentChecklistTypes } from '../../types';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { APP_SHORTCUT_NAME } from 'common/src/env.json';
import CommonImage from '../CommonImage';
import { headacheTypeObject } from '../../store/headacheTypes/headacheTypesReducer';
import CommonKeyboardAvoidingView from '../CommonKeyboardAvoidingView';

interface AddEventTypes {
  navigation: any,
  type: string,
  icon: string,
  modalTitle: string,
  infoTxt: string,
  title: string,
  addTitle: string,
  subtitle: string,
  timeTitle: string,
  getData: any,
  getMeRes:any,
  getMedicationsRes:any,
  initialData: any,
  checklistData: any,
  app?: string;
  onDeleteAction?: DeleteEventAction;
  onChecklistBtnClick: () => void;
  onEditChecklistClick: (data:AppointmentChecklistTypes) => void;
  onDeleteChecklistClick: () => void;
}

type EventTypes = {
  already_passed?: boolean,
  auras?: Array<string>,
  date?: string,
  duration?: number,
  end_date?: string,
  intensity?: string,
  life_impact?: Array<string>,
  start_date?: string,
  headache?: headacheTypeObject | null,
  headache_id?: string,
  name?:string
  name_obj?: headacheTypeObject | null,
  rescues?: Array<Record<string, any>>,
  symptoms?: Array<string>,
  triggers?: Array<string>,
  msg?: string,
  prevRoute?: string,
  type?: string,
  remark?: string,
  doctor_info?: {
    name: string,
    type: string
  },
  consultation?: Array<string>,
  place?: string,
  checklist?: AppointmentChecklistTypes,
  felt?: string,
  during_sleep?: string,
  emergency_treatments?: Array<string>,
  post_seizure?: Array<string>,
  seizure_type?: string,
  videoList?: Array<any>,
  seizure_type_id?: string,
  same_nomenclature?: any,
  effect_type?: string,
  other_type?: string,
  all_day?: boolean,
  settings?: {
    type: string,
    early_morning: any,
    morning: any,
    afternoon: any,
    evening: any,
    noon: any,
    notification: boolean,
    time: string | number,
    when: string
  }
}

type headacheDataToSaveTypes = {
  data: EventTypes;
  callback: () => void;
}


const CommonAddEventForm = (
  {
    navigation,
    type,
    icon,
    modalTitle,
    infoTxt, 
    title, 
    addTitle,
    subtitle, 
    timeTitle,
    getData,
    getMeRes,
    getMedicationsRes,
    initialData,
    checklistData,
    app,
    onDeleteAction,
    onChecklistBtnClick,
    onEditChecklistClick,
    onDeleteChecklistClick
  }: AddEventTypes
  ) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();
  const [scrollState, setScrollState] = useState<any>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false)
  const [date, setDate] = useState((initialData?.date? DateTime.fromISO(initialData?.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : DateTime.now()).toFormat('dd-MM-yyyy'));
  const [time, setTime] = useState((initialData?.date? DateTime.fromISO(initialData?.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : DateTime.now()).toFormat('HH:mm'));
  const [buttonValue, setButtonValue] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [checkDate, setCheckDate] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isWarningModalEnabled, setIsWarningModalEnabled] = useState<boolean>(false);
  const [isHeadacheDurationModalEnabled, setHeadacheDurationModal] = useState<boolean>(false);
  const [headacheDataToSave, setHeadacheDataToSave] = useState<headacheDataToSaveTypes>();
  let currentTime = DateTime.fromJSDate(new Date()).toFormat('HH:mm')
  let currentDate = DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy')
  const changeDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setDate(inputDate)
      enableWarningModal(true)
    }
    if(compareDatesForEvents(inputDate, currentDate, 'dd-MM-yyyy') && (type === 'Seizure' || type === 'Side Effect')){
      setCheckDate(false)
      enableWarningModal(true)
    }
    else{
      setCheckDate(true)
      setTime(DateTime.now().toFormat('HH:mm'))
      enableWarningModal(true)
    }
  }
  const enableWarningModal = (data) => {
    setIsWarningModalEnabled(data)
  }

  const checkHeadacheDuration = (data: EventTypes, callback: () => void) => {
    setHeadacheDataToSave({data, callback});
    const headacheDurationInDays = DateTime.fromISO(data.end_date || new Date().toISOString()).diff(DateTime.fromISO(data.date || new Date().toISOString()), "days").days;
    if(headacheDurationInDays > 7)
      setHeadacheDurationModal(true);
    else {
      getData(data, callback)
    }
    setButtonValue(false);
  }

  icon = icon || 'image'

  const sendDataBack = (data, callback) => {
    let obj: EventTypes = {};
    let dateTime = dateTimeEvents(date, time)
    switch(data.type) {
      case 'appointment':
        obj = {
          date: dateTime,
          doctor_info: {
            name: data.physician,
            type: data.doctorType
          },
          consultation: data.appointmentType,
          place: data.location,
          remark: data.remarks,
          checklist: data.checklist,
          type: data.type,
          msg: data.msg
        }
        break;
      case 'seizure':
        obj = {
          date: dateTime,
          felt: data.answer,
          during_sleep: data.answerDuringSleep,
          duration: data.duration,
          emergency_treatments: data.emergencyType,
          post_seizure: data.postSeizuresType,
          seizure_type: data.seizureType,
          triggers: data.triggersType,
          auras: data.aurasType,
          place: data.whereType,
          remark: data.remarks,
          type: data.type,
          videoList: data.videoResponse ? [data.videoResponse] : undefined,
          msg: data.msg,
          seizure_type_id: data.seizureTypeId,
          same_nomenclature: data.sameNomenclature || false
        }
        break;
      case 'headache':
        obj = data?.headache ? {
          already_passed: true,
          auras: data.beforeHeadache,
          date : data.startDate,
          duration: DateTime.fromISO(data.endDate).diff(DateTime.fromISO(data.startDate), "seconds").seconds,
          end_date: data.endDate,
          intensity: data.intensity,
          life_impact: data.lifeImpacts,
          start_date: data.startDate,
          headache: data.headache,
          headache_id: data.headache_id,
          name: data.headache?.name,
          name_obj: data.headache,
          rescues: data.rescues,
          symptoms: data.headacheSymptoms,
          triggers: data.triggersType,
          msg: data.msg,
          prevRoute: data.prevRoute,
          type: data.type,
          remark: data.remarks,
        }
        :
        {
          already_passed: true,
          auras: data.beforeHeadache,
          date : data.startDate,
          duration: DateTime.fromISO(data.endDate).diff(DateTime.fromISO(data.startDate), "seconds").seconds,
          end_date: data.endDate,
          intensity: data.intensity,
          life_impact: data.lifeImpacts,
          start_date: data.startDate,
          rescues: data.rescues,
          symptoms: data.headacheSymptoms,
          triggers: data.triggersType,
          msg: data.msg,
          prevRoute: data.prevRoute,
          type: data.type,
          remark: data.remarks,
        }

        break;
      case 'side_effect':
        obj = {
          date: dateTimeEvents(date, currentTime),
          effect_type: data.sideEffectType,
          intensity: data.intesityType,
          remark: data.remarks,
          type: data.type,
          msg: data.msg
        }
        break;
      case 'other':
        obj = {
          date: dateTimeEvents(date, data.time || currentTime),
          other_type: data.noteType,
          all_day: data.answer,
          remark: data.remarks,
          type: data.type,
          msg: data.msg
        }
        break;
      case 'reminder':
        setButtonValue(data.btnValue)
        obj = {
          settings: {
            type: data.type,
            early_morning: data.early_morning,
            morning: data.morning,
            afternoon: data.afternoon,
            evening: data.evening,
            noon: data.noon,
            notification: data.notification,
            time: data.time,
            when: data.when
          }
        }
        break;
      default:
        break;
    }
    if(data.type === "headache")
      checkHeadacheDuration(obj, callback);
    else
      getData(obj, callback)
  }

  const navigateBack = () => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate("Main")
  }

  const openModal = () => {
    setModalVisible(true);
  }

  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }

  // keep tracking of the changes of the warningVisible
  const callbackWarningVisible = () => {
    setWarningModal(false);
  }

  const confirmModalBtn = [
    {
      type: 'primary',
      title: t('translation:modal.btn.ok'),
      click: callbackVisible
    }
  ]

  const goBackToDiary = () =>{
    navigation.goBack()
    setWarningModal(false)
  }

  const warningModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackWarningVisible
    },
    {
      type: 'primary',
      title: t('translation:modal.btn.confirm'),
      click: goBackToDiary
    }
  ]

  const headachWarningModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: () => setHeadacheDurationModal(false)
    },
    {
      type: 'primary',
      title: t('translation:modal.btn.ok'),
      click: () => getData(headacheDataToSave?.data, headacheDataToSave?.callback)
    }
  ]
  return (
    <CommonKeyboardAvoidingView>
      { modalVisible &&
        <CommonModal type={'info'} title={modalTitle} text={infoTxt} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      { warningModal &&
        <CommonModal 
          testID="warningModalID" 
          type={'info'} 
          title={t('translation:modal.titles.warning')} 
          text={type === 'Seizure' ? t('translation:events.seizure.data_not_saved_confirm') : type === 'Headache' ? t('translation:headache_event.headache.data_not_saved_confirm') : t('translation:events.appointment.data_not_saved_confirm')} 
          buttons={warningModalBtn} 
          onClose={callbackWarningVisible}
        />
      }
      { isHeadacheDurationModalEnabled &&
        <CommonModal 
          testID="hadacheWarningModalID" 
          type={'info'} 
          title={t('translation:modal.titles.warning')} 
          text={t("translation:modal.headache.duration.description")} 
          buttons={headachWarningModalBtn} 
          onClose={() => setHeadacheDurationModal(false)}
        />
      }
      <CommonHeader
        testIDTitle="addEvent_title"
        title={addTitle}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles[APP_SHORTCUT_NAME].color}
        leftClick={() => {(type === 'Seizure' || type === 'Appointment' ||  type === 'Headache') && isWarningModalEnabled ? setWarningModal(true) : navigateBack()}}
        scroll={scrollState}
      />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          testID="AddEvent_scrollView"
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, 15);
            if (!scrollAtBottom && calc)
              setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
              setScrollAtBottom(false)

            if(scrollState === 0 && event.nativeEvent.contentOffset.y > 0)
              setScrollState(event.nativeEvent.contentOffset.y);
            else if(scrollState > 0 && event.nativeEvent.contentOffset.y === 0)
              setScrollState(0);
          }}
        >
          <View
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            <View style={styles.IconRow}>
              <View style={styles.Icon}>
                <View style={styles.titleIcon}>
                  {
                    type === 'Headache' ?
                      <CommonImage name="headacheIconGreen" height={PR(40)} width={PR(40)}/>
                      :
                      <Icon name={icon} size={PR(40)} color={GlobalStyles[APP_SHORTCUT_NAME].color}/>
                  }
                </View>
                <TouchableOpacity onPress={openModal} style={styles.titleInfo}>
                  <Icon name={"info"} size={PR(25)} color={GlobalStyles[APP_SHORTCUT_NAME].color} />
                </TouchableOpacity>
              </View>

            </View>
            <View style={styles.titleWrapper}>
              <CommonTitle text={title} color={GlobalStyles[APP_SHORTCUT_NAME].color}/>
              <CommonSubtitle text={subtitle}/>
            </View>
            {
              type === 'Reminder' || type === 'Headache' ? null :
                  <View style={styles.dateInput}>
                    <View style={styles.dateInputLabel}>
                      <Text style={styles.dateInputLabelText}>{t('translation:general.events.date')}</Text>
                    </View>
                    <CommonDateInput
                      onChange={d => changeDate(d)}
                      value={date}
                      fieldStyle={styles.dateInputField}
                      textStyle={styles.dateInputFieldText}
                      maxDate={type === 'Seizure' || type === 'Side Effect' ? new Date() : undefined}
                      webInputStyle={{
                        backgroundColor: colors.inputBackground, 
                        border: "none", 
                        width: "100%", 
                        color: GlobalStyles.global.black,
                        fontSize: PR(16),
                        fontFamily: GlobalStyles.global.fontFamily.Medium
                      }}
                    />
                    {!checkDate &&
                      <Text style={styles.errorTxt}>{t('translation:events.future_date_error')}</Text>
                    }  
                  </View>
            }
            {timeTitle !== '' && (
              <View style={styles.dateInput}>
                <View style={styles.dateInputLabel}>
                  <Text style={styles.dateInputLabelText}>{timeTitle}</Text>
                </View>
                <CommonTimeInput
                    onChange={timeData => {enableWarningModal(true); (timeData > currentTime) && (type === 'Seizure' || type === 'Side Effect') && !checkDate? setTime(currentTime) : setTime(timeData)}}
                    value={time}
                    fieldStyle={styles.dateInputField}
                    textStyle={styles.dateInputFieldText}
                    maxTime={(type === 'Seizure' || type === 'Side Effect') && date === DateTime.now().toFormat('dd-MM-yyyy') ? new Date() : undefined}
                    inputDate={type === 'Seizure' || type === 'Side Effect' ? date : undefined}
                    webInputStyle={{
                      backgroundColor: colors.inputBackground, 
                      border: "none", 
                      width: "100%", 
                      color: colors.primary,
                      fontSize: PR(16),
                      fontFamily: GlobalStyles.global.fontFamily.Medium
                    }}
                />
              </View>
            )}
            {type === 'Seizure' &&
              <CommonAddSeizure 
                AddSeizureData={sendDataBack} 
                isSubmitButtonActive={buttonValue} 
                initialData={initialData} 
                checkDate={checkDate} 
                isFormUpdated={enableWarningModal}
                disabled={setIsDisabled}
              />
            }
            {type === 'Headache' &&
              <CommonAddHeadache 
                AddHeadacheData={sendDataBack} 
                isSubmitButtonActive={buttonValue} 
                initialData={initialData} 
                isFormUpdated={enableWarningModal}
                disabled={setIsDisabled}
                navigation={navigation}
              />
            }
            {type === 'Appointment' && 
              <CommonApointment 
                AppointmentData={sendDataBack} 
                isSubmitButtonActive={buttonValue}
                initialData={initialData} 
                checklistData={checklistData}
                onChecklistBtnClick={onChecklistBtnClick} 
                onEditChecklistClick={onEditChecklistClick}
                onDeleteChecklistClick={onDeleteChecklistClick}
                disabled={setIsDisabled}
                isFormUpdated={enableWarningModal}
                app={app}
              />
            }
            {type === 'Side Effect' && 
              <CommonAddSideEffect SideEffectData={sendDataBack} isSubmitButtonActive={buttonValue} initialData={initialData} checkDate={checkDate} disabled={setIsDisabled}/>
            }
            {type === 'Note / Event' && 
              <CommonAddNote NoteData={sendDataBack} isSubmitButtonActive={buttonValue} initialData={initialData} disabled={setIsDisabled}/>
            }
            {type === 'Reminder' && 
              <CommonReminder 
                isSubmitButtonActive={buttonValue} 
                getMedicationsRes={getMedicationsRes} 
                ReminderData={sendDataBack}
                navigation={navigation}
              />
            }
          </View>
        </ScrollView>
        {type === 'Reminder' && 
          <>
            <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
              <CommonBtn type="primary" click={()=>setButtonValue(true)} title={t('translation:general.header.btn_save')} testID="save_reminders" />
            </CommonFooter>
          </>
        }
        {type !== 'Reminder' &&
          <>
            <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
              { 
                onDeleteAction &&
                  <>
                    <CommonBtn type="tertiary" txtColor="red" title={t(`translation:events.${initialData?.type}.delete`)} click={onDeleteAction} /> 
                    <View style={{marginBottom: PR(5)}}></View>
                  </>
              }
              <CommonBtn type="primary" click={()=>setButtonValue(true)} title={t('translation:general.header.btn_save')} testID="save_event" disable={isDisabled}/>
            </CommonFooter>
          </>
        }
      </View>
    </CommonKeyboardAvoidingView>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    scrollViewWrapper: {
      marginTop: PR(5),
      flex: 1,
    },
    scrollview: {
      paddingTop: PR(20),
      flex:1,
    },
    IconRow: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleWrapper: {
      margin: PR(30)
    },
    titleIcon: {
      alignSelf:'center', 
      borderWidth:PR(2), 
      borderRadius:PR(30), 
      borderColor: colors.bodyIconContainer, 
      backgroundColor: colors.bodyIconContainer,
      padding: PR(7)
    },
    titleInfo: {
      position: 'absolute',
      top: '35%',
      right: PR(-35)
    },
    Icon: {
      position: 'relative',
    },
    label: { 
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: colors.primary,
      fontSize: PR(12)
    },
    container: {
      backgroundColor: GlobalStyles.global.lightGrey,
      borderRadius: PR(20),
    },
    inputContainer: {
      flex: 1,
      flexDirection: 'row',
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputValue: {
      flex: 1,
      flexGrow: 1,
      color: colors.primary,
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    dateInput: {
      width:'90%',
      alignSelf:'center',
      marginBottom: PR(30)
    },
    dateInputLabel: {
      marginBottom: PR(10),
      marginLeft: PR(20),
    },
    dateInputLabelText: {
      color: colors.primary,
      fontSize: PR(12)
    },
    dateInputField: {
      backgroundColor: colors.inputBackground,
      borderRadius: PR(20),
      borderColor: '#F2F4F5',
      height: PR(62),
      paddingHorizontal: PR(20),
      paddingVertical: PR(20),
    },
    dateInputFieldText: {
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium,
      color: colors.primary
    },
    errorTxt: {
      color: GlobalStyles.global.redError,
      textAlign: 'center'
    },
  })
}

export default CommonAddEventForm;