import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet } from 'react-native'
import { useTheme } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import Text from './CommonCustomTxt';
import CommonBtn from './CommonBtn'
import GlobalStyles from '../styles/GlobalStyles';
import { APP_SHORTCUT_NAME } from '../env.json';
import { getNotificationsAction, deleteNotificationsAction } from 'common/src/store/actions/authenticationActions';
import CommonImage from './CommonImage';
import { NOTIFICATIONS_TYPES } from '../services/notifications/index';
import cmsFile from "common/src/services/localization/en/en.json";
import { widthPercentageToDP as PR } from '../styles/PixelRatio';
import Loading from 'common/src/components/Loading';

const excludedNotifType = [
    'notif_medication'
]

const CommonNotifications = ({ action, navigation }) => {
    const { colors } = useTheme();
    const { t, i18n } = useTranslation();
    const { language: userLanguage } = i18n;
    const dispatch = useDispatch();
    const GetNotifications = useSelector((state: any) => state.getNotifications.notifications);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        navigation.addListener('focus', () => {
            setIsLoading(true);
            dispatch(getNotificationsAction({
                callback: () => {
                    setIsLoading(false)
                },
                errorCallback: () => {
                    setIsLoading(false)
                }
            }));
        })
    }, [navigation])

    const onNotifClick = (notification) => {
        action(notification);
        removeNotification(notification.id)
    }

    const removeNotification = (notificationId) => {
        dispatch(deleteNotificationsAction({ notificationId }))
    }

    const defineNotificationText = (notification) => {
        const notifData = notification.data;
        let text = notification.text
        if (notification.data && notification.data.text && excludedNotifType.indexOf(notification.type) === -1)
            text = notification.data.text[userLanguage] || notification.data.text.en;
        else if (cmsFile[`server.notifications.${notification.type}`])
            text = t(`translation:server.notifications.${notification.type}`, { time: notifData.time, name: notifData.name, dose: notifData.dose, unit: notifData.unit, neuro_name: notifData.neuro_name, name_form: notifData.name_form });
        return text
    }

    const renderNotification = (notification) => {
        let notifType: any = NOTIFICATIONS_TYPES.find(item => item.type === notification.type) || NOTIFICATIONS_TYPES.find(item => item.type === 'default');
        let notificationText;

        if (notification.data && notification.data.button_text && notification.data.button_text[userLanguage])
            notificationText = notification.data.button_text[userLanguage];

        else if (notification.button_text && notification.button_text.length > 0)
            notificationText = notification.button_text;

        else
            notificationText = t(`translation:${notifType.btnTitle}`)

        return (
            <View style={styles.container}>
                <View style={styles.iconContainer}>
                    <View style={styles.iconBackground}>
                        <CommonImage name={notifType.icon} width={PR(20)} height={PR(20)} />
                    </View>
                </View>
                <View style={styles.textContainer}>
                    <Text style={{ color: colors.primary }}>{defineNotificationText(notification)}</Text>
                </View>
                <View style={styles.buttonContainer}>
                    <CommonBtn type="secondary" title={notificationText} click={() => { onNotifClick(notification) }} size="small" />
                </View>
            </View>
        )
    }

    return (
        <>
            {
                isLoading &&
                <Loading netConnected={true} />
            }
            {!isLoading && GetNotifications && GetNotifications.map((notif, index) => {
                return (
                    <View key={index}>{renderNotification(notif)}</View>
                )
            })}
            {
                !isLoading && GetNotifications.length === 0 &&
                <Text style={{color: colors.primary}}>{t('translation:dashboard.notifications.no_result')}</Text>
            }
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        justifyContent: 'space-between',
    },
    textContainer: {
        flex: 1,
        flexGrow: 0.7,
        justifyContent: 'center',
        paddingVertical: PR(5),
        marginLeft: PR(7)
    },
    buttonContainer: {
        flex: 1,
        flexGrow: 0.3,
        justifyContent: 'center',
    },
    iconContainer: {
        alignItems: 'center',
        padding: PR(3),
        width: PR(30),
    },
    iconBackground: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
        width: PR(30),
        height: PR(30),
        borderRadius: PR(15)
    }
})

export default CommonNotifications;