import React from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Platform} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonBtn from 'common/src/components/CommonBtn';
import WelcomeHeader from 'common/src/components/WelcomeHeader';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubtitle from 'common/src/components/CommonSubtitle';
import GlobalStyle from 'common/src/styles/GlobalStyles';
// @ts-ignore
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useTranslation } from 'react-i18next';

const Welcome = ({ navigation }:any) => {
  const colors = useTheme().colors;
  const styles: any = generateStyleSheet(colors);
  const {t} = useTranslation();

  const goToLogin = () => {
    navigation.navigate('Login');
  }

  const goToRegistration = () => {
    navigation.navigate('Register');
  }

  const subtitle = t('translation:login.migraine_manager.subtitle');
  
  return (
    <>
      <SafeAreaView style={Platform.OS === 'web'? styles.containerWeb: styles.container}>
        <ScrollView
          contentContainerStyle={styles.scrollView}>
            {/* WelcomeHeader takes 3 props title, subtitle and Logo. Logo takes two values migraine or helpilepsy */}
          <WelcomeHeader testID='welcome_header_id' title={`Migraine\nManager`} subtitle={t('translation:headache_event.general.intro')} logo='migraine'/>
          {/* WelcomeTxt takes 2 props mainTxt and color */}
          <View style={styles.textContainer}>
            <CommonTitle text={t('translation:login.title')} color={GlobalStyle.migraine.color}/>
            <CommonSubtitle text={subtitle}/>
          </View>
          {/* CommonBtn takes 4 props click, title, color and txtColor. click return a function to parent component */}
          <CommonBtn type="secondary" click={goToLogin} title={t('translation:login.form.buttonLogin')} />
          <View style={{padding:PR(10)}}></View>
          <CommonBtn type="primary" click={goToRegistration} title={t('translation:login.form.buttonRegister')}/>
        </ScrollView>
      </SafeAreaView>
    </>
  );
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems:'center',
      backgroundColor: colors.background
    },
    containerWeb: {
      flex: 1,
      backgroundColor: GlobalStyle.global.white
    },
    textContainer: {
      width: '75%',
      margin: 50
    },
    scrollView: {
      flex:1,
      alignItems: 'center'
    },
    choiceTxt: {
      margin: 25,
      fontSize: 24,
      color: GlobalStyle.global.grey
    }
  });
}

export default Welcome;