import React from 'react';
import { View, StyleSheet, SafeAreaView, ActivityIndicator } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { widthPercentageToDP as PR } from 'common/src//styles/PixelRatio';
import { useTranslation } from 'react-i18next';
import {APP_SHORTCUT_NAME} from 'common/src/env.json'

type OwnProps = {
  netConnected?: boolean | null
}

const Loading = ({ netConnected }: OwnProps) => {
  const colors = useTheme().colors;
  const styles: any = generateStyleSheet(colors);
  const { t } = useTranslation()
  return (
    <SafeAreaView style={styles.container}>
      {netConnected === false &&
        <View style={styles.formsErrorBanner}>
          <Text renderAsHtml={true} style={{ color: GlobalStyles.global.white }}>{t('translation:widget.no_internet.message')}</Text>
        </View>
      }
      <View style={styles.modal}>
        <ActivityIndicator size='large' color={GlobalStyles[APP_SHORTCUT_NAME].color} />
      </View>
    </SafeAreaView>
  )
}

const generateStyleSheet = (colors:Record<string,string>) => {
  return StyleSheet.create({
    container: {
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
      elevation: 5,
      flex: 1,
      width: '100%',
      backgroundColor: colors.background
    },
    modal: {
      width: '100%',
      height: '100%',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    formsErrorBanner: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: GlobalStyles.global.redError,
      paddingVertical: PR(6),
      width: '100%'
    }
  });
}

export default Loading;