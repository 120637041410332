import { TFunction } from "i18next"
import GlobalStyles from "../../styles/GlobalStyles";
import {isArray} from 'lodash';
import { useTheme } from '@react-navigation/native';
import {APP_SHORTCUT_NAME} from 'common/src/env.json'
import { ThemeColors } from "../../../../../*";

export const getColor = (type: string) => {
  const { colors } = useTheme();
  switch (type) {
    case "seizure":
      return 'white'
    case "appointment":
      return GlobalStyles.global.black;
    case "side_effect":
      return GlobalStyles.global.black;
    case "reminder":
      return GlobalStyles[APP_SHORTCUT_NAME].color;
    case "headache":
      return GlobalStyles.global.black;
    case "form":
    case "nightwatch_report":
      return 'white'
    case "program_action":
      return GlobalStyles.global.actionGreen;
    default:
      return colors.primary;
  }
};

export const getDotsColor = (type: string, themeColors: ThemeColors) => {
  switch (type) {
    case "seizure":
      return '#FB667A'
    case "appointment":
      return '#1DE9B6';
    case "side_effect":
      return '#ffd039';
    case "reminder":
      return GlobalStyles[APP_SHORTCUT_NAME].color;
    case "headache":
      return GlobalStyles.migraine.color;
    case "form":
      return "#3964AE";
    case "program_action":
      return GlobalStyles.global.actionGreen;
    case "nightwatch_report":
      return "#8c7fe5"
    default:
      return themeColors.primary;
  }
}

export const getContainerBackgroundColor = (type: string) => {
  const { colors } = useTheme();
  return type === 'nightwatch_report' ? 
    '#8c7fe5' : 
    type === 'headache' ? 
    GlobalStyles.migraine.color : 
    type === 'side_effect' ? 
    '#ffd039': 
    type === 'seizure' ? 
    '#fb667a': 
    type === 'appointment'? 
    '#1de9b6': 
    type === 'other' || type === 'reminder' || type === 'program_actions' ? 
    '#ffffff00': 
    type === 'form' ? 
    '#3964AE' : 
    colors.primary
}

export const getListCMSData = (t, key, list) => {
    // @ts-ignore
    let index = isArray(t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true})) && t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
      Object.keys(itemType).toString() === key)
    if (index > -1) 
      return t('translation:'+list+'.'+index+'.'+key)
    else 
      return key
}

export const defineFormTitle = (form, lang:string, t:TFunction) => {
    if (!form)
      return
    if (form.title)
      return form.title[lang] || form.title.en
    else if (form.label)
      return t(`translation:forms.${form.label}.title`)
    else
      return t(`translation:forms.${form.name}.title`)
}

export const defineActionTitle = (actionTitle:Record<string, string>, lang: string, t:TFunction) => {
    if(actionTitle)
      return actionTitle[lang] || actionTitle.en
    else 
      return t('translation:general.empty_title')
}

export const getIcon = (type: string) => {
  switch (type) {
    case "seizure":
      return "zap";
    case "appointment":
      return "calendar";
    case "other":
      return "edit";
    case "reminder":
      return "plus-square";
    case "nightwatch":
      return "moon";
    case "headache":
      return "meh";
    case "form":
      return "clipboard";
    case "program_action":
      return "target"
    case "nightwatch_report":
      return "moon"
    default:
      return "thermometer";
  }
};

export const getIconColor = (type: string) => {
  const { colors } = useTheme();
  switch (type) {
    case "seizure":
      return "#fb667a";
    case "appointment":
      return '#1DE9B6';
    case "side_effect":
      return '#ffd039';
    case "reminder":
      return GlobalStyles[APP_SHORTCUT_NAME].color;
    case "headache":
      return GlobalStyles.migraine.color;
    case "form":
      return "#3964AE";
    case "program_action":
      return GlobalStyles.global.actionGreen;
    case "nightwatch_report":
      return "#8c7fe5"
    default:
      return colors.primary;
  }
};

export const getMoodSleepColor = (value:number) => {
  if (typeof(value) !== 'number')
    return '#0079ff'
  else if(value < 0.4)
    return '#fb667a'
  else if(value >= 0.7)
    return '#1de9b6'
  else if(value >= 0.4 && value <=0.6)
    return '#ffd039'
  else
    return '#0079ff'
}