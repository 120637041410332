import { call, takeLatest, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { 
    DigaRegistrationActions,
    CheckEmailInterface,
    VerifyDigaCodeInterface,
    RegistePatientToProgramInterface
} from "./digaRegistrationActions";

export function* checkEmailExistanceSaga(payload: AnyAction & CheckEmailInterface) {
    yield call(makeRequest, {
        path: `/auth/checkemail`,
        method: 'POST',
        data: {
            email: payload.email
        },
        ...payload
    });
}

export function* verifyDigaCodeSaga(payload: AnyAction & VerifyDigaCodeInterface) {
    yield call(makeRequest, {
        path: `/verify/diga/code`,
        method: 'POST',
        data: {
            digaCode: payload.digaCode
        },
        ...payload
    });
}

export function* registerPatientToProgramSaga(payload: AnyAction & RegistePatientToProgramInterface) {
    yield call(makeRequest, {
        path: `/programs/${payload.programId}/patient`,
        method: 'PATCH',
        data: {
            patient: payload.patientId
        },
        ...payload
    })
}

export default function* watch() {
    yield takeLatest(DigaRegistrationActions.CHECK_EMAIL_EXISTANCE, checkEmailExistanceSaga);
    yield takeLatest(DigaRegistrationActions.VERIFY_DIGA_CODE, verifyDigaCodeSaga);
    yield takeLatest(DigaRegistrationActions.REGISTER_PATIENT_TO_PROGRAM, registerPatientToProgramSaga);
}