import React, {useState} from 'react';
import { Platform} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonReminderView from 'common/src/components/CommonReminderView';
import {useTranslation} from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateRemindersAction } from 'common/src/store/actions/authenticationActions';
import { getUniqueEventAction } from 'common/src/store/treatments/treatmentsActions';
import { DateTime } from "luxon";
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const ReminderView = ({navigation, route}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [scrollState, setScrollState] = useState<any>();
  const today = DateTime.now();

  const updateValues = (data) => {
    let findMoment:any = Object.keys(route.params.reminder_settings).find(item => item === Object.keys(data).toString())
    route.params.reminder_settings[findMoment] = Object.values(data)[0]
    dispatch(updateRemindersAction({
      data:{settings:route.params.reminder_settings}, 
      navigation:navigation, msg:t('translation:treatment.intakes.success.edit_reminders'),
      callback: () => {
          let dateObj = {from:today.startOf('day').toUTC().toISO(), to:today.endOf('day').toUTC().toISO()}
          dispatch(getUniqueEventAction(dateObj));
      }
    }))
  }
  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader
        testIDTitle="reminder_view"
        title={t('translation:general.page_title.edit_reminder')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.migraine.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
        scroll={scrollState}
      />
      <CommonReminderView navigation={navigation} route={route} setScroll={setScrollState} onClick={updateValues}/>
    </CommonKeyboardAvoidingView>
  )
}

export default ReminderView;