import React from "react";
import { Linking, Platform, SafeAreaView, StyleSheet, View } from "react-native";
import { useTheme } from '@react-navigation/native';
import { APP_SHORTCUT_NAME } from "common/src/env.json";
import { useTranslation } from "react-i18next";
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import Text from "common/src/components/CommonCustomTxt";
import CommonHeader from "common/src/components/CommonHeader";
import GlobalStyles from "common/src/styles/GlobalStyles";
import CommonImage from "common/src/components/CommonImage";
import CommonBtn from "common/src/components/CommonBtn";

type OwnProps = {
    navigation: NavigationProp<ParamListBase>;
}

const PatientOrganisation = ({navigation}: OwnProps) => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const styles = generateStyleSheet(colors);
    const imageUri = t("translation:headache_event.patient.organisation.image.uri");
    const urlPatientOrganisation = t("translation:headache_event.patient.organisation.btn.url");

    const onBtnClick = () => {
        Platform.OS === 'web' ? window.open(urlPatientOrganisation, '_blank') : Linking.openURL(urlPatientOrganisation)
    }
    return (
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="patient_organisation_title"
                title={t("translation:headache_event.patient.organisation.page.title")}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles[APP_SHORTCUT_NAME].color}
                leftClick={() => navigation.goBack()}
            />
            <View style={styles.bodyWrapper}>
                <CommonImage name="" uri={imageUri} height={100} width={100} />
                <Text testID="body_text_id" style={styles.bodyTxt}>{t("translation:headache_event.patient.organisation.page.text")}</Text>
                <View style={{marginBottom: PR(20)}} />
                <CommonBtn testID="patient_organisation_btn_id" type='primary' click={onBtnClick} title={t("translation:headache_event.patient.organisation.page.btn")}/>
            </View>
        </SafeAreaView>
    )
}

const generateStyleSheet = (colors:Record<string, string>) => {
    return StyleSheet.create({
      container: {
        backgroundColor: colors.background,
        flex: 1,
      },
      bodyWrapper: {
        alignItems:"center", 
        width:"90%", 
        alignSelf:"center"
      },
      bodyTxt: {
        textAlign: "center",
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        fontSize: PR(14)
      }
    })
};

export default PatientOrganisation;