import React from 'react';
import {View, StyleSheet} from 'react-native';
import { useTheme } from '@react-navigation/native';
import CommonImage from './CommonImage';
import GlobalStyles from '../styles/GlobalStyles';
import CommonTitle from './CommonTitle';
import CommonSubTitle from './CommonSubtitle';
import {APP_SHORTCUT_NAME} from '../env.json';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';


interface TitleTypes {
  imageName: string,
  title: string,
  subtitle: string
}

const CommonAddMedicationTitleSection = ({imageName, title, subtitle}:TitleTypes) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);

  return(
    <>
      <View style={styles.titleIcon}>
        <CommonImage name={imageName} width={PR(40)} height={PR(40)}/>
      </View>
      <View style={styles.txtTitleWrapper}>
        <CommonTitle color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} text={title} txtAlign='center' />
        <CommonSubTitle text={subtitle} txtAlign='center' />
      </View>
    </>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    titleIcon: {
      alignSelf:'center', 
      borderWidth:PR(2), 
      borderRadius:PR(30), 
      borderColor: colors.bodyIconContainer, 
      backgroundColor: colors.bodyIconContainer,
      padding: PR(10),
      marginTop: PR(20)
    },
    txtTitleWrapper: {
      width: '90%',
      alignSelf: 'center',
      paddingTop: PR(20)
    }
  })
}

export default CommonAddMedicationTitleSection;