import React, {useState} from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useTheme } from '@react-navigation/native';
import Text from './CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';
import GlobalStyles from '../styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import {translate, convertLangCode} from 'common/src/services/translationList';
import moment from 'moment';
import 'moment/min/locales.min';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonMedications = ({med,dos,from,to, id, navigation}) => {
  const { colors } = useTheme();
  const styles: any = generateStyleSheet(colors);
  const {t, i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  const medDefaultReason = APP_SHORTCUT_NAME === 'helpi' ? 'epilepsy' : 'headache'
  moment.locale(lang)
  const [containerCollapse, setContainerCollapse] = useState<boolean>(false);
  let tempUnitArr:any = []

  const calculateDose = (u) => {
    let count = 0
    let unit = u
    dos.forEach(element => {
      if(unit.length > 0 && element.unit === unit){
        count = count + element.dose
        unit = element.unit
      }
    });
    if(count > 0)
      return count + ' ' + translate(t, unit, 'list.units')
    else
      return ''
  }
  
  return (
    <TouchableOpacity 
      onPress={() => setContainerCollapse(!containerCollapse)}
      style={[styles.container,
      {backgroundColor: med.reason === medDefaultReason ? GlobalStyles[APP_SHORTCUT_NAME].color : GlobalStyles.global.yellow}
      ]}
    >
      <View style={styles.wrapper}>
        <View style={styles.medContainer}>
          <View style={{flexDirection:'row', alignItems:'center'}}>
            <Text style={styles.medMomentTxt}>{med.name}</Text>
              {dos !== null && dos !== undefined && dos?.map((item, index) => {
                let findUnit = tempUnitArr.find(itemUnit => itemUnit === item.unit)
                if(findUnit === undefined )
                {
                  tempUnitArr.push(item.unit)
                  return(
                    <Text key={index} style={styles.medDoseAndUnit}> {calculateDose(item.unit)+' '}</Text>
                  )
                }
                })
              }
          </View>
          <View style={{paddingBottom:PR(5)}}>
            <Text style={styles.medTxt}>{translate(t, med.reason, 'list.medication_reasons')}</Text>
          </View>
        </View>
        <View style={styles.iconContainer}>
          <Icon 
            name={containerCollapse ? "chevron-up" : "chevron-down"} 
            size={PR(20)} 
            color={"#fff"} 
          />
        </View>
      </View>
      {
        containerCollapse &&
        <>
          <View style={styles.horizontalDivider}></View>
          <View style={[styles.timeContainer, {backgroundColor:  med.reason === medDefaultReason ? GlobalStyles[APP_SHORTCUT_NAME].color : GlobalStyles.global.yellow}]}>
            <View style={styles.subTimeContainer}>
              <View style={{width:'40%'}}>
                <Text renderAsHtml={true} style={[styles.medTxt, {flex:1}]}>{t('translation:treatment.general.start')} </Text>
              </View>
              <Text style={[styles.timeValueTxt, {flex:1}]}>{moment(from).format("MMM DD, YYYY")}</Text>
            </View>
            <View style={styles.verticalDivider}></View>
            <View style={styles.subTimeContainer}>
              <View style={{width:'40%'}}>
                <Text renderAsHtml={true} style={[styles.medTxt, {flex:1}]}>{t('translation:treatment.general.end')} </Text>
              </View>
              <Text style={[styles.timeValueTxt, {flex:1}]}>{to !== null ?moment(to).format("MMM DD, YYYY"): '-'}</Text>
            </View>
          </View>
          <View style={[styles.medBottomContainer, {borderColor: colors.medBottomContainerBorder}]}>
            {dos.map((item, index) => {
              return (
                <View key={index} style={[styles.medBottomWrapper, {paddingBottom:PR(10)}]}>
                  {item.period === undefined ?
                  <>
                    <Text>{translate(t, item.moment, 'list.moments')}</Text>
                    <Text>{item.dose+' '+translate(t, item.unit, 'list.units')}</Text>
                  </>
                  :
                  <>
                    <Text>{t('translation:treatment.add_medication.step_frequency.custom.timesPer')+' '+item.every+' '+translate(t, item.period, 'list.periodFrequencies')}</Text>
                    <Text>{item.dose+' '+translate(t, item.unit, 'list.units')}</Text>
                  </>
                  }
                </View>
              )
            })}
            <TouchableOpacity onPress={()=> navigation.navigate('MedicationHistory', {id:id})} style={[styles.medBottomWrapper, {paddingTop:PR(10)}]}>
              <View style={{width:'80%'}}>
                <Text renderAsHtml={true} style={styles.manageEditTxt}>{t('translation:treatment.general.manage_and_edit')}</Text>
              </View>
              <View style={{width:'20%', alignItems:'flex-end'}}>
                <Icon 
                  name={'edit-2'} 
                  size={PR(20)} 
                  color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} 
                />
              </View>
            </TouchableOpacity>
          </View>
        </>
      }
    </TouchableOpacity>
  )
}

const generateStyleSheet = (colors:Record<string, string>) => {
  return StyleSheet.create({
    container: {
      borderRadius: PR(20),
      width:'90%',
      alignSelf:'center', 
      marginBottom:PR(10),
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.10,
      shadowRadius: 13.97,
      elevation: 10,
    },
    wrapper: {
      flexDirection: 'row',
      alignItems:'center',
    },
    medContainer: {
      flex:1,
      minHeight: PR(62),
      padding: PR(10),
      fontSize: PR(16),
      width: '80%',
    },
    medBottomContainer: {
      backgroundColor: colors.background, 
      borderWidth: PR(1), 
      borderBottomLeftRadius: PR(20), 
      borderBottomRightRadius: PR(20), 
      padding:PR(20),
      flexDirection: 'column',
      shadowColor: colors.shadowColor,
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.10,
      shadowRadius: 13.97,
      elevation: 10,
    },
    medBottomWrapper:{
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems:'center'
    },
    timeContainer: {
      flexDirection: 'row',
    },
    subTimeContainer: {
      paddingTop: PR(10),
      paddingBottom: PR(10),
      paddingLeft: PR(10),
      paddingRight: PR(10),
      width: '50%',
      flexDirection: 'row',
    },
    medMomentTxt: {
      marginLeft: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyles.global.fontFamily.Medium,
      color: GlobalStyles.global.white,
      paddingBottom:PR(10)
    },
    medDoseAndUnit: {
      fontSize: PR(12),
      fontFamily: GlobalStyles.global.fontFamily.Medium,
      color: GlobalStyles.global.white,
      paddingBottom:PR(10)
    },
    medTxt: {
      color: GlobalStyles.global.white,
      marginLeft: PR(10),
      fontSize: PR(14),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    timeValueTxt: {
      color: GlobalStyles.global.footerGrey,
      fontSize: PR(14),
      fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    manageEditTxt: {
      color: GlobalStyles[APP_SHORTCUT_NAME].btnColor
    },
    iconContainer: {
      width:'20%',
      paddingRight:PR(20),
      alignItems:'flex-end'
    },
    horizontalDivider: {
      borderBottomColor: colors.background,
      borderBottomWidth: PR(1),
    },
    verticalDivider: {
      borderRightColor: colors.background,
      borderRightWidth: PR(1),
    }
  })
}

export default CommonMedications;