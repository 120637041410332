import { RescueObject } from "./rescueReducer";

export enum RescueActions {
    ADD_RESCUE_MEDICATION = "ADD_RESCUE_MEDICATION",
    ADD_RESCUE_MEDICATION_SUCCESS = "ADD_RESCUE_MEDICATION_SUCCESS",
    GET_RESCUE_MEDICATION = "GET_RESCUE_MEDICATION",
    GET_RESCUE_MEDICATION_SUCCESS = "GET_RESCUE_MEDICATION_SUCCESS",
    DELETE_RESCUE_MEDICATION = "DELETE_RESCUE_MEDICATION",
    DELETE_RESCUE_MEDICATION_SUCCESS = "DELETE_RESCUE_MEDICATION_SUCCESS",
    UPDATE_RESCUE_MEDICATION = "UPDATE_RESCUE_MEDICATION",
    UPDATE_RESCUE_MEDICATION_SUCCESS = "UPDATE_RESCUE_MEDICATION_SUCCESS"
}

export interface AddRescueInterface {
    dosage: number,
    name: string,
    unit: string,
    patientID: string,
    callback: () => void;
    errorCallback?: () => void;
}

export interface GetRescueInterface {
    patientID: string,
    callback: (res: RescueObject[]) => void;
    errorCallback?: () => void;
}

export interface DeleteRescueInterface {
    patientID: string,
    rescueID: string
    callback: () => void;
    errorCallback?: () => void;
}

export interface UpdateRescueInterface {
    dosage: number,
    name: string,
    unit: string,
    patientID: string,
    rescueID: string
    callback: () => void;
    errorCallback?: () => void;
}

export const addRescueAction = (payload: AddRescueInterface) => {
    return {
        type: RescueActions.ADD_RESCUE_MEDICATION,
        ...payload
    };
};

export const getRescueAction = (payload: GetRescueInterface) => {
    return {
        type: RescueActions.GET_RESCUE_MEDICATION,
        ...payload
    };
};

export const deleteRescueAction = (payload: DeleteRescueInterface) => {
    return {
        type: RescueActions.DELETE_RESCUE_MEDICATION,
        ...payload
    };
};

export const updateRescueAction = (payload: UpdateRescueInterface) => {
    return {
        type: RescueActions.UPDATE_RESCUE_MEDICATION,
        ...payload
    };
};