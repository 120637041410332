import {call, put, takeLatest, select} from "redux-saga/effects";
import * as types from "../actions";
import {request} from "common/src/services/callService";
import {makeRequest} from "../../services/requestService";
import {ADD_INTAKE_ACTION, ADD_TREATMENT_ACTION, ADD_TREATMENT_SUCCESS, ADD_INTAKE_SUCCESS, GET_UNIQUE_EVENT} from "./treatmentsActions";


export function* makeCustomRequest ({method, path, data, callback, errorCallback, responseAction, medRes, successMessage}) {
    try {
        yield put ({ type: types.LOADING, response:true});
        const response = yield call(request, {data, method, path})
        if (response.success) {
            yield put ({ type: types.LOADING, response:false});
            if (path.includes('intakes')){
                let obj = medRes
                Object.assign(obj, {intake: data});
                if (successMessage) yield put({ type: types.SUCCESS, message: successMessage });
                yield put({ type: responseAction, response: obj });
                // yield put ({ type: types.UPDATE_DIARY_MEDICATION_SUCCESS, response: {medication: medRes, intake: data, taken_date: null}})
            } 
            if (callback) yield call(callback, response.result);
            return response.result
        } else {
            if (errorCallback) yield call(callback);
            if(response.errors.length > 0){
                for (const err of response?.errors) {
                    yield put({ type: types.ERROR, message: err.message});
                }
            } else {
                yield put({type: types.ERROR, message: 'translation:errors.general_error'})
            }
            yield put ({ type: types.LOADING, response:false});
        }
    } catch(error:any) {
        if (errorCallback) yield call(callback);
        yield put({ type: types.LOADING, response:false});
        if (error.message === 'Network request failed' || error.message === 'Failed to fetch') {
            yield put({ type: types.ERROR, message: 'translation:errors.network_error' });
        }
    }
}

export function* addTreatmentSaga(payload:any) {
    const {obj} = payload
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeCustomRequest, {
        data: obj.data,
        path: `/patients/${id}/medications`,
        method: 'POST',
        responseAction: ADD_TREATMENT_SUCCESS,
        ...payload
    })
}

export function* addIntakeSaga(payload:any) {
    const {t, d, obj, msg} = payload
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeCustomRequest, {
        data: obj.data.intake,
        path: `/patients/${id}/medications/${d.id}/intakes`,
        method: 'POST',
        responseAction: ADD_INTAKE_SUCCESS,
        translation: t,
        medRes: d,
        successMessage: 'translation:'+msg,
        ...payload
    })
}

export function* getUniqueEventSaga(payload:any) {
    const state = yield select();
    const id = state.getMe.response.result.patients.id;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = yield call(makeRequest, {
        path: '/patients/'+id+'/events_dates_rework_v2?from='+payload.obj.from+'&to='+payload.obj.to+'&timezone='+timezone,
        method: 'GET',
      });
      if(response) {
        yield put({ type: types.UPDATE_DIARY_MEDICATION_SUCCESS, response: {success: true, result: response} });
      }
}

export default function* watchUserAuthentication() {
    yield takeLatest(ADD_TREATMENT_ACTION, addTreatmentSaga);
    yield takeLatest(ADD_INTAKE_ACTION, addIntakeSaga);
    yield takeLatest(GET_UNIQUE_EVENT, getUniqueEventSaga);
}