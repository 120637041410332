import {call, put} from "redux-saga/effects";
import * as types from "common/src/store/actions";
import {request} from "common/src/services/callService";
import NetInfo, { NetInfoState } from '@react-native-community/netinfo';

interface MakeRequestParams {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    path: string,
    data?: any,
    callback?: any,
    errorCallback?: any,
    responseAction?: any,
    successMessage?: string,
    noLoadingSpinner?: boolean
}

export function* makeRequest ({method, path, data, callback, errorCallback, responseAction, successMessage, noLoadingSpinner}: MakeRequestParams) {
    try {
        yield put ({ type: types.LOADING, response: !noLoadingSpinner ? true : false});
        const response = yield call(request, {data, method, path})
        if (response.success) {
            response.dataSent = data
            yield put ({ type: types.LOADING, response:false});
            if (successMessage) yield put({ type: types.SUCCESS, message: successMessage });
            if (callback) yield call(callback, response.result);
            if (responseAction) yield put({ type: responseAction, response });
            return response.result ? response.result : response
        } else {
            if(response.errors.length > 0){
                for (const err of response?.errors) {
                    yield put({ type: types.ERROR, message: err.message});
                }
            } else {
                yield put({type: types.ERROR, message: 'errors.general_error'});
            }
            if (errorCallback) yield call(errorCallback, response?.errors.length > 0 ? response?.errors[0]?.message : "errors.general_error");
            yield put({ type: types.LOADING, response:false});
        }
    } catch(error:any) {
        if (errorCallback) yield call(errorCallback, error?.message);
        yield put({ type: types.LOADING, response:false});
        if (error.message === 'Network request failed' || error.message === 'Failed to fetch') {
            const networkStatus: NetInfoState = yield NetInfo.fetch();
            if (networkStatus.isConnected) {
                yield put({ type: types.ERROR, message: 'errors.network_error' });
            } else {
                yield put({ type: types.ERROR, message: 'errors.no_network_error' });
            }
            
        }
    }
}