import React, { useState } from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { Svg, Path, G, Ellipse } from 'react-native-svg';
import { useTranslation } from 'react-i18next';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import CommonLabelForm from "common/src/components/CommonAddEvents/CommonLabelForm";

const HeadMapping = ({ updateLeftSelection, updateRightSelection, leftSide, rightSide }) => {
    const { colors } = useTheme();
    const { t } = useTranslation();
    const [topLeft, setTopLeft] = useState(leftSide.indexOf('top_of_head') > -1);
    const [frontTopLeft, setFrontTopLeft] = useState(leftSide.indexOf('forehead') > -1);
    const [frontBottomLeft, setFrontBottomLeft] = useState(leftSide.indexOf('cheek') > -1);
    const [backTopLeft, setBackTopLeft] = useState(leftSide.indexOf('back_of_head') > -1);
    const [backBottomLeft, setBackBottomLeft] = useState(leftSide.indexOf('neck') > -1);
    const [earsLeft, setEarsLeft] = useState(leftSide.indexOf('behind_ear') > -1);
    const [eyesLeft, setEyesLeft] = useState(leftSide.indexOf('behind_eye') > -1);

    const [topRight, setTopRight] = useState(rightSide.indexOf('top_of_head') > -1);
    const [frontTopRight, setFrontTopRight] = useState(rightSide.indexOf('forehead') > -1);
    const [frontBottomRight, setFrontBottomRight] = useState(rightSide.indexOf('cheek') > -1);
    const [backTopRight, setBackTopRight] = useState(rightSide.indexOf('back_of_head') > -1);
    const [backBottomRight, setBackBottomRight] = useState(rightSide.indexOf('neck') > -1);
    const [earsRight, setEarsRight] = useState(rightSide.indexOf('behind_ear') > -1);
    const [eyesRight, setEyesRight] = useState(rightSide.indexOf('behind_eye') > -1);

    const updateFrontTopLeft = () => {
        setFrontTopLeft(!frontTopLeft)
        updateLeftSelection('forehead')
    }

    const updateTopLeft = () => {
        setTopLeft(!topLeft)
        updateLeftSelection('top_of_head')
    }

    const updateFrontBottomLeft = () => {
        setFrontBottomLeft(!frontBottomLeft)
        updateLeftSelection('cheek')
    }

    const updateBackTopLeft = () => {
        setBackTopLeft(!backTopLeft)
        updateLeftSelection('back_of_head')
    }

    const updateBackBottomLeft = () => {
        setBackBottomLeft(!backBottomLeft)
        updateLeftSelection('neck')
    }

    const updateEyesLeft = () => {
        setEyesLeft(!eyesLeft)
        updateLeftSelection('behind_eye')
    }

    const updateEarsLeft = () => {
        setEarsLeft(!earsLeft)
        updateLeftSelection('behind_ear')
    }

    const updateFrontTopRight = () => {
        setFrontTopRight(!frontTopRight)
        updateRightSelection('forehead')
    }

    const updateTopRight = () => {
        setTopRight(!topRight)
        updateRightSelection('top_of_head')
    }

    const updateFrontBottomRight = () => {
        setFrontBottomRight(!frontBottomRight)
        updateRightSelection('cheek')
    }

    const updateBackTopRight = () => {
        setBackTopRight(!backTopRight)
        updateRightSelection('back_of_head')
    }

    const updateBackBottomRight = () => {
        setBackBottomRight(!backBottomRight)
        updateRightSelection('neck')
    }

    const updateEyesRight = () => {
        setEyesRight(!eyesRight)
        updateRightSelection('behind_eye')
    }

    const updateEarsRight = () => {
        setEarsRight(!earsRight)
        updateRightSelection('behind_ear')
    }

    return (
        <View>
            <CommonLabelForm label={t('translation:headache_event.type.location_label')} isOptional={false} />

            <View style={{ width: PR(240), alignSelf: 'center', marginBottom: PR(30) }}>
                {/** LEFT SIDE */}
                <View style={{ position: 'absolute', zIndex: 99, left: PR(13), top: PR(10) }}>
                    <TouchableOpacity style={{ height: PR(38), width: PR(30) }} onPress={updateFrontTopLeft}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(44), top: PR(5) }}>
                    <TouchableOpacity style={{ height: PR(30), width: PR(27) }} onPress={updateTopLeft}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(73), top: PR(13) }}>
                    <TouchableOpacity style={{ height: PR(50), width: PR(30) }} onPress={updateBackTopLeft}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(12), top: PR(50) }}>
                    <TouchableOpacity style={{ height: PR(30), width: PR(22) }} onPress={updateEyesLeft}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(11), top: PR(80) }}>
                    <TouchableOpacity style={{ height: PR(35), width: PR(25) }} onPress={updateFrontBottomLeft}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(42), top: PR(35) }}>
                    <TouchableOpacity style={{ height: PR(25), width: PR(30) }} onPress={updateEarsLeft}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(70), top: PR(65) }}>
                    <TouchableOpacity style={{ height: PR(50), width: PR(30) }} onPress={updateBackBottomLeft}></TouchableOpacity>
                </View>

                {/** RIGHT SIDE */}
                <View style={{ position: 'absolute', zIndex: 99, left: PR(192), top: PR(10) }}>
                    <TouchableOpacity style={{ height: PR(38), width: PR(30) }} onPress={updateFrontTopRight}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(164), top: PR(5) }}>
                    <TouchableOpacity style={{ height: PR(30), width: PR(27) }} onPress={updateTopRight}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(132), top: PR(13) }}>
                    <TouchableOpacity style={{ height: PR(50), width: PR(30) }} onPress={updateBackTopRight}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(200), top: PR(50) }}>
                    <TouchableOpacity style={{ height: PR(30), width: PR(22) }} onPress={updateEyesRight}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(198), top: PR(80) }}>
                    <TouchableOpacity style={{ height: PR(35), width: PR(25) }} onPress={updateFrontBottomRight}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(162), top: PR(35) }}>
                    <TouchableOpacity style={{ height: PR(25), width: PR(30) }} onPress={updateEarsRight}></TouchableOpacity>
                </View>

                <View style={{ position: 'absolute', zIndex: 99, left: PR(135), top: PR(65) }}>
                    <TouchableOpacity style={{ height: PR(50), width: PR(30) }} onPress={updateBackBottomRight}></TouchableOpacity>
                </View>

                <View style={{ zIndex: -1, flex: 1, flexDirection: 'row' }}>
                    <View>
                        <Svg width={PR(108)} height={PR(120)} viewBox="0 0 88 100">
                            {/* #### LEFT SIDE #### */}
                            {/* TOP */}
                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path fill={topLeft ? "#3191FC" : colors.background} d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5z" />
                                <Path d="M36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* FRONT TOP */}
                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30z" opacity=".2" />
                                <Path fill={frontTopLeft ? "#1DE9B6" : colors.background} d="M29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* FRONT BOTTOM */}
                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5z" opacity=".2" />
                                <Path fill={frontBottomLeft ? "#C4D631" : colors.background} d="M28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* BACK TOP */}
                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path fill={backTopLeft ? "#FFD039" : colors.background} d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30z" />
                                <Path d="M29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* BACK BOTTOM */}
                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v100H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44z" opacity=".2" />
                                <Path fill={backBottomLeft ? "#8C7FE5" : colors.background} d="M55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5z" />
                                <Path d="M28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* EARS  */}
                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5z" opacity=".2" />
                                <Path fill={earsLeft ? "#FB667A" : colors.background} d="M36 28l22.5 4 3 22L30 44z" />
                                <Path d="M55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* EYES */}
                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path fill={eyesLeft ? "#D86CE2" : colors.background} d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" />
                            </G>

                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                                <G transform="translate(1 1)">
                                    <G fill={colors.primary} fillRule="nonzero">
                                        <Path d="M17.983 72.566c-1.11.995-2.584 1.543-4.152 1.543s-3.043-.548-4.151-1.543c-.842-.755-.842 1.667 0 2.422 1.923 1.725 1.432 2.988 4.151 2.988 2.72 0 5.277-.95 7.2-2.675.842-.755.842-1.98 0-2.735-.842-.755-2.207-.755-3.048 0z" />
                                        <Path d="M84.771 26.03c0-34.608-79.475-34.608-79.475 0 0 6.381-.657 16.836-.568 27.013C4.743 54.807 2 57.162.5 60c-1.5 2.838 4.582 2.838 4.796 4.977 1.455 7.948 5.279 15.279 11.126 21.145C24.056 93.782 34.205 98 45 98c10.785-.001 23-3 33.5-16.5s6.271-39.485 6.271-55.47zM70.547 83.342c-6.823 6.749-15.894 10.466-25.543 10.467-9.655 0-18.733-3.775-25.564-10.629-6.815-6.838-10.57-15.895-10.57-25.502V27.09c0-31.34 72.259-31.34 72.259 0v30.983c0 9.545-3.758 18.52-10.582 25.268z" />
                                    </G>
                                    <Path stroke={colors.primary} strokeWidth="3.6" d="M83.5 53l-23.89.695L6.5 35.5M57.5 31L35 27M28 43c1.472 9.21.972 15.377-1.5 18.5-2.472 3.123-8.305 4.956-17.5 5.5" />
                                    <Path stroke={colors.primary} strokeWidth="3.6" d="M28 43c4.667-5.864 7-11.531 7-17 0-5.469-1.064-13.27-3.193-23.402M69.11 7.192c-6.026 3.123-9.747 7.326-11.162 12.609C56.532 25.083 57.383 36.15 60.5 53M61 53.5c-2.499 5.42-4.456 10.772-5.871 16.055-1.416 5.282-.625 13.43 2.371 24.445M31.5 94l-4.693-33" />
                                    <Ellipse cx="17.217" cy="53.183" fill={colors.primary} fillRule="nonzero" rx="4.217" ry="4.183" />
                                    <Path fill={colors.primary} fillRule="nonzero" d="M10.647 42.366a2.05 2.05 0 0 0 0 2.99 2.28 2.28 0 0 0 3.124 0c1.574-1.507 4.136-1.506 5.71 0 .432.413.997.62 1.563.62.565 0 1.13-.207 1.562-.62a2.05 2.05 0 0 0 0-2.99c-3.297-3.155-8.661-3.155-11.959 0z" />
                                </G>
                            </G>
                        </Svg>
                    </View>
                    <View>
                        <Svg width={Platform.OS === 'web' ? PR(180) : PR(320)} height={PR(120)} viewBox="0 0 58 100">
                            {/* #### RIGHT SIDE #### */}
                            {/* TOP */}
                            <G fill="none" fillRule="evenodd" transform="scale(-1,1) translate(0, 0)" transform-origin="center">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path fill={topRight ? "#3191FC" : colors.background} d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5z" />
                                <Path d="M36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* FRONT TOP */}
                            <G fill="none" fillRule="evenodd" transform="scale(-1,1) translate(0, 0)" transform-origin="center">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30z" opacity=".2" />
                                <Path fill={frontTopRight ? "#1DE9B6" : colors.background} d="M29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* FRONT BOTTOM */}
                            <G fill="none" fillRule="evenodd" transform="scale(-1,1) translate(0, 0)" transform-origin="center">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5z" opacity=".2" />
                                <Path fill={frontBottomRight ? "#C4D631" : colors.background} d="M28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* BACK TOP */}
                            <G fill="none" fillRule="evenodd" transform="scale(-1,1) translate(0, 0)" transform-origin="center">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path fill={backTopRight ? "#FFD039" : colors.background} d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30z" />
                                <Path d="M29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* BACK BOTTOM */}
                            <G fill="none" fillRule="evenodd" transform="scale(-1,1) translate(0, 0)" transform-origin="center">
                                <Path stroke={colors.background} d="M.5.5h87v100H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44z" opacity=".2" />
                                <Path fill={backBottomRight ? "#8C7FE5" : colors.background} d="M55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5z" />
                                <Path d="M28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* EARS  */}
                            <G fill="none" fillRule="evenodd" transform="scale(-1,1) translate(0, 0)" transform-origin="center">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5z" opacity=".2" />
                                <Path fill={earsRight ? "#FB667A" : colors.background} d="M36 28l22.5 4 3 22L30 44z" />
                                <Path d="M55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                            </G>

                            {/* EYES */}
                            <G fill="none" fillRule="evenodd" transform="scale(-1,1) translate(0, 0)" transform-origin="center">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path fill={eyesRight ? "#D86CE2" : colors.background} d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" />
                            </G>

                            <G fill="none" fillRule="evenodd">
                                <Path stroke={colors.background} d="M.5.5h87v99H.5z" />
                                <Path d="M84.5 54l-22.5.5c-1.757-10.095-2.757-16.928-3-20.5-.636-9.355.636-14.196 0-12.5-1.5 4 3.777-11.083 10-12.5 6.024-1.371 15.5 11 15.5 15v30zM29 44l-18-6c-2-2.773-3-6.44-3-11C9.333 15 17.667 7.167 33 3.5c2 7.144 3 14.977 3 23.5 0 3.033-2.333 8.7-7 17z" opacity=".2" />
                                <Path d="M32.5 4.5c11.1-1.493 19.267-1.826 24.5-1 5.233.826 9.566 2.66 13 5.5-5.53 3.174-9.03 6.507-10.5 10-1.47 3.493-2.304 7.66-2.5 12.5L36 28l-.866-12.531L32.5 4.5zM36 28l22.5 4 3 22L30 44zM55 78.5L60.5 55 83 54c1.833 13.333-.333 22.667-6.5 28-6.167 5.333-12.167 9.667-18 13L55 78.5zM28 63.5L8 68c3.5 8.667 8.333 15.667 14.5 21s9.5 7.333 10 6L28 63.5z" opacity=".2" />
                                <Path d="M30 44c.667 13.333-2.5 20.833-9.5 22.5-7 1.667-11.167 2.167-12.5 1.5V36.5L30 44z" opacity=".2" />
                                <G transform="scale(-1,1) translate(1, 1)" transform-origin="center">
                                    <G fill={colors.primary} fillRule="nonzero">
                                        <Path d="M17.983 72.566c-1.11.995-2.584 1.543-4.152 1.543s-3.043-.548-4.151-1.543c-.842-.755-.842 1.667 0 2.422 1.923 1.725 1.432 2.988 4.151 2.988 2.72 0 5.277-.95 7.2-2.675.842-.755.842-1.98 0-2.735-.842-.755-2.207-.755-3.048 0z" />
                                        <Path d="M84.771 26.03c0-34.608-79.475-34.608-79.475 0 0 6.381-.657 16.836-.568 27.013C4.743 54.807 2 57.162.5 60c-1.5 2.838 4.582 2.838 4.796 4.977 1.455 7.948 5.279 15.279 11.126 21.145C24.056 93.782 34.205 98 45 98c10.785-.001 23-3 33.5-16.5s6.271-39.485 6.271-55.47zM70.547 83.342c-6.823 6.749-15.894 10.466-25.543 10.467-9.655 0-18.733-3.775-25.564-10.629-6.815-6.838-10.57-15.895-10.57-25.502V27.09c0-31.34 72.259-31.34 72.259 0v30.983c0 9.545-3.758 18.52-10.582 25.268z" />
                                    </G>
                                    <Path stroke={colors.primary} strokeWidth="3.6" d="M83.5 53l-23.89.695L6.5 35.5M57.5 31L35 27M28 43c1.472 9.21.972 15.377-1.5 18.5-2.472 3.123-8.305 4.956-17.5 5.5" />
                                    <Path stroke={colors.primary} strokeWidth="3.6" d="M28 43c4.667-5.864 7-11.531 7-17 0-5.469-1.064-13.27-3.193-23.402M69.11 7.192c-6.026 3.123-9.747 7.326-11.162 12.609C56.532 25.083 57.383 36.15 60.5 53M61 53.5c-2.499 5.42-4.456 10.772-5.871 16.055-1.416 5.282-.625 13.43 2.371 24.445M31.5 94l-4.693-33" />
                                    <Ellipse cx="17.217" cy="53.183" fill={colors.primary} fillRule="nonzero" rx="4.217" ry="4.183" />
                                    <Path fill={colors.primary} fillRule="nonzero" d="M10.647 42.366a2.05 2.05 0 0 0 0 2.99 2.28 2.28 0 0 0 3.124 0c1.574-1.507 4.136-1.506 5.71 0 .432.413.997.62 1.563.62.565 0 1.13-.207 1.562-.62a2.05 2.05 0 0 0 0-2.99c-3.297-3.155-8.661-3.155-11.959 0z" />
                                </G>
                            </G>
                        </Svg>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default HeadMapping